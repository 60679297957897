import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardDataAction } from "../../Actions/CREAction";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut ,Bar} from "react-chartjs-2"
import Loading from "../../Components/Loading";
import moment from "moment";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);
const CreHomePage = () => {
	const navigate = useNavigate();
	const [onSiteVisitor, setOnSiteVisitor] = useState([]);
	const [cpVisit, setCpVisit] = useState(0);
	const [refVisit, setRefVisit] = useState(0);
	const [directVisit, setDirectVisit] = useState(0);
	const dispatch = useDispatch();
	const { loading, error, creDashboardData } = useSelector(
		(state) => state?.cre?.dashboardData
	);
	const {userInfo } = useSelector(
		(state) => state?.userState
	);

	useEffect(() => {
		if (creDashboardData !== undefined) {
			setCpVisit(creDashboardData?.cp_visit);
			setDirectVisit(creDashboardData?.direct_visit);
			setOnSiteVisitor(
				creDashboardData?.onsite_visitor?.map((d) => {
					return {
						id: d.id,
						img: d.img,
						name: d.name,
						checkInTime: d.check_in_time,
						assigned: d.sales_executive !== null,
						assignedTo: d.sales_executive,
						fromSource: false,
						source: d.source,
					};
				})
			);
		}
	}, [creDashboardData]);

	useEffect(() => {
		dispatch(getDashboardDataAction());
	}, []);


	function roundUpToNearestTen(value) {
		return Math.ceil(value / 10) * 13;
	}

	const options = {
		responsive: true,
		barThickness: 20,
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				display: false,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
				position: "bottom",
			},
			title: {
				display: false,
				text: "Chart.",
			},
			datalabels: {
				display: true,
				color: "black",
				formatter: Math.round,
				anchor: "end",
				offset: directVisit+ cpVisit+ refVisit > 0? 0 : -20,
				align: "start",
			},
		},
	};

	const data = {
		labels: ["Direct", "Channel Partner", "Reference"],
		datasets: [
			{
				label: "Visitors",
				data: [directVisit, cpVisit, refVisit],
				// data: [4, 6, 5],
				backgroundColor: [
					"rgba(255, 193, 7, 0.2)",
					"rgba(9, 255, 7, 0.2)",
					"rgba(127, 3, 252 , 0.2)",
				],
				borderColor: [
					"rgba(255, 193, 7, 1)",
					"rgba(9, 255, 7, 1)",
					"rgba(127, 3, 252, 1)",
				],
				borderWidth: 1,
			},
		],
	};
	if (loading) {
		return (
			<>
				<Layout pageTitle={"Dashboard"}>
					<div className="main_container">
						<div className="d-flex justify-content-center align-items-center h-100">
							<Loading />
						</div>
					</div>
				</Layout>
			</>
		);
	}
	return (
		<Layout pageTitle={"Dashboard"}>
			<div className=" w-100 ps-md-0 mt-n4 ">
				<div className="bg-accent py-1 py-3 m-0  w-100 rounded-bottom-4">
					<div className="row h-100  my-3">
						<div className="col-1"></div>
						<div className="col-3 col-lg-2 ps-4">
							<div className="d-flex justify-content-center ">
								<img
									className="img-thumbnail rounded-circle"
									src={"https://api.eazyapp.in" + userInfo?.image}
									alt=""
								/>
							</div>
						</div>
						<div className="col-7">
							<div className="">
								<p className="text-capitalize fw-bold fs-5 text-white p-0 m-0">
									{userInfo?.name}{" "}
								</p>
								<p className="text-white-50 p-0 m-0 fs-9">{userInfo?.email} </p>
								<p className="text-white p-0 m-0 fs-9">
									{userInfo?.designation}{" "}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="" style={{ height: "90%", overflow: "scroll" }}>
				<div className="m-3 border shadow rounded ">
					<div>
						<div className="row">
							<div className="col-12 ">
								<h4 className="section_heading text-center mt-3 my-2">
									Visitors : {moment().format("DD-MM-YYYY")}
								</h4>
							</div>
							<div className="col-3 p-4 d-flex justify-content-center align-items-center">
								<p className="display-2 ">{directVisit + cpVisit + refVisit}</p>
							</div>
							<div className="col-9  p-4">
								<div className="d-flex justify-content-center align-items-center h-100 w-100">
									<Bar options={options} data={data} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="w-100 rounded-top-5  pt-3  bg-faded-info"
					style={{ height: "100%" }}
				>
					<div className="text-center my-3 " style={{ height: "10%" }}>
						<h5 className="text-muted section_heading ">Onsite Visitors</h5>
					</div>
					<div className="" style={{ height: "90%", overflow: "scroll" }}>
						<div className="row px-2 ms-1 w-100 d-flex justify-centent-center  gap-3">
							{onSiteVisitor?.length === 0 && (
								<div className="col-lg-5 shadow border rounded p-3">
									<h1 className="my-5 text-center">
										On Site Visitors Not Available
									</h1>
								</div>
							)}
							{onSiteVisitor?.map((d) => {
								return (
									<div className="col-md-5  border rounded p-2 bg-white shadow">
										<div className="d-flex">
											<img
												src={"https://api.eazyapp.in" + d.img}
												alt=""
												className="me-3  img-thumbnail"
												style={{ height: "100px", width: "100px" }}
											/>
											<div className=" ">
												<h4 className="p-0 m-0 card_heading">{d.name}</h4>
												<p className="p-0 m-0 text-muted ">
													{moment(d.checkInTime).fromNow()}
												</p>

												{d.fromSource && (
													<p className="p-0 m-0">
														<span>Channel partner:</span> {d.source}
													</p>
												)}
												{d.assigned ? (
													<p className="m-0 p-0"> Assigned to: {d.assignedTo}</p>
												) : (
													<button
														className="btn btn-warning d-block w-100"
														onClick={() => {
															navigate(
																`/check-in/${d.id}/assign-sales-executive`
															);
														}}
													>
														Assign
													</button>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default CreHomePage;
