import CPDashboard from "./CPDashboard";
import CPLeads from "./CPLeads";
import CPLead from  "./CpLead"
import ProjectSearch from "./ProjectSearch";
import AddLeads from "./AddLeads";
import MyBucket from "./MyBucket";
import ProjectDetail from "./ProjectDetail";
import Colaterals from "./Colaterals";
import Layout from "./Layout/Layout";
import Profile from "../../Profile";
import ManageEmployees from "./ManageEmployees";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import EditProfile from "../EditProfile";
const CPProfile = () => {
	return (
		<Layout pageTitle={"Profile"}>
			<Profile />
		</Layout>
	);
};

const CPEditProfile=()=>{
	return	(
		<Layout pageTitle={"Edit Profile"} >
			<EditProfile />
		</Layout>
	)
}
export {
	CPDashboard,
	CPLeads,
	CPLead,
	ProjectSearch,
	AddLeads,
	MyBucket,
	ProjectDetail,
	Colaterals,
	ManageEmployees,
	AddEmployee,
	EditEmployee,
	CPProfile,
	CPEditProfile,
};
