import React, { useState } from 'react'
import { forgotPasswordAction } from '../../Actions/UserAction';
import { useDispatch } from 'react-redux';
const ForgotPassword = ({ setShowForgotPassword }) => {
  const dispatch = useDispatch();
  const submitHandler=(event)=>{
    event.preventDefault()
    const formData=new FormData(event.target)
    dispatch(forgotPasswordAction(formData.get("email")))
  }

  return (
    <div  className='p-5' >
      <form onSubmit={submitHandler}>
        <div className="d-grid gap-3">
          <div>
            <h2>Forgot Password</h2>
            <p>Enter your email and we'll send you a link to reset your password.</p>
          </div>
        <div>
          <label htmlFor="email">Email</label>
          <input type="email" id="email"  name="email" className="form-control" placeholder='Enter Email' />
        </div>
          <div className="text-end">
            <span className="text-primary  " style={{ cursor: "pointer" }} onClick={() => setShowForgotPassword(false)}>Login?</span>
          </div>
        <div className="text-end">
          <button className="btn btn-warning">Submit</button>
        </div>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
