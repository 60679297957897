import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ButtonType = ({ obj, index, key, buttonsHandler }) => {
    const [data, setData] = useState(obj)
    const [urlType, setUrlType] = useState("static")
    useEffect(() => {
        buttonsHandler(data, index)
    },[data])
    switch (obj.type) {
        case "QUICK_REPLY":
            return <div>
                <label htmlFor="text">Button Text</label>
                <input type="text" name="text" id='text' placeholder='Enter Button Text' className='form-control' onChange={(e) => {
                    const newData = {...data}
                    newData.text = e.target.value
                    setData(newData)
                }} />
            </div>
        case "URL":
            return <div className='d-grid gap-2' >
                <div>
                    <label htmlFor="text">Button Text</label>
                    <input type="text" name="text" id='text' placeholder='Enter Button Text' className='form-control' onChange={(e) => {
                        const newData = {...data}
                        newData.text = e.target.value
                        setData(newData)
                    }} />
                </div>
                <div>
                    <label htmlFor="url">URL</label>
                    <input type="text" name="url" id='url' placeholder='Enter URL' className='form-control' onChange={(e) => {
                        const newData = {...data}
                        newData.url = e.target.value
                        setData(newData)
                    }} />
                </div>
                <div>
                    <select name="url_type" id="url_type" className='form-select' value={urlType} onChange={(e) => setUrlType(e.target.value)} >
                        <option value="static">Static</option>
                        <option value="dynamic">Dynamic</option>
                    </select>
                </div>
                {
                    urlType == "dynamic" && <div>
                        <label htmlFor="url_dynamic">URL Dynamic</label>
                        <input type="text" name="url_dynamic" id='url_dynamic' placeholder='Enter URL Dynamic Example' className='form-control' onChange={(e) => {
                            const newData = {...data}
                            newData.example = [e.target.value]
                            setData(newData)
                        }} />
                    </div>
                }
            </div>
        case "PHONE_NUMBER":
            return <div>
                <div>
                    <label htmlFor="text">Button Text</label>
                    <input type="text" name="text" id='text' placeholder='Enter Button Text' className='form-control' onChange={(e) => {
                        const newData = {...data}
                        newData.text = e.target.value
                        setData(newData)
                    }} />
                </div>
                <div>
                    <label htmlFor="phone_number">Phone Number</label>
                    <input type="text" name="phone_number" id='phone_number' placeholder='Enter Phone Number' className='form-control' onChange={(e) => {
                        const newData = {...data}
                        newData.phone_number = e.target.value
                        setData(newData)
                    }} />
                </div>
            </div>
        case "COPY_CODE":
            return <div>
                <label htmlFor="example">Offer Code</label>
                <input type="text" name="example" id='example' placeholder='Enter Offer Code' className='form-control' onChange={(e) => {
                    const newData = {...data}
                    newData.example = e.target.value
                    setData(newData)
                }} />
            </div>
        default:                                                                                                                                                                                                                                                                                                                                                                                        
            return <></>
    }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
}                   

const AddButton = ({ whatsappDataHandler, dataToShow }) => {                    
    const [buttons, setButtons] = useState({
        type: "BUTTONS",
        buttons: []
    })                                

    useEffect(() => {
        if(buttons.buttons.length > 0){
            whatsappDataHandler(buttons)
            dataToShow({ type: "buttons", value: buttons?.buttons != undefined ? buttons.buttons : [] })
        }
    }, [buttons])

    const buttonsHandler = (e, index) => {
        const data = {...buttons}
        data.buttons[index] = e
        setButtons(data)
    }
    if (buttons){
        return (
            <div>
                <Dropdown
                    className=" "
                    style={{ zIndex: "1" }}
                >
                    <Dropdown.Toggle
                        className="hide-arrow"
                        variant=""
                        id="dropdown-basic"
                    >
                       Add Button <FontAwesomeIcon icon={icon.faAdd} />
                    </Dropdown.Toggle>
    
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                var data = {...buttons}
                                console.log(data ,  "before")
                                data["buttons"].push({ type: "QUICK_REPLY" })
                                console.log(data , "after"  )
                                setButtons(data)
                            }}
                        >
                            Add Quick Reply Button
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                var data = {...buttons}
                                data.buttons.push({ type: "URL" })
                                setButtons(data)
                            }}
                        >
                            Add URL Button
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                var data = {...buttons}
                                data.buttons.push({ type: "PHONE_NUMBER" })
                                setButtons(data)
                            }}
                        >
                            Add Phone Number Button
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                var data = {...buttons}
                                data.buttons.push({ type: "COPY_CODE" })
                                setButtons(data)
                            }}
                        >
                            Add Copy Code Button
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='d-grid gap-4  ' >
                    {
                        buttons.buttons?.map((obj, index) => {
                            return <div className='d-flex p-2 rounded border shadow-sm  ' >
                                <div className=' d-grid p-3' >
                                    <Icon.ChevronBarUp  onClick={()=>{
                                        const data = {...buttons}
                                        data.buttons.move(index, index - 1)
                                        setButtons(data)
                                    }}  />
                                    <br />
                                    <br />
                                    <Icon.ChevronBarDown onClick={() => {
                                        const data = {...buttons}
                                        data.buttons.move(index, index + 1)
                                        setButtons(data)
                                    }} />
                                </div>
                                <div className='flex-fill' > 
                                    <ButtonType obj={obj} index={index} key={index} buttonsHandler={buttonsHandler} />
                                </div>
                            </div>
                        })
                    }
                </div>
    
            </div>
        )
    }

}

export default AddButton
