import React, { useState } from "react";
import Layout from "./CPScreens/Layout/Layout";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/eazyapp-logo-blue.png";
import * as Icon from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { changePassword } from "../Actions/CommonAction";
import { useDispatch } from "react-redux";

const PasswordInput = ({ name, label }) => {
	const [showpassword, setShowpassword] = useState(false);
	return (
		<div className="">
			<label className="label">{label}</label>
			<div className="input-group border border-top-0 border-end-0 border-start-0">
				<input
					type={showpassword ? "text" : "password"}
					name={name}
					className="form-control password-field border-0 "
					placeholder="**********"
				/>
				<span
					className="btn btn-white h-100  rounded-start-0 "
					type="button"
					onClick={() => {
						setShowpassword(!showpassword);
					}}
				>
					{showpassword ? <Icon.EyeSlash /> : <Icon.Eye />}{" "}
				</span>
			</div>
		</div>
	);
};

const PasswordChanger = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { employeeId } = useParams();
	const queryParameters = new URLSearchParams(window.location.search);
	const reason = queryParameters.get("reason");
	const getPageTitle = (reason) => {
		switch (reason) {
			case "FP":
				return "Forgot Password";
			case "SNP":
				return "Set New Password";
			case "RP":
				return "Reset Password";
			default:
				navigate("/");
				return "";
		}
	};
	const SubmitEvent = (e) => {
        e.preventDefault();
		const formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
        if (object.password !== object.confirmPassword){
            toast.error("Password Does not Match")
            return
        }
        delete object.confirmPassword
		dispatch(changePassword(employeeId, object, navigate));
        // alert(JSON.stringify(object));
	};
	return (
		<div>
			<div
				className=" d-flex justify-content-center align-items-center vh-100 bg-white h-100 w-100 "
				// style={{ height: "90vh !important" }}
			>
				<form className="d-grid gap-3 w-75" onSubmit={SubmitEvent}>
					<div>
						<img style={{ height: "100px", width: "auto" }} src={Logo} alt="" />
						<p className="text-dark h1 my-4 ">{getPageTitle(reason)}</p>
						{/* <p className="m-0"></p> */}
					</div>
					{/* <input type="text" name="employee_id" value={employeeId} hidden /> */}
					<PasswordInput name={"password"} label={"Password"} />
					<PasswordInput name={"confirmPassword"} label={"Confirm Password"} />
					<div className="text-end">
						<button className="btn btn-warning">Submit</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PasswordChanger;
