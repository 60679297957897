import React,{useEffect,useState} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { useDispatch,useStore } from 'react-redux'
import Layout from '../../Layout/Layout'
import axios from 'axios'
import { addMandateCompany } from '../../../../Actions/MyAdminAction'
import toast from 'react-hot-toast'
const AddMandateCompany = () => {
    const [mandate,setMandate]=useState();
    const [mandateCompanyList,setMandateCompanyList]=useState([]);
    const { getState } = useStore();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {projectId}=useParams();

    const searchHandler = (value, setResponse, api) => {
        if (value === "") {
            return () => {
                
                console.log("Nothing to run");
            };
        }
        return async () => {
            try {
                const {
                    userState: { userInfo },
                } = getState();
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${userInfo?.token}`,
                    },
                };
                const response = await axios.post(
                    api,
                    { searched_term: value,project_id:projectId },
                    config
                );
                setResponse(response.data);
            } catch (error) {
                toast.error("Something went wrong");
            }
        };
    };

    const responsHandler = (response) => {
        setMandateCompanyList(response.data.map((item) => {return{...item, in_project: response.in_project.includes(item.id)}}));
    };
    useEffect(() => {
        const Debouncer = setTimeout(
            searchHandler(
                mandate,
                responsHandler,
                `https://api.eazyapp.in/api/admin/search-mandate-company`
            ),
            1000
        );
        return () => clearTimeout(Debouncer);
    }, [mandate]);

  return (
    <Layout pageTitle={"Add Mandate Company"}>
      <div className="d-grid gap-3 p-3">
        <div>
          <input type="text" name="project_id" value={projectId} hidden />
          <label>Serach for Name Of Mandate Company or Rera Number</label>
          <input
            className="form-control"
            type="text"
            name="name"
            onChange={(e)=>setMandate(e.target.value)}
          />
        </div>
        {
                  mandateCompanyList.map((item) => {
                    return (
                        <>
                        <div className="border rounded shadow-sm p-3">
                            <div className="d-flex gap-2">
                                <img
                                    style={{ height: "64px", width: "64px" }}
                                    src={"https://api.eazyapp.in" + item.logo}
                                    alt=""
                                />
                                <div className="d-grid place-items">
                                    <div>
                                        {item.name}
                                    </div>
                                    <div>
                                        {item.rera_number}
                                    </div>
                                    <div>
                                            {item.in_project ? <span className='bg-success btn text-white' >Already In Project</span> :
                                                <button className="btn btn-primary" onClick={() => {
                                                    const postData = {
                                                        project_id: projectId,
                                                        mandate_company_id: item.id
                                                    }
                                                    dispatch(addMandateCompany(postData, navigate))
                                                }}  >Add To Project</button>
                                            }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )
                  })
        }
        <div className="text-end">
            <button className='btn btn-dark'  onClick={() => navigate(-1)} > Cancel </button>
        </div>
      </div>
    </Layout>
  )
}

export default AddMandateCompany
