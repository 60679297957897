import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { get_projects } from '../../../Actions/CommonAction'
import { saveCheckInData } from "../../../Actions/CREAction";
const Requirements = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const projects=useSelector(state=>state?.project?.projects)
	useEffect(()=>{
		dispatch(get_projects());
	},[])
	const { checkInData } = useSelector((state) => state?.cre?.checkInDetial);
	const [configs, setConfigs] = useState([
		"1Rk",
		"1Bhk",
		"2Bhk",
		"3Bhk",
		"4Bhk",
	]);
	const [propertyType, setPropertyType] = useState("residential");
	const budgetOption = [
		{ minPrice: 0, maxPrice: 2500000, diff: 2500000, label: "Up to 25 lakhs" },
		{
			minPrice: 2500000,
			maxPrice: 5000000,
			diff: 2500000,
			label: "25 lakhs - 50 lakhs",
		},
		{
			minPrice: 5000000,
			maxPrice: 7500000,
			diff: 2500000,
			label: "50 lakhs - 75 lakhs",
		},
		{
			minPrice: 7500000,
			maxPrice: 10000000,
			diff: 2500000,
			label: "75 lakhs - 1 crore",
		},
		{
			minPrice: 10000000,
			maxPrice: 15000000,
			diff: 5000000,
			label: "1 crore - 1.5 crores",
		},
		{
			minPrice: 15000000,
			maxPrice: 20000000,
			diff: 5000000,
			label: "1.5 crores - 2 crores",
		},
		{
			minPrice: 20000000,
			maxPrice: 25000000,
			diff: 5000000,
			label: "2 crores - 2.5 crores",
		},
		{
			minPrice: 25000000,
			maxPrice: 30000000,
			diff: 5000000,
			label: "2.5 crores - 3 crores",
		},
		{
			minPrice: 30000000,
			maxPrice: 35000000,
			diff: 5000000,
			label: "3 crores - 3.5 crores",
		},
		{
			minPrice: 35000000,
			maxPrice: 40000000,
			diff: 5000000,
			label: "3.5 crores - 4 crores",
		},
		{
			minPrice: 40000000,
			maxPrice: 45000000,
			diff: 5000000,
			label: "4 crores - 4.5 crores",
		},
		{
			minPrice: 45000000,
			maxPrice: 50000000,
			diff: 5000000,
			label: "4.5 crores - 5 crores",
		},
		{
			minPrice: 50000000,
			maxPrice: 55000000,
			diff: 5000000,
			label: "5 crores - 5.5 crores",
		},
		{
			minPrice: 55000000,
			maxPrice: 60000000,
			diff: 5000000,
			label: "5.5 crores - 6 crores",
		},
		{
			minPrice: 60000000,
			maxPrice: 65000000,
			diff: 5000000,
			label: "6 crores - 6.5 crores",
		},
		{
			minPrice: 65000000,
			maxPrice: 70000000,
			diff: 5000000,
			label: "6.5 crores - 7 crores",
		},
		{
			minPrice: 70000000,
			maxPrice: 75000000,
			diff: 5000000,
			label: "7 crores - 7.5 crores",
		},
		{
			minPrice: 75000000,
			maxPrice: 80000000,
			diff: 5000000,
			label: "7.5 crores - 8 crores",
		},
		{
			minPrice: 80000000,
			maxPrice: 85000000,
			diff: 5000000,
			label: "8 crores - 8.5 crores",
		},
		{
			minPrice: 85000000,
			maxPrice: 90000000,
			diff: 5000000,
			label: "8.5 crores - 9 crores",
		},
		{
			minPrice: 90000000,
			maxPrice: 95000000,
			diff: 5000000,
			label: "9 crores - 9.5 crores",
		},
		{
			minPrice: 95000000,
			maxPrice: 100000000,
			diff: 5000000,
			label: "9.5 crores - 10 crores",
		},
	];

	useEffect(() => {
		switch (propertyType) {
			case "residential":
				setConfigs(["1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK","Jodi"]);
				return;
			case "commercial":
				setConfigs(["Shop", "Office"]);
				return;
			default:
				setConfigs(["1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK","Jodi"]);
				return;
		}
	}, [propertyType]);

	const submitHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		if (!Object.keys(object).includes("config")) {
			toast.error("Select Atleast 1 config");
			return;
		}
		object["config"]=formData.getAll('config')
		object["project"] = formData.getAll("project");
		object["budget"] = JSON.parse(object.budget);
		Object.keys(object).map((key) => {
			if (object[key] === "" || object[key] === undefined || object[key] === null  ) {
				toast.error(`Please fill ${key}`);
				return
			}
		})
		const postData = { ...checkInData, requirement: object };
		dispatch({
			type: "ADD_CHECKIN_DATA",
			payload: postData,
		});
		if (checkInData.source === undefined){

			navigate("/check-in/source");
		}else{
			dispatch(saveCheckInData(postData, navigate));
		}
	};
	return (
		<div className="h-100 p-3">
			<h2 className="section_heading">Requirements</h2>
			<form action="" className="h-100" onSubmit={submitHandler}>
				<div className="row">
					<div className="col-md-6 pt-3">
						<label>Select Project</label>
						<select className="form-select" name="project" multiple>
							{projects?.map((i) => {
								return (
									<option
										value={i.id}
										key={i.id}
										selected={checkInData?.requirement?.project === i.id}
									>
										<span className="text-capitalize">
											{i.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
												letter.toUpperCase()
											)}
										</span>
									</option>
								);
							})}
						</select>
					</div>
					<div className="col-md-6 pt-3">
						<label>Select Property Type </label>
						<br />
						<div
							class=" d-flex gap-4 "
							role="group"
							aria-label="Basic radio toggle button group"
						>
							<input
								type="radio"
								class="btn-check"
								name="property_type"
								value="Residential"
								id="btnradio1"
								autocomplete="off"
								checked={propertyType === "residential"}
							/>
							<label
								class="btn btn-outline-warning"
								for="btnradio1"
								onClick={() => {
									setPropertyType("residential");
								}}
							>
								Residential
							</label>

							<input
								type="radio"
								class="btn-check"
								name="property_type"
								value="Commercial"
								id="btnradio2"
								autocomplete="off"
								checked={propertyType === "commercial"}
							/>
							<label
								class="btn btn-outline-warning"
								onClick={() => {
									setPropertyType("commercial");
								}}
								for="btnradio2"
							>
								Commercial
							</label>
						</div>
					</div>
					<div className="col-md-6 pt-3">
						<label>Select Required Configurations</label>
						<div
							class=" "
							role="group"
							aria-label="Basic checkbox toggle button group"
						>
							{configs.map((d, index) => {
								return (
									<>
										<input
											type="checkbox"
											class="btn-check"
											id={`btncheck${index}`}
											name="config"
											value={d}
											autocomplete="off"
											defaultChecked={
												checkInData?.requirement?.config !== undefined &&
												checkInData?.requirement?.config.includes(d)
											}
										/>
										<label
											class="btn btn-outline-primary ms-2 mt-2"
											for={`btncheck${index}`}
										>
											{d}
										</label>
									</>
								);
							})}
						</div>
					</div>
					<div className="col-md-6 pt-3">
						<label>Tentative Budget</label>
						<select name="budget" id="" className="form-select">
							{budgetOption.map((d) => {
								return (
									<option
										value={JSON.stringify({
											minPrice: d.minPrice,
											maxPrice: d.maxPrice,
										})}
										selected={
											d.minPrice <= checkInData?.requirement?.budget?.minPrice &&
											d.maxPrice >= checkInData?.requirement?.budget?.maxPrice
										}
									>
										{d.label}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="row mt-5 ">
					<div className="col-md-4"></div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<div className="row">
							<div className="col-6">
								<button type="button" className="btn btn-dark w-100" onClick={() => navigate(-1)} >Back</button>
							</div>
							<div className="col-6">
								<button className="btn bg-accent text-white w-100 ">
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Requirements;
