import {
	GET_SE_DASHBOARD_DATA_REQUEST,
	GET_SE_DASHBOARD_DATA_SUCCESS,
	GET_SE_DASHBOARD_DATA_FAIL,
	//
	VISITOR_ASSIGNED_STATUS_REQUEST,
	VISITOR_ASSIGNED_STATUS_SUCCESS,
	VISITOR_ASSIGNED_STATUS_FAIL,
	//
	GET_VISITOR_DETAIL_REQUEST,
	GET_VISITOR_DETAIL_SUCCESS,
	GET_VISITOR_DETAIL_FAIL,
	//
	GET_VISITORS_REQUEST,
	GET_VISITORS_SUCCESS,
	GET_VISITORS_FAIL,
	//
	GET_VISITORS_DETAIL_REQUEST,
	GET_VISITORS_DETAIL_SUCCESS,
	GET_VISITORS_DETAIL_FAIL,
	//
	GET_MY_TEAM_MEMBERS_REQUEST,
	GET_MY_TEAM_MEMBERS_SUCCESS,
	GET_MY_TEAM_MEMBERS_FAIL,
	//
	GET_CHANNEL_PARTNERS_REQUEST,
	GET_CHANNEL_PARTNERS_SUCCESS,
	GET_CHANNEL_PARTNERS_FAIL,
	//
	GET_DETAIL_CHANNEL_PARTNER_REQUEST,
	GET_DETAIL_CHANNEL_PARTNER_SUCCESS,
	GET_DETAIL_CHANNEL_PARTNER_FAIL,
	//
	GET_INVENTORY_REQUEST,
	GET_INVENTORY_SUCCESS,
	GET_INVENTORY_FAIL,
	//
	GET_CHANNEL_PARTNER_REQUEST,
	GET_CHANNEL_PARTNER_SUCCESS,
	GET_CHANNEL_PARTNER_FAIL,
	// 
	GET_CP_REPORT_REQUEST,
	GET_CP_REPORT_SUCCESS,
	GET_CP_REPORT_FAIL,
	// 
} from "../Constants/SEConstatant";

export const SEDashboardReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_SE_DASHBOARD_DATA_REQUEST:
			return { loading: true };
		case GET_SE_DASHBOARD_DATA_SUCCESS:
			return { loading: false, dashboardData: action.payload };
		case GET_SE_DASHBOARD_DATA_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const visitorStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case VISITOR_ASSIGNED_STATUS_REQUEST:
			return { loading: true };
		case VISITOR_ASSIGNED_STATUS_SUCCESS:
			return { loading: false, ...action.payload };
		case VISITOR_ASSIGNED_STATUS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const visitorDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_VISITOR_DETAIL_REQUEST:
			return { loading: true };
		case GET_VISITOR_DETAIL_SUCCESS:
			return { loading: false, Detail: action.payload };
		case GET_VISITOR_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const visitorsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_VISITORS_REQUEST:
			return { loading: true };
		case GET_VISITORS_SUCCESS:
			return { loading: false, visitors: action.payload };
		case GET_VISITORS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const VisitorsDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_VISITORS_DETAIL_REQUEST:
			return { loading: true };
		case GET_VISITORS_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case GET_VISITORS_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const MyTeamReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MY_TEAM_MEMBERS_REQUEST:
			return { loading: true };
		case GET_MY_TEAM_MEMBERS_SUCCESS:
			return { loading: false, teams: action.payload };
		case GET_MY_TEAM_MEMBERS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const ChannelPartnerReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CHANNEL_PARTNERS_REQUEST:
			return { loading: true };
		case GET_CHANNEL_PARTNERS_SUCCESS:
			return { loading: false, channel_partners: action.payload };
		case GET_CHANNEL_PARTNERS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
export const ChannelPartnerDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DETAIL_CHANNEL_PARTNER_REQUEST:
			return { loading: true };
		case GET_DETAIL_CHANNEL_PARTNER_SUCCESS:
			return { loading: false, channel_partner: action.payload };
		case GET_DETAIL_CHANNEL_PARTNER_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const inventoryReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_INVENTORY_REQUEST:
			return { loading: true }
		case GET_INVENTORY_SUCCESS:
			return { loading: false, data: action.payload }
		case GET_INVENTORY_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}



export const channelPartnerReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CHANNEL_PARTNER_REQUEST:
			return { loading: true }
		case GET_CHANNEL_PARTNER_SUCCESS:
			return { loading: false, detail: action.payload }
		case GET_CHANNEL_PARTNER_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}


export const cpReportReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CP_REPORT_REQUEST:
			return { loading: true }
		case GET_CP_REPORT_SUCCESS:
			return { loading: false, report: action.payload }
		case GET_CP_REPORT_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}