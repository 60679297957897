import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { get_projects, get_project_report } from "../../Actions/CommonAction";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";


const ProjectReport = () => {

	const [showReport, setShowReport] = useState(false);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(
		new Date().toLocaleDateString("en-CA")
	);
	const dispatch = useDispatch();
	const { projects } = useSelector((state) => state?.project);
	const { reports } = useSelector((state) => state?.se?.projectReport);
	useEffect(()=>{
		if(reports !== undefined){
			setShowReport(true)
		}
	},reports)
	useEffect(() => {
		dispatch(get_projects());
	}, []);

	const reportHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		dispatch(get_project_report(formData));
	};

	return (
		<Layout pageTitle={"EazyReport"}>
			<form action="" onSubmit={reportHandler}>
				<div className="d-grid  p-3 m-2 shadow-sm rounded border align-items-center">
					<div>
						<h5>Select Project</h5>
						{projects !== undefined && (
							<select className="form-control" name="project_id" id="">
								{projects.map((project) => {
									return (
										<option value={project.id}>
											<span className="text-capitalize">{project.name}</span>
										</option>
									);
								})}
							</select>
						)}
					</div>
					<div className="my-2">
						<h5>Select Timeline</h5>
						<div className="row">
							<div className="col-md-5">
								<label>From</label>
								<input
									className="form-control"
									type="date"
									placeholder="From"
									name="from"
									onChange={(e) => setFromDate(e.target.value)}
								/>
							</div>
							<div className="col-md-2 d-flex py-2 align-items-end justify-content-center">
								<Icon.DashLg />
							</div>
							<div className="col-md-5">
								<label>To</label>
								<input
									className="form-control"
									type="date"
									defaultValue={new Date().toLocaleDateString("en-CA")}
									placeholder="To"
									name="to"
									onChange={(e) => setToDate(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="my-2 text-center ">
						<button className="btn btn-warning">Genrate Report</button>
					</div>
				</div>
			</form>
			<Modal
				show={showReport}
				fullscreen={true}
				onHide={() => setShowReport(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="p-2">
						<table class="table">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">First</th>
									<th scope="col">Last</th>
									<th scope="col">Last Visit</th>
									<th scope="col">Status</th>
									<th scope="col">Visits</th>
								</tr>
							</thead>
							<tbody>
								{reports?.visitors?.map((d,index)=>{
									return (
										<>
											<tr>
												<th scope="row">{index}</th>
												<td>{d.first_name}</td>
												<td>{d.last_name}</td>
												<td>{d.last_visit}</td>
												<td>{d.status}</td>
												<td>{d.visit_count}</td>
											</tr>
										</>
									);
								})}
								
								
							</tbody>
						</table>
					</div>
				</Modal.Body>
			</Modal>
		</Layout>
	);
};

export default ProjectReport;
