import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { bookUnit } from "../../Actions/SEAction";
import { useNavigate } from "react-router-dom";
import { getProjectOffers, getProjectPaymentSchedule } from "../../Actions/CommonAction";
const BookUnit = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const inventory = useSelector((state) => state?.se?.inventory);


	const { projectOffers, projectPaymentSchedule } = useSelector((state) => state);


	useEffect(() => {
		dispatch(getProjectOffers(params.projectId))
		dispatch(getProjectPaymentSchedule(params.projectId))
	}, [])




	const submitHandler = (e) => {
		e.preventDefault()
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		const postData = object
		dispatch(bookUnit(postData, navigate,params.projectId))
	}
	return (
		<Layout pageTitle={"Unit Booking"}>
			<form onSubmit={submitHandler}>
				<div className="p-2 gap-3 d-grid">
					<input
						type="text"
						value={params.projectId}
						name="project_id"
						hidden
					/>
					<input type="text" name="inventory_id" value={params.inventoryId} hidden />
					{/* <input type="text" value={inventory.data.wing} name="wing" hidden /> */}
					<input type="text" value={params.unit} name="unit" hidden />
					<div>
						<label> First Name </label>
						<input className="form-control" type="text" name="first_name" />
					</div>
					<div>
						<label> Last Name </label>
						<input className="form-control" type="text" name="last_name" />
					</div>
					<div>
						<label> Email </label>
						<input className="form-control" type="email" name="email" />
					</div>
					<div>
						<label> Mobile Number </label>
						<input className="form-control" type="text" name="mobile_number" />
					</div>
					<div>
						<label> Whatsapp Number </label>
						<input
							className="form-control"
							type="text"
							name="whatsapp_number"
						/>
					</div>
					<div>
						<label> Age </label>
						<input className="form-control" type="number" name="age" />
					</div>

					<div>
						<label htmlFor="offers">Select Offers</label>
						<select name="offers" id="offers" className="form-control" multiple >
							{projectOffers?.list?.map((offer, index) => {
								return <option key={index} value={offer.id}   >
									{offer.key}
								</option>
							})}
						</select>
					</div>
					<div>
						<label htmlFor="payment_schedule">Select Payment Schedule</label>
						<select name="payment_schedule" id="payment_schedule" className="form-control">
							{projectPaymentSchedule?.list?.map((payment_schedule, index) => {
								return <option key={index} value={payment_schedule.id}   >
									{payment_schedule.name}
								</option>
							})}
						</select>
					</div>

					{/* <div>
						<label> Final Amount </label>
						<input className="form-control" type="number" name="amount" />
					</div> */}
					<div className="row">
						<div className="col-6">
							<button
								className="btn btn-danger"
								type="button"
								onClick={() => navigate("/inventory")}
							>
								Back
							</button>
						</div>
						<div className="col-6">
							<button className="btn btn-dark">Submit</button>
						</div>
					</div>
				</div>
			</form>
		</Layout>
	);
};

export default BookUnit;
