import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getOfferDetail, updateOfferDetail } from '../../../../Actions/AdminAction';
import Layout from '../../Layout/Layout';
const EditOffersData = () => {
    const [key, setKey] = useState();
    const [value, setValue] = useState();
    const [useValue, setUseValue] = useState();
    const [inPercentage, setInPercentage] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { offerId } = useParams();

    const { loading, error, offerDetail } = useSelector(state => state?.developer?.offerDetail)


    useEffect(() => {
        if (offerDetail) {
            setKey(offerDetail.key);
            setValue(offerDetail?.value);
            setUseValue(offerDetail?.use_value)
            setInPercentage(offerDetail?.in_percantage)
        }
    }, [offerDetail])

    useEffect(() => {
        dispatch(getOfferDetail(offerId))
    }, [])


    const submitHandler = () => {
        const postData = {
            key: key,
            value: value,
            use_value: useValue,
            in_percantage: inPercentage,
        }

        dispatch(updateOfferDetail(postData,offerId,navigate))
    }

    return (
        <Layout pageTitle={"Offer Edit Page"}>
            <div className="d-grid p-3 gap-3">
                <div>
                    <label>Offer Name</label>
                    <input type="text" value={key} className='form-control' onChange={(e) => {
                        setKey(e.target.value)
                    }} />
                </div>
                <div>
                    <label>Offer Value</label>
                    <input type="text" className='form-control' value={value} onChange={(e) => {
                        setValue(e.target.value)
                    }} />
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={useValue} onChange={(e) => {
                        setUseValue(e.target.checked)
                    }} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Use Value
                    </label>
                </div>
                <div className="d-flex gap-3">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Flat Value</label>
                    <div className="form-check form-switch d-flex">
                        <input className="form-check-input" checked={inPercentage} onChange={(e) => {
                            setInPercentage(e.target.checked)
                        }} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" for="flexSwitchCheckDefault">In Percentage</label>
                    </div>
                </div>
                <div className="d-flex justify-content-end gap-3">
                    <button className="btn btn-success" onClick={submitHandler} >Save</button>
                    <button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Cancel</button>
                </div>
            </div>

        </Layout>
    )
}
export default EditOffersData
