import React from 'react'
import Layout from './Layout/Layout'
import { useNavigate } from 'react-router-dom'
const Report = () => {
    const navigate=useNavigate();
  return (
		<Layout pageTitle={"EazyReports"}>
			<h2>Select To Genrate Report </h2>
			<div className="d-grid gap-3 h-50 mx-md-5 align-items-center">
				<button
					className="btn btn-outline-primary btn-lg "
					onClick={() => {
						navigate("/sales-report");
					}}
				>
					Sales
				</button>
				<button
					className="btn btn-outline-primary btn-lg "
					onClick={() => {
						navigate("/project-report");
					}}
				>
					Project
				</button>
				<button
					className="btn btn-outline-primary btn-lg "
					onClick={() => {
						navigate("/cp-report");
					}}
				>
					Channel Partner
				</button>
			</div>
		</Layout>
	);
}

export default Report
