import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeDetail } from "../../Actions/CPAction";
import { useParams,useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Loading from "../../Components/Loading";
import phone_countries from "../../assets/PhoneNumberValidations";
const EditEmployee = () => {
	const [validNumber, setValidNumber] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { employeeId } = useParams();
	const {loading,error,detail}=useSelector((state)=>state?.cp?.employeeDetail)
	console.log(employeeId)
	useEffect(() => {
		dispatch(getEmployeeDetail(employeeId));
	}, [employeeId]);

	const submitHandler = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		alert(JSON.stringify(object));
	};
	
	return (
		<Layout pageTitle={"Manage Employees"}>
			{loading ? 
			<div className="h-100 d-flex justify-content-center align-items-center">
				 
				<Loading />
			</div>
			:
			
			<div className="h-100">
				<h1 className="m-2">Edit Employee</h1>
				<form className="d-grid gap-3 p-2" onSubmit={submitHandler}>
					<div className="">
						<label>First Name</label>
						<input
							type="text"
							name="first_name"
							className="form-control  bg-white "
							defaultValue={detail?.first_name}
							required
						/>
					</div>
					<div className="">
						<label>Last Name</label>
						<input
							type="text"
							name="last_name"
							className="form-control  bg-white "
							defaultValue={detail?.last_name}
							required
						/>
					</div>
					<div className="">
						<label>Email</label>
						<input
							type="email"
							name="email"
							className="form-control  bg-white "
							defaultValue={detail?.email}
							required
						/>
					</div>
					<div className="">
						<label>Mobile Number</label>
						<PhoneInput
							country={"in"}
							countryCodeEditable={false}
							placeholder="Enter phone number"
							inputProps={{
								name: "mobile_number",
								required: true,
							}}
							isValid={(inputNumber, country, countries) => {
								let validation = phone_countries.filter(
									(phone) => phone.iso2 === country.iso2.toUpperCase()
								)[0].validation;
								if (validation.test(inputNumber)) {
									setValidNumber(true);
									return true;
								} else {
									setValidNumber(false);
									return false;
								}
							}}
							enableSearch={true}
							value={detail?.phone_number}
							containerClass="my-container-class  "
							// className="form-control"
							inputClass="form-control"
						/>
					</div>
					<div className="">
						<label>Designation</label>
						<select name="designation" className="form-select" defaultValue={detail?.designation} >
							{["Sales Manager", "Sales Executive", "Team Lead"].map(
								(designation) => {
									return (
										<option key={designation} value={designation}  >
											{designation}
										</option>
									);
								}
							)}
						</select>
					</div>
					<div>
						<button className="btn btn-success">Save</button>
							<button
								className="btn btn-dark"
								type='button'
								onClick={() => {
									navigate(-1);
								}}
							>
								Cancel
							</button>
					</div>
				</form>
			</div>
			}
		</Layout>
	);
};

export default EditEmployee;
