import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDeveloperEmployeeDetails, updateDeveloperEmployeeDetails, deleteDeveloperEmployee } from '../../../Actions/MyAdminAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icon from "@fortawesome/free-solid-svg-icons";
import ImageUploader from '../../../Components/ImageUploader'
import PhoneInput from 'react-phone-input-2'
const EmployeeScreen = () => {
    const [edit, setEdit] = useState(false)
    const [value, setValue] = useState("")
    const { employeeId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, employee, error } = useSelector(state => state?.myAdmin?.developerEmployeeDetails);
    const { developer_employees_edit, developer_employees_delete } = useSelector(state => state?.permissions)
    useEffect(() => {
        if (employee) {
            setValue(employee?.phone_number)
        }
    }, [employee])
    useEffect(() => {
        dispatch(getDeveloperEmployeeDetails(employeeId))
    }, [])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append("phone_number", value)
        dispatch(updateDeveloperEmployeeDetails(formData, employeeId, setEdit))
    }

    if (employee) {

        return (
            <Layout pageTitle={"Employee Detail"}>
                <div className="d-grid p-3 gap-3  position-relative">
                    {
                        developer_employees_edit &&

                        <FontAwesomeIcon onClick={() => setEdit(!edit)} className='position-absolute top-0 end-0 m-3  h4' icon={edit ? icon.faXmark : icon.faPenToSquare} />
                    }

                    {
                        edit ? <>
                            <div className="p-3">
                                <form onSubmit={submitHandler} >
                                    <div className="d-grid gap-3 position-relative ">
                                        <div className="d-flex justify-content-center">
                                            <div className="w-30">
                                                <ImageUploader dbImage={"https://api.eazyapp.in" + employee?.photo} />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="form-label">First Name</label>
                                            <input type="text" className="form-control" name="first_name" defaultValue={employee?.first_name} />
                                        </div>
                                        <div>
                                            <label className="form-label">Last Name</label>
                                            <input type="text" className="form-control" name="last_name" defaultValue={employee?.last_name} />
                                        </div>
                                        <div>
                                            <label className="form-label">Email</label>
                                            <input type="email" className='form-control' name="email" defaultValue={employee?.email} />
                                        </div>
                                        <div>
                                            <label className="form-label">Phone Number</label>
                                            <PhoneInput
                                                country={"in"}
                                                countryCodeEditable={false}
                                                placeholder="Enter phone number"
                                                value={value}
                                                enableSearch={true}
                                                containerClass="my-container-class"
                                                // className="form-control"
                                                inputClass="form-control"
                                                onChange={setValue}
                                            />
                                        </div>
                                        <div>
                                            <label className="form-label">Designation</label>
                                            <input type="text" name='designation' className='form-control' defaultValue={employee?.designation} placeholder='Enter Designation' />
                                        </div>
                                        <div className="sticky-bottom py-1 text-end">
                                            <button className="btn btn-success">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </> :
                            <>

                                <div className="d-flex p-2 gap-3 align-items-center">
                                    <img src={"https://api.eazyapp.in" + employee?.photo} style={{ height: "150px", width: "150px" }} className="rounded" alt="" />
                                    <div className='flex-fill'>
                                        <h5 className="section_heading">{employee?.name}</h5>
                                        <p className='m-0' ><span className='fw-bold' >Designation:</span> {employee.designation}</p>
                                        <p className='m-0' ><span className='fw-bold' >Email:</span> {employee.email}</p>
                                        <p className='m-0' ><span className='fw-bold' >Number:</span> {employee.phone_number}</p>
                                    </div>
                                </div>
                                <div className='p-3'>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Project</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Reporting To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employee.projects.map((item, index) => {

                                                return <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.project}</td>
                                                    <td>{item.designation}</td>
                                                    <td>{item.report_to}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    developer_employees_delete &&
                                    <div className="sticky-bottom text-end p-3">
                                        <button className="btn btn-danger" onClick={()=>dispatch(deleteDeveloperEmployee(employeeId,navigate))} >
                                            Delete
                                        </button>
                                    </div>
                                }
                            </>
                    }
                </div>
               
            </Layout>
        )
    }
}

export default EmployeeScreen
