import React, { useEffect, useState } from "react";
import { json, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
	getVisitorDetail,
	updateVisitorDetail,
	checkOutVisitor,
} from "../../../Actions/SEAction";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import ReactAutocomplete from "react-autocomplete";
import axios from "axios";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Activity from "../../../Components/Activity";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import toast from "react-hot-toast";
const VisitorDetail = () => {
	const navigate=useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const [page, setPage] = useState("Detail");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [age, setAge] = useState("");
	const [whatsappNumber, setWhatsappNumber] = useState("");
	const [religion, setReligion] = useState("");
	const [ethnicity, setEthnicity] = useState("");
	const [residentialAddress, setResidentialAddress] = useState("");
	const [residentialConfig, setResidentialConfig] = useState("");
	const [residentailPropertyStatus, setResidentialPropertyStatus] =
		useState("");
	const [getFeedBack, setGetFeedBack] = useState(false);
	const [workAddress, setWorkAddress] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [designation, setDesignation] = useState("");
	const [requirments, setRequirements] = useState({});
	const [createTask, setCreateTask] = useState(false);
	const [feedback,setFeedback]=useState("")
	const [visitorStatus,setVisitorStatus]=useState("Warm")
	const [followUpDate, setFollowUpDate] = useState(
		moment().add(3, "days").format("YYYY-MM-DD")
	);

	const checkOutVisitorHandler=(e)=>{
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		dispatch(checkOutVisitor(object,navigate));
	}

	const [getNewFollowUpdate,setGetNewFollowupDate]=useState(false)
	const [locationItems, setLocationItems] = useState([]);
	const budgetOption = [
		{
			minPrice: 0,
			maxPrice: 2500000,
			diff: 2500000,
			label: "Up to 25 lakhs",
		},
		{
			minPrice: 2500000,
			maxPrice: 5000000,
			diff: 2500000,
			label: "25 lakhs - 50 lakhs",
		},
		{
			minPrice: 5000000,
			maxPrice: 7500000,
			diff: 2500000,
			label: "50 lakhs - 75 lakhs",
		},
		{
			minPrice: 7500000,
			maxPrice: 10000000,
			diff: 2500000,
			label: "75 lakhs - 1 crore",
		},
		{
			minPrice: 10000000,
			maxPrice: 15000000,
			diff: 5000000,
			label: "1 crore - 1.5 crores",
		},
		{
			minPrice: 15000000,
			maxPrice: 20000000,
			diff: 5000000,
			label: "1.5 crores - 2 crores",
		},
		{
			minPrice: 20000000,
			maxPrice: 25000000,
			diff: 5000000,
			label: "2 crores - 2.5 crores",
		},
		{
			minPrice: 25000000,
			maxPrice: 30000000,
			diff: 5000000,
			label: "2.5 crores - 3 crores",
		},
		{
			minPrice: 30000000,
			maxPrice: 35000000,
			diff: 5000000,
			label: "3 crores - 3.5 crores",
		},
		{
			minPrice: 35000000,
			maxPrice: 40000000,
			diff: 5000000,
			label: "3.5 crores - 4 crores",
		},
		{
			minPrice: 40000000,
			maxPrice: 45000000,
			diff: 5000000,
			label: "4 crores - 4.5 crores",
		},
		{
			minPrice: 45000000,
			maxPrice: 50000000,
			diff: 5000000,
			label: "4.5 crores - 5 crores",
		},
		{
			minPrice: 50000000,
			maxPrice: 55000000,
			diff: 5000000,
			label: "5 crores - 5.5 crores",
		},
		{
			minPrice: 55000000,
			maxPrice: 60000000,
			diff: 5000000,
			label: "5.5 crores - 6 crores",
		},
		{
			minPrice: 60000000,
			maxPrice: 65000000,
			diff: 5000000,
			label: "6 crores - 6.5 crores",
		},
		{
			minPrice: 65000000,
			maxPrice: 70000000,
			diff: 5000000,
			label: "6.5 crores - 7 crores",
		},
		{
			minPrice: 70000000,
			maxPrice: 75000000,
			diff: 5000000,
			label: "7 crores - 7.5 crores",
		},
		{
			minPrice: 75000000,
			maxPrice: 80000000,
			diff: 5000000,
			label: "7.5 crores - 8 crores",
		},
		{
			minPrice: 80000000,
			maxPrice: 85000000,
			diff: 5000000,
			label: "8 crores - 8.5 crores",
		},
		{
			minPrice: 85000000,
			maxPrice: 90000000,
			diff: 5000000,
			label: "8.5 crores - 9 crores",
		},
		{
			minPrice: 90000000,
			maxPrice: 95000000,
			diff: 5000000,
			label: "9 crores - 9.5 crores",
		},
		{
			minPrice: 95000000,
			maxPrice: 100000000,
			diff: 5000000,
			label: "9.5 crores - 10 crores",
		},
	];

	function areObjectsEqual(obj1, obj2) {
		if (obj1 === undefined || obj2 === undefined) {
			return true;
		}
		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		// Check if both objects have the same number of properties
		if (keys1.length !== keys2.length) {
			return false;
		}

		// Check if each property in obj1 has the same value in obj2
		for (const key of keys1) {
			if (obj1[key] !== obj2[key]) {
				return false;
			}
		}

		return true;
	}

	const [configs, setConfigs] = useState([
		"1Rk",
		"1Bhk",
		"2Bhk",
		"3Bhk",
		"4Bhk",
	]);
	useEffect(() => {
		switch (requirments.property_type) {
			case "Residential":
				setConfigs(["1Rk", "1Bhk", "2Bhk", "3Bhk", "4Bhk"]);
				return;
			case "Commercial":
				setConfigs(["Shop", "Office"]);
				return;
			default:
				setConfigs(["1Rk", "1Bhk", "2Bhk", "3Bhk", "4Bhk"]);
				return;
		}
	}, [requirments.property_type]);
	const { Detail, loading, error } = useSelector(
		(state) => state?.se.visitorDetail
	);
	useEffect(() => {
		if (Detail !== undefined) {
			setFirstName(Detail?.first_name);
			setLastName(Detail?.last_name);
			setDateOfBirth(Detail?.date_of_birth);
			setReligion(Detail?.religion);
			setAge(moment().diff(Detail?.date_of_birth, "years", false));
			setWhatsappNumber(Detail?.whatsapp_number);
			setResidentialAddress(Detail?.resdential_address?.location);
			setResidentialConfig(Detail?.resdential_address?.config);
			setWorkAddress(Detail?.work_detail?.location);
			setCompanyName(Detail?.work_detail?.company_name);
			setDesignation(Detail?.work_detail?.designation);
			setRequirements(Detail?.requirements);
			setResidentialPropertyStatus(Detail?.resdential_address?.property_status);
		}
	}, [Detail]);
	useEffect(() => {
		if (residentialAddress === "") {
			return;
		}
		const delay = 1000;
		const Debouncer = setTimeout(async () => {
			try {
				const response = await axios.post(
					"https://houzyy.com/api/v1/get-area-list/",
					{ searched_term: residentialAddress }
				);
				setLocationItems(response.data);
			} catch (error) {
				toast.error("Something went wrong");
			}
		}, delay);
		return () => clearTimeout(Debouncer);
	}, [residentialAddress]);

	useEffect(() => {
		if (workAddress === "") {
			return;
		}
		const delay = 1000;
		const Debouncer = setTimeout(async () => {
			try {
				const response = await axios.post(
					"https://houzyy.com/api/v1/get-area-list/",
					{ searched_term: workAddress }
				);
				setLocationItems(response.data);
			} catch (error) {
				toast.error("Something went wrong");
			}
		}, delay);
		return () => clearTimeout(Debouncer);
	}, [workAddress]);

	useEffect(() => {
		dispatch(getVisitorDetail(params.visitId));
	}, [params.visitId]);

	if(getNewFollowUpdate){
		return (
			<>
				<div className="main_container m-0 p-0">
					<div className="w-100">
						<button
							className="btn ms-0"
							onClick={() => {
								setGetNewFollowupDate(false);
							}}
						>
							<Icon.ArrowLeft />
						</button>
					</div>
					<div className="row ">
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(1, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(1, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">Tomorrow</p>
								<p className=" fs-6 text-muted">
									{moment().add(1, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(2, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(2, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 2 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(2, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(3, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(3, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 3 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(3, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(7, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(7, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(7, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(14, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(14, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">2 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(14, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(30, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(30, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(30, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(90, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(90, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">3 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(90, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(180, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(180, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">6 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(180, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-12">
							{/* <button
								className={`btn border w-100 border-primary ${moment().add(180, "days").format(
									"YYYY-MM-DD"
								) === followUpDate && "border-3"}`}
								value={moment().add(180, "days").format("YYYY-MM-DD")}
								onClick={(e) => {
									setFollowUpDate(e.target.value);
									setGetNewFollowupDate(false);
								}}
							></button> */}
						</div>
					</div>
				</div>
			</>
		);
	}

	if (Detail){
		return (
			// <Layout pageTitle={"Visitor Detail"}>
			<div className="main_container  p-md-5 p-3">
				<h2 className="text-center my-3">Visitor Detail Page</h2>
				<div className="row">
					<div className="col-md-3 order-md-last p-5">
						<img
							className="img-fluid  img-thumbnail"
							src={"https://api.eazyapp.in" + Detail?.image}
							alt=""
						/>
						<div className="text-center py-2 ">
							<p className="section_heading">
								{firstName} {lastName}
							</p>
						</div>
					</div>
	
					<div className="col-md-9 order-md-first">
						<div className="row gy-2 mt-2">
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 d-flex align-items-center">
										<label className="text-nowrap ">First Name:</label>
									</div>
									<div className="col-8">
										<input
											type="text"
											name="first_name"
											className="form-control"
											value={firstName}
											onChange={(e) => {
												setFirstName(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">Last Name:</label>
									</div>
									<div className="col-8">
										<input
											type="text"
											name="last_name"
											className="form-control"
											value={lastName}
											onChange={(e) => {
												setLastName(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">DOB:</label>
									</div>
									<div className="col-8">
										<input
											type="date"
											name="date_of_birth"
											className="form-control"
											value={dateOfBirth}
											onChange={(e) => {
												setDateOfBirth(e.target.value);
												const calculatedAge = moment().diff(
													e.target.value,
													"years",
													false
												);
												setAge(calculatedAge);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">Age:</label>
									</div>
									<div className="col-8">
										<input
											type="text"
											name="age"
											className="form-control"
											disabled
											value={age}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">Mobile No.:</label>
									</div>
									<div className="col-8">
										<PhoneInput
											country={"in"}
											countryCodeEditable={false}
											placeholder="Enter phone number"
											value={Detail?.mobile_number}
											enableSearch={true}
											containerClass="w-100"
											inputProps={{
												name: "mobile_number",
												required: true,
												class: "form-control w-100",
												disabled: true,
											}}
											disabled
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">Whatsapp No.:</label>
									</div>
									<div className="col-8">
										<PhoneInput
											country={"in"}
											countryCodeEditable={false}
											placeholder="Enter phone number"
											value={whatsappNumber}
											enableSearch={true}
											containerClass="my-container-class"
											inputProps={{
												name: "whatsapp_number",
												required: true,
												class: "form-control w-100",
											}}
											// className="form-control"
											inputClass="form-control"
											onChange={setWhatsappNumber}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">Religion:</label>
									</div>
									<div className="col-8">
										<input
											type="text"
											name="religion"
											className="form-control"
											value={religion}
											onChange={(e) => {
												setReligion(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6 ">
								<div className="row">
									<div className="col-4 align-items-center d-flex">
										<label className="text-nowrap ">Ethnicity:</label>
									</div>
									<div className="col-8">
										<input
											type="text"
											name="ethnicity"
											className="form-control"
											value={ethnicity}
											onChange={(e) => {
												setEthnicity(e.target.value);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="py-3">
					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header>Residence details</Accordion.Header>
							<Accordion.Body>
								<div className="row  g-3">
									<div className="col-md-5 ">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap ">Location:</label>
											</div>
											<div className="col-8">
												<ReactAutocomplete
													getItemValue={(item) => item.name}
													items={locationItems}
													renderItem={(item, isHighlighted) => (
														<div
															class={`alert m-0  mt-1 ${
																isHighlighted ? "alert-primary" : "alert-light"
															}`}
															role="alert"
														>
															{item.name}
														</div>
													)}
													value={residentialAddress}
													menuStyle={{
														borderRadius: "3px",
														boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
														background: "rgba(255, 255, 255, 0.9)",
														fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
														display: "grid",
														maxHeight: "200px",
														overflow: "scroll",
														position: "inherit",
														zIndex: 999999999999,
													}}
													inputProps={{
														className: "form-control",
														name: "location",
														required: true,
													}}
													wrapperProps={{ className: "w-100" }}
													onChange={(e) => {
														setResidentialAddress(e.target.value);
													}}
													onSelect={(val, item) => {
														setResidentialAddress(val);
													}}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-5 ">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap ">Config:</label>
											</div>
											<div className="col-8">
												<select
													name="config"
													className="form-select"
													onChange={(e) => {
														setResidentialConfig(e.target.value);
													}}
												>
													<option
														disabled
														selected={undefined === residentialConfig}
													>
														Select Config
													</option>
													<option
														value="1Rk"
														selected={"1Rk" === residentialConfig}
													>
														1Rk
													</option>
													<option
														value="1Bhk"
														selected={"1Bhk" === residentialConfig}
													>
														1Bhk
													</option>
													<option
														value="2Bhk"
														selected={"2Bhk" === residentialConfig}
													>
														2Bhk
													</option>
													<option
														value="3Bhk"
														selected={"3Bhk" === residentialConfig}
													>
														3Bhk
													</option>
													<option
														value="4Bhk"
														selected={"4Bhk" === residentialConfig}
													>
														4Bhk
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-md-5 ">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap">Property Status:</label>
											</div>
											<div className="col-8">
												<div
													className="btn-group ms-md-5"
													role="group"
													aria-label="Basic radio toggle button group"
												>
													<input
														type="radio"
														class="btn-check"
														name="property_status"
														value="owned"
														id="btnradiopropertystatus1"
														autocomplete="off"
														onChange={(e) =>
															e.target.checked &&
															setResidentialPropertyStatus(e.target.value)
														}
														checked={residentailPropertyStatus === "owned"}
													/>
													<label
														class="btn btn-outline-warning"
														for="btnradiopropertystatus1"
													>
														Owned
													</label>
													<input
														type="radio"
														class="btn-check"
														name="porperty_status"
														value="rent"
														id="btnradiopropertystatus2"
														autocomplete="off"
														checked={residentailPropertyStatus === "rent"}
														onChange={(e) =>
															e.target.checked &&
															setResidentialPropertyStatus(e.target.value)
														}
													/>
													<label
														class="btn btn-outline-warning"
														for="btnradiopropertystatus2"
													>
														Rented
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
							<Accordion.Header>Work details</Accordion.Header>
							<Accordion.Body>
								<div className="row g-3">
									<div className="col-md-5">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap ">Location:</label>
											</div>
											<div className="col-8">
												<ReactAutocomplete
													getItemValue={(item) => item.name}
													items={locationItems}
													renderItem={(item, isHighlighted) => (
														<div
															class={`alert m-0  mt-1 ${
																isHighlighted ? "alert-primary" : "alert-light"
															}`}
															role="alert"
														>
															{item.name}
														</div>
													)}
													value={workAddress}
													menuStyle={{
														borderRadius: "3px",
														boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
														background: "rgba(255, 255, 255, 0.9)",
														fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
														display: "grid",
														maxHeight: "200px",
														overflow: "scroll",
														top: "200px",
														position: "inherit",
														zIndex: 999999999999,
													}}
													inputProps={{
														className: "form-control",
														name: "location",
														required: true,
													}}
													wrapperProps={{ className: "w-100" }}
													onChange={(e) => {
														setWorkAddress(e.target.value);
													}}
													onSelect={(val, item) => {
														setWorkAddress(val);
													}}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-5">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap ">Company Name:</label>
											</div>
											<div className="col-8">
												<input
													type="text"
													name="company_name"
													className="form-control"
													value={companyName}
													onChange={(e) => {
														setCompanyName(e.target.value);
													}}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-5">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap ">Designation:</label>
											</div>
											<div className="col-8">
												<input
													type="text"
													name="company_name"
													className="form-control"
													value={designation}
													onChange={(e) => {
														setDesignation(e.target.value);
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header>Requirements</Accordion.Header>
							<Accordion.Body>
								<div className="row g-3">
									<div className="col-md-6">
										<div className="row">
											<div className="col-4 d-flex align-items-center">
												<label className="text-nowrap ">Property Type:</label>
											</div>
											<div className="col-8">
												<div
													className=" d-flex btn-group ms-md-5"
													role="group"
													aria-label="Basic radio toggle button group"
												>
													<input
														type="radio"
														className="btn-check"
														name="property_type"
														value="Residential"
														id="btnradio1"
														autocomplete="off"
														checked={requirments?.property_type === "Residential"}
													/>
													<label
														className="btn btn-outline-warning"
														for="btnradio1"
														onClick={() => {
															setRequirements({
																...requirments,
																property_type: "Residential",
															});
														}}
													>
														Residential
													</label>
	
													<input
														type="radio"
														className="btn-check"
														name="property_type"
														value="Commercial"
														id="btnradio2"
														autocomplete="off"
														checked={requirments?.property_type === "Commercial"}
													/>
													<label
														className="btn btn-outline-warning"
														onClick={() => {
															setRequirements({
																...requirments,
																property_type: "Commercial",
															});
														}}
														for="btnradio2"
													>
														Commercial
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-8">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label>Config:</label>
											</div>
											<div className="col-8">
												<div
													class=" "
													role="group"
													aria-label="Basic checkbox toggle button group"
												>
													{configs.map((d, index) => {
														return (
															<>
																<input
																	type="checkbox"
																	class="btn-check"
																	id={`btncheck${index}`}
																	name="config"
																	value={d}
																	autocomplete="off"
																	defaultChecked={requirments?.config?.includes(d)}
																	onChange={(e) => {
																		if (e.target.checked) {
																			setRequirements({
																				...requirments,
																				config: [
																					...requirments.config,
																					e.target.value,
																				],
																			});
																		} else {
																			setRequirements({
																				...requirments,
																				config: [
																					...requirments.config.filter(
																						(d) => d !== e.target.value
																					),
																				],
																			});
																		}
																	}}
																/>
																<label
																	class="btn btn-outline-primary ms-2 mt-2"
																	for={`btncheck${index}`}
																>
																	{d}
																</label>
															</>
														);
													})}
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label> Budget:</label>
											</div>
											<div className="col-8">
												<select
													name="budget"
													id=""
													value={JSON.stringify()}
													className="form-select"
													onChange={(e) => {
														setRequirements({
															...requirments,
															budget: JSON.parse(e.target.value),
														});
													}}
												>
													{budgetOption.map((d) => {
														return (
															<option
																value={JSON.stringify({
																	minPrice: d.minPrice,
																	maxPrice: d.maxPrice,
																})}
																selected={
																	requirments?.budget?.minPrice == d.minPrice &&
																	requirments?.budget?.maxPrice == d.maxPrice
																}
															>
																{d.label}
															</option>
														);
													})}
												</select>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label>Carpet:</label>
											</div>
											<div className="col-8 ">
												<div className="input-group">
													<input
														type="number"
														className="form-control"
														value={requirments.carpet_area}
														onChange={(e) => {
															setRequirements({
																...requirments,
																carpet_area: e.target.value,
															});
														}}
													/>
													<span className="input-group-text">(sq.ft)</span>
												</div>
											</div>
										</div>
									</div>
	
									<div className="col-md-6">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label className="text-nowrap">Floor Preference:</label>
											</div>
											<div className="col-8 ">
												<input
													type="number"
													className="form-control"
													value={requirments.floor_no_preference}
													onChange={(e) => {
														setRequirements({
															...requirments,
															floor_no_preference: e.target.value,
														});
													}}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label className="text-nowrap">Vastu:</label>
											</div>
											<div className="col-8 ">
												<select
													className="form-select"
													id=""
													value={requirments.vastu_preference}
													onChange={(e) => {
														setRequirements({
															...requirments,
															vastu_preference: e.target.value,
														});
													}}
												>
													<option value={null} selected disabled>
														Select Vastu Preference
													</option>
													<option value="North">North</option>
													<option value="North-East">North-East</option>
													<option value="East">East</option>
													<option value="South-East">South-East</option>
													<option value="South">South</option>
													<option value="South-West">South-West</option>
													<option value="West">West</option>
													<option value="North-West">North-West</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label className="text-nowrap">View Preference:</label>
											</div>
											<div className="col-8 ">
												<select
													className="form-select"
													id=""
													onChange={(e) => {
														setRequirements({
															...requirments,
															view_preference: e.target.value,
														});
													}}
												>
													<option value={null} selected disabled>
														Select View Preference
													</option>
												</select>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label className="text-nowrap">Funding Mode:</label>
											</div>
											<div className="col-8 ">
												<select
													className="form-select"
													value={requirments.funding_mode}
													id=""
													onChange={(e) => {
														setRequirements({
															...requirments,
															funding_mode: e.target.value,
														});
													}}
												>
													<option value={null} selected disabled>
														Select Funding Mode
													</option>
													<option value="SOP">SOP (Sale of Property)</option>
													<option value="Self">Self</option>
													<option value="Loan">Loan</option>
												</select>
											</div>
										</div>
									</div>
									{requirments.funding_mode === "Loan" && (
										<>
											<div className="col-md-6">
												<div className="row">
													<div className="col-4  d-flex align-items-center">
														<label className="text-nowrap">Self:</label>
													</div>
													<div className="col-8 ">
														<div className="input-group">
															<input
																type="number"
																className="form-control"
																id=""
																onChange={(e) => {
																	setRequirements({
																		...requirments,
																		self_pay_percent: e.target.value,
																	});
																}}
															/>
															<span class="input-group-text">%</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="row">
													<div className="col-4  d-flex align-items-center">
														<label className="text-nowrap">Loan:</label>
													</div>
													<div className="col-8 ">
														<div className="input-group">
															<input
																type="number"
																className="form-control"
																id=""
																onChange={(e) => {
																	setRequirements({
																		...requirments,
																		loan_pay_percent: e.target.value,
																	});
																}}
															/>
															<span class="input-group-text">%</span>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
									<div className="col-md-8">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label className="">Possession TimeLine:</label>
											</div>
											<div className="col-8 ">
												<select
													className="form-select"
													defaultValue={requirments.possession}
													id=""
													onChange={(e) => {
														setRequirements({
															...requirments,
															possession: JSON.parse(e.target.value),
														});
													}}
												>
													<option value={null} selected disabled>
														Select Possession Timeline
													</option>
													<option value="RTMI">Ready to Move In</option>
													<option
														value={JSON.stringify({
															date: moment().add(6, "M").format("DD-MM-YYYY"),
															activeKey: "NP",
														})}
														selected={requirments.possession === "NP"}
													>
														Nearing Possession
													</option>
													<option
														value={JSON.stringify({
															date: moment().add(1, "Y").format("DD-MM-YYYY"),
															activeKey: "1y",
														})}
														selected={requirments.possession === "1y"}
														// value={moment().add(1, "Y").format("DD-MM-YYYY")}
													>
														Within 1 Year
													</option>
													<option
														value={JSON.stringify({
															date: moment().add(2, "Y").format("DD-MM-YYYY"),
															activeKey: "2y",
														})}
														selected={requirments.possession === "2y"}
													>
														1 to 2 Years
													</option>
													<option
														value={JSON.stringify({
															date: moment().add(3, "Y").format("DD-MM-YYYY"),
															activeKey: "3y",
														})}
														selected={requirments.possession === "3y"}
													>
														2 to 3 Years
													</option>
													<option
														value={JSON.stringify({
															date: moment().add(1, "Y").format("DD-MM-YYYY"),
															activeKey: "4y",
														})}
														selected={requirments.possession === "4y"}
													>
														3 to 4 Years
													</option>
													<option
														value={JSON.stringify({
															date: moment().add(10, "Y").format("DD-MM-YYYY"),
															activeKey: "+4y",
														})}
														selected={requirments.possession === "+4y"}
													>
														4+ Years
													</option>
												</select>
											</div>
										</div>
									</div>
	
									<div className="col-md-8">
										<div className="row">
											<div className="col-4  d-flex align-items-center">
												<label className="">Purpose Of Purchase:</label>
											</div>
											<div className="col-8 ">
												<select
													className="form-select"
													id=""
													value={requirments.purpose_of_purchasing}
													onChange={(e) => {
														setRequirements({
															...requirments,
															purpose_of_purchasing: e.target.value,
														});
													}}
												>
													<option value={null} selected disabled>
														Select Purpose
													</option>
													<option value={"Investment"}>Investment</option>
													<option value={"Residential living"}>
														Residential living
													</option>
													<option value={"Rental income"}>Rental income</option>
													<option value={"Retirement planning"}>
														Retirement planning
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header>Activities</Accordion.Header>
							<Accordion.Body>
								<div className="activity">
									<div className="row gap-3">
										<div className="col-md-12">
											<h3 className="my-4">Activities</h3>
										</div>
										<Activity
											className="col-md-12 "
											data={Detail?.activity_log}
										></Activity>
									</div>
								</div>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<div className="row">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-7"></div>
								<div className="col-md-2">
									{(Detail?.first_name !== firstName ||
										Detail?.last_name !== lastName ||
										Detail?.date_of_birth !== dateOfBirth ||
										Detail?.whatsapp_number !== whatsappNumber ||
										Detail?.religion !== religion ||
										Detail?.resdential_address?.location !== residentialAddress ||
										Detail?.resdential_address?.config !== residentialConfig ||
										Detail?.resdential_address?.property_status !==
											residentailPropertyStatus ||
										Detail?.work_detail?.location !== workAddress ||
										Detail?.work_detail?.company_name !== companyName ||
										Detail?.work_detail?.designation !== designation ||
										!areObjectsEqual(Detail?.requirements, requirments)) && (
										<button
											className="btn btn-success w-100 my-1"
											onClick={() => {
												const postData = {};
												if (Detail?.first_name !== firstName) {
													postData["first_name"] = firstName;
												}
												if (Detail?.last_name !== lastName) {
													postData["last_name"] = lastName;
												}
												if (Detail?.date_of_birth !== dateOfBirth) {
													postData["date_of_birth"] = dateOfBirth;
												}
												if (Detail?.whatsapp_number !== whatsappNumber) {
													postData["whatsapp_number"] = whatsappNumber;
												}
												if (Detail?.religion !== religion) {
													postData["religion"] = religion;
												}
												if (
													Detail?.resdential_address?.location !==
														residentialAddress ||
													Detail?.resdential_address?.config !==
														residentialConfig ||
													Detail?.resdential_address?.property_status !==
														residentailPropertyStatus
												) {
													postData["resdential_address"] = {
														location: residentialAddress,
														config: residentialConfig,
														property_status: residentailPropertyStatus,
													};
												}
												if (
													Detail?.work_detail?.location !== workAddress ||
													Detail?.work_detail?.company_name !== companyName ||
													Detail?.work_detail?.designation !== designation
												) {
													postData["work_detail"] = {
														location: workAddress,
														company_name: companyName,
														designation: designation,
													};
												}
												if (!areObjectsEqual(Detail?.requirements, requirments)) {
													postData["requirements"] = requirments;
												}
												if (Object.keys(postData).length > 0) {
													postData["visit_id"] = params.visitId;
													postData["visitor_id"] = Detail?.id;
													dispatch(updateVisitorDetail(postData));
												}
											}}
										>
											Update
										</button>
									)}
								</div>
							</div>
						</div>
						<div className="col-md-3"></div>
						<div className="col-md-3 py-3">
							<button
								className="btn btn-warning w-100 my-1"
								onClick={() => setGetFeedBack(true)}
							>
								CheckOut Visitor
							</button>
							<Modal
								show={getFeedBack}
								onHide={() => {
									setGetFeedBack(false);
								}}
							>
								<Modal.Header closeButton>
									<Modal.Title>
										Feedback on {firstName} {lastName}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<form
										className="d-grid gap-3"
										method=""
										onSubmit={checkOutVisitorHandler}
									>
										<input type="text" name="log_type" value="Feedback" hidden />
										<input
											type="number"
											name="visitor_id"
											value={Detail?.id}
											hidden
										/>
										<input
											type="number"
											name="visit_id"
											value={params.visitId}
											hidden
										/>
										<div className="">
											<label>Feedback:</label>
											<textarea
												className="form-control"
												name="comment"
												id=""
												cols="20"
												rows="4"
												required
												defaultValue={feedback}
												onChange={(e) => {
													setFeedback(e.target.value);
												}}
											></textarea>
										</div>
										<div className="">
											<label className="align-middle">Select Status:</label>
											<select
												className="form-select"
												name="status"
												defaultValue={visitorStatus}
												id=""
												onChange={(e) => {
													setVisitorStatus(e.target.value);
												}}
											>
												<option value="Warm">Warm</option>
												<option value="Cold">Cold</option>
												<option value="Hot">Hot</option>
												<option value="Lost">Lost</option>
											</select>
										</div>
										<div className="d-flex justify-content-between ">
											<p className="label"> Create Follow Up </p>
											<Form.Check
												type="switch"
												checked={createTask}
												id="custom-switch"
												label=""
												onChange={(e) => {
													setCreateTask(e.target.checked);
												}}
											/>
										</div>
										{createTask && (
											<div>
												{/* <hr /> */}
												<input
													type="text"
													name="follow_up_date"
													value={moment(followUpDate).format("YYYY-MM-DD")}
													hidden
												/>
												<input
													type="text"
													name="task_type"
													value={"Site Visit"}
													hidden
												/>
												<input
													type="text"
													name="task_description"
													value={"Follow up for Site visit"}
													hidden
												/>
												<button
													type="button"
													className="btn p-0  text-start w-100"
													onClick={() => {
														setGetNewFollowupDate(true);
													}}
												>
													<div className="row mx-3">
														<div className="col-9">
															<p
																className="text-muted p-0 m-0"
																style={{ fontSize: "x-small" }}
															>
																Follow up date:
															</p>
															<p className="fw-bold p-0 m-0">
																{moment(followUpDate).format(
																	"ddd, MMMM Do, YYYY"
																)}
															</p>
														</div>
														<div className="col-3 d-flex align-items-center justify-content-end">
															<Icon.ArrowRight />
														</div>
													</div>
												</button>
												{/* <hr /> */}
											</div>
										)}
										<div className="text-end">
											<button className="btn btn-warning mt-3" type="submit">
												Checkout
											</button>
										</div>
									</form>
								</Modal.Body>
							</Modal>
						</div>
						<div className="col-md-3"></div>
					</div>
				</div>
			</div>
			// </Layout>
		);
	}
};

export default VisitorDetail;
