import React, { useState } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import ImageUploader from '../../../Components/ImageUploader'
import PhoneInput from 'react-phone-input-2'
import { addDeveloperEmployeeDetails } from '../../../Actions/MyAdminAction'
const AddEmployee = () => {
    const [value, setValue] = useState("")
    const { developerId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append("phone_number", value)
        dispatch(addDeveloperEmployeeDetails(formData,developerId,navigate))
    }

    return (
        <Layout pageTitle={"Add Developer Employee"}>
            <div className="p-3">
                <form onSubmit={submitHandler} >
                    <div className="d-grid gap-3 position-relative ">
                        <div className="d-flex justify-content-center">
                            <div className="w-30">
                                <ImageUploader dbImage={null} />
                            </div>
                        </div>
                        <div>
                            <label className="form-label">First Name</label>
                            <input type="text" className="form-control" name="first_name" />
                        </div>
                        <div>
                            <label className="form-label">Last Name</label>
                            <input type="text" className="form-control" name="last_name" />
                        </div>
                        <div>
                            <label className="form-label">Email</label>
                            <input type="email" className='form-control' name="email" />
                        </div>
                        <div>
                            <label className="form-label">Phone Number</label>
                            <PhoneInput
                                country={"in"}
                                countryCodeEditable={false}
                                placeholder="Enter phone number"
                                value={value}
                                enableSearch={true}
                                containerClass="my-container-class"
                                inputClass="form-control"
                                onChange={setValue}
                            />
                        </div>
                        <div>
                            <label className="form-label">Designation</label>
                            <input type="text" name='designation' className='form-control' placeholder='Enter Designation' />
                        </div>
                        <div className="sticky-bottom py-1 text-end">
                            <button className="btn btn-success" type='submit' >Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default AddEmployee
