import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../Actions/SEAction";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dropdown from "react-bootstrap/Dropdown";
import * as Icon from "react-bootstrap-icons";
import { complteTask } from "../../Actions/CommonAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels
);

const SEDashboard = () => {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [showQr, setShowQr] = useState(false);
	const [taskId, setTaskId] = useState(null);
	const settings = {
		slidesToShow: 4,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: "40px",
					slidesToShow: 2,
					autoplay: true,
					autoplaySpeed: 2500,
				},
			},
		],
	};

	const settingsChart = {
		dots: true,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
		adaptiveHeight: true,
		slidesToShow: 1,
		swipeToSlide: true,
	};
	const userInfo = useSelector((state) => state?.userState?.userInfo);
	const dashboardData = useSelector(
		(state) => state?.se?.dashboardData?.dashboardData
	);
	const maxData = Math.max(
		...[
			dashboardData?.my_chart?.hot,
			dashboardData?.my_chart?.warm,
			dashboardData?.my_chart?.cold,
			dashboardData?.my_chart?.lost,
			dashboardData?.my_chart?.deal,
		]
	);
	function roundUpToNearestTen(value) {
		return Math.ceil(value / 10) * 13;
	}
	const options = {
		responsive: true,
		barThickness: 20,
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				display: false,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
				},
				max: roundUpToNearestTen(maxData),
			},
		},
		plugins: {
			legend: {
				display: false,
				position: "bottom",
			},
			title: {
				display: false,
				text: "Chart.",
			},
			datalabels: {
				display: true,
				color: "black",
				formatter: Math.round,
				anchor: "end",
				offset: -20,
				align: "start",
			},
		},
	};
	const labels = [`Hot `, "Warm", "Cold", "Lost", "Deal"];
	const data = {
		labels,
		datasets: [
			{
				label: "Visitors",
				data: [
					dashboardData?.my_chart?.hot,
					dashboardData?.my_chart?.warm,
					dashboardData?.my_chart?.cold,
					dashboardData?.my_chart?.lost,
					dashboardData?.my_chart?.deal,
					// 666,999,888,666,888
				],
				backgroundColor: [
					"rgba( 231, 76, 60, 0.5)",
					"rgba( 241, 196, 15 , 0.5)",
					"rgba(34, 167, 240 , 0.5)",
					"rgba(143, 63, 209, 0.5)",
					"rgba(46, 204, 113, 0.5)",
				],
			},
		],
	};
	const teamData = {
		labels,
		datasets: [
			{
				label: "Visitors",
				data: [
					dashboardData?.team_chart?.hot,
					dashboardData?.team_chart?.warm,
					dashboardData?.team_chart?.cold,
					dashboardData?.team_chart?.lost,
					dashboardData?.team_chart?.deal,
					// 666,999,888,666,888
				],
				backgroundColor: [
					"rgba( 231, 76, 60, 0.5)",
					"rgba( 241, 196, 15 , 0.5)",
					"rgba(34, 167, 240 , 0.5)",
					"rgba(143, 63, 209, 0.5)",
					"rgba(46, 204, 113, 0.5)",
				],
			},
		],
	};
	const teamMaxData = Math.max(
		...[
			dashboardData?.team_chart?.hot,
			dashboardData?.team_chart?.warm,
			dashboardData?.team_chart?.cold,
			dashboardData?.team_chart?.lost,
			dashboardData?.team_chart?.deal,
		]
	);
	const teamOptions = {
		responsive: true,
		barThickness: 20,
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				display: false,
				grid: {
					display: false,
				},
				ticks: {
					precision: 0,
				},
				max: roundUpToNearestTen(teamMaxData),
			},
		},
		plugins: {
			legend: {
				display: false,
				position: "bottom",
			},
			title: {
				display: false,
				text: "Chart.",
			},
			datalabels: {
				display: true,
				color: "black",
				formatter: Math.round,
				anchor: "end",
				offset: -20,
				align: "start",
			},
		},
	};

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getDashboardData());
	}, []);
	return (
		<Layout pageTitle={"DashBoard"}>
			<div className=" w-100">
				<div className=" w-100 ps-md-0 ">
					<div className="bg-accent py-1 pb-5 m-0  w-100 rounded-bottom-4">
						<div className="row h-100 pb-4 mb-4 my-3">
							<div className="col-1"></div>
							<div className="col-3 col-lg-2 ps-4">
								<div className="d-flex justify-content-center ">
									<img
										className="img-thumbnail rounded-circle"
										src={"https://api.eazyapp.in" + userInfo?.image}
										alt=""
									/>
								</div>
							</div>
							<div className="col-7">
								<div className="">
									<p className="text-capitalize fw-bold fs-5 text-white p-0 m-0">
										{userInfo?.name}{" "}
									</p>
									<p className="text-white-50 p-0 m-0 fs-9">
										{userInfo?.email}{" "}
									</p>
									<p className="text-white p-0 m-0 fs-9">
										{userInfo?.designation}{" "}
									</p>
									{userInfo?.extra?.qr !== undefined && (
										<button
											onClick={() => setShowQr(true)}
											className=" btn btn-sm btn-white mt-3"
										>
											Show QR
										</button>
									)}
								</div>
							</div>
							{/* <div className="col-7"></div> */}
						</div>
					</div>
				</div>
				<div className="h-80  ">
					<div className="w-100" style={{ marginTop: "-70px" }}>
						<Slider {...settings}>
							<div className=" w-100 p-2 h-100  ">
								<div className="border bg-white rounded-4 h-100 w-100 shadow-sm p-2">
									<div className="text-start">
										<h6>Call</h6>
									</div>
									<div>
										<h2 className="display-4 m-0 ps-2">
											{dashboardData?.stats?.call?.total_calls}
										</h2>
										<hr className="m-0" />
										<p className="fs-9">
											Connected : {dashboardData?.stats?.call?.connected}
										</p>
									</div>
								</div>
							</div>
							<div className=" w-100 p-2 h-100 ">
								<div className="border rounded-4 h-100 w-100 shadow-sm p-2 bg-white">
									<div className="text-start">
										<h6>Email</h6>
									</div>
									<div>
										<h2 className="display-4 m-0 ps-2">
											{dashboardData?.stats?.email_sent}
										</h2>
									</div>
								</div>
							</div>
							<div className=" w-100 p-2 h-100 ">
								<div className="border rounded-4 h-100 w-100 shadow-sm p-2 bg-white">
									<div className="text-start">
										<h6>Site Visits</h6>
									</div>
									<div>
										<h2 className="display-4 m-0 ps-2">
											{dashboardData?.stats?.site_visits}
										</h2>
									</div>
								</div>
							</div>
							<div className=" w-100 p-2 h-100 ">
								<div className="border rounded-4 h-100 w-100 shadow-sm p-2 bg-white">
									<div className="text-start">
										<h6>Meetings</h6>
									</div>
									<div>
										<h2 className="display-4 m-0 ps-2">
											{dashboardData?.stats?.meetings_attended}
										</h2>
									</div>
								</div>
							</div>
						</Slider>
					</div>
					<div className="m-3 border shadow rounded ">
						<div>
							<div className="row">
								<div className="col-12 ">
									<h4 className="section_heading text-center mt-3 my-2">
										My Site Visits
									</h4>
								</div>
								<div className="col-4 p-4 d-flex justify-content-center align-items-center">
									<p className="display-2 ">
										{dashboardData?.my_chart?.total_visitor}
									</p>
								</div>
								<div className="col-8  p-4">
									<div className="d-flex justify-content-center align-items-center h-100 w-100">
										<Bar options={options} data={data} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" d-gird gap-3 px-3 pb-3 pb-md-0 rounded-top-5   bg-faded-info  ">
						<div className="d-flex justify-content-center py-2 pt-4">
							<div className="position-relative">
								<span className="section_heading px-3 ">Today's Task </span>
								{"  "}
								<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ">
									{dashboardData?.tasks?.length}
									<span class="visually-hidden">unread messages</span>
								</span>
							</div>
						</div>
						{dashboardData?.tasks?.length === 0 && (
							<h1 className="text-center my-5">No Task's Available</h1>
						)}
						<div className="row">
							{dashboardData?.tasks?.map((d, index) => {
								return (
									<div className="col-md-6">
										<div className="border bg-white shadow-sm rounded-2 p-2 my-3">
											<div className="row">
												<div className="col-12 position-relative">
													<h3 className="d-flex card_heading">
														{d.task_type} : {d.visitor.first_name}{" "}
														{d.pending && (
															<span className="bg-danger ms-auto p-2 opacity-25 fs-6  text-white px-2 py-0 border-white rounded-5 ">
																Pending
															</span>
														)}
													</h3>
													<hr className="m-0 mb-1 p-0 " />
													<div className="d-flex">
														<p>{d.task_description} </p>
													</div>
												</div>
												<div className="col-12 bg-light shadow-sm">
													<div className=" d-flex justify-content-between px-1  mt-2 ">
														<a
															className="fs-4 fw-bold text-dark "
															onClick={() => {
																setShowModal(true);
																setTaskId(d.id);
															}}
														>
															<Icon.Check2Circle />
														</a>
														<a
															className="fs-4 fw-bold text-warning "
															onClick={() => {
																navigate(`/visitor/${d.visitor.id}`);
															}}
														>
															<Icon.Eye />
														</a>
														<a
															href={`tel:+${d.visitor.mobile_number}`}
															className="fs-4 fw-bold text-primary "
														>
															<Icon.Telephone />
														</a>
														<a
															href={`sms:+${d.visitor.mobile_number}`}
															className="fs-4 fw-bold text-secondary "
														>
															<Icon.Chat />
														</a>
														<a
															href={`http://wa.me/+${d.visitor.whatsapp_number}?text=Hi`}
															className="fs-4 fw-bold text-success "
														>
															<Icon.Whatsapp />
														</a>
														<a
															href={`mailto:${d.visitor.email}`}
															className="fs-4 fw-bold text-danger "
														>
															<Icon.EnvelopeAt />
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<Modal
							show={showModal}
							onHide={() => {
								setShowModal(false);
								setTaskId(null);
							}}
						>
							<Modal.Header closeButton>
								<Modal.Title>Complete Task</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Are You Sure You have Completed the task ?
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="secondary"
									onClick={() => {
										setShowModal(false);
										setTaskId(null);
									}}
								>
									Cancel
								</Button>
								<Button
									variant="primary"
									onClick={() => {
										dispatch(
											complteTask(
												{
													task_id: taskId,
													is_completed: true,
												},
												getDashboardData
											)
										);
										setShowModal(false);
										setTaskId(null);
									}}
								>
									Yes I am Sure
								</Button>
							</Modal.Footer>
						</Modal>
					</div>
				</div>
			</div>
			<Modal
				show={showQr}
				onHide={() => {
					setShowQr(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Channel Partner Registration QR</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-center">
						<img
							src={"https://api.eazyapp.in" + userInfo?.extra?.qr}
							alt=""
							className="img-fluid img-thumbnail"
						/>
					</div>
				</Modal.Body>
			</Modal>
		</Layout>
	);
};

export default SEDashboard;
