import React, { useEffect, useState } from "react";
import { getInventory } from "../../Actions/SEAction";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout/Layout";
import { Modal ,Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { get_projects, getProjectWings } from "../../Actions/CommonAction";

const Column = ({ unit,projectId,inventoryId }) => {
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	return (
		<>
			<td
				colSpan={unit.colSpan}
				rowSpan={unit.rowSpan}
				className={` p-2 border-4 border-white rounded-3 ${
					unit.booked
						? "bg-danger"
						: unit.useable
						? "bg-success"
						: "bg-secondary"
				}`}
				onClick={() => unit.useable && setShow(true)}
			>
				<div className={`p-2 rounded  `}>
					<div className="py-2 text-center">
						<p className="text-white m-0">{unit.unit_no}</p>
					</div>
				</div>
			</td>
			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title> Unit {unit.unit_no} Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Configuration : {unit.config}</p>
					<p>Carpet Area : {unit.carpet_area} sq.ft.</p>
					<p>Booked : {unit.booked ? "Yes" : "No"}</p>
					{!unit.booked && (
						<button
							className="btn btn-dark w-100"
							onClick={() => {
								setShow(false);
								navigate(`/bookunit/${projectId}/${inventoryId}/${unit.unit_no}`)
							}}
						>
							Book Unit {unit.unit_no}
						</button>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

const Inventory = () => {
	const [units, setUnits] = useState(null);
	const [project, setProject] = useState(null);
	const [wing, setWing] = useState(null);
	const dispatch = useDispatch();
	const inventory = useSelector((state) => state?.se?.inventory);
	const projectsData = useSelector((state) => state?.project);
	const projectWings = useSelector((state) => state?.se?.projectWings);
	useEffect(()=>{
		setUnits(null);
		setProject(null);
		setWing(null);
	},[])
	const scrollToBottom=()=>{
		const inventoryBox = document.getElementById("inventoryBox");

		inventoryBox?.scrollTo(0, inventoryBox?.scrollHeight);
	}
	useEffect(() => {
		if (projectsData.projects === undefined) {
				dispatch(get_projects());
			} 
	}, []);

	useEffect(()=>{
		if (project !== null && wing === null) {
			dispatch(getProjectWings(project));
		}
	},[project])

	useEffect(() => {
		if (projectWings?.data?.wings !== undefined && project !== null) {
			setWing(projectWings?.data?.wings[0]);
		}
	}, [projectWings]);
	useEffect(() => {
		if (wing !== undefined && project !== null) {
			dispatch(
				getInventory({
					projectId: project,
					wing: wing,
				})
			);
		}
	}, [wing]);


	useEffect(() => {
		if (project !== null   && inventory?.data !== undefined) {
			setUnits(inventory?.data?.units);
		}
	}, [inventory]);

	return (
		<Layout pageTitle={"Inventory"}>
			{units !== null ? (
				<div className="h-100 position-relative" style={{ overflow: "hidden" }}>
					<div className="position-static h-10">
						<select
							className="form-select"
							defaultValue={wing}
							onChange={(e) => {
								setWing(e.target.value);
							}}
						>
							{projectWings?.data?.wings?.map((d) => {
								return <option value={d}>{d}</option>;
							})}
						</select>
					</div>

					<div
						id="inventoryBox"
						className="h-80"
						style={{ overflow: "scroll", transform: "scaleY(1)" }}
					>
						{scrollToBottom()}
						<Table>
							<thead>
								<tr>
									<th className="text-nowrap">#</th>
									{[...Array(units?.max_unit ? units?.max_unit : 0).keys()].map(
										(unit) => {
											return <th className="text-center">Unit {unit + 1}</th>;
										}
									)}
								</tr>
							</thead>
							<tbody>
								{Object.keys(units)
									.filter((key) => key !== "length" && key !== "max_unit")
									.sort(function (a, b) {
										return b - a;
									})
									.map((key) =>{
										const floor=units[key] 
										return(<tr>
											<td className="text-center">{key}</td>
											{floor.map((unit) => (
												<Column unit={unit}  inventoryId={inventory?.data?.id} projectId={project} />
											))}
										</tr>)
								})}
							</tbody>
						</Table>
					</div>
					<div className="h-10">
						<div className="d-flex flex-grow gap-3 p-3">
							<div>
								<span className="bg-success p-1 px-3 me-2 rounded "></span>
								Available
							</div>
							<div>
								<span className="bg-danger p-1 px-3 me-2 rounded "></span>
								Booked
							</div>
							<div>
								<span className="bg-light p-1 px-3 me-2 rounded "></span>
								N/a
							</div>
						</div>
					</div>
				</div>
			) : project === null ? (
				<>
					<div className="h-100 px-3">
						<div className="row">
							{projectsData?.projects?.map((i) => {
								return (
									<>
										<div className="p-2 col-md-6">
											<div
												className="rounded border shadow-sm px-3 py-2"
												onClick={() => {
													setProject(i.id);
												}}
											>
												<div className="row">
													<div className="col-3">
														<img
															className="img-fluid"
															src={"https://api.eazyapp.in" + i.cover_img}
														/>
													</div>
													<div className="col-9">
														<p className="m-0 card_heading text-capitalize ">
															{i.name}
														</p>
														<p className="m-0 text-capitalize ">{i.configs}</p>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</div>
					</div>
				</>
			) : units === null && wing === null ? (
				<div className="h-100">
					<div className="row row-cols-auto">
						{projectWings?.data?.wings?.map((i) => {
							return (
								<div className="col p-3">
									<div
										className="p-3 rounded border"
										onClick={() => {
											setWing(i);
										}}
									>
										{i}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className="h-100 w-100 d-flex justtify-content-center aligin-items-center">
					<p>loading</p>
				</div>
			)}
		</Layout>
	);
};

export default Inventory;
