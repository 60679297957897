import React, { useEffect } from "react";
import ImageUploader from "../../../../Components/ImageUploader";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { addGalleryItem } from "../../../../Actions/AdminAction";

const AddAdminGalleryItem = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { projectId } = useParams();
	const submitHandler = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		dispatch(addGalleryItem(formData,navigate,projectId));
	};
	return (
		<Layout pageTitle={"Edit Gallery File"}>
			{/* {JSON.stringify(itemDetail)} */}
            <form onSubmit={submitHandler} >
			<div className="d-grid gap-3 p-3">
				<div className="d-flex justify-content-center align-items-center">
					<div className="w-30">
						<ImageUploader dbImage={null} />
					</div>
				</div>
				<div>
					<label>Name</label>
					<input className="form-control" type="text" name="name" />
				</div>
					<div>
						<label id="item_type">Type</label>
						<select name="item_type" className="form-select" id="item_type"  >
							{['Elevation', 'Amenities', 'Master Plan', 'Floor Plan', 'Location Map', 'Exterior', 'Interior', 'Others', 'Entrance Lobby', 'View'].map((type) => {
								return <option value={type}>{type}</option>
							})}
						</select>
					</div>
				<div className="justify-content-end d-flex gap-3">
					<button type="submit" className="btn btn-primary">
						Save
					</button>
					<button type="button" className="btn btn-outline-danger" onClick={()=>{
                        navigate(-1)
                    }} > 
						Cancel
					</button>
				</div>
			</div>
            </form>
		</Layout>
	);
};

export default AddAdminGalleryItem;
