import {
	GET_DASHBOARD_DATA_REQUEST,
	GET_DASHBOARD_DATA_SUCCESS,
	GET_DASHBOARD_DATA_FAIL,
	//
	GET_TEAMS_DATA_REQUEST,
	GET_TEAMS_DATA_SUCCESS,
	GET_TEAMS_DATA_FAIL,
	//
	GET_VISITS_DATA_REQUEST,
	GET_VISITS_DATA_SUCCESS,
	GET_VISITS_DATA_FAIL,
	//
	SEND_VISITOR_OTP_REQUEST,
	SEND_VISITOR_OTP_SUCCESS,
	SEND_VISITOR_OTP_FAIL,
	//
	VERIFY_VISITOR_OTP_REQUEST,
	VERIFY_VISITOR_OTP_SUCCESS,
	VERIFY_VISITOR_OTP_FAIL,
	//
	SAVE_CHECKIN_DATA_REQUEST,
	SAVE_CHECKIN_DATA_SUCCESS,
	SAVE_CHECKIN_DATA_FAIL,
	//
	GET_AVAILBALE_EXECUTIVES_REQUEST,
	GET_AVAILBALE_EXECUTIVES_SUCCESS,
	GET_AVAILBALE_EXECUTIVES_FAIL,
	//
	GET_LEAD_DETAIL_REQUEST,
	GET_LEAD_DETAIL_SUCCESS,
	GET_LEAD_DETAIL_FAIL,
} from "../Constants/CREConstant";
import axios from "./axios";
import toast from "react-hot-toast";

export const getDashboardDataAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_DASHBOARD_DATA_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get("cre/dashborad/", config);

		dispatch({
			type: GET_DASHBOARD_DATA_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_DASHBOARD_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getTeamsDataAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_TEAMS_DATA_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get("cre/teams/", config);

		dispatch({
			type: GET_TEAMS_DATA_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_TEAMS_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const checkInTeamMemeber = (id) => async (dispatch, getState) => {
	try {
		toast.loading("Cheching In Please Wait.");
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.get(`cre/team/check-in/${id}/`, config);
		dispatch(getTeamsDataAction());
		toast.dismiss();
		toast.success("ChecK In SuccessFull");
	} catch (error) {

	}
};

export const checkOutTeamMemeber = (id) => async (dispatch, getState) => {
	try {
		toast.loading("Cheching Out Please Wait.");
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get(`cre/team/check-out/${id}/`, config);
		dispatch(getTeamsDataAction());
		toast.dismiss();
		toast.success("ChecK Out SuccessFull");
	} catch (error) {
	}
};

export const getVisitsDataAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_VISITS_DATA_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.get("cre/visits/", config);

		dispatch({
			type: GET_VISITS_DATA_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_VISITS_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const sendOTPVisitor =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			// toast.loading(`Sending OTP to ${postData.mobile_number} `);
			dispatch({
				type: SEND_VISITOR_OTP_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo.token}`,
				},
			};

			const { data } = await axios.post(
				"cre/send-otp-to-visitor/",
				postData,
				config
			);
			toast.success("OTP Sent");
			navigate(`/check-in/verify-mobile-number/${data.mobile_number}`);
			dispatch({
				type: SEND_VISITOR_OTP_SUCCESS,
				payload: data,
			});
		} catch (error) {
			toast.error("Try again!");
			dispatch({
				type: SEND_VISITOR_OTP_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const verifyOTPVisitor =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			// toast.loading("Verifing OTP");
			dispatch({
				type: VERIFY_VISITOR_OTP_REQUEST,
			});

			const {
				userState: { userInfo },
				cre: { checkInDetial:{checkInData} },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo.token}`,
				},
			};

			const { data } = await axios.post(
				"cre/verfiy-visitor-otp/",
				postData,
				config
			);
			if (checkInData !== null && checkInData !== undefined) {
				var keys = Object.keys(checkInData);
				keys.map((key) => (data.data[key] = checkInData[key]));
			}

			dispatch({
				type: "ADD_CHECKIN_DATA",
				payload: data.data,
			});
				localStorage.setItem("checkInData", JSON.stringify(data.data));
			// localStorage.setItem("checkInData", JSON.stringify(data.data));
			navigate(`/check-in/${data.route_to}`);
			
			dispatch({
				type: VERIFY_VISITOR_OTP_SUCCESS,
				payload: data.data,
			});
		} catch (error) {
			toast.error(
				error.response && error.response.data.error
					? error.response.data.error
					: error.message
			);
			dispatch({
				type: VERIFY_VISITOR_OTP_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const saveCheckInData =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: SAVE_CHECKIN_DATA_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo.token}`,
				},
			};
			const { data } = await axios.post(
				"save/check-in-detail/",
				postData,
				config
			);
			navigate(`/check-in/${data.visitor_id}/upload-photo/${data.visit_id}`,);

			dispatch({
				type: SAVE_CHECKIN_DATA_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SAVE_CHECKIN_DATA_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getAvailableExcutives = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_AVAILBALE_EXECUTIVES_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.get("get/available-executives/", config);

		dispatch({
			type: GET_AVAILBALE_EXECUTIVES_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_AVAILBALE_EXECUTIVES_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const assginExecutive =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			toast.loading("Assiging Executive");
			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo.token}`,
				},
			};
			const { data } = await axios.post("assign-executive/", postData, config);
			toast.dismiss();
			toast.success("Assgined Executive SuccessFully");
			navigate("/");
			dispatch({
				type: "RESET_CHECKIN_DATA",
			});
		} catch (error) {
			toast.dismiss();
			toast.error("Try Again To Assgin");
		}
	};



export const getLeadDetail=(postData)=>async(dispatch,getState)=>{
	try{
		dispatch({
			type:GET_LEAD_DETAIL_REQUEST
		})

		const {userState:{userInfo}}=getState();
		const config={
			headers:{
				"Content-type":"application/json",
				"Authorization":`Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post("get/lead-details/", postData, config);
		dispatch({
			type:GET_LEAD_DETAIL_SUCCESS,
			payload:data
		})

	}catch(error){
		toast.error("Qr Not Found. Please Try Again with vaild QR Code");
		dispatch({
			type: GET_LEAD_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}