import React,{ useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDeveloperSetting, updateDeveloperSetting } from '../../../Actions/AdminAction';
import { getSettingType } from '../../../Actions/MyAdminAction';
const EditSetting = () => {
    const [settings,setSettings]=useState({})
    const dispatch = useDispatch();
    const { settingId } = useParams();
  
    const { developerSettingDetail ,error , loding}=useSelector(state => state.myAdmin?.getDeveloperSettingDetail)

    useEffect(() => {
        if (developerSettingDetail){
            setSettings(developerSettingDetail?.settings)
        }

     }, [developerSettingDetail])

    useEffect(()=>{
        dispatch(getDeveloperSetting(settingId))
    },[])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const object = Object.fromEntries(formData);
        dispatch(updateDeveloperSetting(settingId, { settings: object }))
    }

  return (
    <Layout pageTitle={"Edit Setting"}>
            
        <form onSubmit={submitHandler}>
            <div className="d-grid gap-3 p-3">
                <h3>{developerSettingDetail?.setting_type} - {developerSettingDetail?.name}</h3>

                  {Object.keys(settings).map(key => {
                    return <div>
                        <label className='text-capitalize'>{key.replaceAll("_", " ")}</label>
                        <input type="text" name={key} value={settings[key]} onChange={e=>setSettings({...settings,[key]:e.target.value})} className="form-control" />
                    </div>

                })}
                <div className="text-end">
                    <button className='btn btn-success' type="submit" >Submit</button>
                </div>
            </div>
        </form>
    </Layout>
  )
}

export default EditSetting
