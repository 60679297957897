import {
	GET_DASHBOARD_DATA_REQUEST,
	GET_DASHBOARD_DATA_SUCCESS,
	GET_DASHBOARD_DATA_FAIL,
	//
	GET_TEAMS_DATA_REQUEST,
	GET_TEAMS_DATA_SUCCESS,
	GET_TEAMS_DATA_FAIL,
	//
	GET_VISITS_DATA_REQUEST,
	GET_VISITS_DATA_SUCCESS,
	GET_VISITS_DATA_FAIL,
	//
	VERIFY_VISITOR_OTP_REQUEST,
	VERIFY_VISITOR_OTP_SUCCESS,
	VERIFY_VISITOR_OTP_FAIL,
	//
	GET_AVAILBALE_EXECUTIVES_REQUEST,
	GET_AVAILBALE_EXECUTIVES_SUCCESS,
	GET_AVAILBALE_EXECUTIVES_FAIL,
	//
	GET_LEAD_DETAIL_REQUEST,
	GET_LEAD_DETAIL_SUCCESS,
	GET_LEAD_DETAIL_FAIL,
} from "../Constants/CREConstant";


export const creDashboardReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DASHBOARD_DATA_REQUEST:
			return { loading: true,};
		case GET_DASHBOARD_DATA_SUCCESS:
			return { loading: false, creDashboardData: action.payload };
		case GET_DASHBOARD_DATA_FAIL:
			return { loading: false, error: action.payload};
		default:
			return state;
	}
};


export const creTeamsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TEAMS_DATA_REQUEST:
			return { loading: true };
		case GET_TEAMS_DATA_SUCCESS:
			return { loading: false, creTeamsData: action.payload };
		case GET_TEAMS_DATA_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
export const creVisitReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_VISITS_DATA_REQUEST:
			return { loading: true };
		case GET_VISITS_DATA_SUCCESS:
			return { loading: false, creVisitsData: action.payload };
		case GET_VISITS_DATA_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};


export const checkInDetialsReducer = (state = {}, action) => {
	switch(action.type) {
		case "ADD_CHECKIN_DATA":
			localStorage.setItem("checkInData", JSON.stringify(action.payload));
			return {checkInData:action.payload};
		case "RESET_CHECKIN_DATA":
			localStorage.removeItem("checkInData");
			return {}
		default:
			return state;
	}
}


export const AvailableExcutivesReducer=(state={},action)=>{
	switch(action.type) {
		case GET_AVAILBALE_EXECUTIVES_REQUEST:
			return {loading: true};
		case GET_AVAILBALE_EXECUTIVES_SUCCESS:
			return {loading:false,excutives:action.payload};
		
		case GET_AVAILBALE_EXECUTIVES_FAIL:
			return {loading:false,error:action.payload};
		default:
			return state;
	}
}


export const leadDetailReducer=(state={},action)=>{
	switch(action.type){
		case GET_LEAD_DETAIL_REQUEST:
			return {loading:true};
		case GET_LEAD_DETAIL_SUCCESS:
			return {loading:false,detail:action.payload}
		case GET_LEAD_DETAIL_FAIL:
			return {loading:false,error:action.payload}
		default:
			return state
	}
}