import React ,{useEffect}from 'react'
import Layout from './Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCustomerPaymentSchedule } from '../../Actions/CustomerAction'
import numberToWords from '../numberFormater'


const PaymentSchedule = () => {
    const dispatch = useDispatch();

    const { schedule, error, loading } = useSelector(state => state.customer?.paymentSchedule)
    const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);
    useEffect(() => {
        dispatch(getCustomerPaymentSchedule({booking_id:currentBookingId}))
    }, [])

    return (
        <Layout pageTitle={"Payment Schedule"} >
            <div className="p-3">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">MileStone</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date to Ask</th>
                            <th scope="col">Payed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            schedule?.map((item,index) => {
                                return <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.key}</td>
                                    <td>{numberToWords(item.value)}</td>
                                    <td>{item.date_to_ask}</td>
                                    <td>{item.is_completed ? "Yes": "No"}</td>
                                </tr>
                            })
                        }

                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

export default PaymentSchedule
