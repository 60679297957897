import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTaskAction, complteTask } from "../../Actions/CommonAction";
import * as Icon from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import Loading from "../../Components/Loading";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
const UpcommingTask = (params) => {
	const { navigate, tasks, setShowModal, setTaskId, setCompleted } = params;
	return (
		<div className="h-100 p-2 ">
			<div>
				<h2 className="section_heading px-3 my-3">Today's Task</h2>
				<div className="row pb-5 pb-md-0  px-3">
					{tasks?.filter(
						(task) =>
							moment(task.task_date_time).format("DD-MM-YYYY") ===
							moment().format("DD-MM-YYYY")
					).length === 0 && (
						<div className="col-lg-5 shadow border rounded p-3">
							<h1 className="my-5 text-center">Todays Not Available</h1>
						</div>
					)}
					{tasks?.map((task) => {
						if (
							moment(task.task_date_time).format("DD-MM-YYYY") ===
							moment().format("DD-MM-YYYY")
						) {
							return (
								<div className="col-md-6 ">
									<div className="border shadow rounded-2 p-2 my-2">
										<div className="row">
											<div className="col-4 col-lg-3 p-2">
												<div className="d-flex justify-content-center align-items-center">
													<div
														className="w-90 position-relative"
														style={{ height: "100px", width: "100px" }}
													>
														{task.pending && !task.is_completed && (
															<span className="bg-danger rotate-330 opacity-25 ribbon text-white px-2 py-0 border-white rounded-5 ">
																Pending
															</span>
														)}
														<img
															className="img-thumbnail  rounded-circle"
															src={
																"https://api.eazyapp.in" + task.visitor.image
															}
															alt=""
															style={{ height: "100%", width: "100%" }}
														/>
													</div>
												</div>
											</div>
											<div className="col-8 col-lg-9">
												<h3 className="d-flex card_heading">
													{task.task_type} : {task.visitor.first_name}
												</h3>
												<hr className="m-0 mb-1 p-0 " />
												<div className="d-flex">
													<p>{task.task_description} </p>
												</div>
												<p>
													Task Date:{" "}
													{moment(task.task_date_time).format("DD-MM-YYYY")}
												</p>
											</div>
											<div className="col-12 bg-light shadow-sm">
												<div className=" d-flex justify-content-between px-1  mt-2 ">
													<a
														className="fs-4 fw-bold text-dark "
														onClick={() => {
															setShowModal(true);
															setTaskId(task.id);
															setCompleted(!task.is_completed);
														}}
													>
														<Icon.Check2Circle />
													</a>
													<a
														className="fs-4 fw-bold text-warning "
														onClick={() => {
															navigate(`/visitor/${task.visitor.id}`);
														}}
													>
														<Icon.Eye />
													</a>
													<a
														href={`tel:+${task.visitor.mobile_number}`}
														className="fs-4 fw-bold text-primary "
													>
														<Icon.Telephone />
													</a>
													<a
														href={`sms:+${task.visitor.mobile_number}`}
														className="fs-4 fw-bold text-secondary "
													>
														<Icon.Chat />
													</a>
													<a
														href={`http://wa.me/${task.visitor.whatsapp_number}?text=Hi`}
														className="fs-4 fw-bold text-success "
													>
														<Icon.Whatsapp />
													</a>
													<a
														href={`mailto:${task.visitor.email}`}
														className="fs-4 fw-bold text-danger "
													>
														<Icon.EnvelopeAt />
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						}
					})}
				</div>
				<h2 className="section_heading px-3 my-3">Future Task</h2>
				<div className="row pb-5 pb-md-0 px-3">
					{tasks?.filter(
						(task) =>
							moment(task.task_date_time).format("DD-MM-YYYY") !==
							moment().format("DD-MM-YYYY")
					).length === 0 && (
						<div className="col-lg-5 shadow border rounded p-3">
							<h1 className="my-5 text-center">Upcomming Not Available</h1>
						</div>
					)}
					{tasks?.map((task) => {
						if (
							moment(task.task_date_time).format("DD-MM-YYYY") !==
							moment().format("DD-MM-YYYY")
						) {
							return (
								<div className="col-md-6">
									<div className="border shadow rounded-2 p-2 my-2">
										<div className="row">
											<div className="col-4 col-lg-3 p-2">
												<div className="d-flex justify-content-center align-items-center">
													<div
														className="w-90 position-relative"
														style={{ height: "100px", width: "100px" }}
													>
														{task.pending && !task.is_completed && (
															<span className="bg-danger rotate-330 opacity-25 ribbon text-white px-2 py-0 border-white rounded-5 ">
																Pending
															</span>
														)}
														<img
															className="img-thumbnail  rounded-circle"
															src={
																"https://api.eazyapp.in" + task.visitor.image
															}
															alt=""
															style={{ height: "100%", width: "100%" }}
														/>
													</div>
												</div>
											</div>
											<div className="col-8 col-lg-9">
												<h3 className="d-flex card_heading">
													{task.task_type} : {task.visitor.first_name}
												</h3>
												<hr className="m-0 mb-1 p-0 " />
												<div className="d-flex">
													<p>{task.task_description} </p>
												</div>
												<p>
													Task Date:{" "}
													{moment(task.task_date_time).format("DD-MM-YYYY")}
												</p>
											</div>
											<div className="col-12 bg-light shadow-sm">
												<div className=" d-flex justify-content-between px-1  mt-2 ">
													<a
														className="fs-4 fw-bold text-dark "
														onClick={() => {
															setShowModal(true);
															setTaskId(task.id);
															setCompleted(!task.is_completed);
														}}
													>
														<Icon.Check2Circle />
													</a>
													<a
														className="fs-4 fw-bold text-warning "
														onClick={() => {
															navigate(`/visitor/${task.visitor.id}`);
														}}
													>
														<Icon.Eye />
													</a>
													<a
														href={`tel:+${task.visitor.mobile_number}`}
														className="fs-4 fw-bold text-primary "
													>
														<Icon.Telephone />
													</a>
													<a
														href={`sms:+${task.visitor.mobile_number}`}
														className="fs-4 fw-bold text-secondary "
													>
														<Icon.Chat />
													</a>
													<a
														href={`http://wa.me/${task.visitor.whatsapp_number}?text=Hi`}
														className="fs-4 fw-bold text-success "
													>
														<Icon.Whatsapp />
													</a>
													<a
														href={`mailto:${task.visitor.email}`}
														className="fs-4 fw-bold text-danger "
													>
														<Icon.EnvelopeAt />
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};

const CompletedTask = (params) => {
	const { navigate, tasks, setShowModal, setTaskId, setCompleted } = params;
	return (
		<div className="h-100 p-2">
			<div className="row  pb-5 pb-md-0  px-3">
				{tasks?.length === 0 && (
					<div className="col-lg-5 shadow border rounded p-3">
						<h1 className="my-5 text-center">Completed Task Not Available</h1>
					</div>
				)}
				{tasks?.map((task) => {
					return (
						<div className="col-md-6">
							<div className="border shadow rounded-2 p-2 my-2">
								<div className="row">
									<div className="col-4 col-lg-3 p-2">
										<div className="d-flex justify-content-center align-items-center">
											<div
												className="w-90 position-relative"
												style={{ height: "100px", width: "100px" }}
											>
												{task.pending && !task.is_completed && (
													<span className="bg-danger rotate-330 opacity-25 ribbon text-white px-2 py-0 border-white rounded-5 ">
														Pending
													</span>
												)}
												<img
													className="img-thumbnail  rounded-circle"
													src={"https://api.eazyapp.in" + task.visitor.image}
													alt=""
													style={{ height: "100%", width: "100%" }}
												/>
											</div>
										</div>
									</div>
									<div className="col-8 col-lg-9">
										<h3 className="d-flex card_heading">
											{task.task_type} : {task.visitor.first_name}
										</h3>
										<hr className="m-0 mb-1 p-0 " />
										<div className="d-flex">
											<p>{task.task_description} </p>
										</div>
										<p>
											Task Date:{" "}
											{moment(task.task_date_time).format("DD-MM-YYYY")}
										</p>
									</div>
									<div className="col-12 bg-light shadow-sm">
										<div className=" d-flex justify-content-between px-1  mt-2 ">
											<a
												className="fs-4 fw-bold text-success "
												onClick={() => {
													setShowModal(true);
													setTaskId(task.id);
													setCompleted(!task.is_completed);
												}}
											>
												<Icon.Check2Circle />
											</a>
											<a
												className="fs-4 fw-bold text-warning "
												onClick={() => {
													navigate(`/visitor/${task.visitor.id}`);
												}}
											>
												<Icon.Eye />
											</a>
											<a
												href={`tel:+${task.visitor.mobile_number}`}
												className="fs-4 fw-bold text-primary "
											>
												<Icon.Telephone />
											</a>
											<a
												href={`sms:+${task.visitor.mobile_number}`}
												className="fs-4 fw-bold text-secondary "
											>
												<Icon.Chat />
											</a>
											<a
												href={`http://wa.me/+${task.visitor.whatsapp_number}?text=Hi`}
												className="fs-4 fw-bold text-success "
											>
												<Icon.Whatsapp />
											</a>
											<a
												href={`mailto:${task.visitor.email}`}
												className="fs-4 fw-bold text-danger "
											>
												<Icon.EnvelopeAt />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const PendingTask = (params) => {
	const { navigate, tasks, setShowModal, setTaskId, setCompleted } = params;

	return (
		<div className="h-100  p-2">
			<div className="row pb-5 pb-md-0 px-3">
				{tasks?.length === 0 && (
					<div className="col-lg-5 shadow border rounded p-3">
						<h1 className="my-5 text-center">Overdue Task Not Available</h1>
					</div>
				)}
				{tasks?.map((task) => {
					return (
						<div className="col-md-6 ">
							<div className="border shadow rounded-2 p-2 my-2">
								<div className="row">
									<div className="col-4 col-lg-3 p-2">
										<div className="d-flex justify-content-center align-items-center">
											<div
												className="w-90 position-relative"
												style={{ height: "100px", width: "100px" }}
											>
												{task.pending && !task.is_completed && (
													<span className="bg-danger rotate-330 opacity-25 ribbon text-white px-2 py-0 border-white rounded-5 ">
														Pending
													</span>
												)}
												<img
													className="img-thumbnail  rounded-circle"
													src={"https://api.eazyapp.in" + task.visitor.image}
													alt=""
													style={{ height: "100%", width: "100%" }}
												/>
											</div>
										</div>
									</div>
									<div className="col-8 col-lg-9">
										<h3 className="d-flex card_heading  ">
											{task.task_type} : {task.visitor.first_name}
										</h3>
										<hr className="m-0 mb-1 p-0 " />
										<div className="d-flex">
											<p>{task.task_description} </p>
										</div>
										<p>
											Task Date:{" "}
											{moment(task.task_date_time).format("DD-MM-YYYY")}
										</p>
									</div>
									<div className="col-12 bg-light shadow-sm">
										<div className=" d-flex justify-content-between px-1  mt-2 ">
											<a
												className="fs-4 fw-bold text-dark "
												onClick={() => {
													setShowModal(true);
													setTaskId(task.id);
													setCompleted(!task.is_completed);
												}}
											>
												<Icon.Check2Circle />
											</a>
											<a
												className="fs-4 fw-bold text-warning "
												onClick={() => {
													navigate(`/visitor/${task.visitor.id}`);
												}}
											>
												<Icon.Eye />
											</a>
											<a
												href={`tel:+${task.visitor.mobile_number}`}
												className="fs-4 fw-bold text-primary "
											>
												<Icon.Telephone />
											</a>
											<a
												href={`sms:+${task.visitor.mobile_number}`}
												className="fs-4 fw-bold text-secondary "
											>
												<Icon.Chat />
											</a>
											<a
												href={`http://wa.me/${task.visitor.whatsapp_number}?text=Hi`}
												className="fs-4 fw-bold text-success "
											>
												<Icon.Whatsapp />
											</a>
											<a
												href={`mailto:${task.visitor.email}`}
												className="fs-4 fw-bold text-danger "
											>
												<Icon.EnvelopeAt />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const SETasks = () => {
	const [showModal, setShowModal] = useState(false);
	const [taskId, setTaskId] = useState(null);
	const [completed, setCompleted] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { loading, tasks, error } = useSelector((state) => state?.se?.taskData);

	const [page, setPage] = useState("upcomming");

	useEffect(() => {
		dispatch(getTaskAction(page));
	}, [page]);

	const TaskPage = {
		["upcomming"]: UpcommingTask,
		["completed"]: CompletedTask,
		["pending"]: PendingTask,
	}[page];
	return (
		<Layout pageTitle={"Task"}>
			<div
				className="h-100 py-2"
				style={{ overflowY: "scroll", overflowX: "hidden" }}
			>
				<div className="bg-white  sticky-top no-wrap">
					<Nav
						variant="tabs"
						bg={"light"}
						className="justify-content-center no-wrap "
						defaultActiveKey={page}
					>
						<Nav.Item>
							<Nav.Link
								eventKey="upcomming"
								onClick={() => setPage("upcomming")}
							>
								Upcoming
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="pending" onClick={() => setPage("pending")}>
								Overdue
							</Nav.Link>
						</Nav.Item>

						<Nav.Item>
							<Nav.Link
								eventKey="completed"
								onClick={() => setPage("completed")}
							>
								Completed
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</div>

				{loading ? (
					<div className="h-100 d-flex align-items-center justify-content-center">
						<Loading />
					</div>
				) : (
					<TaskPage
						navigate={navigate}
						tasks={tasks}
						setShowModal={setShowModal}
						setTaskId={setTaskId}
						setCompleted={setCompleted}
					/>
				)}
				<Modal
					show={showModal}
					onHide={() => {
						setShowModal(false);
						setTaskId(null);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>Complete Task</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{completed
							? "Are You Sure You have Finshed the task ?"
							: "Are You Sure You want to Un-Finshed the task "}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => {
								setShowModal(false);
								setTaskId(null);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={() => {
								dispatch(
									complteTask(
										{
											task_id: taskId,
											is_completed: completed,
										},
										getTaskAction,
										page
									)
								);
								setShowModal(false);
								setTaskId(null);
								setCompleted(false);
							}}
						>
							Yes I am Sure
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</Layout>
	);
};

export default SETasks;
