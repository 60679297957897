import {
	GET_TASK_REQUEST,
	GET_TASK_SUCCESS,
	GET_TASK_FAIL,
	//
	GET_ALL_PROJECTS_REQUEST,
	GET_ALL_PROJECTS_SUCCESS,
	GET_ALL_PROJECTS_FAIL,
	//
	GET_REPORT_REQUEST,
	GET_REPORT_SUCCESS,
	GET_REPORT_FAIL,
	//
	GET_PROJECT_REPORT_REQUEST,
	GET_PROJECT_REPORT_SUCCESS,
	GET_PROJECT_REPORT_FAIL,
	//
	GET_PROJECTS_WING_REQUEST,
	GET_PROJECTS_WING_SUCCESS,
	GET_PROJECTS_WING_FAIL,
	//
	GET_PROJECT_COLLATERALS_REQUEST,
	GET_PROJECT_COLLATERALS_SUCCESS,
	GET_PROJECT_COLLATERALS_FAIL,
	// 
	GET_PROJECT_OFFERS_REQUEST,
	GET_PROJECT_OFFERS_SUCCESS,
	GET_PROJECT_OFFERS_FAIL,
	// 
	GET_PROJECT_PAYMENT_SCHEDULE_REQUEST,
	GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS,
	GET_PROJECT_PAYMENT_SCHEDULE_FAIL,
	// 
	GET_PROJECT_PRICING_LIST_REQUEST,
	GET_PROJECT_PRICING_LIST_SUCCESS,
	GET_PROJECT_PRICING_LIST_FAIL,
	// 
	START_LOADING,
	END_LOADING,
	// 
} from "../Constants/CommonConstants";

export const taskReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TASK_REQUEST:
			return { loading: true };
		case GET_TASK_SUCCESS:
			return { loading: false, tasks: action.payload };
		case GET_TASK_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_PROJECTS_REQUEST:
			return { loading: true };
		case GET_ALL_PROJECTS_SUCCESS:
			return { loading: false, projects: action.payload };
		case GET_ALL_PROJECTS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
export const ReportReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_REPORT_REQUEST:
			return { loading: true };
		case GET_REPORT_SUCCESS:
			return { loading: false, reports: action.payload };
		case GET_REPORT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const ProjectReportReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_REPORT_REQUEST:
			return { loading: true };
		case GET_PROJECT_REPORT_SUCCESS:
			return { loading: false, reports: action.payload };
		case GET_PROJECT_REPORT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectWingsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECTS_WING_REQUEST:
			return { loading: true };
		case GET_PROJECTS_WING_SUCCESS:
			return { loading: false, data: action.payload };
		case GET_PROJECTS_WING_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const ProjectCollateralsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_COLLATERALS_REQUEST:
			return { loading: true };
		case GET_PROJECT_COLLATERALS_SUCCESS:
			return { loading: false, files: action.payload };
		case GET_PROJECT_COLLATERALS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const ProjectOffersReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_OFFERS_REQUEST:
			return { loading: true };
		case GET_PROJECT_OFFERS_SUCCESS:
			return { loading: false, list: action.payload };
		case GET_PROJECT_OFFERS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};


export const ProjectPaymentScheduleReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_PAYMENT_SCHEDULE_REQUEST:
			return { loading: true };
		case GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS:
			return { loading: false, list: action.payload };
		case GET_PROJECT_PAYMENT_SCHEDULE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};


export const ProjectPricingListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_PRICING_LIST_REQUEST:
			return { loading: true };
		case GET_PROJECT_PRICING_LIST_SUCCESS:
			return { loading: false, list: action.payload };
		case GET_PROJECT_PRICING_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}

export const loadingReducer = (state = false, action) => {
	switch (action.type) {
		case START_LOADING:
			return true;
		case END_LOADING:
			return false;
		default:
			return state;
	}
}
