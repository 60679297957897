import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDeveloperChannelPartner } from "../../Actions/AdminAction";
import moment from "moment";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
const ChannelPartners = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [query, setQuery] = useState("");
	const [serach, setSearch] = useState("");
	const [dataCount, setDataCount] = useState(20);
	const [hasMore, setHasMore] = useState(false);
	const {
		loading,
		error,
		channel_partners,
		total_channel_partners,
		pages,
		page: currentPage,
		next_page,
	} = useSelector((state) => state?.developer?.channelPartnerList);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		if (channel_partners) {
			setData(channel_partners);
			setPage(currentPage);
			setDataCount(total_channel_partners);
			setHasMore(next_page);
		}
	}, [channel_partners]);

	useEffect(() => {
		const Debouncer = setTimeout(() => {
			setQuery(serach);
		}, 500);
		return () => clearTimeout(Debouncer);
	}, [serach]);
	
	useEffect(() => {
		let params = {
			page: page,
		};
		if (query) {
			params["query"] = query;
		}
		dispatch(getDeveloperChannelPartner(params));
	}, [page, query]);

	return (
		<Layout pageTitle={"Channel Partner"}>
			<div className="p-3">
				<input type="text" className="form-control"  placeholder="Search for Channel Partner" onChange={(e) => setSearch(e.target.value)}/>
			</div>
			<InfiniteScroll
				dataLength={dataCount}
				next={() => setPage(page + 1)}
				className="row "
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				scrollableTarget="scrollableDiv"
			>
				{data.map((cp) => {
					return (
						<div className="col-md-6 p-3">
							<div className="p-2 position-relative">
								<div className="position-absolute end-0 mt-1 me-3 d-flex gap-2">
									<button
										className="btn text-muted"
										onClick={() => {
											navigate(`/channel-partner/${cp.id}`);
										}}
									>
										<FontAwesomeIcon icon={icon.faEye} />{" "}
									</button>
								</div>
								<div className="rounded shadow-sm border p-2 ">
									<div className="d-flex">
										<img
											className="me-3 img-thumbnail"
											src={"https://api.eazyapp.in" + cp.logo}
											alt=""
											style={{ height: "100px", width: "100px" }}
										/>
										<div className="p-2">
											<h3 className="card_heading m-0">{cp.name}</h3>
											<p className="m-0 fs-7">{cp.org_type}</p>
											<p className="m-0">
												<span className="text-muted">Established Year:</span>{" "}
												{moment(cp.established_year).format("Y")}
											</p>
											{/* <div className=" pt-1">
                                                <button className="btn btn-dark">
                                                    View Detail
                                                </button>
                                            </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						
					);
				})}
			</InfiniteScroll>
			<div className="justify-content-end d-flex gap-3">
			<button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Back</button>
			</div>
		</Layout>
		
		
	);
};

export default ChannelPartners;
