import React from 'react'
import Layout from './Layout/Layout'
import { addCustomerDocument } from "../../Actions/CustomerAction"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const AddDocument = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const submitHandler=(event)=>{
    event.preventDefault()
    const formData=new FormData(event.target)
    dispatch(addCustomerDocument(formData))
  }

  return (
    <Layout pageTitle={"Add Document"}>
      <form onSubmit={submitHandler} >
        <div className="d-grid gap-3 p-2">
          <div>
            <label className="text-muted">Select File Type</label>
            <select className="form-select" name="file_type">
              {["Aadhar Card", "Voter ID", "Driving License", "Passport","Pan Card","POA","Other"].map(
                (fileType) => {
                  return (
                    <option
                      value={fileType}
                    >
                      {fileType}
                    </option>
                  )
                }
              )}
            </select>
          </div>
          <div>
            <label htmlFor="formFile" className="form-label">Upload File</label>
            <input type="file" className="form-control" />
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success">Save</button>
            <button
              className="btn btn-dark"
              type='button'
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default AddDocument
