import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { getLeads, getProjects, deleteCPLead } from "../../Actions/CPAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CPLeads = () => {
	const [page, setPage] = useState("leads");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { leads, loading } = useSelector((state) => state?.cp?.leads);

	useEffect(() => {
		dispatch(getLeads());
	}, []);

	const getPage = (page) => {
		switch (page) {
			case "leads":
				return (
					<div>
						{leads?.leads?.length === 0 &&
							<h1 className="my-5 text-center">No Leads Available</h1>
						}
						{leads?.leads?.map((lead) => {
							return (
								<div className="col-md-6 m-0 p-2">
									<div className="rounded shadow w-100 border p-3">
										<div
											onClick={() => {
												navigate(`/lead/${lead?.id}/`);
											}}
										>
											<h6 className="card_heading">{lead.lead_name}</h6>
											<p className="m-0">
												<b>Email:</b> {lead?.email}
											</p>
											<p className="m-0">
												<b>Configurations:</b> {lead?.config?.join(", ")}
											</p>
											<p className="m-0">
												<b>Number Of Projects Tagged:</b>{" "}
												{lead?.projects_tagged}
											</p>
										</div>
										<div className="row mt-2">
											<div className="col-6">
												<button
													className="btn btn-danger w-100"
													onClick={() => {
														dispatch(deleteCPLead(lead?.id));
													}}
												>
													{" "}
													<FontAwesomeIcon icon={icon.faTrash} /> Delete Lead
												</button>
											</div>
											<div className="col-6 ">
												<button
													className="btn btn-dark w-100"
													onClick={() => {
														navigate(`/lead/${lead?.id}/`);
													}}
												>
													{" "}
													<FontAwesomeIcon icon={icon.faCircleInfo} /> Lead
													Details
												</button>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				);
			case "site_visit":
				return (
					<div>
						{leads?.site_visit?.length === 0 && (
							<h1 className="my-5 text-center">No Site Visits Available</h1>
						)}
						{leads?.site_visit?.map((lead) => {
							return (
								<div className="col-md-6 m-0 p-2">
									<div className="rounded shadow w-100 border p-3">
										<div
											onClick={() => {
												navigate(`/lead/${lead?.id}/`);
											}}
										>
											<h6 className="card_heading">{lead.lead_name}</h6>
											<p className="m-0">
												<b>Email:</b> {lead?.email}
											</p>
											<p className="m-0">
												<b>Configurations:</b> {lead?.config?.join(", ")}
											</p>
											<p className="m-0">
												<b>Number Of Projects Tagged:</b>{" "}
												{lead?.projects_tagged}
											</p>
										</div>
										<div className="row mt-2">
											<div className="col-6">
												{/* <button
													className="btn btn-danger w-100"
													onClick={() => {
														dispatch(deleteCPLead(lead?.id));
													}}
												>
													{" "}
													<FontAwesomeIcon icon={icon.faTrash} /> Delete Lead
												</button> */}
											</div>
											<div className="col-6 ">
												<button
													className="btn btn-dark w-100"
													onClick={() => {
														navigate(`/lead/${lead?.id}/`);
													}}
												>
													{" "}
													<FontAwesomeIcon icon={icon.faCircleInfo} /> Lead
													Details
												</button>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				);
			case "booked":
				return (
					<div>
						{leads?.booked?.length === 0 && (
							<h1 className="my-5 text-center">No Bookings Available</h1>
						)}
						{leads?.booked?.map((lead) => {
							return (
								<div className="col-md-6 m-0 p-2">
									<div className="rounded shadow w-100 border p-3">
										<div
											onClick={() => {
												navigate(`/lead/${lead?.id}/`);
											}}
										>
											<h6 className="card_heading">{lead.lead_name}</h6>
											<p className="m-0">
												<b>Email:</b> {lead?.email}
											</p>
											<p className="m-0">
												<b>Configurations:</b> {lead?.config?.join(", ")}
											</p>
											<p className="m-0">
												<b>Number Of Projects Tagged:</b>{" "}
												{lead?.projects_tagged}
											</p>
										</div>
										<div className="row mt-2">
											<div className="col-6">
												{/* <button
													className="btn btn-danger w-100"
													onClick={() => {
														dispatch(deleteCPLead(lead?.id));
													}}
												>
													{" "}
													<FontAwesomeIcon icon={icon.faTrash} /> Delete Lead
												</button> */}
											</div>
											<div className="col-6 ">
												<button
													className="btn btn-dark w-100"
													onClick={() => {
														navigate(`/lead/${lead?.id}/`);
													}}
												>
													{" "}
													<FontAwesomeIcon icon={icon.faCircleInfo} /> Lead
													Details
												</button>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				);
		}
	};
	return (
		<Layout pageTitle={"Leads"}>
			{loading ? (
				<></>
			) : (
				<div className="row  px-3">
					<div className="col-12">
						<div className="d-flex justify-content-end py-3">
							<button
								className="btn btn-primary"
								onClick={() => navigate("/addLead")}
							>
								Add Lead
							</button>
						</div>
					</div>

					<div className="">
						<Nav
							variant="tabs"
							bg={"light"}
							className="justify-content-center no-wrap "
							defaultActiveKey={page}
						>
							<Nav.Item>
								<Nav.Link eventKey="leads" onClick={() => setPage("leads")}>
									Leads{" "}
									<span class="badge text-bg-secondary">
										{leads?.leads?.length}
									</span>
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="site_visit"
									onClick={() => setPage("site_visit")}
								>
									Site Visits{" "}
									<span class="badge text-bg-primary">
										{leads?.site_visit?.length}
									</span>
								</Nav.Link>
							</Nav.Item>

							<Nav.Item>
								<Nav.Link eventKey="booked" onClick={() => setPage("booked")}>
									Booked{" "}
									<span class="badge text-bg-success">
										{leads?.booked?.length}
									</span>
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</div>
					<div className="py-3">

						{getPage(page)}
					</div>
				</div>
			)}
		</Layout>
	);
};

export default CPLeads;
