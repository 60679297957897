import React, { useState, useEffect } from 'react'
import CreateHeader from './CreateHeader'
import CreateBody from './CreateBody'
import CreateFooter from './CreateFooter'
import AddButton from './AddButton' 
import { Tab, Tabs, Accordion, Dropdown, Modal } from "react-bootstrap";
const Card = ({ cards, whatsappDataHandler,dataToShow }) => {
    const [page, setPage] = useState(0)
    const [cardComponent, setCardComponent] = useState([...[...Array(cards).keys()].map(() => { return { components: [] } })])
    const [cardShow, setCardShow] = useState([...[...Array(cards).keys()].map(() => {return { header: null, body: null, footer: null, button: null }})])

    useEffect(() => {
        if (cardComponent.length  < 1) return
        whatsappDataHandler({ type:"CAROUSEL",cards:cardComponent})
        dataToShow(cardShow)
    }, [cardComponent,cardShow])


    return (
        <div className='px-3' >
            <Tabs
                id="controlled-tab-example"
                variant="tabs"
                activeKey={page}
                onSelect={(k) => setPage(k)}
                className=" gap-2 gap-md-4 "
            >
                {[...Array(cards).keys()].map(i => {
                    const data = { ...cardComponent[page] }
                    return <Tab eventKey={i} title={"Card " + (i + 1)}>
                        <CreateHeader whatsappDataHandler={(e) => {
                            if (!data.hasOwnProperty('components')) {
                                data["components"] = []
                            }
                            data["components"] = [...data["components"].filter(i => i.type != "HEADER"), e]
                            const saveNewData = [...cardComponent]
                            saveNewData[page] = data
                            setCardComponent(saveNewData)
                        }} dataToShow={(e) => {
                            const data = [ ...cardShow ]
                            data[page].header = e
                            setCardShow(data)

                        }} />
                        <CreateBody whatsappDataHandler={(e) => {
                            if (!data.hasOwnProperty('components')) {
                                data["components"] = []
                            }
                            data["components"] = [...data["components"].filter(i => i.type != "BODY"), e]
                            const saveNewData = [...cardComponent]
                            saveNewData[page] = data
                            setCardComponent(saveNewData)
                        }} dataToShow={(e) => {
                            const data = [ ...cardShow]
                            data[page].body = e
                            setCardShow(data)
                        }} />
                        <CreateFooter whatsappDataHandler={(e) => {
                            if (!data.hasOwnProperty('components')) {
                                data["components"] = []
                            }
                            data["components"] = [...data["components"].filter(i => i.type != "FOOTER"), e]
                           const saveNewData = [...cardComponent]
                            saveNewData[page] = data
                            setCardComponent(saveNewData)
                        }} dataToShow={(e) => {
                            const data = [ ...cardShow]
                            data[page].footer = e
                            setCardShow(data)
                        }} />
                        <AddButton whatsappDataHandler={(e) => {
                            if (!data.hasOwnProperty('components')) {
                                data["components"] = []
                            }
                            data["components"] = [...data["components"].filter(i => i.type != "BUTTONS"), e]
                           const saveNewData = [...cardComponent]
                            saveNewData[page] = data
                            setCardComponent(saveNewData)
                        }} dataToShow={(e) => {
                            const data =[...cardShow]
                            data[page].button = e
                            setCardShow(data)
                        }} />
                    </Tab>
                })}

            </Tabs>

        </div>
    )
}

export default Card
