import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLeadDetail } from "../../Actions/CPAction";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Offcanvas from "react-bootstrap/Offcanvas";
import html2canvas from "html2canvas";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProjectBucket, tagProjectLead,untagProject } from "../../Actions/CPAction";
import numberToWords from "../numberFormater";
import toast from "react-hot-toast";
// import * as htmlToImage from "html-to-image";
// import { toBlob,} from "html-to-image";
function ProjectContainer({ project, leadName, number }) {
	const [show, setShow] = useState(false);
	const [qr, setQR] = useState(null);
	const handleClose = () => setShow(false);
	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	const toggleShow = async () => {
		setShow((s) => !s);
		const targetDiv = document.getElementById("printableDiv");
		targetDiv.style.display = "block";
		// await sleep(1000);
		html2canvas(targetDiv, {
			useCORS: true,
			ignoreElements: (element) => {
				// Add logic here to exclude certain elements from capture
				// For example, exclude all elements with a specific class
				return element.classList.contains("exclude-from-capture");
			},
		}).then((canvas) => {
			// Convert the canvas to a data URL and update the state

			setQR(canvas.toDataURL("image/png"));
			targetDiv.style.display = "none";
		});
	};
	// if (!navigator.canShare(qr)) {
	// }
	const handleShare = async () => {
		// Fetch the image data
		try {
			const response = await fetch(qr);

			if (!response.ok) {
				throw new Error(`Image fetch failed with status ${response.status}`);
			}

			// Convert the response to a Blob
			const blob = await response.blob();

			// Create a File object with the Blob
			const file = new File([blob], "image.png", {
				type: blob.type,
			});

			// Prepare the data for sharing
			const data = {
				files: [file],
				title: "Image",
				text: "Image description",
			};

			// Share the image
			if (navigator.share) {
				await navigator.share(data);
			} else {
				toast.error("Web Share API is not supported.");
			}
		} catch (error) {
			// console.error(error);
			toast.error(error.message);
		}
	};

	return (
		<>
			<div className="col-md-6 p-2">
				<div className="rounded border shadow-sm p-3" onClick={toggleShow}>
					<div className="row">
						<div className="col-4">
							<img
								className="img-thumbnail"
								src={"https://api.eazyapp.in" + project.project_image}
								alt=""
							/>
						</div>
						<div className="col-8 ">
							<p className="text-capitalize card_heading m-0">
								{project.project_name}
							</p>
							<p> <FontAwesomeIcon icon={icon.faMapPin} /> {project.address}</p>
							<button className="btn btn-warning" >
								Show Pass
							</button>
						</div>
						{/* <div className="col-1 d-flex justify-content-center align-items-center">
							<FontAwesomeIcon icon={icon.faChevronRight} />
						</div> */}
					</div>
				</div>

				{/* Do not touch this it will mess up qr */}
				<div
					id="printableDiv"
					className="p-2 bg-dark"
					style={{ display: "none" }}
				>
					<div className="bg-white py-3 rounded-4 overflow-hidden">
						<p className="text-muted text-capitalize ms-3">Site Visit Pass</p>
						<div>
							<h2 className="fs-4 text-capitalize fw-bold m-0 ms-3">
								{project.project_name}
							</h2>
							<p className="m-0 mb-1 text-muted ms-3">{project.config}</p>
						</div>
						<div className="row w-100 mx-2">
							<div className="col-6 border-end">
								<p className="text-muted m-0">Address:</p>
								<p className="text-capitalize  m-0">{project.address}</p>
							</div>
							<div className="col-6">
								<p className="text-muted m-0">Lead Details:</p>
								<p className="text-capitalize fw-bold m-0">{leadName}</p>
								<p className="text-capitalize fw-bold m-0">XXXXXX{number}</p>
							</div>
						</div>
						<div className="d-flex w-100 mt-4">
							<div
								className="bg-dark p-2 me-2 rounded-circle"
								style={{ marginLeft: "-9px", marginBottom: "-6px" }}
							></div>
							<div className="dashed-line py-1"></div>
							<div
								className="bg-dark p-2 ms-2 rounded-circle"
								style={{ marginRight: "-9px", marginBottom: "-6px" }}
							></div>
						</div>
						<div className="d-flex justify-content-center my-4">
							<img src={"https://api.eazyapp.in" + project.qr} alt="" />
						</div>
						<div className="d-flex w-100 mb-4">
							<div
								className="bg-dark p-2 me-2 rounded-circle"
								style={{ marginLeft: "-9px", marginBottom: "-6px" }}
							></div>
							<div className="dashed-line py-1"></div>
							<div
								className="bg-dark p-2 ms-2 rounded-circle"
								style={{ marginRight: "-9px", marginBottom: "-6px" }}
							></div>
						</div>
						<div className="text-center px-5 ">
							<p>
								Please provide your information and show this QR Code when you
								reach the Project Site
							</p>
						</div>
					</div>
				</div>
				{/* till here */}
				<Offcanvas
					show={show}
					onHide={handleClose}
					placement="bottom"
					backdrop="static"
					className=" bg-transparent py-5 vh-100 px-4"
				>
					<Offcanvas.Body className="bg-white rounded-4 w-100 overflow-hidden p-0">
						<Offcanvas.Header closeButton className="w-100">
							<Offcanvas.Title className="text-capitalize">
								<p className="fs-6 text-muted">Site Visit Pass</p>
							</Offcanvas.Title>
						</Offcanvas.Header>
						<div className="">
							<div>
								<h2 className="fs-4 text-capitalize fw-bold ms-3 m-0">
									{project.project_name}
								</h2>
								<p className="m-0 mb-2 text-muted ms-3">{project.config}</p>
							</div>
							<div className="row w-100 mx-2">
								<div className="col-6 border-end">
									<p className="text-muted mb-1 m-0">Address:</p>
									<span className="text-capitalize  m-0">
										{project.address}
									</span>
								</div>
								<div className="col-6">
									<p className="text-muted m-0">Lead Details:</p>
									<p className="text-capitalize  fw-bold m-0">{leadName}</p>
									<p className="text-capitalize fw-bold m-0">XXXXXX{number}</p>
								</div>
							</div>
							<div className="d-flex w-100 mt-4">
								<div
									className="bg-seprator p-2 me-2 rounded-circle"
									style={{ marginLeft: "-9px", marginBottom: "-6px" }}
								></div>
								<div className="dashed-line py-1"></div>
								<div
									className="bg-seprator p-2 ms-2 rounded-circle"
									style={{ marginRight: "-9px", marginBottom: "-6px" }}
								></div>
							</div>
							<div className="d-flex justify-content-center align-items-center my-4">
								<img src={"https://api.eazyapp.in" + project.qr} alt="" />
							</div>
							<div className="d-flex w-100 mb-4">
								<div
									className="bg-seprator p-2 me-2 rounded-circle"
									style={{ marginLeft: "-9px", marginBottom: "-6px" }}
								></div>
								<div className="dashed-line py-1"></div>
								<div
									className="bg-seprator p-2 ms-2 rounded-circle"
									style={{ marginRight: "-9px", marginBottom: "-6px" }}
								></div>
							</div>
							<div className="text-center ">
								<button className="btn btn-dark" onClick={handleShare}>
									Share
								</button>
							</div>
						</div>
					</Offcanvas.Body>
				</Offcanvas>
			</div>
		</>
	);
}

const CpLead = () => {
	
	const dispatch = useDispatch();
	const [search, setSearch] = useState(null);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [dataCount, setDataCount] = useState(1);
	const [hasMore, setHasMore] = useState(false);
	const [projectShow, setProjectShow] = useState(false);

	const { error, loading, projects } = useSelector(
		(state) => state?.cp?.myBucket
	);

	const searchHandler = (e) => {
		const delay = 1000;
		const Debouncer = setTimeout(() => {
			setSearch(e.target.value);
		}, delay);
		return () => clearTimeout(Debouncer);
	};

	useEffect(() => {
		var postData = {
			page: page,
		};
		if (search !== null) {
			postData["query"] = search;
		}
		dispatch(getProjectBucket(postData));
	}, [page, search]);
	useEffect(() => {
		if (projects !== undefined) {
			setData(projects?.projects);
			setPage(projects?.page);
			setDataCount(projects?.number_of_projects);
			setHasMore(projects?.next_page);
		}
	}, [projects]);

	const { detail } = useSelector((state) => state?.cp?.detailLead);
	const params = useParams();
	useEffect(() => {
		dispatch(getLeadDetail(params.leadId));
	}, []);
	return (
		<Layout pageTitle={"Lead"}>
			<div className="">
				<div className="p-3 text-end">
					<button
						className="btn btn-primary"
						onClick={() => {
							setProjectShow(true);
						}}
					>
						Tag Project
					</button>
				</div>
				<div className="p-3 d-lg-none">
					<div className="shadow rounded p-3">
						<h2 className="card_heading" >Lead Details</h2>
						<p className=" m-0 mb-1">
							<label className="label"> Name </label>: {detail?.lead_name}
						</p>
						<p className=" m-0 mb-1">
							<label className="label"> Email </label>: {detail?.email}
						</p>
						<p className=" m-0 mb-1">
							
							<label className="label"> Budget </label>: {detail?.budget.budget.min} -{" "}
							{detail?.budget.budget.max}
						</p>
						<p className=" m-0 mb-1">
							<label className="label"> Requirements </label>:{" "}
							{detail?.config.join(" | ")}
						</p>
					</div>
				</div>
				<div className="d-none d-lg-block">
					<div className="row  w-100 mx-2">
						<div className="col-lg-3 col-md-4   p-3">
							<div className="rounded border shadow-sm p-3">
								<p className="text-muted m-0  bg-white">Name:</p>
								<p className="fw-bold m-3">{detail?.lead_name} </p>
							</div>
						</div>
						<div className="col-lg-3 col-md-4   p-3">
							<div className="rounded border shadow-sm p-3">
								<p className="text-muted m-0  bg-white">Email:</p>
								<p className="fw-bold m-3">{detail?.email} </p>
							</div>
						</div>
						<div className="col-lg-3 col-md-4   p-3">
							<div className="rounded border shadow-sm p-3">
								<p className="text-muted m-0  bg-white">Budget:</p>
								<p className="fw-bold m-3">
									{numberToWords(detail?.budget.budget.min)} - {numberToWords(detail?.budget.budget.max)}
								</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-4   p-3">
							<div className="rounded border shadow-sm p-3">
								<p className="text-muted m-0  bg-white">Requirements:</p>
								<p className="fw-bold m-3">{detail?.config.join(" | ")}</p>
							</div>
						</div>
					</div>
				</div>
				<h2 className="section_heading p-3" >Tagged Projects</h2>
				<div className="row mx-3">
					{detail?.projects_tagged?.map((project) => {
						return (
							<ProjectContainer
								project={project}
								leadName={detail?.lead_name}
								number={detail?.last_4_digit}
							/>
						);
					})}
				</div>
			</div>
			<Offcanvas
				show={projectShow}
				onHide={() => setProjectShow(false)}
				placement="bottom"
				backdrop="static"
				className=" bg-transparent vh-75 px-4"
			>
				<Offcanvas.Body className="bg-white rounded-4 w-100 overflow-hidden p-0">
					<Offcanvas.Header closeButton className="w-100">
						<Offcanvas.Title className="text-capitalize">
							Tag Projects
						</Offcanvas.Title>
					</Offcanvas.Header>
					<div className="px-3 my-3">
						<div className="input-group">
							<input
								type="text"
								className="form-control border border-end-0 rounded-start-5"
								id="searchBar"
								placeholder="Search"
								onChange={searchHandler}
							/>
							<label
								className="input-group-text bg-white ms-n5 border-top border-end border-bottom"
								forHtml="searchBar"
							>
								<FontAwesomeIcon icon={icon.faSearch} />
							</label>
						</div>
					</div>
					<div className="" style={{ overflowY: "scroll", height: "60vh" }}>
						<InfiniteScroll
							dataLength={dataCount}
							next={() => setPage(page + 1)}
							className="row mx-5"
							hasMore={hasMore}
							loader={<h4>Loading...</h4>}
							scrollableTarget="scrollableDiv"
						>
							{data?.map((project) => {
								return (
									<div className="col-md-6 col-lg-4  p-3">
										<div className="bg-white  rounded border shadow">
											<div className="">
												<img
													className="img-fluid rounded-top"
													src={"https://api.eazyapp.in" + project.cover_img}
													alt=""
												/>
											</div>
											<div className=" p-3">
												<h4 className="text-capitalize">{project.name}</h4>
												<p className="m-0">{project.organization}</p>
												<p className="m-0">
													<b>Configs:</b> {project.configs}
												</p>
												<p className="m-0">
													<b>Starting carpet area:</b>{" "}
													{project.starting_carpet_area} sq.ft
												</p>
												<p className="m-0">
													<b>Starting Price:</b> {project.starting_price}
												</p>
												{detail?.projects_tagged
													?.map((i) => {
														return i.project_id;
													})
													.includes(project.project_id) ? (
													<button
														className="btn btn-dark w-100"
														onClick={() => {
															detail?.projects_tagged?.map((i) => {
																if (i.project_id === project.project_id) {
																	dispatch(
																		untagProject({
																			lead_id: detail.id,
																			project_id: i.project_id,
																		})
																	);
																}
															});
														}}
													>
														UnTag Project
													</button>
												) : (
													<button
														// disabled={detail?.projects_tagged
														// 	?.map((i) => {
														// 		return i.project_id;
														// 	})
														// 	.includes(project.project_id)}
														className="btn btn-dark w-100"
														onClick={() => {
															dispatch(
																tagProjectLead(
																	params.leadId,
																	{ project_id: project.project_id },
																	setProjectShow
																)
															);
														}}
													>
														Tag Project
													</button>
												)}
											</div>
										</div>
									</div>
								);
							})}
						</InfiniteScroll>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</Layout>
	);
};

export default CpLead;
