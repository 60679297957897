import axios, { isObject } from "./axios";
import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_RESET,
	GET_USER_DATA_REQUEST,
	GET_USER_DATA_SUCCESS,
	GET_USER_DATA_FAIL,
	// 
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	// 
} from "../Constants/UserConstant";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { PERMISSIONS_REQUEST, PERMISSIONS_ERROR } from "../Constants/PermissionConstant"


export const userLoginAction = (loginDetails) => async (dispatch, getState) => {
	try {
		toast.loading("Logging In");
		dispatch({
			type: USER_LOGIN_REQUEST,
		});

		const config = {
			headers: {
				"Content-type": "application/json",
			},
		};
		const { data } = await axios.post("auth/login/", loginDetails, config);
		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data,
		});
		dispatch(getUserData());

		if (data?.extra?.error === "checkin_error") {
			// toast.error(data?.extra?.message);
			throw data?.extra?.message;
		}
		toast.dismiss();
		toast.success(`Logged in as ${data.name}`);


		const now = new Date();
		const midnight = new Date(now);
		midnight.setHours(24, 0, 0, 0);
		const timeDifference = midnight - now;
		Cookies.set("userInfo", JSON.stringify(data), {
			expires: new Date(now.getTime() + timeDifference),
		});
		// localStorage.setItem("userInfo", JSON.stringify(data));
	} catch (error) {
		toast.dismiss();
		const errorTypes = [];
		if (isObject(error?.response?.data?.error)) {
			errorTypes.push(...Object.keys(error?.response?.data?.error));
		} else {
			errorTypes.push(error?.response?.data?.error);
			errorTypes.push(error);
		}
		errorTypes.map((e) => {
			if (e !== undefined) {
				switch (e) {
					case "email":
						return toast.error("Email Field Should Not be blank");
					case "password":
						return toast.error("Password Field Should Not be Blank");
					case "non_field_errors":
						return toast.error(
							error?.response?.data?.error?.non_field_errors[0]
						);
					default:
						return toast.error(e);
				}
			}
		});
		dispatch({
			type: USER_LOGIN_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error,
		});
	}
};

export const userLogout = () => async (dispatch, getState) => {
	localStorage.removeItem("userInfo");
	Cookies.remove("userInfo");
	dispatch({ type: USER_LOGIN_RESET });
};


export const getUserData = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_USER_DATA_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("get/profile/", config);
		dispatch({
			type: GET_USER_DATA_SUCCESS,
			payload: data,
		});

	} catch (error) {
		dispatch({
			type: GET_USER_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error,
		});
	}
}

export const permissionsAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: PERMISSIONS_REQUEST });
		const { userState: { userInfo } } = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("get/all-permissions", config);
		data.forEach(element => {
			dispatch({
				type: element,
			});
		});
	} catch (error) {

		dispatch({
			type: PERMISSIONS_ERROR,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error,
		});
	}
}


export const forgotPasswordAction = (email) => async (dispatch, getState) => {
	try {
		dispatch({ type: FORGOT_PASSWORD_REQUEST });
		const config = {
			headers: {
				"Content-type": "application/json",
			},
		};
		const { data } = await axios.post("forgot-password/", { email }, config);
		dispatch({
			type: FORGOT_PASSWORD_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: FORGOT_PASSWORD_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error,
		});
	}
}