import React,{useState,useEffect} from "react";
import Layout from "./Layout/Layout";
import { useParams ,useNavigate} from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { getdeveloperChannelPartnerDetail } from "../../Actions/AdminAction";
const ChannelPartnerDetailView = () => {
    const dispatch=useDispatch()
    const navigate= useNavigate();

    const {cpId}=useParams()
    const { loading, error, detail } = useSelector(
			(state) => state?.developer?.channelPartner
		);
        
    useEffect(() => {
        dispatch(getdeveloperChannelPartnerDetail(cpId))
    }, [cpId])
    if(detail){
        return (
            <Layout pageTitle={"Channel Partner Detail"}>
                <div
                    className="h-100 p-4"
                    style={{ overflowY: "scroll", overflowX: "hidden" }}
                >
                    <div className="w-100 py-4">
                        <div className="d-flex justify-content-center w-100">
                            <div
                                className="m-0 p-0"
                                style={{ height: "200px", width: "200px" }}
                            >
                                <img
                                    className="img-fluid rounded-circle"
                                    src={"https://api.eazyapp.in" + detail?.logo}
                                    alt=""
                                    style={{ height: "100%", width: "100%" }}
                                />
                            </div>
                        </div>
                        <div className="text-center my-3">
                            <h2 className="underlined-text section_heading ">{detail.name} </h2>
                        </div>
                        <div>
                            <label className="label">RERA Number:</label>
                            <p className="card_heading ms-2">{detail?.rera_number}</p>
                        </div>
                        <div>
                            <label className="label">Owner:</label>
                            <p className="card_heading ms-2">{detail?.owner_name}</p>
                        </div>
                        <div>
                            <label className="label">Conatct:</label>
                            <p className="card_heading ms-2">{detail?.owner_contact}</p>
                        </div>
    
                        <div className="">
                            {detail.about?.split("\r\n\r").map((d, index) => {
                                return (
                                    <p className="text-wrap" key={index}>
                                        {d}
                                    </p>
                                );
                            })}
                            {/* <p className="text-wrap">{JSON.stringify(detail)}</p> */}
                        </div>
                    </div>
                </div>
                <div className="justify-content-end d-flex gap-3">
                <button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Back</button>
                </div>
            </Layout>
        );
    }
};

export default ChannelPartnerDetailView;
