import React,{useEffect,useState,useRef} from 'react'
import Layout from './Layout/Layout'
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch ,useSelector } from 'react-redux';
import { getMyTeamMemberAction } from "../../Actions/SEAction";
import { get_sale_reports, get_projects } from "../../Actions/CommonAction";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from "chart.js";
import { Bar ,Pie} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ChartDataLabels,
	ArcElement
);
const SEReport = () => {
	const genreatedPdf = useRef(null)
  const [employees,setEmployees]=useState([])
  const [projectsId,setProjectsId]=useState([])
  const [showReport, setShowReport] = useState(false);
  const [genratingPdf, setGenratingPdf] = useState(false);
  const [chartOptions,setChartOptions]=useState(null);
  const [fromDate,setFromDate]=useState(null);
  const [toDate, setToDate] = useState(new Date().toLocaleDateString("en-CA"));
  const [chartData,setChartData]=useState(null)
  const [pieData,setPieData]=useState(null)
  const dispatch=useDispatch()
  const { teams } = useSelector((state) => state?.se?.MyTeam);
  const { projects } = useSelector((state) => state?.project);
  const {reports}= useSelector((state)=>state?.se?.report)
  useEffect(() => {
	if(reports !== undefined){
		setShowReport(true);
		var new_visitor =0
		var hot = 0;
		var warm = 0;
		var cold = 0;
		var booked = 0;
		var completedTask=0
		var unCompletedTask=0
		reports.every(d=>{
			new_visitor =new_visitor + d.new
			hot =hot + d.hot
			warm =warm + d.warm
			cold =cold + d.cold
			booked =booked + d.booked
			completedTask = completedTask + d.completed_task
			unCompletedTask = unCompletedTask + (d.total_task - d.completed_task);
		})
		const maxData = Math.max(
			...[
				new_visitor,
				hot,
				warm,
				cold,
				booked,
			]
		);
		function roundUpToNearestTen(value) {
			return Math.ceil(value / 10) * 13;
		}
		const options = {
			responsive: true,
			barThickness: 20,
			scales: {
				x: {
					grid: {
						display: false,
					},
				},
				y: {
					display: false,
					grid: {
						display: false,
					},
					ticks: {
						precision: 0,
					},
					max: roundUpToNearestTen(maxData),
				},
			},
			plugins: {
				legend: {
					display: false,
					position: "bottom",
				},
				title: {
					display: false,
					text: "Chart.",
				},
				datalabels: {
					display: true,
					color: "black",
					formatter: Math.round,
					anchor: "end",
					offset: -20,
					align: "start",
				},
			},
		};
		const labels = ["New",`Hot`, "Warm", "Cold", "Deal"];
		const data = {
			labels,
			datasets: [
				{
					label: "Visitors",
					data: [
						new_visitor,
						hot,
						warm,
						cold,
						booked,
						// 666,999,888,666,888
					],
					backgroundColor: [
						"rgba( 231, 76, 60, 0.5)",
						"rgba( 241, 196, 15 , 0.5)",
						"rgba(34, 167, 240 , 0.5)",
						"rgba(143, 63, 209, 0.5)",
						"rgba(46, 204, 113, 0.5)",
					],
				},
			],
		};
		setChartOptions(options);
		setChartData(data);

		const piedata = {
  labels: ['Completed Task','Uncomplete Task'],
  datasets: [
    {
      label: '# Tasks',
      data: [completedTask,unCompletedTask],
      backgroundColor: [
		  'rgba(75, 192, 192, 0.2)',
        'rgba(255, 99, 132, 0.2)',
      ],
      borderColor: [
		  'rgba(75, 192, 192, 1)',
        'rgba(255, 99, 132, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
	setPieData(piedata);
	}
  }, [reports]);
const downloadPDFHandler = () => {
	// Get the content of the generated PDF div
	// setShowReport(false);
	setGenratingPdf(true);
	
};
	useEffect(()=>{
		if(genratingPdf){
			setTimeout(function () {
				const content = genreatedPdf.current;
				// Convert the HTML element to a canvas element.
				html2canvas(content).then(function (canvas) {
					// Create a new jsPDF document with the height of the content.

					const doc = new jsPDF({
						orientation: "portrait",
						unit: "mm",
						format: "a4",
						marginLeft: 10,
						marginRight: 10,
						marginTop: 10,
						marginBottom: 10,
					});
					// Add the canvas element to the PDF document.
					 const scaleFactor = Math.min(
							doc.internal.pageSize.width /
								canvas.width,
							doc.internal.pageSize.height /
								canvas.height
						);
					doc.addImage(canvas, "JPEG", 10, 10,(canvas.width * scaleFactor)  , (canvas.height * scaleFactor ),);

					// Save the PDF document.
					doc.save("download.pdf");
				});
				setGenratingPdf(false);
			},1000);
			
		}
	},[genratingPdf])

	const reportHandler=(e)=>{
		e.preventDefault()
		var formData = new FormData(e.target);
		dispatch(get_sale_reports(formData));
	}

  useEffect(()=>{
    dispatch(getMyTeamMemberAction());
    dispatch(get_projects());
  },[])
  return (
		<Layout pageTitle={"EazyReport"}>
			<form action="" method="post" onSubmit={reportHandler}>
				<div className="d-grid p-3 m-2 shadow-sm rounded border align-items-center">
					{teams !== undefined && (
						<div className="my-2">
							<h5>Select Employees</h5>
							<Dropdown className="w-100 border" autoClose="outside">
								<Dropdown.Toggle
									className="w-100 remove-after"
									variant="white"
									id="dropdown-basic"
								>
									{employees.length > 0
										? teams?.every((v, i) => employees.includes(v.emp_id))
											? "All Employees"
											: employees.map((id) => {
													var emp = teams.filter((e) => e.emp_id === id)[0];
													return (
														<span className="bg-light border rounded py-2 px-1">
															{emp.name}
														</span>
													);
											  })
										: "Select Employees"}{" "}
									<Icon.ChevronDown className="ms-auto " />
								</Dropdown.Toggle>

								<Dropdown.Menu className="w-100">
									<Dropdown.Item
										className={`p-2 rounded my-1`}
										key={"all"}
										onClick={() => {
											if (
												!teams?.every((v, i) => employees.includes(v.emp_id))
											) {
												setEmployees(
													teams?.map((e) => {
														return e.emp_id;
													})
												);
											} else {
												setEmployees([]);
											}
										}}
									>
										<div className="d-flex">
											<Form.Check
												type={"checkbox"}
												id={`default-all`}
												value={"All"}
												checked={teams.every((v, i) =>
													employees.includes(v.emp_id)
												)}
												label={"All"}
											/>
										</div>
									</Dropdown.Item>
									{teams?.map((i) => {
										return (
											<Dropdown.Item
												className={`p-2 rounded my-1 `}
												key={i.emp_id}
												onClick={() => {
													if (!employees.includes(i.emp_id)) {
														setEmployees([
															...employees.filter((id) => id !== i.emp_id),
															i.emp_id,
														]);
													} else {
														setEmployees(
															employees.filter((id) => id !== i.emp_id)
														);
													}
												}}
											>
												<div className="d-flex">
													<Form.Check
														type={"checkbox"}
														name="employees_id"
														value={i.emp_id}
														id={`default-${i.emp_id}`}
														checked={employees.includes(i.emp_id)}
														label={i.name}
													/>
												</div>
											</Dropdown.Item>
										);
									})}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					)}
					{projects !== undefined && (
						<div className="my-2">
							<h5>Select Projects</h5>
							<Dropdown className="w-100 border" autoClose="outside">
								<Dropdown.Toggle
									className="w-100 remove-after"
									variant="white"
									id="dropdown-basic"
								>
									{projectsId.length > 0
										? projects?.every((v, i) => projectsId.includes(v.id))
											? "All Projects"
											: projectsId.map((id) => {
													var project = projects.filter((e) => e.id === id)[0];
													return (
														<span className="bg-light border rounded py-2 px-1">
															{project.name}
														</span>
													);
											  })
										: "Select Projects"}{" "}
									<Icon.ChevronDown className="ms-auto " />
								</Dropdown.Toggle>

								<Dropdown.Menu className="w-100">
									<Dropdown.Item
										className={`p-2 rounded my-1`}
										key={"all"}
										onClick={() => {
											if (
												!projects?.every((v, i) => projectsId.includes(v.id))
											) {
												setProjectsId(
													projects?.map((e) => {
														return e.id;
													})
												);
											} else {
												setProjectsId([]);
											}
										}}
									>
										<div className="d-flex">
											<Form.Check
												type={"checkbox"}
												id={`default-all`}
												value={"All"}
												checked={projects.every((v, i) =>
													projectsId.includes(v.id)
												)}
												label={"All"}
											/>
										</div>
									</Dropdown.Item>
									{projects?.map((i) => {
										return (
											<Dropdown.Item
												className={`p-2 rounded my-1 `}
												key={i.id}
												onClick={() => {
													if (!projectsId.includes(i.id)) {
														setProjectsId([
															...projectsId.filter((id) => id !== i.id),
															i.id,
														]);
													} else {
														setProjectsId(
															projectsId.filter((id) => id !== i.id)
														);
													}
												}}
											>
												<div className="d-flex">
													<Form.Check
														type={"checkbox"}
														name="projects_id"
														value={i.id}
														id={`default-${i.id}`}
														checked={projectsId.includes(i.id)}
														label={i.name}
													/>
												</div>
											</Dropdown.Item>
										);
									})}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					)}
					<div className="my-2">
						<h5>Select Timeline</h5>
						<div className="row">
							<div className="col-md-5">
								<label>From</label>
								<input
									className="form-control"
									type="date"
									placeholder="From"
									name="from"
									onChange={(e) => setFromDate(e.target.value)}
								/>
							</div>
							<div className="col-md-2 d-flex py-2 align-items-end justify-content-center">
								<Icon.DashLg />
							</div>
							<div className="col-md-5">
								<label>To</label>
								<input
									className="form-control"
									type="date"
									defaultValue={new Date().toLocaleDateString("en-CA")}
									placeholder="To"
									name="to"
									onChange={(e) => setToDate(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="my-2 text-center ">
						<button className="btn btn-warning">Genrate Report</button>
					</div>
				</div>
			</form>
			<Modal
				show={showReport}
				fullscreen={true}
				className=""
				onHide={() => setShowReport(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="p-2 bg-white">
						<div className="row my-2">
							<div className="col-md-6 bg-white ">
								{chartOptions !== null && chartData !== null && (
									<Bar options={chartOptions} data={chartData} />
								)}
							</div>
							<div className="col-md-6">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">New</th>
											<th scope="col">Warm</th>
											<th scope="col">Hot</th>
											<th scope="col">Cold</th>
											<th scope="col">Booked</th>
											<th scope="col">Total</th>
										</tr>
									</thead>
									<tbody>
										{reports?.map((emp) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{emp.name}
													</th>
													<td>{emp.new}</td>
													<td>{emp.warm}</td>
													<td>{emp.hot}</td>
													<td>{emp.cold}</td>
													<td>{emp.booked}</td>
													<td>{emp.total}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
						<div className="row my-2">
							<div className="col-md-8">
								<table class="table">
									<thead>
										<tr>
											<th scope="col">Name</th>
											<th scope="col">Task</th>
											<th scope="col"> Completion Date Till </th>
											<th scope="col">Completed</th>
										</tr>
									</thead>
									<tbody>
										{reports?.map((report) => {
											return report.task.map((task) => {
												return (
													<tr>
														<th scope="row" className="text-capitalize">
															{report.name}
														</th>
														<td>{task.task_type}</td>
														<td>
															{moment(task.task_completion_date).format(
																"MMMM Do, YYYY  hh:mm a"
															)}{" "}
														</td>
														<td className="text-center">
															{task.completed && (
																<Icon.Check2Circle className="text-success" />
															)}
														</td>
													</tr>
												);
											});
										})}
									</tbody>
								</table>
							</div>
							<div className="col-md-4 bg-white ">
								<Pie data={pieData} />
							</div>
						</div>
					</div>
					<div className="text-center">
						<button className="btn btn-warning " onClick={downloadPDFHandler}>
							<Icon.FileEarmarkArrowDown /> Download
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{genratingPdf && (
				<div className="p-2 bg-white a4-page " ref={genreatedPdf}>
					<div className="text-center">
						<h1 class="h3 mb-5">
							Report {fromDate !== null ? fromDate + " - " + toDate : toDate}
						</h1>{" "}
					</div>
					<div className="row my-2">
						<div className="col-6 bg-white ">
							{chartOptions !== null && chartData !== null && (
								<Bar options={chartOptions} data={chartData} />
							)}
						</div>
						<div className="col-6">
							<table class="table">
								<thead>
									<tr>
										<th scope="col">Name</th>
										<th scope="col">New</th>
										<th scope="col">Warm</th>
										<th scope="col">Hot</th>
										<th scope="col">Cold</th>
										<th scope="col">Booked</th>
										<th scope="col">Total</th>
									</tr>
								</thead>
								<tbody>
									{reports?.map((emp) => {
										return (
											<tr>
												<th scope="row" className="text-capitalize">
													{emp.name}
												</th>
												<td>{emp.new}</td>
												<td>{emp.warm}</td>
												<td>{emp.hot}</td>
												<td>{emp.cold}</td>
												<td>{emp.booked}</td>
												<td>{emp.total}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
					<div className="row my-2">
						<div className="col-8">
							<table class="table">
								<thead>
									<tr>
										<th scope="col">Name</th>
										<th scope="col">Task</th>
										<th scope="col"> Completion Date Till </th>
										<th scope="col">Completed</th>
									</tr>
								</thead>
								<tbody>
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
									{reports?.map((report) => {
										return report.task.map((task) => {
											return (
												<tr>
													<th scope="row" className="text-capitalize">
														{report.name}
													</th>
													<td>{task.task_type}</td>
													<td>
														{moment(task.task_completion_date).format(
															"MMMM Do, YYYY  hh:mm a"
														)}{" "}
													</td>
													<td className="text-center">
														{task.completed && (
															<Icon.Check2Circle className="text-success" />
														)}
													</td>
												</tr>
											);
										});
									})}
								</tbody>
							</table>
						</div>
						<div className="col-4 bg-white ">
							{pieData && <Pie data={pieData} />}
						</div>
					</div>
				</div>
			)}
		</Layout>
	);
}

export default SEReport
