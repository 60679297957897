import React from 'react'
import Whatsapp from '../Components/Whatsapp'
const TestPage = () => {
  return (
    <div>
      <Whatsapp />
    </div>
  )
}

export default TestPage
