import React, { useState, useEffect, useRef } from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSettingType, addDeveloperSetting } from '../../../Actions/MyAdminAction';
import axios from 'axios';
import toast from 'react-hot-toast';

const GetHeaderComponent = ({ header, orgId, templateName }) => {
    const [headerData, setHeaderData] = useState()
    const dispatch = useDispatch();
    const [variables, setVariables] = useState({})
    const textRef = useRef()
    const handleFileUpload = async (e) => {
        try {
            if(templateName == ""){
                toast.error("Please Enter Template Name")
                e.target.value = ""
                return 
            }
            const file = e.target.files[0];
            console.log(file)
            if (file.size == 0){
                toast.error("Please Select File")
                e.target.value = ""
                return
            }
            const formData = new FormData();
            formData.append("file", file)
            formData.append("template_name", templateName)
            const { data } = await axios.post("https://api.eazyapp.in/api/upload-file/" + orgId, formData)
            setHeaderData({"type":"HEADER","format":header.type,"value":data});
        } catch (error) {
            console.log(error)
        }
    }
    switch (header.type) {
        case "None":
            return <></>
        case "Text":
            return <div className='py-3' >

                <input ref={textRef} type="text" name="value" onKeyUp={(e) => {
                    const data = {
                        ...variables
                    }
                    if (e.key === "{") {
                        var new_var = `{{${Object.keys(variables).length + 1}}}`
                        textRef.current.value = textRef.current.value.slice(0, -2) + new_var
                        data[new_var] = null
                    }
                    setVariables(data)
                }} defaultValue={header.value} onChange={e => {
                    const data = {
                        ...variables
                    }
                    Object.keys(data).map(i => {
                        if (!e.target.value.includes(i)) {
                            delete data[i]
                        }
                    })
                    setVariables(data)

                }} className="form-control" />
                <span onClick={() => {
                    var new_var = `{{${Object.keys(variables).length + 1}}}`
                    textRef.current.value = textRef.current.value + new_var
                    const data = {
                        ...variables
                    }
                    data[new_var] = null
                    setVariables(data)
                }} >Add Variable</span>
                {Object.keys(variables).filter(i => "length" !== i).map(i => <p>{i} : <input type="text" className='form-control' /></p>)}

            </div>
        case "Image":
            return <div className='py-3' >
                <h5>Samples for header content</h5>
                <p>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
                <span>Image</span> <input type="file" className="form-control" onChange={handleFileUpload} />

            </div>
        case "Video":
            return <div className='py-3' >
                <h5>Samples for header content</h5>
                <p>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
                <span>Video</span> <input type="file" className="form-control" onChange={handleFileUpload} />
            </div>
        case "Document":
            return <div className='py-3' >
                <h5>Samples for header content</h5>
                <p>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
                <span>Document</span> <input type="file" className="form-control" onChange={handleFileUpload} />
            </div>
        case "Location":
            return <div className='py-3' ></div>
    }
}

const AddSetting = () => {
    const [settingType, setSettingtype] = useState();
    const [currentType, setCurrentType] = useState();
    const [templateName,setTemplateName] = useState("");
    const [header, setHeader] = useState({
        type: null,
        value: null
    })
    const { orgId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { typeList, loading, error } = useSelector(state => state.myAdmin?.settingTypes)
    useEffect(() => {
        if (setSettingtype) {
            setCurrentType(typeList?.find(x => x.id == settingType))
        }
    }, [settingType])
    useEffect(() => {
        dispatch(getSettingType())
    }, [])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const object = Object.fromEntries(formData)
        const postData = {
            "org_id": orgId,
            "settings": object,
            "setting_type": settingType
        }
        dispatch(addDeveloperSetting(postData, navigate))
    }

    if (typeList) {
        return (
            <Layout pageTitle={"Add Setting"}>
                <div className='p-3'>
                    <label>Setting Type</label>
                    <select name="setting_type" onChange={(e) => setSettingtype(e.target.value)} value={settingType} id="" className="form-select">
                        <option value="" selected disabled >Select Setting Type</option>
                        {
                            typeList.map(obj => {
                                return <option key={obj.id} value={obj.id}>{obj.name}</option>
                            })

                        }
                    </select>
                </div>
                <form onSubmit={submitHandler}>
                    <div className="d-grid gap-3 p-3">
                        {currentType && Object.keys(currentType.fields_to_ask).map(key => {
                            var element = currentType.fields_to_ask[key]
                            if (element === "str") {
                                return <div>
                                    <label className='text-capitalize'>{key.replaceAll("_", " ")}</label>
                                    <input type="text" name={key} className="form-control" />
                                </div>
                            } else if (element === "int") {
                                return <div>
                                    <label className='text-capitalize'>{key.replaceAll("_", " ")}</label>
                                    <input type="number" name={key} className="form-control" />
                                </div>
                            } else if (element === "text") {
                                return <div>
                                    <label className='text-capitalize'>{key.replaceAll("_", " ")}</label>
                                    <textarea name={key} className='form-control' rows="8"></textarea>
                                    <div className="text-center">
                                        <p>(Note:- {"{#var#}"}) will be replaced with actual value</p>
                                    </div>
                                </div>
                            } else if (element === "boolean") {
                                return <div class="form-check">
                                    <input class="form-check-input" name={key} type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label text-capitalize" for="flexCheckDefault">
                                        {key.replaceAll("_", " ")}
                                    </label>
                                </div>
                            }
                        })}
                        <div className="text-end">
                            <button className='btn btn-success' type="submit" >Submit</button>
                        </div>
                    </div>
                </form>
            </Layout>
        )
    }
    if (loading) {

        return (
            <div>
                Loading
            </div>
        )
    }
}

export default AddSetting
