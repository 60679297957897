import React from 'react'
import Layout from '../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getChannelPartnerDetail, editAdminChannelPartner } from '../../../Actions/MyAdminAction';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUploader from '../../../Components/ImageUploader';
import { deleteCPEmployee } from '../../../Actions/CPAction';
const AdminChannelPartnerDetail = () => {
    const [basicDetailEdit, setBasicDetailEdit] = React.useState(false)
    const [tab, setTab] = React.useState("basic_detail")
    React.useEffect(() => {
        if (window.location.hash.replace("#", "") !== "") {
            setTab(window.location.hash.replace("#", ""));
        }
    }, []);
    React.useEffect(() => {
        window.location.hash = tab;
    }, [tab]);

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { cpId } = useParams();
    const { channel_partner, loading, error } = useSelector(state => state?.myAdmin?.channelPartnerDetail)
    React.useEffect(() => {
        dispatch(getChannelPartnerDetail(cpId))
    }, [cpId])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        dispatch(editAdminChannelPartner(cpId, formData, setBasicDetailEdit))
    }


    if (loading) {
        return <>Loading</>
    }

    if (channel_partner) {

        return (
            <Layout pageTitle={"Channel Partner Detail"} >
                <div className="p-2">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={tab}
                        onSelect={(k) => setTab(k)}
                        className="mb-3 justify-content-center"
                    >
                        <Tab eventKey="basic_detail" title="Basic Detail">
                            <div className="d-grid gap-3 p-3 position-relative">
                                <FontAwesomeIcon className='position-absolute top-0 end-0 fs-3' icon={basicDetailEdit ? icon.faXmark : icon.faPenToSquare} onClick={() => setBasicDetailEdit(!basicDetailEdit)} />
                                {
                                    basicDetailEdit ? <>
                                        <form onSubmit={submitHandler}>
                                            <div className='d-grid gap-3 p-3'>
                                                <div className="row">
                                                    <div className='col-2' >
                                                        <ImageUploader dbImage={"https://api.eazyapp.in" + channel_partner?.logo} name="logo" />
                                                    </div>
                                                    <div className="col-10">
                                                        <div>
                                                            <label>Organization Name</label>
                                                            <input type="text" className='form-control' defaultValue={channel_partner?.name} name="name" />
                                                        </div>

                                                        <div>
                                                            <label>Organization Type</label>
                                                            <select name="org_type" className="form-select" defaultValue={channel_partner?.org_type}>
                                                                {["Proprietorship Firm",
                                                                    "Private Limited Company",
                                                                    "Limited Liability Partnership",
                                                                    "Public Limited Company",
                                                                    "Partnership Firm",
                                                                    "Partnership Firm"].map(item => {
                                                                        return <option value={item}>{item}</option>
                                                                    })}
                                                            </select>
                                                        </div>

                                                        <div>
                                                            <label>RERA Number</label>
                                                            <input type="text" className='form-control' defaultValue={channel_partner?.rera_number} name="rera_number" />
                                                        </div>

                                                    </div>
                                                </div>


                                                <div>
                                                    <label>About</label>
                                                    <textarea className='form-control' rows="8" defaultValue={channel_partner?.about} name="about" ></textarea>
                                                </div>

                                                <div>
                                                    <label>Established Year</label>
                                                    <input type="number" min="1950" max={new Date().getFullYear()} className='form-control' defaultValue={moment(channel_partner?.established_year).format("yyyy")} name="established_year" />
                                                </div>
                                                <div className="text-end">
                                                    <button className='btn btn-dark' type='submit' >Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </> :

                                        <div>
                                            <div className="d-flex gap-3 border-bottom pb-3">
                                                <img src={"https://api.eazyapp.in" + channel_partner?.logo} style={{ height: "200px", width: "200px" }} alt="" />
                                                <div className="">
                                                    <h3 className=''>{channel_partner?.name}</h3>
                                                    <p className='m-0' >{channel_partner?.org_type}</p>
                                                    <p className='m-0' ><span>RERA Number:</span> {channel_partner?.rera_number}</p>
                                                    <p className='m-0' >Since {moment(channel_partner?.established_year).format("yyyy")}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <h4 className="section_heading">About {channel_partner.name}</h4>
                                                <pre className='text-warp'>{channel_partner?.about}</pre>
                                            </div>
                                        </div>
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="employee" title="Employee">
                            <div className="d-grid gap-3 p-3">
                                <div className="text-end">
                                    <button className="btn btn-dark" onClick={() => navigate(`/add-channel-partner-employee/${cpId}`)} >Add</button>
                                </div>
                                {
                                    channel_partner?.employees?.map(employee => {
                                        return (
                                            <div className='border rounded shadow position-relative'>
                                                <Dropdown
                                                    className="position-absolute end-0  m-1 "
                                                    style={{ zIndex: "1" }}
                                                >
                                                    <Dropdown.Toggle
                                                        className="hide-arrow"
                                                        variant=""
                                                        id="dropdown-basic"
                                                    >
                                                        <FontAwesomeIcon icon={icon.faEllipsis} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                navigate(`/edit-channel-partner-employee/${employee?.id}`)
                                                            }}
                                                        >
                                                            Edit
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                dispatch(deleteCPEmployee(employee?.id, getChannelPartnerDetail,cpId))
                                                            }}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="d-flex p-3 align-items-center gap-3">
                                                    <img src={"https://api.eazyapp.in" + employee?.photo} style={{ height: "100px", width: "100px" }} alt="" />
                                                    <div className='flex-fill'>
                                                        <h3 className='card_heading'>{employee?.name}</h3>
                                                        <p className='m-0' >{employee?.designation}</p>
                                                        <p className='m-0' ><span>Phone:</span> {employee?.phone_number}</p>
                                                        <p className='m-0' >Email: {employee?.email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </Layout>
        )
    }
}

export default AdminChannelPartnerDetail
