import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getProjectConfigs } from "../../../Actions/AdminAction";

const Column = (unit) => {
	return <div className="p-2 "></div>;
};

const AddUnit = ({ floorNo, unitDataHandler,removeFloor ,configList}) => {
	const [show, setShow] = useState(false);
	const [allFloor, setAllFloor] = useState(false);
	const [jodi, setJodi] = useState(false);
	const [duplex, setDuplex] = useState(false);
	const [useAble, setUseAble] = useState(true);
	const [label, setLabel] = useState("");
	const [config, setConfig] = useState("");
	const [unitNo, setUnitNo] = useState(0);
	const [carpetArea, setCarpetArea] = useState(0);
	const [multipleUnits, setMuiltipleUnits] = useState(false);
	const [numberOfUnitts, setNumberOfUnits] = useState(0);
	const [configId, setConfigId] = useState(null);

	

	const submitHandler = () => {
		const data = {};
		data["label"] = label;
		data["floor_no"] = floorNo;

		data["booked"] = false;
		data["useable"] = useAble;
		if (allFloor) {
			data["colSpan"] = "100%";
			data["unit_no"] = label;
		} else {
			data["config"] = config;
			data["unit_no"] = unitNo;
			data["carpet_area"] = carpetArea;
			data["config_id"] = configId;
			if (jodi || multipleUnits) {
				data["colSpan"] = multipleUnits ? numberOfUnitts.toString() : "2";
				if (multipleUnits) {
					data["unit_no"] = label;
				}
			}
			if (duplex) {
				data["rowSpan"] = "2";
			}
		}
		setShow(false);
		setAllFloor(false);
		setJodi(false);
		setDuplex(false);
		setMuiltipleUnits(false);
		setUseAble(true);
		setLabel("");
		setConfig("");
		setUnitNo(0);
		setCarpetArea(0);
		unitDataHandler(data);
	};

	return (
		<>
			
				<div className="">
					<button
						className="btn btn-primary"
						onClick={() => {
							setShow(true);
						}}
					>
						add Unit
					</button>
				</div>
				<Modal show={show} onHide={setShow}>
					<Modal.Header closeButton>
						<Modal.Title>Floor no {floorNo}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="p-2 ">
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									value=""
									id="full_floor_new"
									onChange={(e) => {
										setAllFloor(e.target.checked);
									}}
								/>
								<label class="form-check-label" for="full_floor_new">
									Full Floor
								</label>
							</div>
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									value=""
									id="combine_multiple_units_new"
									onChange={(e) => {
										setMuiltipleUnits(e.target.checked);
									}}
								/>
								<label class="form-check-label" for="combine_multiple_units_new">
									Combine Muiltiple Units
								</label>
							</div>

							<div>
								<label>Label</label>
								<input
									type="text"
									className="form-control"
									name="label"
									placeholder="Enter Label For the unit"
									onChange={(e) => {
										setLabel(e.target.value);
									}}
								/>
							</div>
							{!allFloor && !multipleUnits && (
								<>
									<div>
										<label>Unit Number</label>
										<input
											type="number"
											className="form-control"
											name="unit_number"
											placeholder="Enter Unit Number"
											onChange={(e) => {
												setUnitNo(parseInt(e.target.value));
											}}
										/>
									</div>
									<div>
										<label>Cofiguration</label>
										<select name="config" className="form-select" onChange={(e)=> {
											
											const conf=configList.filter(i=>i.id==e.target.value)[0]
											setConfigId(e.target.value)
											setCarpetArea(conf.carpet_area)
											setConfig(conf.config)
											}
											}  >
										{configList?.map(config => <option value={config.id}>{config.name} ( {config.carpet_area}sq.ft )</option>)}
											{/* <option value="1 RK">1 RK</option>
											<option value="1 BHK">1 BHK</option>
											<option value="2 BHK">2 BHK</option>
											<option value="3 BHK">3 BHK</option>
											<option value="4 BHK">4 BHK</option>
											<option value="5 BHK">5 BHK</option>
											<option value="Shop">Shop</option>
											<option value="Office">Office</option> */}
										</select>
										{/* <input
											type="text"
											className="form-control"
											name="config"
											placeholder="Enter Configuration of the unit"
											onChange={(e) => {
												setConfig(e.target.value);
											}}
										/> */}
									</div>
									{/* <div>
										<label>Carpet Area</label>
										<input
											type="number"
											className="form-control"
											name="carpet_area"
											placeholder="Enter Unit Number"
											onChange={(e) => {
												setCarpetArea(parseInt(e.target.value));
											}}
										/>
									</div> */}
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value=""
											id="useable_new"
											defaultChecked
											onChange={(e) => {
												setUseAble(e.target.checked);
											}}
										/>
										<label class="form-check-label" for="useable_new">
											Useable
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value=""
											id="jodi_new"
											onChange={(e) => {
												setJodi(e.target.checked);
											}}
										/>
										<label class="form-check-label" for="jodi_new">
											Jodi
										</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value=""
											id="duplex_new"
											onChange={(e) => {
												setDuplex(e.target.checked);
											}}
										/>
										<label class="form-check-label" for="duplex_new">
											Duplex
										</label>
									</div>
								</>
							)}
							{multipleUnits && (
								<>
									<div>
										<label>Number Of Units to Combine</label>
										<input
											type="text"
											className="form-control"
											name="label"
											placeholder="Enter number of units to combine"
											onChange={(e) => {
												setNumberOfUnits(e.target.value);
											}}
										/>
									</div>
								</>
							)}
							<div className="text-center">
								<button className="btn btn-success" onClick={submitHandler}>
									Add
								</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
		</>
	);
};


const InventoryUnit = ({ unit, inventoryDataHandler, projectId, configList }) => {
	const [edit, setEdit] = useState(false)
	const [allFloor, setAllFloor] = useState(false);
	const [jodi, setJodi] = useState(false);
	const [duplex, setDuplex] = useState(false);
	const [useAble, setUseAble] = useState(true);
	const [label, setLabel] = useState("");
	const [config, setConfig] = useState("");
	const [unitNo, setUnitNo] = useState(0);
	const [carpetArea, setCarpetArea] = useState(0);
	const [multipleUnits, setMuiltipleUnits] = useState(false);
	const [numberOfUnitts, setNumberOfUnits] = useState(0);
	const [configId,setConfigId]=useState(null)

	useEffect(()=>{
		setAllFloor(unit.colSpan == "100%")
		setMuiltipleUnits(unit.colSpan > 2)
		setDuplex(unit.rowSpan == "2")
		setJodi(unit.colSpan == "2")
		setCarpetArea(unit.carpet_area)
		setLabel(unit.label)
		setConfig(unit.config)
		setUnitNo(unit.unit_no)
		setConfigId(unit.config_id)
	},[unit])
	const submitHandler = () => {
		const data = {};
		data["label"] = label;
		data["floor_no"] = unit.floor_no;

		data["booked"] = false;
		data["useable"] = useAble;
		if (allFloor) {
			data["colSpan"] = "100%";
			data["unit_no"] = label;
		} else {
			data["config"] = config;
			data["config_id"] = configId
			data["unit_no"] = unitNo;
			data["carpet_area"] = carpetArea;
			if (jodi || multipleUnits) {
				data["colSpan"] = multipleUnits ? numberOfUnitts.toString() : "2";
				if (multipleUnits) {
					data["unit_no"] = label;
				}
			}
			if (duplex) {
				data["rowSpan"] = "2";
			}
		}
		setEdit(false);
		setAllFloor(false);
		setJodi(false);
		setDuplex(false);
		setMuiltipleUnits(false);
		setUseAble(true);
		setLabel("");
		setConfig("");
		setUnitNo(0);
		setCarpetArea(0);
		inventoryDataHandler(data);
	};

	return (
		<>
			<Modal show={edit} onHide={setEdit}>
				<Modal.Header closeButton>
					<Modal.Title>Floor no {unit.floorNo}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="p-2 ">
						<div class="form-check">
							<input
								class="form-check-input"
								type="checkbox"
								value=""
								defaultChecked={unit.colSpan == "100%"}
								id="full_floor"
								onChange={(e) => {
									setAllFloor(e.target.checked);
								}}
							/>
							<label class="form-check-label" for="full_floor">
								Full Floor
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="checkbox"
								value=""
								id="combine_multiple_units"
								defaultChecked={unit.colSpan > 2}
								onChange={(e) => {
									setMuiltipleUnits(e.target.checked);
								}}
							/>
							<label class="form-check-label" for="combine_multiple_units">
								Combine Muiltiple Units
							</label>
						</div>

						<div>
							<label>Label</label>
							<input
								type="text"
								className="form-control"
								name="label"
								placeholder="Enter Label For the unit"
								defaultValue={unit.label}
								onChange={(e) => {
									setLabel(e.target.value);
								}}
							/>
						</div>
						{!allFloor && !multipleUnits && (
							<>
								<div>
									<label>Unit Number</label>
									<input
										type="number"
										className="form-control"
										name="unit_number"
										placeholder="Enter Unit Number"
										defaultValue={unit.unit_no}
										onChange={(e) => {
											setUnitNo(parseInt(e.target.value));
										}}
									/>
								</div>
								<div>
									<label>Configuration</label>
									<select name="config" className="form-select" defaultValue={configId} onChange={(e) => {
										const conf = configList.filter(i => i.id == e.target.value)[0]
										setConfigId(e.target.value)
										setCarpetArea(conf.carpet_area)
										setConfig(conf.config)
									}}  >
										{configList?.map((c,i)=> <option key={i} value={c.id}>{c.name} ( {c.carpet_area}sq.ft )</option>)}
									</select>
								</div>
								{/* <div>
									<label>Carpet Area</label>
									<input
										type="number"
										className="form-control"
										name="carpet_area"
										placeholder="Enter Unit Number"
										defaultValue={unit.carpet_area}
										onChange={(e) => {
											setCarpetArea(parseInt(e.target.value));
										}}
									/>
								</div> */}
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="useable"
										defaultChecked={unit.useable}
										onChange={(e) => {
											setUseAble(e.target.checked);
										}}
									/>
									<label class="form-check-label" for="useable">
										Useable
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="jodi"
										defaultChecked={unit.colSpan == "2"}
										onChange={(e) => {
											setJodi(e.target.checked);
										}}
									/>
									<label class="form-check-label" for="jodi">
										Jodi
									</label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="duplex"
										defaultChecked={unit.rowSpan == "2"}
										onChange={(e) => {
											setDuplex(e.target.checked);
										}}
									/>
									<label class="form-check-label" for="duplex">
										Duplex
									</label>
								</div>
							</>
						)}
						{multipleUnits && (
							<>
								<div>
									<label>Number Of Units to Combine</label>
									<input
										type="text"
										className="form-control"
										defaultValue={unit.colSpan}
										name="label"
										placeholder="Enter number of units to combine"
										onChange={(e) => {
											setNumberOfUnits(e.target.value);
										}}
									/>
								</div>
							</>
						)}
						<div className="text-center">
							<button className="btn btn-success" onClick={submitHandler}>
								Update
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<td
				colSpan={unit?.colSpan ? unit?.colSpan : "1"}
				rowSpan={unit?.rowSpan ? unit?.rowSpan : "1"}
				style={{
					width: unit?.colSpan
						? unit?.colSpan !== "100%"
							? `${10 * unit?.colSpan}%`
							: "100%"
						: "10%",
				}}
				onClick={() => {
					setEdit(true)
				}}
			>

				{unit.label}
			</td>
		</>
	)
}


const Inventory = ({ dbInventoryData, inventoryDataHandler ,projectId }) => {

	const dispatch = useDispatch();
	const { configList } = useSelector((state) => state?.developer?.projectConfigList);
	useEffect(() => {
		dispatch(getProjectConfigs(projectId));
	}, []);

	const [inventoryData, setInventoryData] = useState(
		{
			0: [],
			length: 1,
			max_unit: 0,
		}
	)
	useEffect(()=>{
		if(dbInventoryData !== undefined && dbInventoryData !== null){
			setInventoryData(dbInventoryData)
		}else{
			setInventoryData({
				0: [],
				length: 1,
				max_unit: 0,
			})
		}
	},[dbInventoryData])

	// useEffect(() => {
	// 	inventoryDataHandler(inventoryData);
	// }, [setInventoryData]);

	return (
		<div className="h-100" style={{ overflowX: "scroll" }}>
			<button
				className="btn btn-secondary w-100"
				onClick={() => {
					let data = {
						length: inventoryData.length + 1,
						...Object.keys(inventoryData)
							.filter((item) => item !== "length")
							.reduce((newObj, key) => {
								newObj[key] = inventoryData[key];
								return newObj;
							}, {}),
					};
					data[inventoryData.length] = [];
					setInventoryData(data);
				}}
			>
				add Floor
			</button>
			<Table responsive={true} bordered hover>
				<thead>
					<tr>
						<th className="text-nowrap">#</th>
						{[
							...Array(
								inventoryData?.max_unit ? inventoryData?.max_unit : 0
							).keys(),
						].map((unit) => {
							return <th className="text-center">Unit {unit + 1}</th>;
						})}
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(inventoryData)
						.filter((key) => key !== "length" && key !== "max_unit")
						.sort(function (a, b) {
							return b - a;
						})
						.map((key) => {
							const floor = inventoryData[key];
							return (
								<tr style={{ width: "100%" }}>
									<td className="text-center">{key}</td>

									{floor.map((unit) => {
										return (
											<InventoryUnit unit={unit} projectId={projectId} configList={configList} inventoryDataHandler={(e)=>{
												const data={...inventoryData}
												data[key]=[...data[key].filter((item)=>{
													return item.unit_no !== unit.unit_no
												}), e].dynamicSort("unit_no")
												inventoryDataHandler(data)
											}} />
											// <td
											// 	colSpan={unit?.colSpan ? unit?.colSpan : "1"}
											// 	rowSpan={unit?.rowSpan ? unit?.rowSpan : "1"}
											// 	style={{
											// 		width: unit?.colSpan
											// 			? unit?.colSpan !== "100%"
											// 				? `${10 * unit?.colSpan}%`
											// 				: "100%"
											// 			: "10%",
											// 	}}
											// 	onClick={() => {
											// 		console.log("unit", unit)
											// 	}}
											// >

											// 	{unit.label}
											// </td>
										);
									})}
									{(floor[0]?.colSpan === undefined ||
										floor[0].colSpan !== "100%") ? (
											<td className="d-flex gap-2" >
											<AddUnit
												floorNo={key}
												projectId={projectId}
												configList={configList}
												unitDataHandler={(e) => {
													const data = {...inventoryData}
													data[key] = [...data[key], e].dynamicSort("unit_no")
													inventoryDataHandler(data)
												}}
											/>
											<button className="btn btn-danger" onClick={() => {
												const data = { ...inventoryData }
												delete data[key]
												inventoryDataHandler(data)
											}} >
												Remove floor
											</button>
											
											</td>
										) : <td>
											<button className="btn btn-danger" onClick={() => {
												const data = { ...inventoryData }
												delete data[key]
												inventoryDataHandler(data)
											}} >
												Remove floor
											</button>
										</td>}
								</tr>
							);
						})}
				</tbody>
			</Table>
			{/* <div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
				<button className="btn btn-success" onClick={()=>{
					
				}} >Save Inventory</button>
				 <button className="btn btn-dark">Cancel</button>
			</div> */}
			
			
		</div>
	);
};

export default Inventory;
