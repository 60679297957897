import React, { useState, useEffect, Component } from 'react'
import "./whatsapp.css"
import Slider from "react-slick";
import moment from 'moment/moment';
import { getWhatsappTemplate } from '../Actions/AdminAction';
import { useDispatch, useSelector } from 'react-redux';



const HeaderHandler = ({ header }) => {

    if (header === null) return <></>;
    let content = null;

    switch (header.type) {
        case "text":
            content = <p>{header.value}</p>;
            break;

        case "image":
            const imageUrl = header.value;
            console.log(imageUrl)
            content = (
                <>
                    <div
                        style={{
                            width: "280px",
                            height: "320px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: `url(${imageUrl})`,
                        }}
                    ></div>
                    <br />
                </>
            );
            break;

        case "video":
            const videoUrl = header.value;
            content = <video src={videoUrl} controls />;
            break;

        case "document":
            const documentUrl = header.value;
            content = (
                <div>
                    Document:{" "}
                    <a href={documentUrl} target="_blank" rel="noopener noreferrer">
                        Open Document
                    </a>
                </div>
            );
            break;

        case "location":
            content = <div>Location: {header.value}</div>;
            break;

        default:
            content = <></>;
    }

    return content;
};



const ComponentHandler = ({ component, context }) => {
    switch (component.type) {
        case "HEADER":
            return <HeaderHandler
                header={{
                    type: component.format.toLowerCase(),
                    value: context?.parameters[0] && context?.parameters[0][context?.parameters[0]?.type.toLowerCase()]
                        ? context.parameters[0][context.parameters[0].type.toLowerCase()].link
                        : ""
                }}
            />


        case "BODY":
            var body = component.text 
            if(context?.parameters !== undefined){
                context?.parameters?.map((d,index)=>{
                    body = body.replace("{{"+(index +1)+"}}",d.text)
                })
            }

            return <>
            <pre className='text-wrap' >{body?.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}</pre> 
                <div className='w-100 d-flex text-secondary text-sm py-2 ' >  <span className='ms-auto' >{moment().format("hh:mm A")}</span></div>
            </> 
        case "FOOTER":
            return <></>
        case "BUTTONS":
            return <>
                {component?.buttons?.map((btn) => {
                    return <div className=' w-100 border-top p-3 border-primary text-center '>{btn.text}</div>
                })}
            </>
        case "CAROUSEL":

            const settings = {
                dots: false,
                infinite: false,
                centerMode: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
            };
            return <>  <Slider {...settings}>
                    {component.cards.map((cardData,index)=>{
                        return <div>
                            {cardData.components.map((cardComponent) => {
                                return <ComponentHandler key={index} component={cardComponent} context={context.cards?.filter((d) => d.card_index === index)[0].components?.filter((d) => d.type === cardComponent.type)[0]} />
                            })}
                        </div>
                        })}
                </Slider> </>
    }

}



const WhatsappView = ({ context }) => {
    const dispatch = useDispatch()
    const { whatsappTemplate } = useSelector((state) => state.developer.project.whatsappTemplate)

    useEffect(() => {
        dispatch(getWhatsappTemplate(context.template.name))
    }, [])



    return (
        <div className='  d-flex  '  >

            <div className="right-container">
                {/* <!--header --> */}
                <div className="header py-5 px-3">
                    <div className="img-text ">
                        <div className="user-img">
                            <img className="dp" src="https://images.pexels.com/photos/2474307/pexels-photo-2474307.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                        </div>
                        <h4>Leo<br /><span>Online</span></h4>
                    </div>
                    <div className="nav-icons">
                        <li><i className="fa-solid fa-magnifying-glass"></i></li>
                        <li><i className="fa-solid fa-ellipsis-vertical"></i></li>
                    </div>
                </div>

                {/* <!--chat-container --> */}
                <div className="chat-container">
                    <div className="message-box my-message">
                        <p>Hey!<br /><span>07:43</span></p>
                    </div>
                         <div className="message-box friend-message">
                             <div className="p-2  bg-white" style={{ width: "300px", borderRadius: "0.8rem" }} >
                                {
                                    context !== undefined && whatsappTemplate !== undefined &&
                                    whatsappTemplate?.components?.map((component) => {
                                        return <ComponentHandler component={component} context={context?.template?.components?.filter(d=> {
                                            return d.type  == component.type })[0]} />
                                    })
                                }
                        </div>
                    </div>
                </div>
                {/* <!--input-bottom --> */}
                <div className="chatbox-input">
                    <i className="fa-regular fa-face-grin"></i>
                    <i className="fa-sharp fa-solid fa-paperclip"></i>
                    <input type="text" placeholder="Type a message" disabled />
                    <i className="fa-solid fa-microphone"></i>
                </div>
            </div>
        </div>
    )

}

export default WhatsappView

