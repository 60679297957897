import React, { useEffect, useState } from "react";
import Layout from "./Screens/CreScreens/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { userLogout, getUserData } from "./Actions/UserAction";
const Profile = () => {
	const navigate=useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getUserData());
	},[])

	const { profile } = useSelector((state) => state?.userProfile);

	return (
		<div className="d-grid h-100">
				<div className="row">
					<div className="col-md-6">
						<div
							className="row rounded-5 w-100 rounded-top-0 h-100  mx-1"
							style={{
								backgroundImage: `url('https://img.freepik.com/free-vector/white-abstract-background_23-2148810113.jpg')`,
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
							}}
						>
							<div className="position-relative">
								<button className="btn position-absolute end-0 m-3 border" onClick={()=>navigate("/edit-profile")} style={{zIndex:"99"}} >
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.57342 2.71915L10.1913 2.10123C11.2151 1.07742 12.8751 1.07742 13.8989 2.10123C14.9227 3.12503 14.9227 4.78494 13.8989 5.80874L13.2809 6.42666M9.57342 2.71915C9.57342 2.71915 9.65066 4.03223 10.8093 5.19082C11.9679 6.34942 13.2809 6.42666 13.2809 6.42666M9.57342 2.71915L3.89259 8.39997C3.50782 8.78475 3.31543 8.97714 3.14997 9.18926C2.9548 9.4395 2.78746 9.71025 2.65094 9.99672C2.5352 10.2396 2.44916 10.4977 2.27708 11.0139L1.54791 13.2014M13.2809 6.42666L7.60011 12.1075C7.21533 12.4923 7.02295 12.6847 6.81082 12.8501C6.56059 13.0453 6.28984 13.2126 6.00336 13.3491C5.7605 13.4649 5.50239 13.5509 4.98616 13.723L2.79865 14.4522M2.79865 14.4522L2.26393 14.6304C2.00989 14.7151 1.72981 14.649 1.54046 14.4596C1.35111 14.2703 1.28499 13.9902 1.36967 13.7361L1.54791 13.2014M2.79865 14.4522L1.54791 13.2014" stroke="currentColor" stroke-width="1.6"></path>
								</svg>
								Edit
								</button>
							</div>
							<div className="col-2 col-md-4"></div>
							<div className="col-8 col-md-4">
								<div className=" text-center py-2">
									<img
										className="img-thumbnail rounded-circle"
										src={"https://api.eazyapp.in" + profile?.photo}
										alt=""
									/>
									<p className="fw-bold fs-4">{profile?.name}</p>
								</div>
							</div>
							<div className="col-2 col-md-4"></div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="mx-4 py-2">
							<h3>
								<Icon.Briefcase /> Works At {profile?.organization.name} as{" "}
								{profile?.designation}{" "}
							</h3>
							<h3>
								{" "}
								<Icon.EnvelopeAt /> {profile?.email}
							</h3>
							<h3>
								{" "}
								<Icon.Telephone /> {profile?.phone_number}
							</h3>
						</div>
					</div>
				</div>

				<div className="mt-5">
					<div className="row gap-3 px-2">

						<div className="col-md-5 "></div>
						<div className="col-12 col-md-3 ">
							<button className="btn btn-danger w-100" onClick={()=>{
								dispatch(userLogout());
							}} >
								Logout
							</button>
						</div>
						{/* <div className="col-12 col-md-3 ">
							<button className="btn btn-outline-success w-100">
								Edit Profile
							</button>
						</div> */}
					</div>
				</div>
			</div>
	);
};

export default Profile;
