import React, { useEffect, useState,useRef } from 'react'
import Layout from '../Layout/Layout'
import { useParams,useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectAutomationList } from '../../../Actions/AdminAction'
import WhatsappView from '../../../Components/WhatsappView'

const MailViewHandler= ({body})=>{
  return <div className="miniature-window" dangerouslySetInnerHTML={{ __html: body }}  ></div>
}


const MessageViewHandler= ({body})=>{
   
  return  <div class="chat-card">
    <div class="chat-header">
      <div class="h2">EAZYAPP</div>
    </div>
    <div class="chat-body">
      <div class="message incoming">
        <p>{body}</p>
      </div>
      

    </div>
    <div class="chat-footer">
      <input placeholder="Type your message" type="text" disabled style={{width:"100%"}}  />
        <button>Send</button>
    </div>
  </div>

}


const WhatsappViewHandler= ({context})=>{
  if (context === undefined) return <></>;
  return <div className='miniature-window'>
    <WhatsappView context={context} />
  </div>
}


const AutomationSetting = () => {
  const { projectId } = useParams()
  const dispatch = useDispatch()
  const navigate=useNavigate()

  const { automationList } = useSelector((state) => state.developer.project.projectAutomationList)

  const user=useSelector(state=>state?.userState?.userInfo)

  useEffect(() => {
    dispatch(getProjectAutomationList(projectId))
  }, [])

  if (automationList !== undefined) {
    return (
      <Layout pageTitle={"Automation Setting"}>

        {!user.extra?.viewer_only &&<div className="text-end">
          <button className="btn btn-primary"  onClick={()=>navigate(`/project/${projectId}/automation/add`)} >Add Automation</button>
        </div>
}
        <table className="table">
          <thead>
            <tr>
              <th  scope="col">Automation Day</th>
              <th  scope="col">Automation Type</th>
              <th scope="col">Body</th>
            </tr>
          </thead>
          <tbody>
            {automationList.map((automation) => (
              <tr key={automation.id}>
                <td >{automation.day}</td>
                <td >{automation.is_mail ? "Mail" :
                  automation.is_message ? "Message" :
                    automation.is_whatsapp && "Whatsapp"}</td>
                <td >{automation.is_mail ? <MailViewHandler body={automation.body}  /> :
                  automation.is_message ? <MessageViewHandler body={automation.body}  /> :
                    automation.is_whatsapp && <WhatsappViewHandler context={automation?.context} />}</td>
              </tr>
            ))}
          </tbody>
        </table>


      </Layout>
    )
  }
}

export default AutomationSetting
