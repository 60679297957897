import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import { getMyTeamMemberAction } from "../../Actions/SEAction";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import Accordion from "react-bootstrap/Accordion";
const Item=(props)=>{
	return (
		<div key={props.id} className="col-md-5  border rounded p-2 shadow">
			<div className="row">
				<div className="col-3 d-flex align-items-center justify-content-center">
					<img
						src={"https://api.eazyapp.in" + props.photo}
						alt=""
						className="img-fluid  img-thumbnail"
					/>
				</div>
				<div className="col-9 ">
					<h4 className="p-0 m-0 text-capitalize ">{props.name}</h4>
					<p className="p-0 m-0 text-muted ">{props.designation}</p>
					<div className=" d-flex justify-content-between px-1 border-top mt-2 ">
						<a
							href={`tel:${props.phone_number}`}
							className="fs-4 fw-bold text-primary "
						>
							<Icon.Telephone />
						</a>
						<a
							href={`sms:+${props.phone_number}`}
							className="fs-4 fw-bold text-secondary "
						>
							<Icon.Chat />
						</a>
						<a
							href={`mailto:${props.email}`}
							className="fs-4 fw-bold text-danger "
						>
							<Icon.EnvelopeAt />
						</a>
					</div>
				</div>
				<div className="col-12">
					<Accordion >
						<Accordion.Item eventKey="0">
							<Accordion.Header>Show Projects</Accordion.Header>
							<Accordion.Body>
								{props.emp_relation.map(d=>{
									return (
										<p className="text-capitalize"  >{d.project} - {d.designation}</p>
									)
								})}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</div>
			</div>
		</div>
	);
}

const SETeams = () => {
	const dispatch = useDispatch();
	const { teams } = useSelector((state) => state?.se?.MyTeam);
	useEffect(() => {
		dispatch(getMyTeamMemberAction());
	}, []);


	return (
		<Layout pageTitle={"Teams"}>
			<div className="main_container py-4">
				<div className="row w-100 gap-3">
					{teams?.map((d) => {
						return (
							<Item {...d}/>
						);
					})}
				</div>
			</div>
		</Layout>
	);
};

export default SETeams;
