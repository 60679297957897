import React, { useEffect } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getDeveloperDashboard } from "../../Actions/AdminAction";
import LoadingPage from "../LoadingPage";
const AdminDashboard = () => {
	const dispatch = useDispatch();
	const { loading, error, dashData } = useSelector(
		(state) => state?.developer?.dashData
	);
	useEffect(() => {
		dispatch(getDeveloperDashboard());
	}, []);

	if (loading && dashData === undefined) {
		return <LoadingPage />;
	}

	if (dashData) {
		return (
			<Layout pageTitle={"Dashboard"}>
				<div className="p-3">
					<div className="row">
						<div className="col-md-6 p-2 ">
							{" "}
							<div className="border p-3 rounded shadow">
								<h2 className="section_heading">No. Of Projects</h2>
								<p className="m-0 display-3">{dashData.total_projects}</p>
							</div>{" "}
						</div>
						<div className="col-md-6 p-2 ">
							{" "}
							<div className="border p-3 rounded shadow">
								<h2 className="section_heading">No. Of Inventories</h2>
								<p className="m-0 display-3">{dashData.total_units}</p>
							</div>{" "}
						</div>
						<div className="col-md-6 p-2 ">
							{" "}
							<div className="border p-3 rounded shadow">
								<h2 className="section_heading">No. Of Employees</h2>
								<p className="m-0 display-3">{dashData.total_employees}</p>
							</div>{" "}
						</div>
						<div className="col-md-6 p-2 ">
							{" "}
							<div className="border p-3 rounded shadow">
								<h2 className="section_heading">No. Of Channel Partners</h2>
								<p className="m-0 display-3">{dashData.total_cp}</p>
							</div>{" "}
						</div>
						<div className="col-md-6 p-2 ">
							{" "}
							<div className="border p-3 rounded shadow">
								<h2 className="section_heading">No. Of Visitors</h2>
								<p className="m-0 display-3">{dashData.total_visitors}</p>
							</div>{" "}
						</div>
					</div>
				</div>
			</Layout>
		);
	}
};

export default AdminDashboard;
