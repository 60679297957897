export const GET_DEVELOPER_LIST_REQUEST = 'GET_DEVELOPER_LIST_REQUEST'
export const GET_DEVELOPER_LIST_SUCCESS = 'GET_DEVELOPER_LIST_SUCCESS'
export const GET_DEVELOPER_LIST_FAIL = 'GET_DEVELOPER_LIST_FAIL' 

export const GET_DEVELOPER_DETAILS_REQUEST = 'GET_DEVELOPER_DETAILS_REQUEST'
export const GET_DEVELOPER_DETAILS_SUCCESS = 'GET_DEVELOPER_DETAILS_SUC'
export const GET_DEVELOPER_DETAILS_FAIL = 'GET_DEVELOPER_DETAFAIL'

export const UPDATE_DEVELOPER_DETAILS_REQUEST = 'UPDATE_DEVELOPER_DETAILS_REQUEST'
export const UPDATE_DEVELOPER_DETAILS_SUCCESS = 'UPDATE_DEVELOPER_DETAILS_SUCCESS'
export const UPDATE_DEVELOPER_DETAILS_FAIL = 'UPDATE_DEVELOPER_DETAILS_FAIL'


export const GET_DEVELOPER_EMPLOYEES_REQUEST = 'GET_DEVELOPER_EMPLOYEES_REQUEST'
export const GET_DEVELOPER_EMPLOYEES_SUCCESS = 'GET_DEVELOPER_EMPLOYEES_SUCCESS'
export const GET_DEVELOPER_EMPLOYEES_FAIL = 'GET_DEVELOPER_EMPLOYEES_FAIL'


export const GET_DEVELOPER_EMPLOYEE_DETAILS_REQUEST = 'GET_DEVELOPER_EMPLOYEE_DETAILS_REQUEST'
export const GET_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS = 'GET_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS'
export const GET_DEVELOPER_EMPLOYEE_DETAILS_FAIL = 'GET_DEVELOPER_EMPLOYEE_DETAILS_FAIL'


export const ADD_DEVELOPER_EMPLOYEE_DETAILS_REQUEST = 'ADD_DEVELOPER_EMPLOYEE_DETAILS_REQUEST'
export const ADD_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS = 'ADD_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS'
export const ADD_DEVELOPER_EMPLOYEE_DETAILS_FAIL = 'ADD_DEVELOPER_EMPLOYEE_DETAILS_FAIL'


export const UPDATE_DEVELOPER_EMPLOYEE_DETAILS_REQUEST = 'UPDATE_DEVELOPER_EMPLOYEE_DETAILS_REQUEST'
export const UPDATE_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS = 'UPDATE_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS'
export const UPDATE_DEVELOPER_EMPLOYEE_DETAILS_FAIL = 'UPDATE_DEVELOPER_EMPLOYEE_DETAILS_FAIL'

export const DELETE_DEVELOPER_EMPLOYEE_REQUEST = 'DELETE_DEVELOPER_EMPLOYEE_REQUEST'
export const DELETE_DEVELOPER_EMPLOYEE_SUCCESS = 'DELETE_DEVELOPER_EMPLOYEE_SUCCESS'
export const DELETE_DEVELOPER_EMPLOYEE_FAIL = 'DELETE_DEVELOPER_EMPLOYEE_FAIL'


export const GET_DEVELOPER_SETTING_TYPE_REQUEST = 'GET_DEVELOPER_SETTING_TYPE_REQUEST'
export const GET_DEVELOPER_SETTING_TYPE_SUCCESS = 'GET_DEVELOPER_SETTING_TYPE_SUCCESS'
export const GET_DEVELOPER_SETTING_TYPE_FAIL = 'GET_DEVELOPER_SETTING_TYPE_FAIL'


export const GET_SETTING_TYPE_REQUEST = 'GET_SETTING_TYPE_REQUEST'
export const GET_SETTING_TYPE_SUCCESS = 'GET_SETTING_TYPE_SUCCESS'
export const GET_SETTING_TYPE_FAIL = 'GET_SETTING_TYPE_FAIL'


export const ADD_DEVELOPER_SETTING_REQUEST = 'ADD_DEVELOPER_SETTING_REQUEST'
export const ADD_DEVELOPER_SETTING_SUCCESS = 'ADD_DEVELOPER_SETTING_SUCCESS'
export const ADD_DEVELOPER_SETTING_FAIL = 'ADD_DEVELOPER_SETTING_FAIL'



export const GET_PROJECT_LIST_REQUEST = 'GET_PROJECT_LIST_REQUEST'
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS'
export const GET_PROJECT_LIST_FAIL = 'GET_PROJECT_LIST_FAIL'

export const GET_PROJECT_DETAILS_REQUEST = 'GET_PROJECT_DETAILS_REQUEST'
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS'
export const GET_PROJECT_DETAILS_FAIL = 'GET_PROJECT_DETAILS_FAIL'

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_FAIL = 'ADD_PROJECT_FAIL'


export const ADD_MANDATE_COMPANY_REQUEST = 'ADD_MANDATE_COMPANY_REQUEST'
export const ADD_MANDATE_COMPANY_SUCCESS = 'ADD_MANDATE_COMPANYSUCCESS'
export const ADD_MANDATE_COMPANY_FAIL = 'ADD_MANDATE_COMPANY_FAIL'


export const REMOVE_MANDATE_COMPANY_REQUEST = "REMOVE_MANDATE_COMPANY_REQUEST"
export const REMOVE_MANDATE_COMPANY_SUCCESS = "REMOVE_MANDATE_COMPANY_SUCCESS"
export const REMOVE_MANDATE_COMPANY_FAIL = "REMOVE_MANDATE_COMPANY_FAIL"


export const GET_CHANNEL_PARTNER_LIST_REQUEST = 'GET_CHANNEL_PARTNER_LIST_REQUEST'
export const GET_CHANNEL_PARTNER_LIST_SUCCESS = 'GET_CHANNEL_PARTNER_LIST_SUCCESS'
export const GET_CHANNEL_PARTNER_LIST_FAIL = 'GET_CHANNEL_PARTNER_LIST_FAIL'



export const GET_CHANNEL_PARTNER_DETAIL_REQEUST = 'GET_CHANNEL_PARTNER_DETAIL_REQEUST'
export const GET_CHANNEL_PARTNER_DETAIL_SUCCESS = 'GET_CHANNEL_PARTNER_DETAIL_SUCCESS'
export const GET_CHANNEL_PARTNER_DETAIL_FAIL = 'GET_CHANNEL_PARTNER_DETAIL_FAIL'


export const ADD_CHANNEL_PARTNER_REQUEST = 'ADD_CHANNEL_PARTNER_REQUEST'
export const ADD_CHANNEL_PARTNER_SUCCESS = 'ADD_CHANNEL_PARTNER_SUCCESS'
export const ADD_CHANNEL_PARTNER_FAIL = 'ADD_CHANNEL_PARTNER_FAIL'



export const ADD_CHANNEL_PARTNER_EMPLOYEE_REQUEST = 'ADD_CHANNEL_PARTNER_EMPLOYEE_REQUEST'
export const ADD_CHANNEL_PARTNER_EMPLOYEE_SUCCESS = 'ADD_CHANNEL_PARTNER_EMPLOYEE_SUCCESS'
export const ADD_CHANNEL_PARTNER_EMPLOYEE_FAIL = 'ADD_CHANNEL_PARTNER_EMPLOYEE_FAIL'


export const GET_CP_EMPLOYEE_DETAILS_REQUEST = "GET_CP_EMPLOYEE_DETAILS_REQUEST"
export const GET_CP_EMPLOYEE_DETAILS_SUCCESS = "GET_CP_EMPLOYEE_DETAILS_SUCCESS"
export const GET_CP_EMPLOYEE_DETAILS_FAIL = "GET_CP_EMPLOYEE_DETAILS_FAIL"


export const UPDATE_CP_EMPLOYEE_DETAIL_REQUEST = 'UPDATE_CP_EMPLOYEE_DETAIL_REQUEST'
export const UPDATE_CP_EMPLOYEE_DETAIL_SUCCESS = 'UPDATE_CP_EMPLOYEE_DETAIL_SUCCESS'
export const UPDATE_CP_EMPLOYEE_DETAIL_FAIL = 'UPDATE_CP_EMPLOYEE_DETAIL_FAIL'


export const GET_DASHBOARD_DATA_REQUEST = 'GET_DASHBOARD_DATA_REQUEST'
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS'
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL'


export const GET_ADMIN_USER_LIST_REQUEST = 'GET_ADMIN_USER_LIST_REQUEST'
export const GET_ADMIN_USER_LIST_SUCCESS = 'GET_ADMIN_USER_LIST_SUCCESS'
export const GET_ADMIN_USER_LIST_FAIL = 'GET_ADMIN_USER_LIST_FAIL'



export const GET_ADMIN_USER_DETAIL_REQUEST = 'GET_ADMIN_USER_DETAIL_REQUEST'
export const GET_ADMIN_USER_DETAIL_SUCCESS = 'GET_ADMIN_USER_DETAIL_SUCCESS'
export const GET_ADMIN_USER_DETAIL_FAIL = 'GET_ADMIN_USER_DETAIL_FAIL'


export const ADD_ADMIN_USER_REQUEST = 'ADD_ADMIN_USER_REQUEST'
export const ADD_ADMIN_USER_SUCCESS = 'ADD_ADMIN_USER_SUCCESS'
export const ADD_ADMIN_USER_FAIL = 'ADD_ADMIN_USER_FAIL'


export const GET_ADMIN_ROLES_REQUEST = 'GET_ADMIN_ROLES_REQUEST'
export const GET_ADMIN_ROLES_SUCCESS = 'GET_ADMIN_ROLES_SUCCESS'
export const GET_ADMIN_ROLES_FAIL = 'GET_ADMIN_ROLES_FAIL'


export const UPDATE_ADMIN_USER_DETAIL_REQUEST = 'UPDATE_ADMIN_USER_DETAIL_REQUEST'
export const UPDATE_ADMIN_USER_DETAIL_SUCCESS = 'UPDATE_ADMIN_USER_DETAIL_SUCCESS'
export const UPDATE_ADMIN_USER_DETAIL_FAIL = 'UPDATE_ADMIN_USER_DETAIL_FAIL'


export const GET_ADMIN_PERMISSIONS_REQUEST = 'GET_ADMIN_PERMISSIONS_REQUEST'
export const GET_ADMIN_PERMISSIONS_SUCCESS = 'GET_ADMIN_PERMISSIONS_SUCCESS'
export const GET_ADMIN_PERMISSIONS_FAIL = 'GET_ADMIN_PERMISSIONS_FAIL'


export const CHANGE_ROLE_PERMISSION_REQUEST = 'CHANGE_ROLE_PERMISSION_REQUEST'
export const CHANGE_ROLE_PERMISSION_SUCCESS = 'CHANGE_ROLE_PERMISSION_SUCCESS'
export const CHANGE_ROLE_PERMISSION_FAIL = 'CHANGE_ROLE_PERMISSION_FAIL'


export const ADD_NEW_ADMIN_ROLE_REQUEST = 'ADD_NEW_ADMIN_ROLE_REQUEST'
export const ADD_NEW_ADMIN_ROLE_SUCCESS = 'ADD_NEW_ADMIN_ROLE_SUCCESS'
export const ADD_NEW_ADMIN_ROLE_FAIL = 'ADD_NEW_ADMIN_ROLE_FAIL'


export const ADD_DEVELOPER_REQUEST = 'ADD_DEVELOPER_REQUEST'
export const ADD_DEVELOPER_SUCCESS = 'ADD_DEVELOPER_SUCCESS'
export const ADD_DEVELOPER_FAIL = 'ADD_DEVELOPER_FAIL'

export const EDIT_ADMIN_PROJECT_DETAIL_REQUEST = 'EDIT_ADMIN_PROJECT_DETAIL_REQUEST'
export const EDIT_ADMIN_PROJECT_DETAIL_SUCCESS = 'EDIT_ADMIN_PROJECT_DETAIL_SUCCESS'
export const EDIT_ADMIN_PROJECT_DETAIL_FAIL = 'EDIT_ADMIN_PROJECT_DETAIL_FAIL'


export const EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_REQUEST = 'EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_REQUEST'
export const EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_SUCCESS = 'EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_SUCCESS'
export const EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_FAIL = 'EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_FAIL'