import React from "react";
import moment from "moment";
import "./activity.css";
import * as Icon from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { delelteTaskOrActivity } from "../Actions/CommonAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Activity = ({ className = "col-md-6", data, visitorId }) => {
	const navigate = useNavigate();
	const userInfo = useSelector((state) => state?.userState?.userInfo);
	const dispatch = useDispatch();
	const textColor = {
		warm: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-warning opacity-75 bg-warning",
		cold: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-info opacity-75 bg-info",
		hot: "text-light rounded-5  ms-3 py-1 fs-9  px-2 border border-1 border-danger opacity-75 bg-danger",
		lost: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-primary opacity-75 bg-primary",
		new: "text-light  rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-success opacity-75 bg-success ",
	};

	return (
		<div className={className} id="content">
			{/* <h3>FeedBacks</h3> */}
			<ul class="timeline">
				{data?.map((activity) => {
					if (activity.data_type === "activity") {
						return (
							<>
								<li className="timeline-item mb-5 rounded border p-3 shadow">
									<h5 className={`fw-bold d-flex align-items-start  `}>
										{activity.log_type}{" "}
										<span
											className={` ${textColor[activity.status.toLowerCase()]}`}
											style={{ minWidth: "60px" }}
										>
											{activity.status}
										</span>
										{activity.emp_id === userInfo.id && (
											<Dropdown className="ms-auto">
												<Dropdown.Toggle
													className="hide-arrow"
													variant="white"
													id="dropdown-basic"
												>
													<Icon.ThreeDotsVertical />
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															if (activity.log_type.toLowerCase() === "note") {
																// navigate("");
																navigate("/note", {
																	state: {
																		visitorId: visitorId,
																		logType: activity.log_type,
																		log: activity,
																	},
																});
															} else {
																navigate("/log", {
																	state: {
																		visitorId: visitorId,
																		logType: activity.log_type,
																		log: activity,
																	},
																});
															}
														}}
													>
														{" "}
														<Icon.PencilSquare /> Edit
													</Dropdown.Item>

													<Dropdown.Item
														onClick={() => {
															dispatch(
																delelteTaskOrActivity(
																	{
																		model: "activity",
																		id: activity.id,
																	},
																	visitorId
																)
															);
														}}
													>
														{" "}
														<Icon.Trash /> Delete
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										)}
									</h5>
									<p className="text-muted m-0 fw-bold fs-6">
										{moment(activity.created_at).format(
											"dddd, MMMM Do, YYYY -> hh:mm a"
										)}
									</p>
									{activity.call_outcome !== null && (
										<p className="text-muted lh-sm m-0">
											Call Status :{activity.call_outcome}
										</p>
									)}
									<p className="text-muted lh-sm m-0">{activity.comment}</p>
									<p>
										Logged By:
										<span className="text-muted text-capitalize">
											{activity.emp_name}
										</span>
									</p>
								</li>
							</>
						);
					} else if (activity.data_type === "task") {
						return (
							<>
								<li className="timeline-item mb-5 rounded border p-3 shadow">
									<h5 className={`fw-bold d-flex align-items-start `}>
										{activity.task_type}{" "}
										{(activity.emp_id === userInfo.id ||
											activity.visitor_executive_id === userInfo.id) && (
											<Dropdown className="ms-auto">
												<Dropdown.Toggle
													className="hide-arrow"
													variant="white"
													id="dropdown-basic"
												>
													<Icon.ThreeDotsVertical />
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															navigate("/task", {
																state: {
																	visitorId: visitorId,
																	task: activity,
																},
															});
														}}
													>
														{" "}
														<Icon.PencilSquare /> Edit
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															dispatch(
																delelteTaskOrActivity(
																	{
																		model: "task",
																		id: activity.id,
																	},
																	visitorId
																)
															);
														}}
													>
														{" "}
														<Icon.Trash /> Delete
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										)}
									</h5>
									<p className="text-muted mb-2 fw-bold fs-6">
										{moment(activity.created_at).format(
											"dddd, MMMM Do, YYYY -> hh:mm a"
										)}
									</p>
									<p className="text-muted lh-sm">
										{activity.task_description}

										{activity.task_date_time !== null &&
											" on " +
												moment(activity.task_date_time)
													.format("dddd, MMMM Do,YYYY __ hh:mm a")
													.replace("__", "at")}
									</p>
									<p>
										Logged By:
										<span className="text-muted text-capitalize">
											{activity.assign_to}
										</span>
									</p>
								</li>
							</>
						);
					} else {
						return <></>;
					}
				})}
			</ul>
		</div>
	);
};

export default Activity;
