import React, { useState } from "react";
import Layout from "../Layout/Layout";
import ImageUploader from "../../../Components/ImageUploader";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewEmployee } from "../../../Actions/AdminAction";


const AddEmployee = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [mobileNumber, setMobileNumber] = useState("");
	const submitHandler = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		dispatch(addNewEmployee(formData, navigate))
	};
	return (
		<Layout pageTitle={"Add Employee"}>
			<form onSubmit={submitHandler}>
				<div className="p-3">
					<div className="row">
						<div className="col-md-7 p-2">
							<div className="d-flex justify-content-center">
								<div className="w-30">
									<ImageUploader dbImage={null} />
									<p className="text-center m-0 fw-bolder">
										Upload Profile Photo
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-7 p-2">
							<div>
								<label>First Name</label>
								<input type="text" className="form-control" name="first_name" />
							</div>
						</div>
						<div className="col-md-7 p-2">
							<div>
								<label>Last Name</label>
								<input type="text" className="form-control" name="last_name" />
							</div>
						</div>
						<div className="col-md-7 p-2">
							<div>
								<label>Email</label>
								<input type="email" className="form-control" name="email" />
							</div>
						</div>
						<div className="col-md-7 p-2">
							<div>
								<label>Mobile Number</label>
								<PhoneInput
									country={"in"}
									countryCodeEditable={false}
									placeholder="Enter phone number"
									value={mobileNumber}
									enableSearch={true}
									containerClass="my-container-class"
									inputProps={{
										name: "phone_number",
									}}
									// className="form-control"
									inputClass="form-control"
									onChange={setMobileNumber}
								/>
							</div>
						</div>
						<div className="col-md-7 p-2">
							<div>
								<label>Organization Designation</label>
								<input	
									type="text"
									className="form-control"
									name="designation"
								/>
							</div>
						</div>
						<div className="col-md-7 p-2">
							<div className="d-flex justify-content-end gap-3">
								<button className="btn btn-success">Save</button>
								<button className="btn btn-dark"  type="button" onClick={()=>navigate(-1)}>Cancel</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Layout>
	);
};

export default AddEmployee;
