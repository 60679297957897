import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { addProjectPricing } from "../../../../Actions/AdminAction";
const PricingSlab = ({ item, itemHandler }) => {
	return (
		<div className="d-grid p-3 border rounded gap-2">
			<div>
				<label>Label</label>
				<input
					type="text"
					value={item.key}
					onChange={(e) => {
						const data = { ...item };
						data["key"] = e.target.value;
						itemHandler(data);
					}}
					name="key"
					className="form-control"
				/>
			</div>
			<div>
				<label>Value</label>
				<input
					type="number"
					value={item.value}
					onChange={(e) => {
						const data = { ...item };
						data["value"] = e.target.value;
						itemHandler(data);
					}}
					name="value"
					className="form-control"
				/>
			</div>
			<div class="form-check">
				<input
					class="form-check-input"
					type="checkbox"
					checked={item.in_percantage}
					onChange={(e) => {
						const data = { ...item };
						data["in_percantage"] = e.target.checked;
						itemHandler(data);
					}}
					id="flexCheckDefault"
				/>
				<label class="form-check-label" for="flexCheckDefault">
					Value is in Percantage
				</label>
			</div>
		</div>
	);
};
const AddPricingData = () => {
	const [pricingList, setPricingList] = useState([]);
	const {projectId}=useParams()
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const submitHandler = () => {
		const postData = {
			pricing_list: pricingList,
		};
		dispatch(addProjectPricing(postData,projectId,navigate))
	};
	return (
		<Layout pageTitle={"Add Pricing"}>
			<div className="d-grid gap-3 p-3">
				<div
					className="border rounded d-grid p-2 gap-2 position-relative"
					style={{
						maxHeight: "600px",
						overflowY: "scroll",
						overflowX: "hidden",
					}}
				>
					{pricingList.map((item) => {
						return (
							<PricingSlab
								item={item}
								itemHandler={(e) => {
									setPricingList(
										[...pricingList.filter((d) => d.id !== e.id), e].sort(
											(a, b) => {
												return a.id - b.id;
											}
										)
									);
								}}
							/>
						);
					})}
					<div className="text-center sticky-bottom bg-white p-2">
						<button
							className="btn btn-primary"
							onClick={() => {
								setPricingList([
									...pricingList,
									{
										id:
											pricingList.length > 0
												? pricingList[pricingList.length - 1].id + 1
												: pricingList.length,
										key:"",
										value:0,
										in_percantage:false
									},
								]);
							}}
						>
							Add More
						</button>
					
					</div>
				</div>

				<div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
					<button className="btn btn-success" onClick={submitHandler}>
						Save
					</button>
					
					<button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Cancel</button>
				</div>
			</div>
		</Layout>
	);
};

export default AddPricingData;
