import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { getVisitorStatus } from "../../../Actions/SEAction";
import { userLogout, getUserData} from "../../../Actions/UserAction";
import useWebSocket, { ReadyState } from "react-use-websocket";
import toast from "react-hot-toast"
import Offcanvas from "react-bootstrap/Offcanvas";
import Alert from "../../../assets/alert.gif"
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Layout = ({ children, pageTitle }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [message, setMessage] = useState(null);
	const [reloadStatus, setReloadStatus] = useState(true);
	const userInfo = useSelector((state) => state?.userState?.userInfo);
	const showQR = userInfo?.extra?.qr !== undefined;
	const { status: visitorStatus, visit_id } = useSelector(
		(state) => state?.se?.visitor
	);
	useEffect(() => {
		// console.log(navigate,"----------------")
		
		if (visitorStatus) {
			toast.dismiss()
			toast(
				(t) => (
					<span className=" w-100 h-100 p-2 rounded m-0">
						<div className="row">
							<div className="col-4">
								<img className="img-fluid" src={Alert} alt=""  />
							</div>
							<div className="col-8">
								<p className="text-white">
									Hi <span className="text-capitalize">{userInfo?.name}</span>,
									<br />
									You have been assigned a new visitor !
								</p>
							</div>
							<div className="col-12">
								<button
									className="btn btn-white w-100 text-danger"
									onClick={() => {
										toast.dismiss(t.id);
										navigate(`/visit/${visit_id}`, { replace: true });
									}}
								>
									Show Details
								</button>
							</div>
						</div>
					</span>
				),
				{
					duration: Infinity,
					style: {
						padding:"0px",
						backgroundColor:"var(--danger)"
					},
				}
			);
		}
	}, [visitorStatus]);
	
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		`ws://192.168.1.4:8000/ws/socket-server/${userInfo?.id}/`
	);
	useEffect(() => {
		if (lastMessage?.data !== undefined && lastMessage?.data !== null) {
			const data = JSON.parse(lastMessage?.data);
			setMessage(data.message);
			if (data.type === "reload_visitor_status") {
				setReloadStatus(true);
			}
		}
	}, [lastMessage]);
	useEffect(() => {
		if (reloadStatus) {
			dispatch(getVisitorStatus());
			setReloadStatus(false);
		}
	}, [reloadStatus]);
	const connectionStatus = {
		[ReadyState.CONNECTING]: "Connecting",
		[ReadyState.OPEN]: "Open",
		[ReadyState.CLOSING]: "Closing",
		[ReadyState.CLOSED]: "Closed",
		[ReadyState.UNINSTANTIATED]: "Uninstantiated",
	}[readyState];
useEffect(()=>{
	dispatch(getUserData());
},[])
	
	const location = useLocation();
	const [showSidebar, setShowSidebar] = useState(false);
	const isMobileView = window.innerWidth <= 768;
	const deivceHeight = window.innerHeight;
	const showBottomBar = () => {
		if (location.pathname == "/") {
			return true;
		}
		const conditionRouteList = [
			"/visit",
			"/team",
			"/profile",
			"tasks",
			"channel-partner",
			"report",
			"inventory",
		];
		return conditionRouteList.some((element) =>
			location.pathname.includes(element)
		);
	};
	return (
		<>
			<div className="sidebar_outer_container d-flex w-100">
				<Sidebar />
				<div className="sidebar_right_container p-0 pt-md-2 pb-md-2 pe-md-2   ">
					<div
						className="sidebar_right_inner_container w-100 "
						// style={{
						// 	height: `${isMobileView ? deivceHeight : deivceHeight - 15}px`,
						// 	overflow: "hidden",
						// }}
					>
						<div
							className="right_screen w-100 "
							// style={{
							// 	height: `${isMobileView ? deivceHeight : deivceHeight - 15}px`,
							// 	overflow: "hidden",
							// }}
						>
							<Header pageTitle={pageTitle} />
							<div
								className="right_inner_screen w-100  "
								// style={{
								// 	height: `${
								// 		isMobileView ? deivceHeight - 130 : deivceHeight - 70
								// 	}px `,
								// 	overflowX: "hidden",
								// }}
							>
								{isMobileView ? (
									<div
										className="h-80 w-100"
										style={{ overflowY: "scroll", overflowX: "hidden" }}
									>
										{children}
									</div>
								) : (
									children
								)}
								{/* <Outlet/> */}
								{isMobileView && showBottomBar() ? (
									<>
										{/* <div 
											className="bg-white w-100 position-fixed  bottom-0 z-index-10 "
											style={{ height: "66px" }}
										></div> */}
										<div className=" d-md-none d-block  w-100 position-fixed  bottom-0 z-index-10 ">
											<div className="row m-0 p-0 bg-accent pb-2 text-center ">
												<div className="col mx-1 p-0">
													<Link
														className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
															location.pathname == "/"
																? "btn-light"
																: "bg-accent text-light"
														} w-100 py-3 px-1`}
														to="/"
														activeClassName="active"
													>
														<span style={{ fontSize: "medium" }}>
															<Icon.HouseFill />
															<br />
															<span className="ms-2">Home</span>
														</span>
													</Link>
												</div>
												<div className="col mx-1 p-0">
													<Link
														to="/visit"
														className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
															location.pathname == "/visit"
																? "btn-light"
																: "bg-accent text-light"
														} w-100 py-3 px-1`}
														activeClassName="active"
													>
														<span style={{ fontSize: "medium" }}>
															<Icon.CarFrontFill />
															<br />
															<span className="ms-2">EazyVisits</span>
														</span>
													</Link>
												</div>
												<div className="col mx-1 p-0">
													<Link
														className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
															location.pathname == "/tasks"
																? "btn-light"
																: "bg-accent text-light"
														} w-100 py-3 px-1`}
														to="/tasks"
														activeClassName="active"
													>
														<span style={{ fontSize: "medium" }}>
															{" "}
															<Icon.ListTask />
															<br />
															<span className="ms-2">EazyTask</span>
														</span>
													</Link>
												</div>

												<div className="col mx-1 p-0">
													<button
														className={`btn rounded-top-0  mt-0 fs-1 pt-0 rounded-bottom-circle ${
															showSidebar ? "btn-light" : "bg-accent text-light"
														}  w-100 py-3 px-1`}
														onClick={() => setShowSidebar(true)}
													>
														<span classname="display-1">
															{showSidebar ? (
																<Icon.XLg />
															) : (
																<Icon.FilterRight />
															)}
														</span>
													</button>
												</div>
											</div>
										</div>
									</>
								) : (
									isMobileView && (
										<></>
										// <div
										// 	className="bg-white w-100 position-fixed  bottom-0 z-index-10 "
										// 	style={{ height: "66px" }}
										// ></div>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Offcanvas
				className={"bg-light w-60"}
				scroll={false}
				backdrop={false}
				placement={"end"}
				show={showSidebar}
				onHide={() => setShowSidebar(false)}
			>
				<Offcanvas.Header closeVariant={"dark"} closeButton>
					<Offcanvas.Title>Pages</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className="d-grid p-0 pe-1 py-3">
					<div class="list-group  h-100  m-0 rounded-0 p-0 w-100">
						<Link
							to="/profile"
							className={`list-group-item rounded-end-4 list-group-item-action ${
								location.pathname == "/profile" && "active"
							}  py-3 `}
							activeClassName="active"
						>
							<span style={{ fontSize: "medium" }}>
								<Icon.PersonCircle />
								<span className="ms-2">Profile</span>
							</span>
						</Link>
						<Link
							className={`list-group-item rounded-end-4 list-group-item-action ${
								location.pathname == "/team" && "active"
							} py-3 `}
							to="/team"
							activeClassName="active"
						>
							<span style={{ fontSize: "medium" }}>
								{" "}
								<Icon.PeopleFill />
								<span className="ms-2">EazyTeams</span>
							</span>
						</Link>
						{showQR && (
							<Link
								className={`list-group-item rounded-end-4 list-group-item-action ${
									location.pathname == "/channel-partner" && "active"
								} py-3 `}
								to="/channel-partner"
								activeClassName="active"
							>
								<span style={{ fontSize: "medium" }}>
									<FontAwesomeIcon icon={icon.faUserTie} />
									<span className="ms-2">ChannelPartner</span>
								</span>
							</Link>
						)}
						<Link
							className={`list-group-item rounded-end-4 list-group-item-action ${
								location.pathname == "/inventory" && "active"
							} py-3 `}
							to="/inventory"
							activeClassName="active"
						>
							<span style={{ fontSize: "medium" }}>
								<FontAwesomeIcon icon={icon.faBuilding} />
								<span className="ms-2">Inventory</span>
							</span>
						</Link>
					</div>
					{showQR && (
						<div className="p-3">
							<img
								className="img-fluid imgh-thumbnail"
								src={"https://api.eazyapp.in" + userInfo?.extra?.qr}
								alt=""
							/>
						</div>
					)}
					<button
						className="bg-danger   mt-auto text-white px-3 py-3 list-group-item rounded-end-4 list-group-item-action"
						onClick={() => dispatch(userLogout())}
					>
						<Icon.BoxArrowLeft /> Logout
					</button>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

export default Layout;
