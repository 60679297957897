import React, { useState ,useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../Actions/axios";
import { registerChannelPartner } from "../../Actions/CPAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


function OTPResendTimer({ otpHandler }) {
	const [minutes, setMinutes] = useState(2);
	const [seconds, setSeconds] = useState(0);
	const [isResendEnabled, setIsResendEnabled] = useState(false);

	useEffect(() => {
		const timer = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			} else {
				if (minutes > 0) {
					setMinutes(minutes - 1);
					setSeconds(59);
				} else {
					clearInterval(timer);

					setIsResendEnabled(true);
				}
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [minutes, seconds]);

	const handleResendOTP = () => {
		setMinutes(2);
		setSeconds(0);
		setIsResendEnabled(false);
		otpHandler(); // Call your OTP resend logic here
	};

	return (
		<div className="text-end" >
			{isResendEnabled ? (
				<p className="text-primary" style={{ cursor: "pointer" }} onClick={handleResendOTP}>Resend OTP</p>
			) : (
				<p>Resend OTP in {minutes} minutes {seconds} seconds</p>
			)}
		</div>
	);
}

const ChannelPartnerSignUp = () => {
	const [mobileNumber, setMobileNumber] = useState();
	const [otp, setOTP] = useState();
	const [askOtp, setAskOTP] = useState(false);
	const [verified, setVerified] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { sourceId } = useParams();

	function validatePassword(password) {
		// Define the criteria
		// toast.error(JSON.stringify(password));
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasDigit = /\d/.test(password);
		const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

		// Check if all criteria are met
		const isLengthValid = password.length >= minLength;
		const isValid =
			hasUpperCase &&
			hasLowerCase &&
			hasDigit &&
			hasSpecialChar &&
			isLengthValid;

		return isValid;
	}

	const verfifyOTP = async () => {
		try {
			const config = {
				headers: {
					"Content-type": "application/json",
				},
			};
			const { data } = await axios.post(
				`verify-mobile-number/`,
				{ mobile_number: mobileNumber, otp: otp },
				config
			);
			setVerified(true);
			toast.success("OTP Verified");
		} catch (error) {
			toast.error(
				error.response && error.response.data.error
					? error.response.data.error
					: error.message
			);
		}
	};
	const mobileNumberValidtor = (phoneNumber) => {
		const phoneRegex = /^(\+?91|0)?[6789]\d{9}$/;
		return phoneRegex.test(phoneNumber);
	};

	const sendOTP = async () => {
		if (!mobileNumberValidtor(mobileNumber)) {
			toast.error("Enter a Valid Mobile Number");
			return;
		}
		try {
			const config = {
				headers: {
					"Content-type": "application/json",
				},
			};
			const { data } = await axios.get(
				`send-otp-to-mobile-number/${mobileNumber}`,
				config
			);
			setAskOTP(true);
		} catch (error) {
			toast.error(
				error.response && error.response.data.error
					? error.response.data.error
					: error.message
			);
		}
	};

	const submitHandler = async (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		object["phone_number"] = mobileNumber;
		if (!validatePassword(object.password)) {
			toast.error(
				"Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number."
			);
			return;
		}
		if (object.password == object.confirm_password) {
			dispatch(registerChannelPartner(object, navigate));
		} else {
			toast.error("Passowrd does not match");
		}
	};
	return (
		<div
			className="vh-100 vw-100 p-3"
			style={{ overflowY: "scroll", overflowX: "hidden" }}
		>
			<h2 className="section_heading">Welcome Channel Partner !</h2>
			<p>Channel Partner SignUp Form</p>
			<form className="d-grid gap-3" onSubmit={(e) => submitHandler(e)}>
				<input
					type="text"
					name="sourcing"
					value={sourceId}
					id=""
					hidden
					disabled
				/>
				<div>
					{!verified && <label>First Name</label>}
					<input
						type="text"
						name="first_name"
						className="form-control"
						required
						hidden={verified}
					/>
				</div>
				<div>
					{!verified && (
						<>
							<label>Last Name</label>
						</>
					)}
					<input
						type="text"
						name="last_name"
						className="form-control"
						required
						hidden={verified}
					/>
				</div>
				<div>
					{!verified && (
						<>
							<label>Email</label>
						</>
					)}
					<input
						type="email"
						name="email"
						className="form-control"
						required
						hidden={verified}
					/>
				</div>
				<div>
					<label>Mobile Number</label>
					<PhoneInput
						country={"in"}
						countryCodeEditable={false}
						placeholder="Enter phone number"
						value={mobileNumber}
						enableSearch={true}
						containerClass="my-container-class w-100"
						// onBlur={sendOTP}
						inputProps={{
							name: "phone_number",
						}}
						className=" w-100"
						disabled={verified}
						inputClass="form-control w-100"
						onChange={setMobileNumber}
					/>
					{askOtp && !verified ? (
						<>
							<div className="mt-4">
								<label> Enter Otp </label>
								<input
									type="number"
									className="form-control"
									onChange={(e) => setOTP(e.target.value)}
								/>
							</div>
							<OTPResendTimer otpHandler={sendOTP}  />
							<div className="text-center py-3">
								<button
									type="button"
									className="btn btn-dark"
									onClick={verfifyOTP}
								>
									Verify Mobile Number
								</button>
							</div>
						</>
					) : !verified ? (
						<div className="text-center py-3">
							<button className="btn btn-dark" type="button" onClick={sendOTP}>
								Send OTP
							</button>
						</div>
					) : (
						<></>
					)}
				</div>
				{verified && (
					<>
						<div>
							<label>Company Name</label>
							<input
								type="text"
								name="company_name"
								className="form-control"
								required
							/>
						</div>
						<div>
							<label>Office Location</label>
							<input
								type="text"
								name="office_location"
								className="form-control"
								required
							/>
						</div>
						<div>
							<label>RERA No.</label>
							<input
								type="text"
								name="rera_number"
								className="form-control"
								required
							/>
						</div>
						<div>
							<label>Password</label>
							<input
								type="password"
								name="password"
								className="form-control"
								required
							/>
						</div>
						<div>
							<label>Confirm Password</label>
							<input
								type="password"
								name="confirm_password"
								className="form-control"
								required
							/>
						</div>
						<div className="row mt-2">
							<div className="col-md-4"></div>
							<div className="col-md-4">
								<button className="btn btn-warning w-100">Submit</button>
							</div>
							<div className="col-md-4"></div>
						</div>
					</>
				)}
			</form>
		</div>
	);
};

export default ChannelPartnerSignUp;
