import React, { useEffect, useState, useRef } from "react";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction } from "../../Actions/UserAction";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Logo from "../../assets/eazyapp-logo-blue.png"

import WhiteLogo from "../../assets/eazyapp-logo-white.png"
import ForgotPassword from "./ForgotPassword";

const LoginPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showpassword, setShowpassword] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const isMobileView = window.innerWidth <= 1024 ? true : false;
	const sliderRef = useRef();
	useEffect(() => {
		if (isMobileView) {
			setTimeout(() => {
				sliderRef.current.slickNext();
			}, 1000)
		}
	}, [])
	const loginHandler = (form) => {

		form.preventDefault();
		var formData = new FormData(form.target);
		const object = Object.fromEntries(formData);
		dispatch(userLoginAction(object));

	};


	const settings = {
		dots: false, // Show navigation dots
		infinite: false, // Loop the carousel
		speed: 200, // Transition speed in milliseconds
		slidesToShow: 1, // Number of slides to show at a time
		slidesToScroll: 1, // Number of slides to scroll at a time
		arrows: false,
		adaptiveHeight: true,
		swipeToSlide: false,
		arrows: false,
		draggable: false,
		swipe: false,
		fade: true,
		cssEase: "linear",
	};

	return (
		<>
			{isMobileView ? (
				<Slider
					ref={sliderRef}
					style={{ height: "100vh", overflow: "hidden" }}
					{...settings}
				>
					<div className="bg-accent p-5 d-grid gap-2 vh-100 justify-content-center align-items-center ">
						<img
							style={{ height: "100px", width: "auto" }}
							src={WhiteLogo}
							alt=""
						/>
					</div>
					<div
						className=" d-flex justify-content-center align-items-center vh-100 bg-white h-100 w-100 "
					// style={{ height: "90vh !important" }}
					>
						{showForgotPassword ? <ForgotPassword  setShowForgotPassword={setShowForgotPassword} /> :

							<form className="d-grid gap-3 w-75" onSubmit={loginHandler}>
								<div>
									<img
										style={{ height: "100px", width: "auto" }}
										src={Logo}
										alt=""
									/>
									<p className="text-dark h1 my-4 ">
										<span className="text-danger ">Truly Digital </span>
										Real Estate Interactions.
									</p>
									<p className="m-0">Welcome! Please Login to Continue.</p>
								</div>
								<div>
									<label className="label">Email</label>
									<div className="input-group border border-top-0 border-end-0 border-start-0">
										<input
											type="email"
											name="email"
											className="form-control border-0 "
											placeholder="example@mail.com"
										/>
										<span className="p-1">
											<Icon.EnvelopeAt />
										</span>
									</div>
								</div>
								<div className="">
									<label className="label">Password</label>
									<div className="input-group border border-top-0 border-end-0 border-start-0">
										<input
											type={showpassword ? "text" : "password"}
											name="password"
											className="form-control password-field border-0 "
											placeholder="**********"
										/>
										<span
											className="btn btn-white fs-4 border-start-0 border  rounded-start-0 "
											type="button"
											onClick={() => {
												setShowpassword(!showpassword);
											}}
										>
											{showpassword ? <Icon.EyeSlash /> : <Icon.Eye />}{" "}
										</span>
									</div>
								</div>
								<div className="text-end">
									<span className="text-primary" style={{ cursor: "pointer" }}  onClick={() => setShowForgotPassword(true)}>Forgot Password?</span>
								</div>
								<div className="text-end">
									<button className="btn btn-warning">Submit</button>
								</div>
							</form>

						}

					</div>
				</Slider>
			) : (
				<div
					className=" d-flex justify-content-center align-items-center  bg-warning"
					style={{ minHeight: "100vh", minWidth: "100vw", overflow: "hidden" }}
				>
					<div className="row rounded">
						<div
							key={0}
							className="col-md-6 bg-accent p-5  rounded-start"
							style={{ width: "600px", height: "600px" }}
						>
							<img
								style={{ height: "100px", width: "auto" }}
								src={WhiteLogo}
								alt=""
							/>
							<p className="text-white my-3 fs-3">Welcome !</p>
							<p className="text-white fs-4 ">Please Login to Continue</p>
							<p className="text-white fs-4 "></p>

							<p className="text-white h1  ">
								<span className="text-warning">Truly Digital </span>
								Real Estate Interactions.
							</p>
						</div>
						<div
							key={1}
							className="col-md-6 bg-white d-flex align-items-center justify-content-center rounded-end"
							// style={{ position: "relative" }}
							style={{ width: "600px", height: "600px" }}
						>
							{showForgotPassword ? <ForgotPassword  setShowForgotPassword={setShowForgotPassword} /> :

								<form className="d-grid gap-3 w-50" onSubmit={loginHandler}>
									<h2>Login</h2>
									<div>
										<label>Email</label>
										<input type="email" name="email" className="form-control" />
									</div>
									<div className="">
										<label>Password</label>
										<div className="input-group">
											<input
												type={showpassword ? "text" : "password"}
												name="password"
												className="form-control border-end-0 password-field"
											/>
											<span
												className="bg-white  p-2 fs-5   border-end border-top border-bottom  rounded-start-0 rounded "
												onClick={() => {
													setShowpassword(!showpassword);
												}}
											>
												{showpassword ? <Icon.EyeSlash /> : <Icon.Eye />}{" "}
											</span>
										</div>
									</div>
										<div className="text-end">
											<span className="text-primary" style={{ cursor: "pointer" }} onClick={() => setShowForgotPassword(true)}>Forgot Password?</span>
										</div>
									<div className="text-end">
										<button className="btn btn-warning">Submit</button>
									</div>
								</form>

							}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LoginPage;
