import React, { useEffect, useState } from "react";
import QrReader from "react-qr-scanner";

const QRScanner = ({ setData }) => {
	const [cameras, setCameras] = useState([]);
	const [camera, setCamera] = useState(null);
	const [show, setShow] = useState(true);
	const [myerror, setError] = useState("No result");
	useEffect(() => {
		if (!show) {
			setShow(true);
		}
	}, [camera]);
	useEffect(() => {
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			setCameras(devices.filter(({ kind }) => kind === "videoinput"));
			if (devices.filter(({ kind }) => kind === "videoinput").length > 1) {
				setCamera(
					devices.filter(
						({ kind, label }) =>
							kind === "videoinput" && label.includes("facing back")
					)[0].deviceId
				);
			} else {
				setCamera(
					devices.filter(({ kind, label }) => kind === "videoinput")[0].deviceId
				);
			}
			setShow(false);
		});
	}, []);
	return (
		<>
			{show && (
				<QrReader
					constraints={{
						facingMode: "environment",
						video: { deviceId: camera },
					}}
					chooseDeviceId={camera}
					style={{ width: "100%" }}
					onScan={(result, error) => {
						if (!!result) {
							setData(result?.text);
						}
						if (error) {
							setError(error);
						}
					}}
					onError={(error) => {
						setError(error);
					}}
				/>
			)}
			<div className="px-3">
			<select
				name=""
				id=""
				className="form-select "
				value={camera}
				onChange={(e) => {
					setCamera(e.target.value);
					setShow(false);
				}}
			>
				{cameras?.map((e, index) => {
					return (
						<option value={e.deviceId}>
							{" "}
							{e.label !== "" ? e.label : "Camera"}
						</option>
					);
				})}
			</select>
			</div>
		</>
	);
};

export default QRScanner;
