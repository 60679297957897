import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { userLogout } from './Actions/UserAction';
const InactivityTimer = () => {
  const inactivityTime =  15 * 60 * 1000; // 15 minutes in milliseconds
  const timeoutIdRef = useRef(null);
  const dispatch = useDispatch();
  const onInactivity = () => {
    logoutUser();
  };

  const resetInactivityTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(onInactivity, inactivityTime);
  };

  const logoutUser = () => {
    // Clear user session or token
    // For example, removing token from local storage:
    dispatch(userLogout());
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'click', 'scroll', 'keydown'];

    const setupInactivityListeners = () => {
      events.forEach(event => {
        window.addEventListener(event, resetInactivityTimer);
      });
    };

    const cleanupInactivityListeners = () => {
      events.forEach(event => {
        window.removeEventListener(event, resetInactivityTimer);
      });
    };

    setupInactivityListeners();
    resetInactivityTimer(); // start the timer when the component mounts

    return () => {
      cleanupInactivityListeners();
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []); // empty dependency array ensures this effect runs only once on mount

  return (<></>);
};

export default InactivityTimer;
