import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const BasicInfo = () => {
  const navigate=useNavigate();
  const [age,setAge]=useState("");
  const [mobileNumber,setMobileNumber]=useState("");
  const [whatsappNumber,setWhatsappNumber]=useState("");
  const [mobileNumberIsWhatsappNumber,setMobileNumberIsWhatsappNumber]=useState(true);
	const dispatch = useDispatch();
	const { checkInData } = useSelector((state) => state?.cre?.checkInDetial);

  useEffect(() => {
    if(checkInData !== undefined || checkInData !== null){
      setMobileNumber(checkInData?.mobile_number)
      if(checkInData?.date_of_birth !== undefined || checkInData?.date_of_birth !== null){
        setAge(moment().diff(checkInData?.date_of_birth, "years", false));
      }
    }
  }, [checkInData]);

  const submitBasicInfo=(e)=>{
    e.preventDefault();
    var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
    if (!mobileNumberIsWhatsappNumber){

		object["whatsapp_number"] = whatsappNumber;
	}
	  Object.keys(object).map((key) => {
		  if (object[key] === "" || object[key] === undefined || object[key] === null  ) {
			  toast.error(`Please fill ${key}`);
			  return
		  }
	  })
	var keys =Object.keys(checkInData)
	var objectKeys = Object.keys(object);
	var addKey= keys.filter(key=>!objectKeys.includes(key))

	for(let i in addKey){
		object[addKey[i]]=checkInData[addKey[i]]
	}
    dispatch({
			type: "ADD_CHECKIN_DATA",
			payload: object,
		});
    navigate("/check-in/residential-info");
  }

	return (
		<div className="h-100 w-100 p-3">
			<h2 className="section_heading">Personal Details</h2>
			<form className="" onSubmit={submitBasicInfo}>
				<input
					type="text"
					className="form-control"
					name="mobile_number"
					value={mobileNumber}
					hidden
				/>
				<div className="row gap-3 px-3">
					<div className="col-md-12">
						<div className="row gap-3">
							<div className="col-md-6">
								<label>First Name</label>
								<input
									type="text"
									className="form-control"
									name="first_name"
									defaultValue={
										checkInData?.first_name !== undefined
											? checkInData?.first_name
											: null
									}
									required
								/>
							</div>
							<div className="col-md-6">
								<label>Last Name</label>
								<input
									type="text"
									className="form-control"
									defaultValue={
										checkInData?.last_name !== undefined
											? checkInData?.last_name
											: null
									}
									name="last_name"
									required
								/>
							</div>
						</div>
					</div>
					<div className="col-md-5">
						<label>Email</label>
						<input
							type="email"
							className="form-control"
							defaultValue={
								checkInData?.email !== undefined ? checkInData?.email : null
							}
							name="email"
							required
						/>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-8">
								<label>Date Of Birth</label>
								<input
									type="date"
									className="form-control"
									name="date_of_birth"
									defaultValue={
										checkInData?.date_of_birth !== undefined
											? checkInData?.date_of_birth
											: null
									}
									required
									onChange={(e) => {
										const calculatedAge = moment().diff(
											e.target.value,
											"years",
											false
										);
										setAge(calculatedAge);
									}}
								/>
							</div>
							<div className="col-4 d-flex align-items-end">
								<p>Age : {age}</p>
							</div>
						</div>
					</div>
					<div className="col-md-12 d-flex justify-content-between">
						<label>
							Can we contact on WhatsApp by this number +{mobileNumber} ?
						</label>
						<div className="d-flex btn-group">
							<button
								type="button"
								className={`btn ${
									mobileNumberIsWhatsappNumber ? "btn-warning" : "btn-dark"
								}`}
								onClick={() => {
									setMobileNumberIsWhatsappNumber(true);
								}}
							>
								Yes
							</button>
							<button
								type="button"
								className={`btn ${
									mobileNumberIsWhatsappNumber ? "btn-dark" : "btn-warning"
								}`}
								onClick={() => {
									setMobileNumberIsWhatsappNumber(false);
								}}
							>
								No
							</button>
						</div>
					</div>
					{!mobileNumberIsWhatsappNumber ? (
						<div className="col-12">
							<label>WhatsApp Number</label>
							<PhoneInput
								country={"in"}
								countryCodeEditable={false}
								placeholder="Enter phone number"
								value={whatsappNumber}
								enableSearch={true}
								containerClass="my-container-class"
								inputProps={{ name: "whatsapp_number", required: true }}
								// className="form-control"
								inputClass="form-control"
								onChange={setWhatsappNumber}
							/>
						</div>
					) : (
						<input
							type="text"
							className="form-control"
							name="whatsapp_number"
							value={mobileNumber}
							hidden
						/>
					)}
				</div>
				<div className="row mt-5">
					<div className="col-md-4"></div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<button className="btn btn-success w-100 ">Next</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default BasicInfo;
