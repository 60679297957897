import React, { useEffect,useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../Layout/Layout'
import { getProjectDetails } from '../../../Actions/MyAdminAction'
import {
    deleteConfig,
    deleteGalleryItem,
    deleteDocument,
    editGalleryDetail
} from "../../../Actions/AdminAction";
import numberToWords from "../../numberFormater";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, Accordion, Dropdown, Modal } from "react-bootstrap";
import moment from "moment";
import Inventory from "../Components/Inventory";
import { saveInventory, editInventoryData,deleteInventory } from "../../../Actions/AdminAction";
import { updateProjectDetail, removeMandateCompnay, addMandateCompany } from "../../../Actions/MyAdminAction";


const ConfirmDelete = ({id ,callBack,projectId , label="Delete"}) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    return (
        <>
        <div className='' onClick={() => setShow(true)} >{label}</div>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this item?
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-danger"
                    onClick={() => {
                        setShow(false);
                        dispatch(callBack(id,projectId))
                    }}
                >
                    Delete
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={() => setShow(false)}
                >
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
        </>
    )
  
};

const AdminProjectDetail = () => {
    const [page, setPage] = useState("basic_detail");
    const [basicDetailEdit, SetBasicDetailEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const { projectId } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { projects_edit } =useSelector(state=>state?.permissions)
    const { loading, projectDetail, error } = useSelector(state => state?.myAdmin.projectDetails)
    useEffect(() => {
        dispatch(getProjectDetails(projectId))
    }, [])
    useEffect(() => {
        if (window.location.hash.replace("#", "") !== "") {
            setPage(window.location.hash.replace("#", ""));
        }
    }, []);

    useEffect(() => {
        window.location.hash = page;
    }, [page]);

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    const ListOfFiles = projectDetail?.documents
        ? [...new Set(projectDetail.documents.map((item) => item.file_type))]
        : [];

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const object = Object.fromEntries(formData);
        dispatch(updateProjectDetail(projectId, object, navigate));
    };

    if (projectDetail) {

        return (
            <Layout pageTitle={"Project Title"}>
                <div className="p-2">
                    <Tabs
                        id="controlled-tab-example"
                        variant="tabs"
                        activeKey={page}
                        onSelect={(k) => setPage(k)}
                        className=" gap-2 gap-md-4 "
                    >
                        <Tab eventKey="basic_detail" title="Basic Detail">
                            {!basicDetailEdit && projects_edit && (
                                <div className="position-relative">
                                    <button
                                        className="btn  position-absolute end-0 mx-2 my-1 fs-4 "
                                        onClick={() => SetBasicDetailEdit(true)}
                                    >
                                        <FontAwesomeIcon icon={icon.faEdit} />{" "}
                                        Edit
                                    </button>
                                </div>
                            )}
                            <form onSubmit={submitHandler}>
                                <div className="d-grid gap-2 p-2 py-4">
                                    <div className="myinput-group">
                                        <label>Project Name</label>
                                        <input
                                            type="text"
                                            className={`form-control  ${basicDetailEdit ? "" : " bg-white border-0 "
                                                } `}
                                            defaultValue={projectDetail.name}
                                            name="name"
                                            disabled={!basicDetailEdit}
                                        />
                                    </div>
                                    <div className="myinput-group">
                                        <label>About</label>
                                        <textarea
                                            type="text"
                                            className={`form-control  ${basicDetailEdit ? "" : " bg-white border-0  "
                                                } `}
                                            defaultValue={projectDetail.about}
                                            rows={8}
                                            name="about"
                                            disabled={!basicDetailEdit}
                                        />
                                    </div>
                                    <div className="myinput-group">
                                        <label>Possession Date</label>
                                        <input
                                            type="date"
                                            className={`form-control  ${basicDetailEdit
                                                    ? ""
                                                    : " bg-white border-0 .content-none "
                                                } `}
                                            defaultValue={projectDetail.possession_date}
                                            name="possession_date"
                                            disabled={!basicDetailEdit}
                                        />
                                    </div>
                                    <div className="myinput-group">
                                        <label>Launch Date</label>
                                        <input
                                            type="date"
                                            className={`form-control  ${basicDetailEdit ? "" : " bg-white border-0 content-none"
                                                } `}
                                            defaultValue={projectDetail.launch_date}
                                            name="launch_date"
                                            disabled={!basicDetailEdit}
                                        />
                                    </div>
                                    {basicDetailEdit && (
                                        <div className="d-flex justify-content-end gap-2 p-3 text-end">
                                            <button type="submit" className="btn btn-primary">
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    setReload(true);
                                                    SetBasicDetailEdit(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="config" title="Configuration">
                            <div className="row  py-4 p-2">
                                {
                                    projects_edit &&

                                <div className="col-12 text-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => navigate(`/add-configuration/${projectId}`)}
                                    >
                                        Add 
                                    </button>
                                </div>
                                }
                                {projectDetail?.configs.map((config) => {
                                    return (
                                        <div className="col-md-6 col-12 p-2">
                                            <div className="border rounded p-2">
                                                <div className="row ">
                                                    <div className="col-4">
                                                        <img
                                                            src={"https://api.eazyapp.in" + config.file}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <di className="col-8">
                                                        <div className="position-relative">
                                                            {
                                                                projects_edit &&
                                                            <Dropdown
                                                                className="position-absolute end-0  "
                                                                style={{ zIndex: "1" }}
                                                            >
                                                                <Dropdown.Toggle
                                                                    className="hide-arrow"
                                                                    variant=""
                                                                    id="dropdown-basic"
                                                                >
                                                                    <FontAwesomeIcon icon={icon.faEllipsis} />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            navigate(`/edit-configuration/${config.id}`);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        // onClick={() => {
                                                                        //     dispatch(
                                                                        //         deleteConfig(config.id, projectId)
                                                                        //     );
                                                                        // }}
                                                                    >
                                                                                <ConfirmDelete id={config.id} callBack={deleteConfig} projectId={projectId}/>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            }
                                                        </div>
                                                        <p className="card_heading m-0">{config.name}</p>
                                                        <p className="m-0">
                                                            {" "}
                                                            <span>Configuration: </span> {config.config}
                                                        </p>
                                                        <p className="m-0">
                                                            {" "}
                                                            <span>Carpet Area: </span> {config.carpet_area}
                                                            sq.ft
                                                        </p>
                                                        <p className="m-0">
                                                            <span>Price: </span>
                                                            {numberToWords(config.price)}{" "}
                                                            {config.all_in ? "All Inclusive" : "++"}{" "}
                                                        </p>
                                                    </di>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Tab>
                        <Tab eventKey="gallery" title="Gallery">
                            <div className="row p-2">
                                {
                                    projects_edit &&

                                <div className="col-12 text-end py-3">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            navigate(`/add-gallery-item/${projectId}`);
                                        }}
                                    >
                                        Add 
                                    </button>
                                </div>
                                }
                                {projectDetail?.gallery?.map((item) => {
                                    return (
                                        <div className="col-md-4 col-12 p-2">
                                            <div className="p-2 border rounded position-relative ">
                                                <div className="postion-relative">
                                                    {
                                                        projects_edit &&
                                                    <Dropdown
                                                        className="position-absolute end-0 me-3 "
                                                        style={{ zIndex: "1" }}
                                                    >
                                                        <Dropdown.Toggle
                                                            className="hide-arrow"
                                                            variant=""
                                                            id="dropdown-basic"
                                                        >
                                                            <FontAwesomeIcon icon={icon.faEllipsis} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {!item.cover_img && (
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            editGalleryDetail({ cover_img: !item.cover_img }, navigate, item.id)
                                                                        )
                                                                    }}
                                                                >
                                                                    {item.cover_img
                                                                        ? "Remove Cover Image"
                                                                        : "Set Cover Image"}
                                                                </Dropdown.Item>
                                                            )}
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    navigate(`/edit-gallery-item/${item.id}`);
                                                                }}
                                                            >
                                                                Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                            
                                                            >
                                                                <ConfirmDelete id={item.id} callBack={deleteGalleryItem} projectId={projectId}/>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    }
                                                </div>
                                                <div className="d-grid place-items-center">
                                                    <img
                                                        style={{ height: "200px", width: "250px" }}
                                                        src={"https://api.eazyapp.in" + item.item}
                                                        alt=""
                                                    />
                                                    <p className="m-0">{item.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Tab>
                        <Tab eventKey="documents" title="Documents">
                            {projects_edit &&
                            <div className="py-3 text-end">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        navigate(`/add-document/${projectId}`);
                                    }}
                                >
                                    Add 
                                </button>
                            </div>
                            }
                            <Accordion>
                                {ListOfFiles.map((key, index) => {
                                    return (
                                        <Accordion.Item eventKey={index.toString()}>
                                            <Accordion.Header>{key}</Accordion.Header>
                                            <Accordion.Body>
                                                {projectDetail?.documents.map((item) => {
                                                    if (item.file_type == key) {
                                                        return (
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <a href={"https://api.eazyapp.in"+item.file} target="_blank">

                                                                        {
                                                                            item.file.split("/")[
                                                                            item.file.split("/").length - 1
                                                                            ]
                                                                        }
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <label className="text-muted m-n2">
                                                                            Modified On
                                                                        </label>
                                                                        <p className="m-0">
                                                                            {moment(item?.updated_at).fromNow()}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <label className="text-muted m-n2">
                                                                            Created On
                                                                        </label>
                                                                        <p className="m-0">
                                                                            {moment(item?.updated_at).fromNow()}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        {
                                                                            projects_edit &&
                                                                        <Dropdown
                                                                            className=" "
                                                                            style={{ zIndex: "1" }}
                                                                        >
                                                                            <Dropdown.Toggle
                                                                                className="hide-arrow"
                                                                                variant=""
                                                                                id="dropdown-basic"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={icon.faEllipsis}
                                                                                />
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item
                                                                                    onClick={() => {
                                                                                        navigate(
                                                                                            `/edit-document/${item?.id}`
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Dropdown.Item>
                                                                                <Dropdown.Item
                                                                                >
                                                                                    <ConfirmDelete id={item.id} callBack={deleteDocument} projectId={projectId}/>
                                                                                </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                            {/* {JSON.stringify(projectDetail.documents)} */}
                        </Tab>
                        <Tab eventKey="amenities" title="Amenities">
                            {
                                projects_edit &&

                            <div className="text-end p-3">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        navigate(`/edit-amenitie-list/${projectId}`);
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                            }
                            <div className="row row-cols-auto ">
                                {projectDetail.amenities.map((item) => {
                                    return (
                                        <div className="col p-2">
                                            <div className="position-relative">
                                                {projects_edit &&
                                                <Dropdown
                                                    className="position-absolute end-0  m-1 "
                                                    style={{ zIndex: "1" }}
                                                >
                                                    <Dropdown.Toggle
                                                        className="hide-arrow"
                                                        variant=""
                                                        id="dropdown-basic"
                                                    >
                                                        <FontAwesomeIcon icon={icon.faEllipsis} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                            }}
                                                        >
                                                            Remove From project
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                }
                                            </div>
                                            <div className="d-grid border rounded p-4 place-items-center">
                                                <img
                                                    src={item.icon
                                                        .replace("/media/", "")
                                                        .replace("%3A", ":/")}
                                                    alt=""
                                                    style={{
                                                        width: "64px",
                                                        aspectRatio: "1/1",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                                <p className="m-0">{item.name}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Tab>
                        <Tab eventKey="mandate_company" title="Mandate Companys" >
                            <div className="d-grid gap-3 p-3">
                                <div className="text-end">
                                    <button className="btn btn-primary" onClick={()=>navigate(`/add/mandate-company/${projectId}`)} >
                                        Add Mandate companys
                                    </button>
                                </div>
                                {
                                    projectDetail?.mandate_company?.map(mandate_org=>{
                                        const org = mandate_org.mandate_org
                                        return <div className="border shadow-sm rounded position-relative">
                                            {
                                                mandate_org?.is_valid ? <button className="btn btn-danger position-absolute end-0 m-1 "   >

                                                    <ConfirmDelete id={mandate_org.id} callBack={removeMandateCompnay} projectId={projectId} label={"Remove From Project"} />

                                                </button> :
                                                    <button className="btn btn-success position-absolute end-0 m-1 " onClick={() => { dispatch(addMandateCompany(projectId, getProjectDetails, mandate_org.id)) }}  >
                                                   Add To Project
                                                </button>
                                            }
                                            
                                            <div className="d-flex gap-3 align-items-center p-3 ">
                                                <img src={"https://api.eazyapp.in"+org.logo} className='img-thumbnail' style={{height:"100px",width:"100px"}} alt="" />
                                                <div>
                                                    <h3 className="card_heading">{org.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </Tab> 
                        <Tab eventKey="team" title="Teams">
                            <div className="d-grid gap-3 p-3">
                                <div className=" text-end">
                                    <button className="btn btn-primary" onClick={() => {
                                        navigate(`/add/team-member/${projectId}`);
                                    }} >Add </button>
                                </div>
                                {projectDetail?.teams?.map((team) => {
                                    return (
                                        <>
                                            <div className="p-2 rounded-border shadow-sm position-relative">
                                                {
                                                    projects_edit &&

                                                <div>
                                                    <Dropdown
                                                        className="position-absolute end-0  m-1 "
                                                        style={{ zIndex: "1" }}
                                                    >
                                                        <Dropdown.Toggle
                                                            className="hide-arrow"
                                                            variant=""
                                                            id="dropdown-basic"
                                                        >
                                                            <FontAwesomeIcon icon={icon.faEllipsis} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/view/team-members/${team.name}/${projectId}`
                                                                    );
                                                                }}
                                                            >
                                                                Edit Team Members
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                }
                                                <h3 className="m-0 section_heading">{team.name}</h3>
                                                {/* <p className="m-0">
													<span className="text-muted">Members:</span>{" "}
													{team.members_count}
												</p> */}
                                                {team.members.map((team) => {
                                                    return (
                                                        <>
                                                            <div className="p-2">
                                                                <div className="rounded border shadow p-3">
                                                                    <div className="d-flex">
                                                                        <img
                                                                            className="img-thumbnail rounded-circle me-3"
                                                                            style={{ height: "70px", width: "70px" }}
                                                                            src={
                                                                                "https://api.eazyapp.in" +
                                                                                team.employee.photo
                                                                            }
                                                                            alt=""
                                                                        />
                                                                        <div>
                                                                            <h4 className="card_heading">
                                                                                {team.employee.name}
                                                                            </h4>
                                                                            <p className="m-0 text-muted">
                                                                                {team.designation}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </Tab>
                        <Tab eventKey="inventory" title="Inventory">
                            <div className="p-3">
                                <div className="text-end p-2">
                                    <button className="btn btn-primary" onClick={() => navigate(`/add-new-inventory/${projectId}`)} >Add</button>
                                </div>

                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>No. Of Floors</th>
                                            <th>No. Of Units</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectDetail?.inventories?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.wing}</td>
                                                    <td>{item.units.length}</td>
                                                    <td>{Object.keys(item.units).map(k => {
                                                        if (k !== "length" && k !== "max_unit") {
                                                            return parseInt(item.units[k].length)
                                                        }
                                                    }).filter(item => item != undefined).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</td>
                                                    <td>
                                                        <div className="d-grid">
                                                            <button className="btn btn-sm" onClick={() => navigate(`/edit-inventory/${projectId}/${item.id}`)}>Edit</button>
                                                            <button className="btn btn-sm" onClick={() => {
                                                                // dispatch(deleteInventory(item.id, projectId, getProjectDetails))
                                                            }}>
                                                                <ConfirmDelete id={item.id} callBack={deleteInventory} projectId={projectId} />
                                                            </button>
                                                            <button className="btn btn-sm" onClick={() => navigate(`/view-inventory/${projectId}/${item.id}`)} >View</button>
                                                        </div>
                                                      </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                {/* {projectDetail?.inventories?.map((item) => {
                                    return (
                                        <div className="p-3">
                                            <Wing
                                                addNewWing={false}
                                                dbWing={item.wing}
                                                projectId={projectId}
                                                inventoryId={item.id}
                                                inventoryData={item.units}
                                                projects_edit={projects_edit}
                                            />
                                        </div>
                                    );
                                }
                                )} */}

                                {/* <div className="p-3">
                                    <Wing addNewWing={true} projectId={projectId} projects_edit={projects_edit} />
                                </div> */}
                            </div>
                        </Tab>
                    </Tabs>
                </div>

            </Layout>
        )
    }
}

export default AdminProjectDetail
