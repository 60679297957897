import React,{useState,useEffect} from 'react'
import Layout from '../Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch ,useSelector } from 'react-redux'
import { getEmployeeDetail } from '../../../Actions/AdminAction'
import ImageUploader from '../../../Components/ImageUploader'
import PhoneInput from 'react-phone-input-2'
import { updateEmployeeDetail } from '../../../Actions/AdminAction'

const EditEmployee = () => {
    const [mobileNumber, setMobileNumber] = useState("");
    const dispatch = useDispatch();
    const { employeeId } = useParams();
    const navigate = useNavigate();
    const { employeeDetail } = useSelector((state) => state?.developer?.employee?.detail);
    useEffect(() => {
        dispatch(getEmployeeDetail(employeeId))
    }, []);
    useEffect(()=>{
        if(employeeDetail){
            setMobileNumber(employeeDetail?.phone_number)
        }
    },[employeeDetail])

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(updateEmployeeDetail(employeeId, formData, navigate));
    };


  return (
    <Layout pageTitle={"Edit Employee"}>
          <form onSubmit={submitHandler}>
              <div className="p-3">
                  <div className="row">
                      <div className="col-md-7 p-2">
                          <div className="d-flex justify-content-center">
                              <div className="w-30">
                                  <ImageUploader dbImage={employeeDetail?.photo} />
                                  <p className="text-center m-0 fw-bolder">
                                      Upload Profile Photo
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-7 p-2">
                          <div>
                              <label>First Name</label>
                              <input type="text" className="form-control" name="first_name" defaultValue={employeeDetail?.first_name} />
                          </div>
                      </div>
                      <div className="col-md-7 p-2">
                          <div>
                              <label>Last Name</label>
                              <input type="text" className="form-control" name="last_name" defaultValue={employeeDetail?.last_name} />
                          </div>
                      </div>
                      <div className="col-md-7 p-2">
                          <div>
                              <label>Email</label>
                              <input type="email" className="form-control" name="email" defaultValue={employeeDetail?.email} />
                          </div>
                      </div>
                      <div className="col-md-7 p-2">
                          <div>
                              <label>Mobile Number</label>
                              <PhoneInput
                                  country={"in"}
                                  countryCodeEditable={false}
                                  placeholder="Enter phone number"
                                  value={mobileNumber}
                                  enableSearch={true}
                                  containerClass="my-container-class"
                                  inputProps={{
                                      name: "phone_number",
                                  }}
                                  // className="form-control"
                                  inputClass="form-control"
                                  onChange={setMobileNumber}
                              />
                          </div>
                      </div>
                      <div className="col-md-7 p-2">
                          <div>
                              <label>Organization Designation</label>
                              <input
                                  type="text"
                                  className="form-control"
                                  name="designation"
                                  defaultValue={employeeDetail?.designation}
                              />
                          </div>
                      </div>
                      <div className="col-md-7 p-2">
                          <div className="d-flex justify-content-end gap-3">
                              <button className="btn btn-success">Save</button>
                              <button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Cancel</button>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
    </Layout>
  )
}

export default EditEmployee
