import React from 'react';
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

// Configure the default icon for Leaflet
L.Icon.Default.mergeOptions({
	iconRetinaUrl: iconRetina,
	iconUrl: iconMarker,
	shadowUrl: iconShadow,
});

// Define the Map component
const Map = ({ coords, popUpInnerElement }) => {
	return (
		<MapContainer
			center={coords}
			zoom={15}
			scrollWheelZoom={false}
			style={{ flex: 1, width: "100%", height: "100%", borderRadius: "0.2rem" }}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker position={coords}>
				<Popup>{popUpInnerElement}</Popup>
			</Marker>
		</MapContainer>
	);
};

export default Map;
