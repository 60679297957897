import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addTaskAction,editTaskACtion } from "../Actions/CommonAction";
import { useNavigate, useLocation } from "react-router-dom";
const AddTask = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	const dispatch = useDispatch();
	const [followUpDate, setFollowUpDate] = useState(
		moment().add(3, "days").format("YYYY-MM-DD")
	);
	const [description, setDescription] = useState("");
	const [taskType, setTaskType] = useState("");
	const [priority, setPriority] = useState("");
	const [getNewFollowUpdate, setGetNewFollowupDate] = useState(false);

	useEffect(() => {
		if (
			state?.task?.task_description !== null &&
			state?.task?.task_description !== undefined
		) {
			setDescription(state?.task?.task_description);
		}
		if (state?.task?.priority !== null && state?.task?.priority !== undefined) {
			setPriority(state?.task?.priority);
		}
		if (
			state?.task?.task_type !== null &&
			state?.task?.task_type !== undefined
		) {
			setTaskType(state?.task?.task_type);
		}
		if (
			state?.task?.task_date_time !== null &&
			state?.task?.task_date_time !== undefined
		) {
			setFollowUpDate(moment(state?.task?.task_date_time).format("YYYY-MM-DD"));
		}
	}, []);

	if (getNewFollowUpdate) {
		return (
			<>
				<div className="main_container m-0 p-0">
					<div className="w-100">
						<button
							className="btn ms-0"
							onClick={() => {
								setGetNewFollowupDate(false);
							}}
						>
							<Icon.ArrowLeft />
						</button>
					</div>
					<div className="row ">
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(1, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(1, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">Tomorrow</p>
								<p className=" fs-6 text-muted">
									{moment().add(1, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(2, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(2, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 2 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(2, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(3, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(3, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 3 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(3, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(7, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(7, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(7, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(14, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(14, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">2 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(14, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(30, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(30, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(30, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(90, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(90, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">3 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(90, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(180, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(180, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">6 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(180, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-12">
							{/* <button
								className={`btn border w-100 border-primary ${moment().add(180, "days").format(
									"YYYY-MM-DD"
								) === followUpDate && "border-3"}`}
								value={moment().add(180, "days").format("YYYY-MM-DD")}
								onClick={(e) => {
									setFollowUpDate(e.target.value);
									setGetNewFollowupDate(false);
								}}
							></button> */}
						</div>
					</div>
				</div>
			</>
		);
	}

	const AddTaskHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		if (state?.task?.id === undefined) {
			// dispatch(addNoteAction(object, navigate));
			dispatch(addTaskAction(object, navigate));
		} else {
			dispatch(editTaskACtion(object, navigate));
			// console.log("sennd to edit api");
		}
	};

	return (
		<div className="vh-100 p-1">
			<div className="mx-1 border-bottom">
				<h3 className="">
					{" "}
					<button className="btn fs-5" onClick={() => navigate(-1)}>
						<Icon.ArrowLeft />
					</button>{" "}
					Add Task{" "}
				</h3>
			</div>
			<div className="h-93">
				<div className="px-3 d-flex align-items-start pt-5 h-100 justify-content-center ">
					<form
						action=""
						className="w-80 d-grid gap-3"
						onSubmit={AddTaskHandler}
					>
						{state?.task?.id !== undefined && (
							<input
								type="text"
								name="task_id"
								value={state?.task?.id}
								hidden
							/>
						)}
						<input
							type="text"
							name="visitor_id"
							value={state.visitorId}
							hidden
						/>
						<input
							type="text"
							name="follow_up_date"
							value={moment(followUpDate).format("YYYY-MM-DD")}
							hidden
						/>
						<div>
							<label className=" fs-8 text-muted bg-white ">Task :</label>
							<textarea
								type="text"
								className="form-control"
								name="task_description"
								onChange={(e) => setDescription(e.target.value)}
								value={description}
								cols="20"
								rows="4"
								required
							/>
						</div>
						<div className="row">
							<div className="col-6">
								<select
									name="task_type"
									className="form-select"
									onChange={(e) => setTaskType(e.target.value)}
									value={taskType}
								>
									<option value="Todo">To-Do</option>
									<option value="Call">Call</option>
									<option value="Email">Email</option>
									<option value="Site Visit">Site Visit</option>
								</select>
							</div>
							<div className="col-6">
								<select
									name="priority"
									className="form-select"
									value={priority}
									onChange={(e) => setPriority(e.target.value)}
								>
									<option value="Low">Low</option>
									<option value="Medium">Medium</option>
									<option value="High">High</option>
								</select>
							</div>
						</div>
						<button
							type="button"
							className="btn p-0 border  text-start w-100"
							onClick={() => {
								setGetNewFollowupDate(true);
							}}
						>
							<div className="row mx-3">
								<div className="col-9">
									<p
										className="text-muted p-0 m-0"
										style={{ fontSize: "x-small" }}
									>
										Follow up date :
									</p>
									<p className="fw-bold p-0 m-0">
										{moment(followUpDate).format("ddd, MMMM Do, YYYY")}
									</p>
								</div>
								<div className="col-3 d-flex align-items-center justify-content-end">
									<Icon.ArrowRight />
								</div>
							</div>
						</button>

						<div className="text-center">
							<button className="btn btn-warning">
								{state?.task?.id !== undefined ? "Edit" : "Add"} Task
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddTask;
