import React,{useState,useEffect} from 'react'
import Layout from './Layout/Layout'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getProjectList } from '../../Actions/MyAdminAction'
import numberToWords from '../numberFormater'
const AdminProjects = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [serach, setSearch] = useState("");
    const [dataCount, setDataCount] = useState(20);
    const [hasMore, setHasMore] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, projects, error, total_projects, pages, page: currentPage, next_page } = useSelector(state => state?.myAdmin?.projectList)

    useEffect(() => {
        const Debouncer = setTimeout(() => {
            setQuery(serach);
        }, 500);
        return () => clearTimeout(Debouncer);
    }, [serach]);

    useEffect(() => {
        if (projects) {
            setData(projects);
            setPage(currentPage);
            setDataCount(total_projects);
            setHasMore(next_page);
        }
    }, [projects]);

    useEffect(() => {
        let params = {
            page: page,
        };
        if (query) {
            params["query"] = query
            setData([])
        }
        dispatch(getProjectList(params))
    }, [page, query])

  return (
    <Layout pageTitle={"Projects"}>
        <div className="d-flex">
          <div className="p-3 flex-fill">
              <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Project Name"
                  onChange={(e) => setSearch(e.target.value)}
              />
          </div>
            <div className="d-grid place-items-center px-2"><button className='btn btn-primary' onClick={() => navigate("/add-project")} >Add New Project</button></div>
        </div>
          <InfiniteScroll
              dataLength={dataCount}
              next={() => setPage(page + 1)}
              className="d-grid gap-2 p-2"
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
          >
              {data.map((project) => {
                  return (
                      <div className="bg-white d-flex p-3 rounded border shadow">
                              <img
                                  className="img-fluid rounded-top"
                                  src={"https://api.eazyapp.in" + project.cover_img}
                                  alt=""
                                  style={{ width: "150px", height: "150px" }}
                              />
                          <div className="p-3 flex-fill h-100 position-relative">
                              <h4 className="text-capitalize card_heading ">{project.name}</h4>
                              <p>{project.organization}</p>
                              <button
                                  className="btn btn-primary position-absolute bottom-0 w-90"
                                  onClick={() => {
                                      navigate(`/project-detail/${project.id}`);
                                  }}
                              >
                                  View Detail
                              </button>
                          </div>
                      </div>
                  );
              })}
          </InfiniteScroll>
    </Layout>
  )
}

export default AdminProjects
