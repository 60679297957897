import {
    CUSTOMER_GET_MY_BOOKINGS_REQUEST,
    CUSTOMER_GET_MY_BOOKINGS_SUCCESS,
    CUSTOMER_GET_MY_BOOKINGS_FAIL,
    UPDATE_CURRENT_BOOKING_ID,
    // 
    GET_CUSTOMER_DASHBOARD_DATA_REQUEST,
    GET_CUSTOMER_DASHBOARD_DATA_SUCCESS,
    GET_CUSTOMER_DASHBOARD_DATA_FAIL,
    // 
    GET_CUSTOMER_PROJECT_DOCUMNETS_REQUEST,
    GET_CUSTOMER_PROJECT_DOCUMNETS_SUCCESS,
    GET_CUSTOMER_PROJECT_DOCUMNETS_FAIL,
    // 
    GET_CUSTOMER_PRICING_PAYMENTS_REQUEST,
    GET_CUSTOMER_PRICING_PAYMENTS_SUCCESS,
    GET_CUSTOMER_PRICING_PAYMENTS_FAIL,
    // 
    GET_CUSTOMER_PAYMENT_SCHEDULE_REQUEST,
    GET_CUSTOMER_PAYMENT_SCHEDULE_SUCCESS,
    GET_CUSTOMER_PAYMENT_SCHEDULE_FAIL,
    //
    GET_ALL_CUSTOMER_PAYMENT_LIST_REQUEST,
    GET_ALL_CUSTOMER_PAYMENT_LIST_SUCCESS,
    GET_ALL_CUSTOMER_PAYMENT_LIST_FAIL,
    // 
    
} from '../Constants/CustomerConstants'


export const MyBookingsReducer=(state={},action)=>{
    switch(action.type){
        case CUSTOMER_GET_MY_BOOKINGS_REQUEST:
            return {loading:true}
        case CUSTOMER_GET_MY_BOOKINGS_SUCCESS:
            return {loading:false,myBookings:action.payload}
        case CUSTOMER_GET_MY_BOOKINGS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}

export const CurrentBookingIdReducer=(state=null,action)=>{
    switch(action.type){
        case UPDATE_CURRENT_BOOKING_ID:
            return action.payload
        default:
            return state
    }
}


export const customerDashboardDataReducer=(state={},action)=>{
    switch(action.type){
        case GET_CUSTOMER_DASHBOARD_DATA_REQUEST:
            return {loading:true}
        case GET_CUSTOMER_DASHBOARD_DATA_SUCCESS:
            return {loading:false,dashboardData:action.payload}
        case GET_CUSTOMER_DASHBOARD_DATA_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const customerProjectDocumentReducer=(state={},action)=>{
    switch(action.type){
        case GET_CUSTOMER_PROJECT_DOCUMNETS_REQUEST:
            return {loading:true}
        case GET_CUSTOMER_PROJECT_DOCUMNETS_SUCCESS:
            return {loading:false,documents:action.payload}
        case GET_CUSTOMER_PROJECT_DOCUMNETS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const customerPricingPaymentReducer=(state={},action)=>{
    switch(action.type){
        case GET_CUSTOMER_PRICING_PAYMENTS_REQUEST:
            return {loading:true}
        case GET_CUSTOMER_PRICING_PAYMENTS_SUCCESS:
            return {loading:false,pricings:action.payload}
        case GET_CUSTOMER_PRICING_PAYMENTS_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const customerPaymentScheduleReducer=(state={},action)=>{
    switch(action.type){
        case GET_CUSTOMER_PAYMENT_SCHEDULE_REQUEST:
            return {loading:true}
        case GET_CUSTOMER_PAYMENT_SCHEDULE_SUCCESS:
            return {loading:false,schedule:action.payload}
        case GET_CUSTOMER_PAYMENT_SCHEDULE_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


export const customerPaymentListReducer=(state={},action)=>{
    switch(action.type){
        case GET_ALL_CUSTOMER_PAYMENT_LIST_REQUEST:
            return {loading:true}
        case GET_ALL_CUSTOMER_PAYMENT_LIST_SUCCESS:
            return {loading:false,paymentList:action.payload}
        case GET_ALL_CUSTOMER_PAYMENT_LIST_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state
    }
}


