import React from 'react'
import Layout from '../Layout/Layout'
import Whatsapp from '../../../Components/Whatsapp'
import { useParams } from 'react-router-dom'



const WhatsappCreateTemplate = () => {
    const {developerId}=useParams();
  return (
    <Layout pageTitle={"Whatsapp Create Template"} >
          <Whatsapp developerId={developerId} />
    </Layout>
  )
}

export default WhatsappCreateTemplate
