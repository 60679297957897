import React from 'react'
import Layout from '../Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ImageUploader from '../../../Components/ImageUploader'
import PhoneInput from 'react-phone-input-2'
import { addChannelPartnerEmployee } from '../../../Actions/MyAdminAction'
const AddCPEmployee = () => {
    const [phoneNumber, setPhoneNumber] = React.useState();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cpId } = useParams();
    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append("phone_number", phoneNumber)
        dispatch(addChannelPartnerEmployee(formData, cpId, navigate))
    }

    return (
        <Layout pageTitle={"Add Employee"}>
            <form onSubmit={submitHandler}>
                <div className="d-grid p-3 gap-3">
                    <div className="d-flex gap-3">
                        <div className="w-30">
                            <ImageUploader dbImage={null} name="photo" />
                        </div>
                        <div className='flex-fill d-grid gap-3'>
                            <div>
                                <label>First Name</label>
                                <input type="text" name="first_name" className="form-control" />
                            </div>
                            <div>
                                <label>Last Name</label>
                                <input type="text" name="last_name" className='form-control' />
                            </div>
                        </div>

                    </div>
                    <div>
                        <label>Email</label>
                        <input type="email" name='email' className='form-control' />
                    </div>
                    <div>
                        <label>Phone number</label>
                        <PhoneInput
                            country={"in"}
                            countryCodeEditable={false}
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            enableSearch={true}
                            containerClass="my-container-class"
                            // className="form-control"
                            inputClass="form-control"
                            onChange={setPhoneNumber}
                        />
                    </div>
                    <div>
                        <label>Designation</label>
                        <input type="text" name="designation" className='form-control' />
                    </div>
                    <div className="text-end">
                        <button className="btn btn-success" type="submit" >Submit</button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default AddCPEmployee
