import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProjects, addProject } from "../../Actions/CPAction";
import { useDispatch, useSelector } from "react-redux";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numberToWords from "../numberFormater";
const ProjectSearch = () => {
	const [search, setSearch] = useState(null);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [dataCount, setDataCount] = useState(1);
	const [hasMore, setHasMore] = useState(false);
	const dispatch = useDispatch();

	const { error, loading, projectsData } = useSelector(
		(state) => state?.cp?.projects
	);

	const searchHandler = (e) => {
		const delay = 1000;
		const Debouncer = setTimeout(() => {
			setSearch(e.target.value);
		}, delay);
		return () => clearTimeout(Debouncer);
	};

	useEffect(() => {
		var postData = {
			page: page,
		};
		if (search !== null) {
			postData["query"] = search;
		}
		dispatch(getProjects(postData));
	}, [page, search]);
	useEffect(() => {
		if (projectsData !== undefined) {
			setData(projectsData?.projects);
			setPage(projectsData?.page);
			setDataCount(projectsData?.number_of_visitor);
			setHasMore(projectsData?.next_page);
		}
	}, [projectsData]);

	return (
		<Layout pageTitle={"Projects"}>
			<div className="px-3 my-3">
				<div className="input-group">
					<input
						type="text"
						className="form-control border border-end-0 rounded-start-5"
						id="searchBar"
						placeholder="Search"
						onChange={searchHandler}
					/>
					<label
						className="input-group-text bg-white ms-n5 border-top border-end border-bottom"
						forHtml="searchBar"
					>
						<FontAwesomeIcon icon={icon.faSearch} />
					</label>
				</div>
			</div>
			<InfiniteScroll
				dataLength={dataCount}
				next={() => setPage(page + 1)}
				className="row mx-5"
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				scrollableTarget="scrollableDiv"
			>
				{data.map((project) => {
					return (
						<div className="col-md-6 col-lg-4 p-3">
							<div className="bg-white d-flex p-2 rounded border shadow-sm">
								<div>
									<img
										className="img-thumbnail "
										src={"https://api.eazyapp.in" + project.cover_img}
										style={{ height: "150px", width: "100px" }}
										alt=""
									/>
								</div>
								<div className="p-3">
									<h4 className="text-capitalize card_heading">
										{project.name}
									</h4>
									<p>{project.organization}</p>
									<p>
										<b>Configs:</b> {project.configs}
									</p>
									<p>
										<b>Starting carpet area:</b> {project.starting_carpet_area}{" "}
										sq.ft
									</p>
									<p>
										<b>Starting Price:</b>{" "}
										{numberToWords(project.starting_price)}
									</p>
									<button
										className="btn btn-success w-100"
										onClick={() => {
											var postData = {
												page: 1,
											};
											dispatch(
												addProject({
													project_id: project.id,
												},
													getProjects,
													postData)
											);
										}}
									>
										Add To Project
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</InfiniteScroll>
		</Layout>
	);
};

export default ProjectSearch;
