import React, { useState, useEffect } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import Layout from "../Layout/Layout";
import { useDispatch } from "react-redux";
import { verifyOTPVisitor } from "../../../Actions/CREAction";
import { useParams, useNavigate } from "react-router-dom";

const VerifyNumber = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
	const [OTP, setOTP] = useState("");
	const isMobileView = window.innerWidth <= 768;
	const deivceHeight = window.innerHeight;
	let params = useParams();
	return (
		<div className="d-grid h-80 align-items-center justify-content-center ">
			<div>
				<h3 className="">Enter Visitor OTP</h3>
				<div className="w-100 d-flex justify-content-center">
					<OTPInput
						inputClassName="form-control"
						maxTime={60}
						value={OTP}
						onChange={setOTP}
						autoFocus
						OTPLength={4}
						otpType="number"
						disabled={false}
						secure
						inputStyles={{
							height: 50,
							width: 50,
						}}
					/>
				</div>
				<ResendOTP
					onResendClick={() => console.log("Resend clicked")}
					renderButton={(buttonProps) => {
						return (
							<a
								className={buttonProps.remainingTime !== 0? "link-secondary text-decoration-none":"link-warning text-decoration-none"}
								{...buttonProps}
								disabled={buttonProps.remainingTime !== 0}
							>
								Resend Otp
							</a>
						);
					}}
				/>
				<div className="text-center">

				<button
					className="btn mt-4 btn-warning"
					onClick={() => {
						dispatch(
							verifyOTPVisitor(
								{
									mobile_number: params.mobile_number,
									otp: OTP,
								},
								navigate
							)
						);
					}}
				>
					Verify Now
				</button>
				</div>
			</div>
		</div>
	);
};

export default VerifyNumber;
