import React, { useState, useEffect } from 'react'
import Layout from '../Layout/Layout'
import PhoneInput from 'react-phone-input-2'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import { getProjectOffers , getProjectPaymentSchedule} from '../../../Actions/CommonAction'
import { bookUnit } from '../../../Actions/AdminAction'

const AddCustomer = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [useMobileNumber, setUseMobileNumber] = useState(true);
  const { inventoryId, unitId, projectId } = useParams();
  const [whatsappNumber, setWhatsappNumber] = useState("91")
  const dispatch = useDispatch();
  const navigate=useNavigate();



  const { projectOffers, projectPaymentSchedule } = useSelector((state) => state);


  useEffect(()=>{
    dispatch(getProjectOffers(projectId))
    dispatch(getProjectPaymentSchedule(projectId))
  },[])



  const submitHandler=(e)=>{
    e.preventDefault()
    const formData = new FormData(e.target)
    dispatch(bookUnit(formData,navigate,projectId))
  }

  return (
    <Layout pageTitle={'Add Customer'}>
      <form onSubmit={submitHandler}>
        <div className="d-grid  gap-2 p-2 ">
          <input type="text" name="inventory_id" value={inventoryId} hidden />
          <input type="text" name="unit_id" value={unitId} hidden />
          <div className="d-flex gap-3 flex-wrap ">
            <div className='w-49 w-md-100' >
              <label htmlFor="first_name">Customer First Name</label>
              <input type="text" id="first_name" name="first_name"  className='form-control' />
            </div>
            <div className='w-49 w-md-100' >
              <label htmlFor="last_name">Customer Last Name</label>
              <input type="text" id="last_name"  name="last_name" className='form-control' />
            </div>
          </div>
          <div>
            <label htmlFor="email">Customer Email</label>
            <input type="email" id="email" name="email" className='form-control' />
          </div>
          <div>
            <label htmlFor="phone">Customer Phone</label>
            <PhoneInput
              country={"in"}
              countryCodeEditable={false}
              placeholder="Enter phone number"
              value={mobileNumber}
              enableSearch={true}
              containerClass="my-container-class"
              inputProps={{
                name: "phone_number",
                id: "phone",
              }}
              // className="form-control"
              inputClass="form-control"
              onChange={setMobileNumber}
            />
          </div>
          <div>
            <div className='d-flex flex-wrap gap-3' >
              <label htmlFor="whatsapp_number">Whatsapp Number </label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => setUseMobileNumber(e.target.checked)} checked={useMobileNumber} />
                <label class="form-check-label" for="flexSwitchCheckDefault">Same As Mobile Number</label>
              </div>
            </div>
            <PhoneInput
              country={"in"}
              countryCodeEditable={false}
              placeholder="Enter whatsapp number"
              value={useMobileNumber ? mobileNumber : whatsappNumber}
              enableSearch={true}
              containerClass="my-container-class"
              inputProps={{
                name: "whatsapp_number",
                id: "whatsapp_number",
              }}
              // className="form-control"
              inputClass="form-control"
              onChange={setWhatsappNumber}
              disabled={useMobileNumber}
            />
          </div>
          <div>
              <label htmlFor="offers">Select Offers</label>
              <select name="offers" id="offers" className="form-control" multiple >
              {projectOffers?.list?.map((offer, index) => {
                return <option  key={index} value={offer.id}   >
                  {offer.key}
                </option>
              })}
              </select>
          </div>
          <div>
            <label htmlFor="payment_schedule">Select Payment Schedule</label>
            <select name="payment_schedule" id="payment_schedule" className="form-control">
              {projectPaymentSchedule?.list?.map((payment_schedule, index) => {
                return <option key={index} value={payment_schedule.id}   >
                  {payment_schedule.name}
                </option>
              })}
            </select>
          </div>
            
          <div className="text-end">
            <button  className='btn btn-primary'  type="submit" >Book Unit</button>
          </div>

        </div>
      </form>
    </Layout>
  )
}

export default AddCustomer
