import React from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Navbar, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {userLogout} from "../../../Actions/UserAction";

const Header = ({pageTitle}) => {
	const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const location = useLocation();
	const showCheckInbutton=()=>{
		if(location.pathname == "/" || location.pathname == "/team" || location.pathname == "/visit"){
			return true;
		}
		return false;
	}
	const user = useSelector((state) => state?.userState?.userInfo);
	return (
		<div>
			<Navbar
				collapseOnSelect
				expand="lg"
				className="bg-accent"
				// bg={location.pathname == "/" ? "primary" : "accent"}
				variant="light"
				sticky="top"
			>
				<Navbar.Brand
					className="ms-3 text-light"
				>
					{pageTitle}
				</Navbar.Brand>

				{/* <Dropdown className="ms-auto me-2">
					<Dropdown.Toggle variant="white" id="dropdown-basic">
						{user?.name}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								dispatch(userLogout());
							}}
						>
							<Icon.BoxArrowLeft />
							Logout
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown> */}
			</Navbar>
		</div>
	);
};

export default Header;
