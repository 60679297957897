import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import { getPaymentScheduleList,deletePaymentSchedule,deletePaymentSlab } from "../../../Actions/AdminAction";
import LoadingPage from "../../LoadingPage";
import Accordion from "react-bootstrap/Accordion";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmDelete from "../Components/ConfirmDelete";


const PaymentSetting = () => {
	const { projectId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, error, paymentScheduleList } = useSelector(
		(state) => state?.developer?.project?.paymentScheduleList
	);
	const user = useSelector((state) => state?.userState?.userInfo);
	useEffect(() => {
		dispatch(getPaymentScheduleList(projectId));
	}, []);

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Layout pageTitle={"Payment Setting"}>
			<div className="d-grid gap-2 p-3">
				{!user.extra?.viewer_only &&	<div className="text-end">
					<button
						className="btn btn-primary"
						onClick={() => {
							navigate(`/add-payment-schedule/${projectId}`);
						}}
					>
						Add New Payment Schedule
					</button>
				</div>}
				<div className="">
					<Accordion defaultActiveKey={0}>
						{paymentScheduleList?.map((item, index) => {
							return (
								<Accordion.Item eventKey={index}>
									<Accordion.Header>
										{item.name} {item.payment_type}
									</Accordion.Header>
									<Accordion.Body>
										<ul class="list-group">
											<li class="list-group-item">
												<div className="d-flex gap-2">
													<div
														style={{
															width: `${
																item.payment_type == "Date Driven"
																	? 100 / 4
																	: 100 / 5
															}%`,
														}}
													>
														Label
													</div>
													<div
														style={{
															width: `${
																item.payment_type == "Date Driven"
																	? 100 / 4
																	: 100 / 5
															}%`,
														}}
													>
														Value
													</div>
													{item.payment_type == "Date Driven" ? (
														<div
															style={{
																width: `${
																	item.payment_type == "Date Driven"
																		? 100 / 4
																		: 100 / 5
																}%`,
															}}
														>
															Date
														</div>
													) : (
														<>
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																Completed
															</div>
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																File
															</div>
														</>
													)}

													<div
														style={{
															width: `${
																item.payment_type == "Date Driven"
																	? 100 / 4
																	: 100 / 5
															}%`,
														}}
													>
														Action
													</div>
												</div>
											</li>
											{item.payment_slabs.map((slab) => {
												return (
													<li class="list-group-item">
														<div className="d-flex gap-2">
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																{slab.key}
															</div>
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																{slab.value}
															</div>
															{item.payment_type == "Date Driven" ? (
																<div
																	style={{
																		width: `${
																			item.payment_type == "Date Driven"
																				? 100 / 4
																				: 100 / 5
																		}%`,
																	}}
																>
																	{slab.date_to_ask}
																</div>
															) : (
																<>
																	<div
																		style={{
																			width: `${
																				item.payment_type == "Date Driven"
																					? 100 / 4
																					: 100 / 5
																			}%`,
																		}}
																	>
																		{slab.is_completed ? "Yes" : "No"}
																	</div>
																	<div
																		style={{
																			width: `${
																				item.payment_type == "Date Driven"
																					? 100 / 4
																					: 100 / 5
																			}%`,
																		}}
																	>
																		{slab.file}
																	</div>
																</>
															)}
															<div
																className="gap-2 d-flex "
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																{item.can_delete_or_update && !user.extra?.viewer_only && (
																	<>
																		<button
																			className="btn btn-success rounded-circle"
																			onClick={() => {
																				navigate(
																					`/edit-payment-slab/${slab.id}`
																				);
																			}}
																		>
																			<FontAwesomeIcon icon={icon.faEdit} />
																		</button>
																		<button className="btn btn-danger rounded-circle" 
																			onClick={()=>{
																				dispatch(deletePaymentSlab(slab.id))
																			}}
																		>
																			<FontAwesomeIcon icon={icon.faTrash} />
																		</button>
																	</>
																)}
															</div>
														</div>
													</li>
												);
											})}
										</ul>
										{item.can_delete_or_update && !user.extra?.viewer_only && (
											<div className="d-flex justify-content-end gap-3 p-3">
												<button
													className="btn btn-success"
													onClick={() =>
														navigate(`/edit-payment-schedule/${projectId}/${item.id}`)
													}
												>
													Edit 
												</button>
												<button
													className="btn btn-danger"
													// onClick={()=>{
													// 	dispatch(deletePaymentSchedule(item.id,projectId));
													// }}
												>
													<ConfirmDelete id={item.id} projectId={projectId} callback={deletePaymentSchedule}  label="Delete" />
												</button>
											</div>
										)}
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
					</Accordion>
				</div>
			</div>
		</Layout>
	);
};

export default PaymentSetting;
