import React, { useEffect,useState } from "react";
import Layout from "./Layout/Layout";
import { getProjectDetail } from "../../Actions/CPAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Map from "../../Components/Map";
import LightGallery from "../../Components/LightGallery";
import numberToWords from "../numberFormater";

const ProjectDetail = () => {
	const [show,setShow]=useState(false);
	const [index,setIndex]=useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { detail } = useSelector((state) => state?.cp?.project);
	const user = useSelector((state) => state?.userState?.userInfo);
	console.log(detail?.website_url)

	const params = useParams();
	useEffect(() => {
		dispatch(getProjectDetail(params.projectId));
	}, []);

	var settings = {
		gallery: {
			dots: false,
			infinite: true,
			centerMode: true,
			speed: 500,
			slidesToShow: detail?.gallery.length < 3 ? detail?.gallery.length : 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 480,
					settings: {
						arrows: false,
						centerMode: true,
						centerPadding: "30px",
						slidesToShow: 1,
						autoplay: true,
						autoplaySpeed: 2500,
					},
				},
				{
					breakpoint: 780,
					settings: {
						arrows: false,
						centerMode: true,
						centerPadding: "20px",
						slidesToShow: 2,
						autoplay: true,
						autoplaySpeed: 2500,
					},
				},
				{
					breakpoint: 1000,
					settings: {
						arrows: false,
						centerMode: true,
						centerPadding: "10px",
						slidesToShow: 3,
						autoplay: true,
						autoplaySpeed: 2500,
					},
				},
			],
		},
	};

	if (detail === undefined) {
		return <>Loading</>;
	}
	return (
		<Layout pageTitle={<h1 className="text-capitalize m-0">{detail?.name}</h1>}>
			<Slider {...settings.gallery}>
				{detail?.gallery?.map((item) => {
					return (
						<div className="p-2 w-100 h-100">
							<img
								className="img-fluid rounded h-100 w-100"
								src={"https://api.eazyapp.in" + item.item}
								alt={item.name}
							/>
						</div>
					);
				})}
			</Slider>
			<div className="p-4">
				<h2 className="h5 text-dark section_heading fw-bold">About</h2>
				<p>{detail?.about}</p>

				<h2 className="h5 text-dark fw-bold section_heading">Features</h2>
				<div className="d-flex justify-content-between ">
					{" "}
					<label className="text-dark">Price Range</label>{" "}
					<p>
						<span>{numberToWords(detail?.features?.price?.min)}</span>-{" "}
						<span>{numberToWords(detail?.features?.price?.max)}</span>{" "}
					</p>
				</div>
				<div className="d-flex justify-content-between ">
					{" "}
					<label className="text-dark">Carpet Area Range</label>{" "}
					<p>{detail?.features?.carpet_area} sq.ft.</p>
				</div>
				<div className="d-flex justify-content-between ">
					{" "}
					<label className="text-dark">Configurations</label>{" "}
					<p>{detail?.features?.configs}</p>
				</div>
				<h2 className="h5 text-dark mt-2 fw-bold section_heading">Location</h2>
				<div className="p-3">
					<div className="row">
						<div className="col-md-4">
							<div className="w-100 vh-25">
								<Map
									coords={detail.lat_long}
									popUpInnerElement={
										<h1 className="text-capitalize m-0">{detail?.name}</h1>
									}
								/>
							</div>
						</div>
						<div className="col-md-4"></div>
						<div className="col-md-4"></div>
					</div>
				</div>
				<h2 className="h5 text-dark mt-2 fw-bold section_heading">Amenities</h2>
				<ul className="row row-cols-auto gap-3">
					{detail?.amenities !== undefined &&
						detail?.amenities?.map((amenitie) => {
							return (
								<>
									<li className="col d-flex gap-3"> <img src={amenitie.icon} alt="" style={{width:"20px",height:"20px"}} />  {amenitie.name}</li>
								</>
							);
						})}
				</ul>
				<h2 className="h5 text-dark mt-2 fw-bold section_heading">Gallery</h2>
				<div className="row">
					{detail?.gallery?.map((item, index) => {
						return (
							<>
								<div
									className={`col-4  position-relative ${
										index > 2 && "d-none"
									}`}
									onClick={() => {
										setIndex(index);
										setShow(true);
									}}
								>
									{index == 2 && (
										<div className="overlay bg-dark rounded ">
											<div className="h-100 w-100 d-flex justify-content-center align-items-center ">
												<p className="text-white m-0">
													+ {detail?.gallery.length - (index + 1)}
												</p>
											</div>
										</div>
									)}

									<img
										className="img-fluid h-100 w-100 rounded"
										src={"https://api.eazyapp.in" + item.item}
										alt={item.name}
									/>
								</div>
							</>
						);
					})}
				</div>
				{/* show gallery */}
				<h2 className="h5 text-dark mt-2 fw-bold section_heading">Nearby</h2>
				{detail?.nearbys !== undefined &&
					detail?.nearbys?.map((nearby) => {
						return (
							<>
								<div className="row w-100">
									{" "}
									<div className="col-9">
										<p className="text-truncate">{nearby.name}</p>{" "}
									</div>
									<div className="col-3 text-end">
										<span>{nearby.distance} Km.</span>
									</div>
								</div>
							</>
						);
					})}

				<div className="row mt-3 mb-9 ">
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<button
							className="btn w-100 btn-dark"
							onClick={() => {
								navigate(`/project/${params.projectId}/colaterals`);
							}}
						>
							Collaterals
						</button> 
					</div>
					<div className="col-md-4">
						{/* {detail?.webiste_url?.domain !== undefined ?  :""} */}
						<a href={"https://" + detail?.website_url?.domain + "/" + user.extra?.organizaition} className="btn w-100 btn-dark" target="_blank" rel="noreferrer" > Share Website </a>
					</div>
				</div>
			</div>
			<LightGallery
				show={show}
				setShow={(e) => setShow(e)}
				index={index}
				slides={detail?.gallery?.map((item) => {
					return { src: "https://api.eazyapp.in" + item.item };
				})}
			/>
		</Layout>
	);
};

export default ProjectDetail;
