import React from 'react'
import Layout from '../Layout/Layout'
import axios from 'axios'
import ReactAutocomplete from 'react-autocomplete'
import ImageUploader from '../../../Components/ImageUploader'
import { addChannelPartner } from '../../../Actions/MyAdminAction'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
const AdminAddChannelPartner = () => {
    const [location, setLocation] = React.useState()
    const [locationItems, setLocationItems] = React.useState([])

    const dispatch = useDispatch();
    const navigate = useNavigate();


    React.useEffect(() => {
        if (location === "") {
            return;
        }
        const delay = 1000;
        const Debouncer = setTimeout(async () => {
            try {
                const response = await axios.post(
                    "https://houzyy.com/api/v1/get-area-list/",
                    { searched_term: location }
                );
                setLocationItems(response.data)
            } catch (error) {
                toast.error("Something went wrong");
            }
        }, delay);
        return () => clearTimeout(Debouncer);
    }, [location]);

    const submitHandler=(e)=>{
        e.preventDefault()
        const formData=new FormData(e.target)
        dispatch(addChannelPartner(formData,navigate))
    }

    return (
        <Layout pageTitle={"Add Channel Partner"}>
            <form onSubmit={submitHandler}>
                <div className='d-grid gap-3 p-3'>
                    <div className="row">
                        <div className='col-4' >
                            <ImageUploader dbImage={null} name="logo" />
                        </div>
                        <div className="col-8">
                            <div>
                                <label>Organization Name</label>
                                <input type="text" className='form-control' name="name" />
                            </div>

                            <div>
                                <label>Organization Type</label>
                                <select name="org_type" className="form-select">
                                    {["Proprietorship Firm",
                                        "Private Limited Company",
                                        "Limited Liability Partnership",
                                        "Public Limited Company",
                                        "Partnership Firm",
                                        "Partnership Firm"].map(item => {
                                            return <option value={item}>{item}</option>
                                        })}
                                </select>
                            </div>

                            <div>
                                <label>RERA Number</label>
                                <input type="text" className='form-control' name="rera_number" />
                            </div>

                        </div>
                    </div>


                    <div>
                        <label>About</label>
                        <textarea className='form-control' rows="8" name="about" ></textarea>
                    </div>

                    <div>
                        <label>Established Year</label>
                        <input type="number" min="1950" max={new Date().getFullYear()} className='form-control' name="established_year" />
                    </div>
                    <div className="text-end">
                        <button className='btn btn-dark' type='submit' >Save</button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default AdminAddChannelPartner
