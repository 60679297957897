import React, { useEffect, useState } from 'react'
import Inventory from './Components/Inventory'
import { useParams, useNavigate } from 'react-router-dom'
import Layout from './Layout/Layout'
import { getInventoryData, saveInventory, editInventoryData, getProjectDetail } from '../../Actions/AdminAction'
import { useDispatch, useSelector } from 'react-redux'
const WingInventoryData = () => {
  // const [inventoryData, setInventoryData] =useState()
  const { projectId, inventoryId } = useParams()
  const [postData, setPostData] = useState({
    wing: "", project_id: projectId, units: {
      0: [],
      length: 1,
      max_unit: 0,
    }
  })
  const dispatch = useDispatch();

  const { inventoryData } = useSelector((state) => state?.developer?.inventoryData);
  
  const submitHandler = () => {
    if (inventoryId) {
      dispatch(editInventoryData(postData, inventoryId))
    } else {
      dispatch(saveInventory(postData))
    }
  }

  useEffect(() => {
    if (inventoryData) {
      setPostData({
        wing: inventoryData.wing,
        project_id: projectId,
        units: inventoryData.units
      })

    }
  },[inventoryData])

  useEffect(() => {
    dispatch(getInventoryData(inventoryId))
  }, [])

  return (
    <Layout pageTitle={inventoryId !== undefined ? "Edit Inventory" : "Wing Inventory"}>
      <div className="p-2 d-grid gap-2">
        <div>
          <label htmlFor="wing_name">Wing</label>
          <input type="text" className="form-control" placeholder='Enter Wing Name' value={postData.wing} onChange={(e) => {
            setPostData({ ...postData, wing: e.target.value })
          }} />
        </div>
        <Inventory
          dbInventoryData={postData.units}
          projectId={projectId}
          inventoryDataHandler={(e) => { 
            setPostData({ ...postData, units: e });
        }}
        />
        <div className="text-end">
          <button className="btn btn-success" onClick={submitHandler} >Save</button>
        </div>
      </div>
    </Layout>
  )
}

export default WingInventoryData
