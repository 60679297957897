import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDetailChannelPartners } from "../../Actions/SEAction";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import user from "../../assets/user.png";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Employees = (props) => {
	const [showModal, setShowModal] = useState(false);
	const [empId, setEmpId] = useState(null);
	const { employees } = props;

	const employee = empId ? employees?.find((d) => d.id === empId) : null;
	return (
		<>
			<div className="row mx-3 my-5">
				{employees?.map((employee) => {
					return (
						<div className="col-md-6 p-2" key={employee.id}>
							<div className="shadow rounded border p-3">
								<div className="row">
									<div
										className="col-4"
										style={{ height: "100px", width: "100px" }}
									>
										<img
											src={
												employee?.photo !== null
													? "https://api.eazyapp.in" + employee?.photo
													: user
											}
											className="img-fluid"
											alt={`${employee?.first_name} ${employee?.last_name}`}
											style={{ height: "100%", width: "100%" }}
										/>
									</div>
									<div className="col-8">
										<h5 className="m-0 p-0 card_heading ">
											{employee?.first_name} {employee?.last_name}
										</h5>
										<h6>
											{" "}
											<span>Visits:</span> {employee?.performance?.visitor}
										</h6>
										<div className="d-flex gap-3 ">
											<a
												href={`tel:${employee?.phone_number}`}
												className="fs-4 fw-bold text-primary "
											>
												<Icon.Telephone />
											</a>
											<a
												href={`sms:${employee?.phone_number}`}
												className="fs-4 fw-bold text-secondary "
											>
												<Icon.Chat />
											</a>
											<a
												href={`mailto:${employee?.email}`}
												className="fs-4 fw-bold text-danger "
											>
												<Icon.EnvelopeAt />
											</a>
											<a
												className=" fs-4 fw-bold text-dark"
												onClick={() => {
													setEmpId(employee?.id);
													setShowModal(true);
												}}
											>
												{" "}
												<Icon.GraphUp />{" "}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<Modal
				show={showModal}
				fullscreen="lg-down"
				onHide={() => setShowModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{" "}
						{employee?.first_name} {employee?.last_name} Performance
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h6>
						Last Visited on{" "}
						{moment(employee?.last_visit).format("dddd, MMMM Do, YYYY")}{" "}
					</h6>
					<ul class="timeline">
						{employee?.performance?.project_visits?.map((d) => {
							return (
								<li className="timeline-item mb-5">
									<p>
										Visited {d?.project} on{" "}
										{moment(d?.visited_on).format("dddd, MMMM Do, YYYY")}
									</p>
								</li>
							);
						})}
					</ul>
				</Modal.Body>
			</Modal>
		</>
	);
};

const Visitors = (props) => {
	const navigate=useNavigate();
	 const textColor = {
			warm: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-warning opacity-75 bg-warning",
			cold: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-info opacity-75 bg-info",
			hot: "text-light rounded-5  ms-3 py-1 fs-9  px-2 border border-1 border-danger opacity-75 bg-danger",
			lost: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-primary opacity-75 bg-primary",
			new: "text-light  rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-success opacity-75 bg-success ",
		};
	const {visitors}=props
	return (
		<>
			<div className="row justify-content-center gap-3 mt-4 mx-3">
				{visitors?.map((d,index)=>{
					return (
						<div className="col-lg-5 shadow border rounded p-3">
							<div className="row  ">
								<div
									className="col-4 d-flex align-items-center justify-content-center "
									onClick={() => navigate(`/visitor/${d.id}`)}
									style={{height:"100px",width:"100px"}}
								>
									{d.image !== null ? (
										<img
											src={"https://api.eazyapp.in" + d.image}
											alt=""
											className="img-fluid  img-thumbnail "
											style={{height:"100%",width:"100%"}}
										/>
									) : (
										<img
											src={user}
											alt=""
											className="img-fluid  img-thumbnail "
											style={{height:"100%",width:"100%"}}
										/>
									)}
								</div>
								<div
									className="col-8 "
									onClick={() => navigate(`/visitor/${d.id}`)}
								>
									<h5 className="fw-bold  m-0">
										{d.first_name} {d.last_name}
										<span
											style={{ minWidth: "60px" }}
											className={textColor[d.status.toLowerCase()]}
										>
											{d.status}
										</span>
									</h5>
									<p className="m-0">
										<span className="text-muted fs-6">Visits: </span>
										{d.visit_count}
									</p>
									<p className="m-0 text-capitalize">
										<span className="text-muted fs-6">Executive: </span>
										{d.employee?.name}
									</p>
									<p className="m-0">
										<span className="text-muted fs-6">Last Visit: </span>
										{d.last_visit !== "Checking In"
											? moment(d.last_visit).fromNow()
											: d.last_visit}
									</p>
									<div className=" d-flex justify-content-between px-1 border-top mt-2 ">
										<a
											href={`tel:+${d.mobile_number}`}
											className="fs-4 fw-bold text-primary "
										>
											<Icon.Telephone />
										</a>
										<a
											href={`sms:+${d.mobile_number}`}
											className="fs-4 fw-bold text-secondary "
										>
											<Icon.Chat />
										</a>
										<a
											href={`http://wa.me/+${d.whatsapp_number}?text=Hi`}
											className="fs-4 fw-bold text-success "
										>
											<Icon.Whatsapp />
										</a>
										<a
											href={`mailto:${d.email}`}
											className="fs-4 fw-bold text-danger "
										>
											<Icon.EnvelopeAt />
										</a>
									</div>
								</div>
								<div className="col-12 "></div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

const About = (props) => {
	const { name, about ,detail } = props;
	return (
		<div
			className="h-100 p-4"
			style={{ overflowY: "scroll", overflowX: "hidden" }}
		>
			<div className="w-100 py-4">
				<div className="d-flex justify-content-center w-100">
					<div className="m-0 p-0" style={{ height: "200px", width: "200px" }}>
						<img
							className="img-fluid rounded-circle"
							src={"https://api.eazyapp.in" + detail?.logo}
							alt=""
							style={{ height: "100%", width: "100%" }}
						/>
					</div>
				</div>
				<div className="text-center my-3">
					<h2 className="underlined-text section_heading ">{name} </h2>
				</div>
				<div>
					<label className="label">RERA Number:</label>
					<p className="card_heading ms-2">{detail?.rera_number}</p>
				</div>
				<div>
					<label className="label">Owner:</label>
					<p className="card_heading ms-2">{detail?.owner_name}</p>
				</div>
				<div>
					<label className="label">Conatct:</label>
					<p className="card_heading ms-2">{detail?.owner_contact}</p>
				</div>
			
				<div className="">
					{about?.split("\r\n\r").map((d, index) => {
						return (
							<p className="text-wrap" key={index}>
								{d}
							</p>
						);
					})}
					{/* <p className="text-wrap">{JSON.stringify(detail)}</p> */}
				</div>
			</div>
		</div>
	);
};

const ChannelPartnerDetail = () => {
	const [page, setPage] = useState("visitors");
	const dispatch = useDispatch();
	const { channel_partner } = useSelector(
		(state) => state?.se?.channelPartnerDetailState
	);
	const params = useParams();
	useEffect(() => {
		dispatch(getDetailChannelPartners(params.channel_partner_id));
	}, []);
	return (
		<Layout
			pageTitle={channel_partner?.name ? channel_partner?.name : "------------"}
		>
			<div className="h-100 py-3">

			<Nav
				variant="tabs"
				bg={"light"}
				className="justify-content-center no-wrap "
				defaultActiveKey={page}
			>
				<Nav.Item>
					<Nav.Link eventKey="visitors" onClick={() => setPage("visitors")}>
						Visitors
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link eventKey="employees" onClick={() => setPage("employees")}>
						Employees
					</Nav.Link>
				</Nav.Item>
				{channel_partner?.about !== null &&
					channel_partner?.about !== undefined && (
						<Nav.Item>
							<Nav.Link eventKey="about" onClick={() => setPage("about")}>
								About
							</Nav.Link>
						</Nav.Item>
					)}
			</Nav>
			{page !== "employees" ? (
				page !== "visitors" ? (
					page === "about" && (
						<About
							name={channel_partner?.name}
							about={channel_partner?.about}
							detail={channel_partner}
						/>
					)
				) : (
					<Visitors visitors={channel_partner?.visitors} />
				)
			) : (
				<Employees employees={channel_partner?.employees} />
			)}
			</div>
		</Layout>
	);
};

export default ChannelPartnerDetail;
