import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminUserDetail, getAdminRoles, updateAdminUserDetail } from '../../Actions/MyAdminAction'
import ImageUploader from '../../Components/ImageUploader'
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const AdminUserDetail = () => {
    const [removeRole, setRemoveRole] = useState([])
    const [addRole, setAddRole] = useState([])
    const [edit, setEdit] = useState(false)

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, adminDetail } = useSelector(state => state?.myAdmin?.adminUserDetail)
    const { roleList } = useSelector(state => state?.myAdmin?.adminRoleList)
    const { admin_user_edit } = useSelector(state => state?.permissions)
    useEffect(() => {
        dispatch(getAdminUserDetail(id))
        dispatch(getAdminRoles())
    }, [])
    const submitHandler = (e) => {
        e.preventDefault()
        const formData=new FormData(e.target)
        formData.append("add_role",addRole)
        formData.append("remove_role",removeRole)
        dispatch(updateAdminUserDetail(id,formData,setEdit))
    }
    if (loading) {
        return <>Loading</>
    }
    return (
        <Layout pageTitle={"Admin User Detail"}>
            <form onSubmit={submitHandler}>
                <div className="d-grid gap-3 p-3 position-relative">
                    {
                        admin_user_edit &&
                        <FontAwesomeIcon icon={edit ? icon.faXmark : icon.faEdit} className='position-absolute text-muted m-2 h3 top-0 end-0' onClick={() => setEdit(!edit)} />
                    }
                    <div className='d-flex justify-content-center'>
                        <div className="w-30">
                            {edit ?
                                <ImageUploader dbImage={"https://api.eazyapp.in" + adminDetail?.photo} name={"photo"} />
                                :
                                <img className='img-thumbnail' src={"https://api.eazyapp.in" + adminDetail?.photo} alt="" />

                            }
                        </div>
                    </div>
                    <div className="">
                        <label>First Name</label>
                        <input type="text" defaultValue={adminDetail?.first_name} name="first_name" className="form-control" disabled={!edit} />
                    </div>
                    <div className="">
                        <label>Last Name</label>
                        <input type="text" defaultValue={adminDetail?.last_name} name="last_name" className="form-control" disabled={!edit} />
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="email" defaultValue={adminDetail?.email} name="email" className="form-control" disabled={!edit} />
                    </div>
                    {
                        edit ? <>
                            <div class="btn-group " role="group" aria-label="Basic checkbox toggle button group">
                                {
                                    roleList?.map((role) => {
                                        return (
                                            <div className='form-check form-check-inline'>
                                                    <input type="checkbox" class="btn-check" value={role.id} name="roles" id={"btncheck" + role.id} defaultChecked={adminDetail?.roles?.map(_ => { return _.id }).includes(role.id)} onChange={(e) => {
                                                    if (adminDetail?.roles?.map(_ => { return _.id }).includes(role.id)) {
                                                        if (e.target.checked) {
                                                            setRemoveRole([...removeRole, role.id])
                                                        } else {
                                                            setRemoveRole(removeRole.filter((d)=>  d.id !== role.id ))
                                                        }
                                                    } else {
                                                        if (e.target.checked) {
                                                            setAddRole([...addRole, role.id])
                                                        } else {
                                                            setAddRole(addRole.filter((d)=>  d.id !== role.id ))
                                                        }
                                                    }
                                                }} autocomplete="off" />
                                                <label class="btn btn-outline-primary" for={"btncheck" + role.id}>{role.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </> :
                            <div className=''>
                                <label>Role</label>
                                <div className=' d-flex flex-wrap gap-3'>
                                    {
                                        adminDetail?.roles?.map((role) => {
                                            return <span className=' rounded text-white py-1 px-2 bg-primary m-1 h5'>{role.name}</span>
                                        })
                                    }
                                </div>
                            </div>
                    }
                    {
                        edit &&
                        <div className='d-flex justify-content-end gap-2 '>
                            <button className='btn btn-success ' type='submit' >Save</button>
                            <button className="btn btn-dark" type='button' onClick={() => setEdit(false)}>Cancel</button>
                        </div>
                    }
                </div>
            </form>

        </Layout>
    )
}

export default AdminUserDetail
