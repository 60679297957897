import React, { useEffect } from "react";
import Layout from "./Layout/Layout";
import { useParams } from "react-router-dom";
import { getProjectDocumnets ,getProjectDetail} from "../../Actions/CPAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import PdfPhoto from "../../assets/file-icons/pdf-icon.png";

const Colaterals = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const { files, loading, error } = useSelector((state) => state?.cp?.document);
	const { detail } = useSelector((state) => state?.cp?.project);
	const { projectId } = params;
	useEffect(() => {
		dispatch(getProjectDocumnets(projectId));
		dispatch(getProjectDetail(projectId));
	}, []);
	return (
		<Layout pageTitle={"Colaterals"}>
			<div className="row px-3">
				{files?.map((file) => {
					return file.files.map(i=>{
						return <div className="col-md-4 p-2 col-lg-2 col-6">
							<div className="rounded border shadow p-3"  >
								<div className="d-flex justify-content-center align-items-center p-3">
									<img src={PdfPhoto} alt="" />
								</div>
								<div className="text-center">
									<p>{file.key}</p>
								</div>
								<div className="d-flex gap-3">
									<a href={`https://api.eazyapp.in${i}`} target="_blank" rel="noreferrer"   >View</a>
									<a href={`https://api.eazyapp.in${i}`}  download={i} >Download</a>
									<div onClick={async () => {
										if (navigator.share) {
											await navigator.share({
												text: `Click on the link to get ${detail.project_id == projectId
													? detail.name.replace(
														/(^\w{1})|(\s+\w{1})/g,
														(letter) => letter.toUpperCase()
													)
													: "Project"
													} ${file.key} `,
												url: `https://api.eazyapp.in${i}`,
											});
										} else {
											toast.error("Web Share API is not supported.");
										}
									}} >Share</div>
								</div>
							</div>
						</div>;
					})
				})}
			</div>
		</Layout>
	);
};

export default Colaterals;


// onClick = { async()=>{
// 	if (navigator.share) {
// 		await navigator.share({
// 			text: `Click on the link to get ${detail.project_id == projectId
// 					? detail.name.replace(
// 						/(^\w{1})|(\s+\w{1})/g,
// 						(letter) => letter.toUpperCase()
// 					)
// 					: "Project"
// 				} ${file.key} `,
// 			url: `https://api.eazyapp.in${file.file}`,
// 		});
// 	} else {
// 		toast.error("Web Share API is not supported.");
// 	}
// }}