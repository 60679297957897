export const ADD_NOTE_REQUEST = 'ADD_NOTE_REQUEST'
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS'
export const ADD_NOTE_FAIL = 'ADD_NOTE_FAIL'


export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST'
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL'

export const ADD_LOG_REQUEST ='ADD_LOG_REQUEST'
export const ADD_LOG_SUCCESS ='ADD_LOG_SUCCESS'
export const ADD_LOG_FAIL ='ADD_LOG_FAIL'


export const DELETE_ACTIVITY_OR_TASK_REQUEST ='DELETE_ACTIVITY_OR_TASK_REQUEST'
export const DELETE_ACTIVITY_OR_TASK_SUCCESS ='DELETE_ACTIVITY_OR_TASK_SUCCESS'
export const DELETE_ACTIVITY_OR_TASK_FAIL ='DELETE_ACTIVITY_OR_TASK_FAIL'


export const EDIT_ACTIVITY_LOG_REQUEST = 'EDIT_ACTIVITY_LOG_REQUEST'
export const EDIT_ACTIVITY_LOG_SUCCESS = 'EDIT_ACTIVITY_LOG_SUCCESS'
export const EDIT_ACTIVITY_LOG_FAIL = 'EDIT_ACTIVITY_LOG_FAIL'


export const EDIT_TASK_REQUEST = 'EDIT_TASK_REQUEST'
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS'
export const EDIT_TASK_FAIL = 'EDIT_TASK_FAIL'



export const GET_TASK_REQUEST = 'GET_TASK_REQUEST'
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS'
export const GET_TASK_FAIL = 'GET_TASK_FAIL'


export const GET_ALL_PROJECTS_REQUEST ="GET_ALL_PROJECTS_REQUEST"
export const GET_ALL_PROJECTS_SUCCESS ="GET_ALL_PROJECTS_SUCCESS"
export const GET_ALL_PROJECTS_FAIL ="GET_ALL_PROJECTS_FAIL"


export const GET_REPORT_REQUEST ="GET_REPORT_REQUEST"
export const GET_REPORT_SUCCESS ="GET_REPORT_SUCCESS"
export const GET_REPORT_FAIL ="GET_REPORT_FAIL"

export const GET_PROJECT_REPORT_REQUEST ="GET_PROJECT_REPORT_REQUEST"
export const GET_PROJECT_REPORT_SUCCESS ="GET_PROJECT_REPORT_SUCCESS"
export const GET_PROJECT_REPORT_FAIL ="GET_PROJECT_REPORT_FAIL"

export const GET_PROJECTS_WING_REQUEST = "GET_PROJECTS_WING_REQUEST"
export const GET_PROJECTS_WING_SUCCESS = "GET_PROJECTS_WING_SUCCESS"
export const GET_PROJECTS_WING_FAIL = "GET_PROJECTS_WING_FAIL"


export const GET_PROJECT_COLLATERALS_REQUEST = "GET_PROJECT_COLLATERALS_REQUEST"
export const GET_PROJECT_COLLATERALS_SUCCESS = "GET_PROJECT_COLLATERALS_SUCCESS"
export const GET_PROJECT_COLLATERALS_FAIL = "GET_PROJECT_COLLATERALS_FAIL"


export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"


export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST"
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS"
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL"


export const GENRATE_REPORT_REQUEST = "GENRATE_REPORT_REQUEST"
export const GENRATE_REPORT_SUCCESS = "GENRATE_REPORT_SUCCESS"
export const GENRATE_REPORT_FAIL = "GENRATE_REPORT_FAIL"



export const GET_PROJECT_OFFERS_REQUEST = "GET_PROJECT_OFFERS_REQUEST"
export const GET_PROJECT_OFFERS_SUCCESS = "GET_PROJECT_OFFERS_SUCCESS"
export const GET_PROJECT_OFFERS_FAIL = "GET_PROJECT_OFFERS_FAIL"


export const GET_PROJECT_PAYMENT_SCHEDULE_REQUEST = "GET_PROJECT_PAYMENT_SCHEDULE_REQUEST"
export const GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS = "GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS"
export const GET_PROJECT_PAYMENT_SCHEDULE_FAIL = "GET_PROJECT_PAYMENT_SCHEDULE_FAIL"



export const GET_PROJECT_PRICING_LIST_REQUEST = "GET_PROJECT_PRICING_LIST_REQUEST"
export const GET_PROJECT_PRICING_LIST_SUCCESS = "GET_PROJECT_PRICING_LIST_SUCCESS"
export const GET_PROJECT_PRICING_LIST_FAIL = "GET_PROJECT_PRICING_LIST_FAIL"


export const SAVE_WHATSAPP_TEMPLATE_REQUEST = "SAVE_WHATSAPP_TEMPLATE_REQUEST"
export const SAVE_WHATSAPP_TEMPLATE_SUCCESS = "SAVE_WHATSAPP_TEMPLATE_SUCCESS"
export const SAVE_WHATSAPP_TEMPLATE_FAIL = "SAVE_WHATSAPP_TEMPLATE_FAIL"


export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"


export const LIST_OF_AVAILABLE_LANGUAGES = [
    { "key": "Afrikaans", "value": "af" },
    { "key": "Albanian", "value": "sq" },
    { "key": "Arabic", "value": "ar" },
    { "key": "Azerbaijani", "value": "az" },
    { "key": "Bengali", "value": "bn" },
    { "key": "Bulgarian", "value": "bg" },
    { "key": "Catalan", "value": "ca" },
    { "key": "Chinese (CHN)", "value": "zh_CN" },
    { "key": "Chinese (HKG)", "value": "zh_HK" },
    { "key": "Chinese (TAI)", "value": "zh_TW" },
    { "key": "Croatian", "value": "hr" },
    { "key": "Czech", "value": "cs" },
    { "key": "Danish", "value": "da" },
    { "key": "Dutch", "value": "nl" },
    { "key": "English", "value": "en" },
    { "key": "English (UK)", "value": "en_GB" },
    { "key": "English (US)", "value": "en_US" },
    { "key": "Estonian", "value": "et" },
    { "key": "Filipino", "value": "fil" },
    { "key": "Finnish", "value": "fi" },
    { "key": "French", "value": "fr" },
    { "key": "Georgian", "value": "ka" },
    { "key": "German", "value": "de" },
    { "key": "Greek", "value": "el" },
    { "key": "Gujarati", "value": "gu" },
    { "key": "Hausa", "value": "ha" },
    { "key": "Hebrew", "value": "he" },
    { "key": "Hindi", "value": "hi" },
    { "key": "Hungarian", "value": "hu" },
    { "key": "Indonesian", "value": "id" },
    { "key": "Irish", "value": "ga" },
    { "key": "Italian", "value": "it" },
    { "key": "Japanese", "value": "ja" },
    { "key": "Kannada", "value": "kn" },
    { "key": "Kazakh", "value": "kk" },
    { "key": "Kinyarwanda", "value": "rw_RW" },
    { "key": "Korean", "value": "ko" },
    { "key": "Kyrgyz (Kyrgyzstan)", "value": "ky_KG" },
    { "key": "Lao", "value": "lo" },
    { "key": "Latvian", "value": "lv" },
    { "key": "Lithuanian", "value": "lt" },
    { "key": "Macedonian", "value": "mk" },
    { "key": "Malay", "value": "ms" },
    { "key": "Malayalam", "value": "ml" },
    { "key": "Marathi", "value": "mr" },
    { "key": "Norwegian", "value": "nb" },
    { "key": "Persian", "value": "fa" },
    { "key": "Polish", "value": "pl" },
    { "key": "Portuguese (BR)", "value": "pt_BR" },
    { "key": "Portuguese (POR)", "value": "pt_PT" },
    { "key": "Punjabi", "value": "pa" },
    { "key": "Romanian", "value": "ro" },
    { "key": "Russian", "value": "ru" },
    { "key": "Serbian", "value": "sr" },
    { "key": "Slovak", "value": "sk" },
    { "key": "Slovenian", "value": "sl" },
    { "key": "Spanish", "value": "es" },
    { "key": "Spanish (ARG)", "value": "es_AR" },
    { "key": "Spanish (SPA)", "value": "es_ES" },
    { "key": "Spanish (MEX)", "value": "es_MX" },
    { "key": "Swahili", "value": "sw" },
    { "key": "Swedish", "value": "sv" },
    { "key": "Tamil", "value": "ta" },
    { "key": "Telugu", "value": "te" },
    { "key": "Thai", "value": "th" },
    { "key": "Turkish", "value": "tr" },
    { "key": "Ukrainian", "value": "uk" },
    { "key": "Urdu", "value": "ur" },
    { "key": "Uzbek", "value": "uz" },
    { "key": "Vietnamese", "value": "vi" },
    { "key": "Zulu", "value": "zu" }
]
