import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import ReactAutocomplete from "react-autocomplete"
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from "../../../../Actions/axios"
import { addProject } from '../../../../Actions/MyAdminAction'
import toast from 'react-hot-toast'
const AddProject = () => {
    const navigate=useNavigate() 
    const dispatch = useDispatch();
    const [developer, setDeveloper] = useState("")
    const [developerId, setDeveloperId] = useState("")
    const [developerList, setDeveloperList] = useState([])
    const [location, setLocation] = React.useState();
    const [locationItems, setLocationItems] = React.useState([]);
    const { token } = useSelector(state => state?.userState?.userInfo);

    useEffect(() => {
        if (location === "") {
            return;
        }
        const delay = 1000;
        const Debouncer = setTimeout(async () => {
            try {
                const response = await axios.post(
                    "https://houzyy.com/api/v1/get-area-list/",
                    { searched_term: location }
                );
                setLocationItems(response.data)
            } catch (error) {
                toast.error("Something went wrong");
            }
        }, delay);
        return () => clearTimeout(Debouncer);
    }, [location]);

    useEffect(() => {
        if (developer === "") {
            return;
        }
        const delay = 1000;
        const Debouncer = setTimeout(async () => {
            try {

                const config = {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
                const response = await axios.post(
                    "admin/developer-search",
                    { searched_term: developer },
                    config
                );
                setDeveloperList(response.data)
            } catch (error) {
                toast.error("Something went wrong");
            }
        }, delay);
        return () => clearTimeout(Debouncer);
    }, [developer])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        dispatch(addProject(formData,navigate))
    }
    return (
        <Layout pageTitle={"Add Project"}>
            <div className="p-2">
                <form onSubmit={submitHandler}>
                    <div className="d-grid gap-2 p-2 py-4">
                        <div>
                            <label>Select Developer</label>
                            <input type="number" value={developerId} name="developer_id" hidden />
                            <ReactAutocomplete
                                getItemValue={(item) => item.name}
                                items={developerList}
                                renderItem={(item, isHighlighted) => (
                                    <div
                                        class={`alert m-0 d-flex gap-2  mt-1 ${isHighlighted ? "alert-primary" : "alert-light"}`}
                                        role="alert"
                                    >
                                        <img style={{ height: "30px", width: "30px" }} src={"https://api.eazyapp.in" + item.logo} alt="" />
                                        <div className="d-grid place-items">
                                            <div>
                                                {item.name}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                value={developer}
                                menuStyle={{
                                    borderRadius: "3px",
                                    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                                    background: "rgba(255, 255, 255, 0.9)",
                                    fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
                                    display: "grid",
                                    maxHeight: "200px",
                                    overflow: "scroll",
                                    position: "fixed",
                                    zIndex: 999999999999,
                                }}
                                inputProps={{
                                    className: "form-control",
                                    required: true,
                                }}
                                wrapperProps={{ className: "w-100" }}
                                onChange={(e) => {
                                    setDeveloper(e.target.value);
                                }}
                                onSelect={(val, item) => {
                                    setDeveloper(val);
                                    setDeveloperId(item.id)
                                }}
                            />
                        </div>

                        <div className="myinput-group">
                            <label>Project Name</label>
                            <input
                                type="text"
                                className={`form-control  `}
                                name="name"
                            />
                        </div>
                        <div className="myinput-group">
                            <label>About</label>
                            <textarea
                                type="text"
                                className={`form-control   `}
                                rows={8}
                                name="about"
                            />
                        </div>
                        <div className="myinput-group">
                            <label>Possession Date</label>
                            <input
                                type="date"
                                className={`form-control  `}
                                name="possession_date"
                            />
                        </div>
                        <div className="myinput-group">
                            <label>Launch Date</label>
                            <input
                                type="date"
                                className={`form-control   `}
                                name="launch_date"
                            />
                        </div>
                        <div className="myinput-group">
                            <label>Tagging Period (In Days)</label>
                            <input
                                type="number"
                                className={`form-control   `}
                                name="tagging_period"
                            />
                        </div>
                        <div className="myinput-group">
                            <label>Location</label>
                            <ReactAutocomplete
                                getItemValue={(item) => item.name}
                                items={locationItems}
                                renderItem={(item, isHighlighted) => (
                                    <div
                                        class={`alert m-0  mt-1 ${isHighlighted ? "alert-primary" : "alert-light"
                                            }`}
                                        role="alert"
                                    >
                                        {item.name}
                                    </div>
                                )}
                                value={location}
                                menuStyle={{
                                    borderRadius: "3px",
                                    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                                    background: "rgba(255, 255, 255, 0.9)",
                                    fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
                                    display: "grid",
                                    maxHeight: "200px",
                                    overflow: "scroll",
                                    position: "fixed",
                                    zIndex: 999999999999,
                                }}
                                inputProps={{
                                    className: "form-control",
                                    name: "location",
                                    required: true,
                                }}
                                wrapperProps={{ className: "w-100" }}
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                }}
                                onSelect={(val, item) => {
                                    setLocation(val);
                                }}
                            />
                        </div>

                        <div>
                            <label>State</label>
                            <input
                                type="text"
                                className={`form-control  `}
                                name="state"
                            />
                        </div>
                        <div>
                            <label>District</label>
                            <input
                                type="text"
                                className={`form-control  `}
                                name="district"
                            />
                        </div>
                        <div>
                            <label>Pincode</label>
                            <input
                                type="text"
                                className={`form-control  `}
                                name="pincode"
                            />
                        </div>

                        
                        <div className="d-flex justify-content-end gap-2 p-3 text-end">
                            <button type="submit" className="btn btn-primary">
                                Save
                            </button>
                            <button
                                className="btn btn-dark"
                                type='button'
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default AddProject
