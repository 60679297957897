import axios from "./axios";
import {
	//
	GET_DASHBOARD_DATA_REQUEST,
	GET_DASHBOARD_DATA_SUCCESS,
	GET_DASHBOARD_DATA_FAIL,
	//
	GET_LEADS_REQUEST,
	GET_LEADS_SUCCESS,
	GET_LEADS_FAIL,
	//
	GET_LEAD_DETAIL_REQUEST,
	GET_LEAD_DETAIL_SUCCESS,
	GET_LEAD_DETAIL_FAIL,
	//
	GET_PROJECTS_REQUEST,
	GET_PROJECTS_SUCCESS,
	GET_PROJECTS_FAIL,
	//
	ADD_PROJECT_IN_MY_BUCKET_REQUEST,
	ADD_PROJECT_IN_MY_BUCKET_SUCCESS,
	ADD_PROJECT_IN_MY_BUCKET_FAIL,
	//
	ADD_LEAD_REQUEST,
	ADD_LEAD_SUCCESS,
	ADD_LEAD_FAIL,
	//
	GET_MY_PROJECT_BUCKET_REQUEST,
	GET_MY_PROJECT_BUCKET_SUCCESS,
	GET_MY_PROJECT_BUCKET_FAIL,
	//
	TAG_PROJECT_REQUEST,
	TAG_PROJECT_SUCCESS,
	TAG_PROJECT_FAIL,
	//
	GET_PROJECT_DETAIL_REQUEST,
	GET_PROJECT_DETAIL_SUCCESS,
	GET_PROJECT_DETAIL_FAIL,
	//
	GET_PROJECT_DOCUMENTS_REQUEST,
	GET_PROJECT_DOCUMENTS_SUCCESS,
	GET_PROJECT_DOCUMENTS_FAIL,
	//
	REGISTER_CHANNEL_PARTNER_REQUEST,
	REGISTER_CHANNEL_PARTNER_SUCCESS,
	REGISTER_CHANNEL_PARTNER_FAIL,
	//
	DELETE_CHANNEL_PARTNER_LEAD_REQUEST,
	DELETE_CHANNEL_PARTNER_LEAD_SUCCESS,
	DELETE_CHANNEL_PARTNER_LEAD_FAIL,
	//
	UNTAG_PROJECT_REQUEST,
	UNTAG_PROJECT_SUCCESS,
	UNTAG_PROJECT_FAIL,
	//
	GET_EMPLOYEES_REQUEST,
	GET_EMPLOYEES_SUCCESS,
	GET_EMPLOYEES_FAIL,
	//
	CREATE_EMPLOYEE_REQUEST,
	CREATE_EMPLOYEE_SUCCESS,
	CREATE_EMPLOYEE_FAIL,
	//
	DETAIL_EMPLOYEES_REQUEST,
	DETAIL_EMPLOYEES_SUCCESS,
	DETAIL_EMPLOYEES_FAIL,
	//
	DELETE_CP_EMPLOYEE_REQUEST,
	DELETE_CP_EMPLOYEE_SUCCESS,
	DELETE_CP_EMPLOYEE_FAIL,
	//
	EDIT_EMPLOYEE_DETAIL_REQUEST,
	EDIT_EMPLOYEE_DETAIL_SUCCESS,
	EDIT_EMPLOYEE_DETAIL_FAIL,
	//
} from "../Constants/CPConstants";
import toast from "react-hot-toast";

export const getDashboardData = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_DASHBOARD_DATA_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get("get/cp-dashboard/", config);
		dispatch({
			type: GET_DASHBOARD_DATA_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_DASHBOARD_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getLeads = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_LEADS_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("get/cp-leads/", config);
		dispatch({
			type: GET_LEADS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_LEADS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getLeadDetail = (leadId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_LEAD_DETAIL_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(`get/cp-lead/${leadId}`, config);
		dispatch({
			type: GET_LEAD_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_LEAD_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getProjects = (postData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PROJECTS_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
			params: postData,
		};
		const { data } = await axios.get("get/project-search", config);
		dispatch({
			type: GET_PROJECTS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PROJECTS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const addProject = (postData,callback,callbackData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ADD_PROJECT_IN_MY_BUCKET_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};

		const { data } = await axios.post(
			"add/project-to-bucket/",
			postData,
			config
		);
		toast.success("Project Added Successfully")
		if(callback){
			dispatch(callback(callbackData));
		}

		dispatch({
			type: ADD_PROJECT_IN_MY_BUCKET_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: ADD_PROJECT_IN_MY_BUCKET_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};
export const addLead = (postData, navigate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ADD_LEAD_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.post("add/cp-leads/", postData, config);
		toast.success("Lead Added Successfully");
		navigate(`/lead/${data.lead_id}`);
		dispatch({
			type: ADD_LEAD_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: ADD_LEAD_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getProjectBucket = (params) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_MY_PROJECT_BUCKET_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
			params: params,
		};
		const { data } = await axios.get("get/project-bucket/", config);
		dispatch({
			type: GET_MY_PROJECT_BUCKET_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_MY_PROJECT_BUCKET_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const tagProjectLead =
	(leadId, postData, setProjectShow) => async (dispatch, getState) => {
		try {
			dispatch({
				type: TAG_PROJECT_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post(
				`add/project-lead/${leadId}/`,
				postData,
				config
			);
			toast.success("Project Added Successfully");
			setProjectShow(false);
			dispatch(getLeadDetail(leadId));
			// dispatch(getProjectBucket({ page: 1 }));
			dispatch({
				type: TAG_PROJECT_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: TAG_PROJECT_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getProjectDetail = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PROJECT_DETAIL_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(
			`get/project-detail/${projectId}/`,
			config
		);

		dispatch({
			type: GET_PROJECT_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PROJECT_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getProjectDocumnets =
	(projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_PROJECT_DOCUMENTS_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/project-documents/${projectId}/`,
				config
			);

			dispatch({
				type: GET_PROJECT_DOCUMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_PROJECT_DOCUMENTS_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const deleteCPLead = (leadId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: DELETE_CHANNEL_PARTNER_LEAD_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get(`delete-cp-lead/${leadId}`, config);
		toast.success("Lead Deleted Successfully");
		dispatch({
			type: DELETE_CHANNEL_PARTNER_LEAD_SUCCESS,
			payload: data,
		});
		dispatch(getLeads());
	} catch (error) {
		dispatch({
			type: DELETE_CHANNEL_PARTNER_LEAD_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const registerChannelPartner =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: REGISTER_CHANNEL_PARTNER_REQUEST,
			});
			const config = {
				headers: {
					"Content-type": "application/json",
				},
			};

			const { data } = await axios.post(
				"register-channel-partner/",
				postData,
				config
			);
			toast.success("Registered Successfully");
			navigate("/login");
			dispatch({
				type: REGISTER_CHANNEL_PARTNER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: REGISTER_CHANNEL_PARTNER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const untagProject = (postData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: UNTAG_PROJECT_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.post("untag-project/", postData, config);
		toast.success("Project Untagged Successfully");
		dispatch({
			type: UNTAG_PROJECT_SUCCESS,
			payload: data,
		});
		dispatch(getLeadDetail(postData.lead_id));
	} catch (error) {
		dispatch({
			type: UNTAG_PROJECT_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getCPEmployees =
	(params = null) =>
		async (dispatch, getState) => {
			try {
				dispatch({
					type: GET_EMPLOYEES_REQUEST,
				});

				const {
					userState: { userInfo },
				} = getState();

				const config = {
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${userInfo?.token}`,
					},
					params: params,
				};

				const { data } = await axios.get("get/cp-employees/", config);

				dispatch({
					type: GET_EMPLOYEES_SUCCESS,
					payload: data,
				});
			} catch (error) {
				dispatch({
					type: GET_EMPLOYEES_FAIL,
					payload:
						error.response && error.response.data.error
							? error.response.data.error
							: error.message,
				});
			}
		};

export const createEmployee = (postData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: CREATE_EMPLOYEE_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.post("create/cp-employee/", postData, config);

		dispatch({
			type: CREATE_EMPLOYEE_SUCCESS,
			payload: data,
		});
		toast.success(data);

		dispatch(getCPEmployees({ page: 1 }));
	} catch (error) {
		dispatch({
			type: CREATE_EMPLOYEE_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getEmployeeDetail = (emp_id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: DETAIL_EMPLOYEES_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get(`detail/cp-employee/${emp_id}`, config);
		dispatch({
			type: DETAIL_EMPLOYEES_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: DETAIL_EMPLOYEES_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};



export const deleteCPEmployee = (emp_id,callback,cpId) => async (dispatch, getState) => {
	try {

		dispatch({
			type: DELETE_CP_EMPLOYEE_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.delete(`delete/cp-employee/${emp_id}`, config)
		toast.success(data)
		dispatch({
			type: DELETE_CP_EMPLOYEE_SUCCESS,
			payload: data
		})
		if (callback) {
			dispatch(callback(cpId))
		}else{
			dispatch(getCPEmployees({ page: 1 }));
		}
	} catch (error) {
		dispatch({
			type: DELETE_CP_EMPLOYEE_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}


export const editCPEMployee = (emp_id,postData)=>async (dispatch,getState)=>{
	try{

		dispatch({
			type: EDIT_EMPLOYEE_DETAIL_REQUEST
		})

		const {userState:{userInfo}}=getState();

		const config ={
			headers:{
				"Content-type":"application/json",
				Authorization:`Bearer ${userInfo?.token}`
			}
		}

		const {data}=await axios.put(`edit/cp-employee/${emp_id}`,postData,config)
		toast.success("Edited Successfully")
		dispatch({
			type:EDIT_EMPLOYEE_DETAIL_SUCCESS,
			payload:data
		})
		dispatch(getEmployeeDetail(emp_id))

	}catch(error){
		dispatch({
			type: EDIT_EMPLOYEE_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}