export const GET_SE_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST"
export const GET_SE_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const GET_SE_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL"


export const VISITOR_ASSIGNED_STATUS_REQUEST = "VISITOR_ASSIGNED_STATUS_REQUEST"
export const VISITOR_ASSIGNED_STATUS_SUCCESS = "VISITOR_ASSIGNED_STATUS_SUCCESS"
export const VISITOR_ASSIGNED_STATUS_FAIL = "VISITOR_ASSIGNED_STATUS_FAIL"


export const GET_VISITOR_DETAIL_REQUEST = 'GET_VISITOR_DETAIL_REQUEST'
export const GET_VISITOR_DETAIL_SUCCESS = 'GET_VISITOR_DETAIL_SUCCESS'
export const GET_VISITOR_DETAIL_FAIL = 'GET_VISITOR_DETAIL_FAIL'


export const UPDATE_VISITOR_DETAIL_REQUEST = 'UPDATE_VISITOR_DETAIL_REQUEST'
export const UPDATE_VISITOR_DETAIL_SUCCESS = 'UPDATE_VISITOR_DETAIL_SUCCESS'
export const UPDATE_VISITOR_DETAIL_FAIL = 'UPDATE_VISITOR_DETAIL_FAIL'


export const CHECKOUT_VISITOR_REQUEST = 'CHECKOUT_VISITOR_REQUEST'
export const CHECKOUT_VISITOR_SUCCESS = 'CHECKOUT_VISITOR_SUCCESS'
export const CHECKOUT_VISITOR_FAIL = 'CHECKOUT_VISITOR_FAIL'


// get/visitors/
export const GET_VISITORS_REQUEST = 'GET_VISITORS_REQUEST'
export const GET_VISITORS_SUCCESS = 'GET_VISITORS_SUCCESS'
export const GET_VISITORS_FAIL = 'GET_VISITORS_FAIL'


export const GET_VISITORS_DETAIL_REQUEST = "GET_VISITORS_DETAIL_REQUEST";
export const GET_VISITORS_DETAIL_SUCCESS = "GET_VISITORS_DETAIL_SUCCESS";
export const GET_VISITORS_DETAIL_FAIL = "GET_VISITORS_DETAIL_FAIL";


export const GET_MY_TEAM_MEMBERS_REQUEST = 'GET_MY_TEAM_MEMBERS_REQUEST'
export const GET_MY_TEAM_MEMBERS_SUCCESS = 'GET_MY_TEAM_MEMBERS_SUCCESS'
export const GET_MY_TEAM_MEMBERS_FAIL = 'GET_MY_TEAM_MEMBERS_FAIL'


export const GET_CHANNEL_PARTNERS_REQUEST = 'GET_CHANNEL_PARTNERS_REQUEST'
export const GET_CHANNEL_PARTNERS_SUCCESS = 'GET_CHANNEL_PARTNERS_SUCCESS'
export const GET_CHANNEL_PARTNERS_FAIL = 'GET_CHANNEL_PARTNERS_FAIL'



export const GET_DETAIL_CHANNEL_PARTNER_REQUEST = "GET_DETAIL_CHANNEL_PARTNER_REQUEST";
export const GET_DETAIL_CHANNEL_PARTNER_SUCCESS = "GET_DETAIL_CHANNEL_PARTNER_SUCCESS";
export const GET_DETAIL_CHANNEL_PARTNER_FAIL = "GET_DETAIL_CHANNEL_PARTNER_FAIL";



export const GET_INVENTORY_REQUEST = "GET_INVENTORY_REQUEST"
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS"
export const GET_INVENTORY_FAIL = "GET_INVENTORY_FAIL"


export const BOOK_UNIT_REQUEST = "BOOK_UNIT_REQUEST"
export const BOOK_UNIT_SUCCESS = "BOOK_UNIT_SUCCESS"
export const BOOK_UNIT_FAIL = "BOOK_UNIT_FAIL"


export const GET_CHANNEL_PARTNER_REQUEST = "GET_CHANNEL_PARTNER_REQUEST"
export const GET_CHANNEL_PARTNER_SUCCESS = "GET_CHANNEL_PARTNER_SUCCESS"
export const GET_CHANNEL_PARTNER_FAIL = "GET_CHANNEL_PARTNER_FAIL"


export const LINK_CHANNEL_PARTNER_REQUEST = "LINK_CHANNEL_PARTNER_REQUEST"
export const LINK_CHANNEL_PARTNER_SUCCESS = "LINK_CHANNEL_PARTNER_SUCCESS"
export const LINK_CHANNEL_PARTNER_FAIL = "LINK_CHANNEL_PARTNER_FAIL"




export const SE_ADD_BOOKING_PRICING_LIST_REQUEST = "SE_ADD_BOOKING_PRICING_LIST_REQUEST"
export const SE_ADD_BOOKING_PRICING_LIST_SUCCESS = "SE_ADD_BOOKING_PRICING_LIST_SUCCESS"
export const SE_ADD_BOOKING_PRICING_LIST_FAIL = "SE_ADD_BOOKING_PRICING_LIST_FAIL"


export const GET_CP_REPORT_REQUEST = "GET_CP_REPORT_REQUEST"
export const GET_CP_REPORT_SUCCESS = "GET_CP_REPORT_SUCCESS"
export const GET_CP_REPORT_FAIL = "GET_CP_REPORT_FAIL"