import React,{useEffect} from "react";
import Layout from "../../Layout/Layout";
import ImageUploader from "../../../../Components/ImageUploader";
import { useParams,useNavigate } from "react-router-dom";
import { useDispatch ,useSelector} from "react-redux";
import { getGalleryDetail ,editGalleryDetail} from "../../../../Actions/AdminAction";
const EditGallery = () => {

    const dispatch=useDispatch();
    const navigate=useNavigate()
    const {galleryId}=useParams();

    const { loading, error, itemDetail } = useSelector(
			(state) => state?.developer?.project?.galleryItem
		);

    const submitHandler=(event)=>{
        event.preventDefault()
        const formData=new FormData(event.target)
        dispatch(editGalleryDetail(formData, navigate, galleryId));
    }

    useEffect(()=>{
        dispatch(getGalleryDetail(galleryId))
    },[])

	return (
		<Layout pageTitle={"Edit Gallery File"}>
			<form onSubmit={submitHandler}>
				<div className="d-grid gap-3 p-3">
					<div className="d-flex justify-content-center align-items-center">
						<div className="w-30">
							<ImageUploader
								dbImage={
									itemDetail?.item
										? "https://api.eazyapp.in" + itemDetail?.item
										: null
								}
							/>
						</div>
					</div>
					<div>
						<label>Name</label>
						<input
							className="form-control"
							type="text"
							name="name"
							defaultValue={itemDetail?.name}
						/>
					</div>
					<div>
						<label id="item_type">Type</label>
						<select name="item_type" className="form-select" id="item_type" value={itemDetail?.item_type} >
							{['Elevation','Amenities','Master Plan','Floor Plan','Location Map','Exterior','Interior','Others','Entrance Lobby','View'].map((type)=>{
								return <option value={type} selected={itemDetail?.item_type===type}>{type}</option>
							})}
						</select>
					</div>
					<div className="d-flex justify-content-end gap-3">
						<button type="submit" className="btn btn-primary">
							Save
						</button>
						<button
							type="button"
							onClick={() => navigate(-1)}
							className="btn btn-dark"
						>
							Cancel
						</button>
					</div>
				</div>
			</form>
		</Layout>
	);
};

export default EditGallery;
