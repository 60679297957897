import React, { useEffect, useState } from "react";
import { json, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVisitorsDetail } from "../../Actions/SEAction";
import Loading from "../../Components/Loading";
import * as Icon from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import Activity from "../../Components/Activity";
import AddNote from "../../Components/AddNote";
import AddTask from "../../Components/AddTask";
import AddLog from "../../Components/AddLog";
import Accordion from "react-bootstrap/Accordion";

const SEVisitorDetail = () => {
	const [page, setPage] = useState("Activity");
	const [addNote, setAddNote] = useState(false);
	const [addTask, setAddTask] = useState(false);
	const [addLog, setAddLog] = useState(false);
	const [logType, setLogType] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { visitorId } = useParams();
	const { error, loading, detail } = useSelector(
		(state) => state?.se?.visitorsDetail
	);

	var a = [
		"",
		"one ",
		"two ",
		"three ",
		"four ",
		"five ",
		"six ",
		"seven ",
		"eight ",
		"nine ",
		"ten ",
		"eleven ",
		"twelve ",
		"thirteen ",
		"fourteen ",
		"fifteen ",
		"sixteen ",
		"seventeen ",
		"eighteen ",
		"nineteen ",
	];
	var b = [
		"",
		"",
		"twenty",
		"thirty",
		"forty",
		"fifty",
		"sixty",
		"seventy",
		"eighty",
		"ninety",
	];

	function numberToWords(num) {
		if ((num = num.toString()).length > 9) return "overflow";
		var n = ("000000000" + num)
			.substr(-9)
			.match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
		if (!n) return;
		var str = "";
		str +=
			n[1] != 0
				? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
				: "";
		str +=
			n[2] != 0
				? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
				: "";
		str +=
			n[3] != 0
				? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
				: "";
		str +=
			n[4] != 0
				? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
				: "";
		str +=
			n[5] != 0
				? (str != "" ? "and " : "") +
				  (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
				  "only "
				: "";
		return str;
	}


	useEffect(() => {
		dispatch(getVisitorsDetail(visitorId));
		setLogType(null);
	}, [addLog, addNote, addTask]);
	if (addNote) {
		return <AddNote visitorId={visitorId} setAddNote={setAddNote} />;
	}
	if (addTask) {
		return <AddTask visitorId={visitorId} setAddTask={setAddTask} />;
	}
	if (addLog) {
		return (
			<AddLog
				visitorId={visitorId}
				setAddLog={setAddLog}
				setLogType={setLogType}
				logType={logType}
			/>
		);
	}
	if (loading) {
		return (
			<div className="vh-100 d-flex justify-content-center align-items-center">
				<Loading></Loading>
			</div>
		);
	} else {
		return (
			<div className="vh-100 px-1 py-1">
				<div className=" mx-1 border-bottom">
					<h3 className="">
						{" "}
						<button className="btn fs-5" onClick={() => navigate(-1)}>
							<Icon.ArrowLeft />
						</button>{" "}
						Visitor Detail{" "}
					</h3>
				</div>
				<div
					className="h-92 w-100"
					style={{ overflowY: "scroll", overflowX: "hidden" }}
				>
					<div className="row ">
						<div className="col-lg-3">
							<div className="row mt-3">
								<div className="col-3"></div>
								<div className="col-6">
									<div className="text-center">
										<div
											className="d-flex justify-content-center align-items-center"
											style={{ height: "200px", width: "200px" }}
										>
											<img
												className="img-thumbnail rounded-circle"
												src={"https://api.eazyapp.in" + detail?.image}
												alt=""
												style={{ height: "100%", width: "100%" }}
											/>
										</div>
										<p className="fs-5 section_heading mt-2">
											{detail?.first_name} {detail?.last_name}
										</p>
									</div>
								</div>
								<div className="col-3"></div>
							</div>
						</div>
						<div className="col-lg-9">
							<div className=" mt-md-4  d-flex gap-4 gap-md-5 justify-content-center ">
								<div className=" text-center">
									<a
										href={`tel:+${detail?.mobile_number}`}
										className="btn btn-primary  btn-shadow rounded-circle fs-3 pt-1"
									>
										{" "}
										<Icon.Telephone />{" "}
									</a>
									<p className="me-3 mt-1">Call</p>
								</div>
								<div className=" text-center">
									<a
										href={`sms:+${detail?.mobile_number}`}
										className="btn btn-secondary  btn-shadow rounded-circle fs-3 pt-1"
									>
										{" "}
										<Icon.Chat />{" "}
									</a>
									<p className=" mt-1">SMS</p>
								</div>
								<div className=" text-center">
									<a
										href={`http://wa.me/+${detail?.whatsapp_number}?text=Hi`}
										className="btn btn-success btn-shadow rounded-circle fs-3 pt-1"
									>
										{" "}
										<Icon.Whatsapp />{" "}
									</a>
									<p className="mt-1">Whatsapp</p>
								</div>
								<div className=" text-center">
									<a
										href={`mailto:${detail?.email}`}
										className="btn btn-danger  btn-shadow rounded-circle fs-3 pt-1"
									>
										{" "}
										<Icon.Envelope />{" "}
									</a>
									<p className="ms-2 mt-1">Email </p>
								</div>
							</div>
						</div>
					</div>
					<div className="">
						<Nav variant="tabs" defaultActiveKey={page}>
							<Nav.Item>
								<Nav.Link
									eventKey="Activity"
									onClick={() => setPage("Activity")}
								>
									Activity{" "}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="Detail" onClick={() => setPage("Detail")}>
									Detail
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="Requirements"
									onClick={() => setPage("Requirements")}
								>
									Requirements
								</Nav.Link>
							</Nav.Item>
						</Nav>
						{page === "Activity" && (
							<div className="activity px-3 bg-light">
								<div className="row gap-3">
									<div className="col-md-12">
										<h3 className="my-4 ms-2">Activities</h3>
									</div>
									<div className="col-md-12">
										<div className="d-flex justify-content-center gap-5">
											<div className=" text-center">
												<button
													// href={`tel:${detail?.mobile_number}`}
													className="btn   btn-shadow btn-light me-3 rounded-circle fs-3"
													onClick={() => {
														// setAddNote(true);
														navigate("/note", {
															state: {
																visitorId: visitorId,
															},
														});
													}}
												>
													{" "}
													<Icon.PencilSquare />{" "}
												</button>
												<p className="me-3 fs-8 mt-1">Add Note</p>
											</div>
											<div className=" text-center">
												<button
													// href={`tel:${detail?.mobile_number}`}
													className="btn   btn-shadow btn-light me-3 rounded-circle fs-3"
													onClick={() => {
														// setAddTask(true);
														navigate("/task", {
															state: {
																visitorId: visitorId,
															},
														});
													}}
												>
													{" "}
													<Icon.Calendar2Plus />{" "}
												</button>
												<p className="me-3 fs-8 mt-1">Add Task</p>
											</div>
											<div className=" text-center">
												<button
													// href={`tel:${detail?.mobile_number}`}
													className="btn   btn-shadow btn-light me-3 rounded-circle fs-3"
													onClick={() => {
														// setAddLog(true);
														navigate("/log", {
															state: {
																visitorId: visitorId,
																logType: null,
															},
														});
													}}
												>
													{" "}
													<Icon.Plus />{" "}
												</button>
												<p className="me-3 fs-8 mt-1">Add Log</p>
											</div>
										</div>
									</div>
									<Activity
										className="col-md-12 px-4"
										data={detail?.activity_log}
										visitorId={visitorId}
									></Activity>
								</div>
							</div>
						)}
						{page === "Detail" && (
							<div className="detail">
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>Personal Details</Accordion.Header>
										<Accordion.Body>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Email</label>
													<br />
													<a
														className="link ms-1 fs-5"
														href={`mailto:${detail?.email}`}
													>
														{detail?.email}
													</a>
												</div>
												<div className="col-2"></div>
											</div>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Mobile Number</label>
													<br />
													<a
														className="link ms-1 fs-5 text-decoration-none"
														href={`tel:${detail?.mobile_number}`}
													>
														+{detail?.mobile_number}
													</a>
												</div>
												<div className="col-2"></div>
											</div>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Whatsapp Number</label>
													<br />
													<a
														className="link ms-1 fs-5 text-decoration-none"
														href={`http://wa.me/${detail?.whatsapp_number}?text=`}
													>
														+{detail?.whatsapp_number}
													</a>
												</div>
												<div className="col-2"></div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>Residence Details</Accordion.Header>
										<Accordion.Body>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Location</label>
													<br />
													<span className=" ms-1 fs-5 text-secondary">
														{detail?.resdential_address?.location}
													</span>
												</div>
												<div className="col-2"></div>
											</div>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Config</label>
													<br />
													<span className=" ms-1 fs-5 text-secondary">
														{detail?.resdential_address?.config}
													</span>
												</div>
												<div className="col-2"></div>
											</div>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Property</label>
													<br />
													<span className=" ms-1 fs-5 text-secondary text-capitalize">
														{detail?.resdential_address?.property_status}
													</span>
												</div>
												<div className="col-2"></div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>Work Details</Accordion.Header>
										<Accordion.Body>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Location</label>
													<br />
													<span className=" ms-1 fs-5 text-secondary">
														{detail?.work_detail?.location}
													</span>
												</div>
												<div className="col-2"></div>
											</div>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Organization Name</label>
													<br />
													<span className=" ms-1 fs-5 text-secondary">
														{detail?.work_detail?.company_name}
													</span>
												</div>
												<div className="col-2"></div>
											</div>
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													<label className="label">Designation</label>
													<br />
													<span className=" ms-1 fs-5 text-secondary text-capitalize">
														{detail?.work_detail?.designation}
													</span>
												</div>
												<div className="col-2"></div>
											</div>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						)}

						{page === "Requirements" && (
							<div className="requirements">
								<div>
									{Object.entries(detail?.requirements).map(([key, value]) => {
										// return
										return (
											<div className="row border-bottom mt-3">
												<div className="col-10 px-3">
													{/* Label */}
													<label className="label text-capitalize">
														{key.replaceAll("_", " ")}
													</label>
													<br />
													{/* Value */}
													{key === "budget" || key === "possession" ? (
														key !== "possession" ? (
															<span className="ms-1 fs-5 text-secondary">
																{`${value.minPrice} - ${value.maxPrice}`}
																<br />
																<span className="fs-8 text-capitalize">
																	{numberToWords(value.minPrice)} to{" "}
																	{numberToWords(value.maxPrice)}
																</span>
															</span>
														) : (
															<span className="ms-1 fs-5 text-secondary">
																{value.date}
															</span>
														)
													) : (
														<span className="ms-1 fs-5 text-secondary">
															{Array.isArray(value) ? value.join(", ") : value}
														</span>
													)}
												</div>
												<div className="col-2"></div>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
};

export default SEVisitorDetail;
