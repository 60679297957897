import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "../LoadingPage";
import {
	getProjectDetail,
	deleteConfig,
	deleteGalleryItem,
	deleteDocument,
	updateProjectDetail,
	removeAmenitie,
	getTeamMembers, removeTeamMember
} from "../../Actions/AdminAction";
import numberToWords from "../numberFormater";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, Accordion, Dropdown, Modal } from "react-bootstrap";
import moment from "moment";
import Inventory from "./Components/Inventory";
import { saveInventory, editInventoryData, deleteInventory } from "../../Actions/AdminAction";
import ConfirmDelete from "./Components/ConfirmDelete";

// const ConfirmDelete = ({id,projectId,callback,label="Delete"})=>{
// 	const dispatch=useDispatch()
// 	const [show, setShow] = useState(false);	
// 	return (
// 		<>
// 		<div onClick={() => setShow(true)} >
// 			{label}
// 		</div>
// 		<Modal  show={show} onHide={() => setShow(false)} >
// 			<Modal.Header closeButton>
// 				<Modal.Title>Confirm Delete</Modal.Title>
// 			</Modal.Header>
// 			<Modal.Body>
// 				Are you sure you want to delete this item?
// 			</Modal.Body>
// 			<Modal.Footer>
// 				<button
// 					className="btn btn-danger"
// 					onClick={() => {
// 						setShow(false);
// 						dispatch(callback(id,projectId))
// 					}}
// 				>
// 					Delete
// 				</button>
// 				<button
// 					className="btn btn-secondary"
// 					onClick={() => setShow(false)}
// 				>
// 					Cancel
// 				</button>
// 			</Modal.Footer>
// 		</Modal>
// 		</>
// 	)


// }

const ProjectDetail = () => {
	const [page, setPage] = useState("basic_detail");
	const [basicDetailEdit, SetBasicDetailEdit] = useState(false);
	const [domainEdit, setDomainEdit] = useState(false);
	const [reload, setReload] = useState(false);
	const [show, setShow] = useState(false);
	const [index, setIndex] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { projectId } = useParams();
	const { loading, error, detail } = useSelector(
		(state) => state?.developer?.project?.detail
	);

	const user = useSelector((state) => state?.userState?.userInfo);

	useEffect(() => {
		if (window.location.hash.replace("#", "") !== "") {
			setPage(window.location.hash.replace("#", ""));
		}
	}, []);
	useEffect(() => {
		dispatch(getProjectDetail(projectId));
	}, []);

	useEffect(() => {
		window.location.hash = page;
	}, [page]);

	useEffect(() => {
		if (reload) {
			setReload(false);
		}
	}, [reload]);

	const ListOfFiles = detail?.documents
		? [...new Set(detail.documents.map((item) => item.file_type))]
		: [];

	const submitHandler = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		dispatch(updateProjectDetail(object, projectId));
	};

	if (detail === undefined || loading) {
		return <LoadingPage />;
	}
	if (!reload) {
		return (
			<Layout pageTitle={"Project Title"}>
				<div className="p-2">
					<Tabs
						id="controlled-tab-example"
						variant="tabs"
						activeKey={page}
						onSelect={(k) => setPage(k)}
						className=" gap-2 gap-md-4 "
					>
						<Tab eventKey="basic_detail" title="Basic Detail">
							{!basicDetailEdit && !user.extra?.viewer_only && (
								<div className="position-relative">
									<button
										className="btn  position-absolute end-0 mx-2 my-1 fs-4 "
										onClick={() => SetBasicDetailEdit(true)}
									>
										<FontAwesomeIcon icon={icon.faEdit} />
										Edit

									</button>
								</div>
							)}
							<form onSubmit={submitHandler}>
								<div className="d-grid gap-2 p-2 py-4">
									<div className="myinput-group">
										<label>Project Name</label>
										<input
											type="text"
											className={`form-control  ${basicDetailEdit ? "" : " bg-white border-0 "
												} `}
											defaultValue={detail.name}
											name="name"
											disabled={!basicDetailEdit}
										/>
									</div>
									<div className="myinput-group">
										<label>About</label>
										<textarea
											type="text"
											className={`form-control  ${basicDetailEdit ? "" : " bg-white border-0  "
												} `}
											defaultValue={detail.about}
											rows={8}
											name="about"
											disabled={!basicDetailEdit}
										/>
									</div>
									<div className="myinput-group">
										<label>Possession Date</label>
										<input
											type="date"
											className={`form-control  ${basicDetailEdit
													? ""
													: " bg-white border-0 .content-none "
												} `}
											defaultValue={detail.possession_date}
											name="possession_date"
											disabled={!basicDetailEdit}
										/>
									</div>
									<div className="myinput-group">
										<label>Launch Date</label>
										<input
											type="date"
											className={`form-control  ${basicDetailEdit ? "" : " bg-white border-0 content-none"
												} `}
											defaultValue={detail.launch_date}
											name="launch_date"
											disabled={!basicDetailEdit}
										/>
									</div>
									{basicDetailEdit && !user.extra?.viewer_only && (
										<div className="d-flex justify-content-end gap-2 p-3 text-end">
											<button type="submit" className="btn btn-success">
												Save
											</button>
											<button
												type="button"
												className="btn btn-dark"
												onClick={() => {
													setReload(true);
													SetBasicDetailEdit(false);
												}}
											>
												Cancel
											</button>
										</div>
									)}
								</div>
							</form>
						</Tab>
						<Tab eventKey="config" title="Configuration">
							<div className="row  py-4 p-2">
								{!user.extra?.viewer_only &&<div className="col-12 text-end">
									<button
										className="btn btn-primary"
										onClick={() => navigate(`/add/config/${projectId}`)}
									>
										Add Configuration
									</button>
								</div>}
								{detail?.configs.map((config) => {
									return (
										<div className="col-md-6 col-12 p-2">
											<div className="border rounded p-2">
												<div className="row ">
													<div className="col-4">
														<img
															src={"https://api.eazyapp.in" + config.file}
															alt=""
														/>
													</div>
													<di className="col-8 position-relative">
														{!user.extra?.viewer_only &&
														<div className="position-relative">
															<Dropdown
																className="position-absolute end-0  "
																style={{ zIndex: "1" }}
															>
																<Dropdown.Toggle
																	className="hide-arrow"
																	variant=""
																	id="dropdown-basic"
																>
																	<FontAwesomeIcon icon={icon.faEllipsis} />
																</Dropdown.Toggle>

																<Dropdown.Menu>
																	<Dropdown.Item
																		onClick={() => {
																			navigate(`/edit/config/${config.id}`);
																		}}
																	>
																		Edit
																	</Dropdown.Item>
																	<Dropdown.Item
																	>
																		<ConfirmDelete id={config.id} projectId={projectId} callback={deleteConfig} />
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>
														</div>}
														<p className="card_heading m-0">{config.name}</p>
														<p className="m-0">
															{" "}
															<span>Configuration: </span> {config.config}
														</p>
														<p className="m-0">
															{" "}
															<span>Carpet Area: </span> {config.carpet_area}
															sq.ft
														</p>
														<p className="m-0">
															<span>Price: </span>
															{numberToWords(config.price)}{" "}
															{config.all_in ? "All Inclusive" : "++"}{" "}
														</p>
													</di>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</Tab>
						<Tab eventKey="gallery" title="Gallery">
							<div className="row p-2">
								{!user.extra?.viewer_only &&<div className="col-12 text-end py-3">
									<button
										className="btn btn-primary"
										onClick={() => {
											navigate(`/add/gallery-item/${projectId}`);
										}}
									>
										Add Gallery Item
									</button>
								</div>}
								{detail?.gallery?.map((item) => {
									return (
										<div className="col-md-4 position-relative  col-12 p-2">
											<div className="p-2 border postion-relative rounded">
												{!user.extra?.viewer_only &&<div className="postion-relative">
													<Dropdown
														className="position-absolute end-0 me-3 "
														style={{ zIndex: "1" }}
													>
														<Dropdown.Toggle
															className="hide-arrow"
															variant=""
															id="dropdown-basic"
														>
															<FontAwesomeIcon icon={icon.faEllipsis} />
														</Dropdown.Toggle>

														<Dropdown.Menu>
															{!item.cover_img && (
																<Dropdown.Item>
																	{item.cover_img
																		? "Remove Cover Image"
																		: "Set Cover Image"}
																</Dropdown.Item>
															)}
															<Dropdown.Item
																onClick={() => {
																	navigate(`/edit/gallery-item/${item.id}`);
																}}
															>
																Edit
															</Dropdown.Item>
															<Dropdown.Item
															>
																<ConfirmDelete id={item.id} projectId={projectId} callback={deleteGalleryItem} />
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>}
												<div className="d-grid place-items-center">
													<img
														style={{ height: "200px", width: "250px" }}
														src={"https://api.eazyapp.in" + item.item}
														alt=""
													/>
													<p className="m-0">{item.name}</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</Tab>
						<Tab eventKey="documents" title="Documents">
							{!user.extra?.viewer_only &&<div className="py-3 text-end">
								<button
									className="btn btn-primary"
									onClick={() => {
										navigate(`/add/document/${projectId}`);
									}}
								>
									Add New Document
								</button>
							</div>}
							<Accordion>
								{ListOfFiles.map((key, index) => {
									return (
										<Accordion.Item eventKey={index.toString()}>
											<Accordion.Header>{key}</Accordion.Header>
											<Accordion.Body>
												{detail?.documents.map((item) => {
													if (item.file_type == key) {
														return (
															<>
																<div className="row">
																	<div className="col-5">
																		{
																			item.file.split("/")[
																			item.file.split("/").length - 1
																			]
																		}
																	</div>
																	<div className="col-3">
																		<label className="text-muted m-n2">
																			Modified On
																		</label>
																		<p className="m-0">
																			{moment(item?.updated_at).fromNow()}
																		</p>
																	</div>
																	<div className="col-3">
																		<label className="text-muted m-n2">
																			Created On
																		</label>
																		<p className="m-0">
																			{moment(item?.updated_at).fromNow()}
																		</p>
																	</div>
																	{!user.extra?.viewer_only &&<div className="col-1">
																		<Dropdown
																			className="  "
																			style={{ zIndex: "1" }}
																		>
																			<Dropdown.Toggle
																				className="hide-arrow"
																				variant=""
																				id="dropdown-basic"
																			>
																				<FontAwesomeIcon
																					icon={icon.faEllipsis}
																				/>
																			</Dropdown.Toggle>

																			<Dropdown.Menu>
																				<Dropdown.Item
																					onClick={() => {
																						navigate(
																							`/edit/document/${item?.id}`
																						);
																					}}
																				>
																					Edit
																				</Dropdown.Item>
																				<Dropdown.Item
																				>
																					<ConfirmDelete id={item.id} projectId={projectId} callback={deleteDocument} />
																				</Dropdown.Item>
																			</Dropdown.Menu>
																		</Dropdown>
																	</div>}
																</div>
															</>
														);
													}
												})}
											</Accordion.Body>
										</Accordion.Item>
									);
								})}
							</Accordion>
							{/* {JSON.stringify(detail.documents)} */}
						</Tab>
						<Tab eventKey="amenities" title="Amenities">
							{!user.extra?.viewer_only &&<div className="text-end p-3">
								<button
									className="btn btn-primary"
									onClick={() => {
										navigate(`/edit/amenitie-list/${projectId}`);
									}}
								>
									Add
								</button>
							</div>}
							<div className="row row-cols-auto ">
								{detail.amenities.map((item) => {
									return (
										<div className="col p-2">
											{!user.extra?.viewer_only &&<div className="position-relative">
												<FontAwesomeIcon onClick={() => {
													dispatch(removeAmenitie({ amenitie_id: item.id }, projectId))
												}} style={{ zIndex: "1" }} className="position-absolute end-0  m-1 " icon={icon.faXmark} />
											</div>}
											<div className="d-grid border rounded p-4 place-items-center">
												<img
													src={item.icon
														.replace("/media/", "")
														.replace("%3A", ":/")}
													alt=""
													style={{
														width: "64px",
														aspectRatio: "1/1",
														objectFit: "contain",
													}}
												/>
												<p className="m-0">{item.name}</p>
											</div>
										</div>
									);
								})}
							</div>
						</Tab>
						<Tab eventKey="team" title="Teams">
							<div className="d-grid gap-3 p-3">
								{!user.extra?.viewer_only &&<div className="p-2 text-end">
									<button className="btn btn-primary" onClick={() => {
										navigate(`/add/team-member/${projectId}`);
									}}  >Add Team Member</button>
								</div>}
								{detail?.teams?.map((team) => {
									return (
										<>
											{team.members.map((team) => {
												return (
													<>
														<div className="p-2 position-relative">
															{!user.extra?.viewer_only &&<Dropdown
																className="position-absolute end-0 m-1 mx-3 "
																style={{ zIndex: "1" }}
															>
																<Dropdown.Toggle
																	className="hide-arrow"
																	variant=""
																	id="dropdown-basic"
																>
																	<FontAwesomeIcon icon={icon.faEllipsis} />
																</Dropdown.Toggle>

																<Dropdown.Menu>
																	<Dropdown.Item
																	>
																		{/* Remove From project */}
																		<ConfirmDelete id={team.id} projectId={projectId} callback={removeTeamMember} label="Remove From Project" />
																	</Dropdown.Item>
																	<Dropdown.Item
																		onClick={() => {
																			navigate(`/edit/team-member/${team.id}/${projectId} " "`);
																		}}
																	>
																		Edit
																	</Dropdown.Item>
																</Dropdown.Menu>
															</Dropdown>}
															<div className="rounded border shadow p-3">
																<div className="d-flex">
																	<img
																		className="img-thumbnail rounded-circle me-3"
																		style={{ height: "70px", width: "70px" }}
																		src={
																			"https://api.eazyapp.in" +
																			team.employee.photo
																		}
																		alt=""
																	/>
																	<div>
																		<h4 className="card_heading">
																			{team.employee.name}
																		</h4>
																		<p className="m-0 text-muted">
																			{team.designation}
																		</p>
																		<p className="m-0 fw-bold"><span className="text-muted" >Team:</span> {team.name} </p>
																	</div>
																</div>
															</div>
														</div>
													</>
												);
											})}
										</>
									);
								})}
							</div>
						</Tab>
						<Tab eventKey="inventory" title="Inventory">
							<div className="p-3">
								{!user.extra?.viewer_only &&<div className="text-end p-2">
									<button className="btn btn-primary" onClick={() => navigate(`/add-new-inventory/${projectId}`)} >Add Wing</button>
								</div>}

								<table className='table'>
									<thead>
										<tr>
											<th>Name</th>
											<th>No. Of Floors</th>
											<th>No. Of Units</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{detail?.inventories?.map((item) => {
											return (
												<tr>
													<td>{item.wing}</td>
													<td>{item.units.length}</td>
													<td>{Object.keys(item.units).map(k => {
														if (k !== "length" && k !== "max_unit") {
															return parseInt(item.units[k].length)
														}
													}).filter(item => item != undefined).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</td>
													<td>
														<div class="flex items-end gap-3">
															{!user.extra?.viewer_only && <><button class="flex items-center justify-center text-primary border-transparent rounded-md transition-all duration-300 hover:text-white hover:bg-primary bg-primary/10 edit-button" onClick={() => navigate(`/edit-inventory/${projectId}/${item.id}`)}>
																<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M9.57342 2.71915L10.1913 2.10123C11.2151 1.07742 12.8751 1.07742 13.8989 2.10123C14.9227 3.12503 14.9227 4.78494 13.8989 5.80874L13.2809 6.42666M9.57342 2.71915C9.57342 2.71915 9.65066 4.03223 10.8093 5.19082C11.9679 6.34942 13.2809 6.42666 13.2809 6.42666M9.57342 2.71915L3.89259 8.39997C3.50782 8.78475 3.31543 8.97714 3.14997 9.18926C2.9548 9.4395 2.78746 9.71025 2.65094 9.99672C2.5352 10.2396 2.44916 10.4977 2.27708 11.0139L1.54791 13.2014M13.2809 6.42666L7.60011 12.1075C7.21533 12.4923 7.02295 12.6847 6.81082 12.8501C6.56059 13.0453 6.28984 13.2126 6.00336 13.3491C5.7605 13.4649 5.50239 13.5509 4.98616 13.723L2.79865 14.4522M2.79865 14.4522L2.26393 14.6304C2.00989 14.7151 1.72981 14.649 1.54046 14.4596C1.35111 14.2703 1.28499 13.9902 1.36967 13.7361L1.54791 13.2014M2.79865 14.4522L1.54791 13.2014" stroke="currentColor" stroke-width="1.6"></path>
																</svg>
																Edit
															</button>
															<div class="flex items-center justify-center text-danger border-transparent rounded-md transition-all duration-300 hover:text-white hover:bg-danger bg-danger/10 delete-button">
																<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M2.29166 5.13898C2.29166 4.75545 2.57947 4.44454 2.93451 4.44454L5.15471 4.44417C5.59584 4.43209 5.985 4.12909 6.13511 3.68084C6.13905 3.66906 6.14359 3.65452 6.15987 3.60177L6.25553 3.29169C6.31407 3.10157 6.36508 2.93594 6.43644 2.78789C6.7184 2.20299 7.24005 1.79683 7.84288 1.69285C7.99547 1.66653 8.15706 1.66664 8.34254 1.66677H11.2409C11.4264 1.66664 11.588 1.66653 11.7406 1.69285C12.3434 1.79683 12.8651 2.20299 13.147 2.78789C13.2184 2.93594 13.2694 3.10157 13.3279 3.29169L13.4236 3.60177C13.4399 3.65452 13.4444 3.66906 13.4484 3.68084C13.5985 4.12909 14.0648 4.43246 14.5059 4.44454H16.6488C17.0038 4.44454 17.2917 4.75545 17.2917 5.13898C17.2917 5.5225 17.0038 5.83341 16.6488 5.83341H2.93451C2.57947 5.83341 2.29166 5.5225 2.29166 5.13898Z" fill="currentColor"></path>
																	<path opacity="0.3" d="M9.67232 18.3333H10.3281C12.5843 18.3333 13.7125 18.3333 14.4459 17.6139C15.1794 16.8946 15.2545 15.7146 15.4046 13.3547L15.6208 9.95428C15.7023 8.67382 15.743 8.03358 15.375 7.62788C15.007 7.22217 14.3856 7.22217 13.1429 7.22217H6.85755C5.61477 7.22217 4.99337 7.22217 4.62541 7.62788C4.25744 8.03358 4.29815 8.67382 4.37959 9.95428L4.59584 13.3547C4.74593 15.7146 4.82097 16.8946 5.55446 17.6139C6.28795 18.3333 7.41607 18.3333 9.67232 18.3333Z" fill="currentColor"></path>
																</svg>
																<ConfirmDelete id={item.id} projectId={projectId} callback={deleteInventory} label="Delete" />
																</div> </>}
															<button className="btn btn-sm" onClick={() => navigate(`/view-inventory/${projectId}/${item.id}`)} >
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M9.75 12C9.75 10.7574 10.7574 9.75 12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12Z" fill="#1C274C"></path>
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 13.6394 2.42496 14.1915 3.27489 15.2957C4.97196 17.5004 7.81811 20 12 20C16.1819 20 19.028 17.5004 20.7251 15.2957C21.575 14.1915 22 13.6394 22 12C22 10.3606 21.575 9.80853 20.7251 8.70433C19.028 6.49956 16.1819 4 12 4C7.81811 4 4.97196 6.49956 3.27489 8.70433C2.42496 9.80853 2 10.3606 2 12ZM12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25Z" fill="#1C274C"></path>
																</svg>
																View
															</button>
														</div>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</Tab>
						<Tab eventKey="website_settings" title="Website Setting">
							<div className="d-gird gap-3 p-3 position-relative">
								{!user.extra?.viewer_only && <div className="text-end">
									<button
										className="btn btn-sm my-1 fs-4 "
										onClick={() => navigate(`/edit-project-website-setting/${projectId}`)}
									>
										<FontAwesomeIcon icon={icon.faEdit} />
										Edit

									</button>
								</div>}
								<h2>{detail.project_domain?.domain}</h2>

								<div>
									<label htmlFor="primaryColor">Primary Color</label>
									<input type="color" id="primaryColor" className="form-control" value={detail.domain_setting?.primary} disabled />
								</div>

								<div>
									<label htmlFor="primaryBGTextColor"> Primary BG Text Color </label>
									<input type="color" id="primaryBGTextColor" className="form-control" value={detail.domain_setting?.text_color_for_primary_bg} disabled />
								</div>
								<div>
									<label htmlFor="main_hero"> Main Hero Image</label>
									<img style={{width:"300px",height:"auto"}}  src={"https://api.eazyapp.in" + detail.domain_setting?.main_hero} alt="" />
								</div>
								<div>
									<label htmlFor="mobile_main_hero"> Mobile Main Hero Image</label>
									<img style={{width:"300px",height:"auto"}}  src={"https://api.eazyapp.in" + detail.domain_setting?.mobile_main_hero} alt="" />
								</div>
								<div>
									<label htmlFor="logo_image"> Logo </label>
									<img style={{width:"300px",height:"auto"}}  src={"https://api.eazyapp.in" + detail.domain_setting?.project_logo} alt="" />
								</div>
								<div>
									<label htmlFor="meta_description">Meta Description</label>
									<textarea type="text" id="meta_description" className="form-control" value={detail.domain_setting?.meta_description} disabled />
								</div>
								<div>
									<label htmlFor="meta_keywords">Meta Keywords</label>
									<textarea type="text" id="meta_keywords" className="form-control" value={detail.domain_setting?.meta_keywords} disabled />
								</div>
								<div>
									<label htmlFor="google_analytics_id">Google Analytics ID</label>
									<input type="text" id="google_analytics_id" className="form-control" maxLength={100} value={detail.domain_setting?.google_analytics_id} disabled />
								</div>
								<div>
									<label htmlFor="google_tag_manager_id">Google Tag Manager ID</label>
									<input type="text" id="google_tag_manager_id" className="form-control" maxLength={100} value={detail.domain_setting?.google_tag_manager_id} disabled />
								</div>
								<div>
									<label htmlFor="google_verfication_content">Google Verification Content</label>
									<input type="text" id="google_verfication_content" className="form-control" maxLength={100} value={detail.domain_setting?.google_verfication_content} disabled />
								</div>


							</div>
						</Tab>
					</Tabs>
				</div>

			</Layout>
		);
	}
};

export default ProjectDetail;




<button type="button" class="border w-10 h-10 flex items-center justify-center text-primary border-transparent rounded-md transition-all duration-300 hover:text-white hover:bg-primary bg-primary/10 edit-button">

</button>