import React, { useEffect } from "react";
import Layout from "./Layout/Layout";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardData } from "../../Actions/CPAction";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import numberToWords from "../numberFormater";
const Home = () => {
	const navigate=useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getDashboardData());
	}, []);
	const { profile } = useSelector((state) => state?.userProfile);
	const { dashData, loading } = useSelector((state) => state?.cp?.dashboard);
	
	if(dashData !== undefined){
		var infoSettings = {
			   dots: false,
			   infinite: true,
			   speed: 500,
			   slidesToShow: 4,
			   slidesToScroll: 1,
			   responsive:[
						   {
							   breakpoint: 480,
							   settings: {
								   arrows: false,
								   centerMode: true,
								   centerPadding: "30px",
								   slidesToShow: 2,
								   autoplay: true,
								   autoplaySpeed: 2500,
							   },
						   },
						   {
							   breakpoint: 780,
							   settings: {
								   arrows: false,
								   centerMode: true,
								   centerPadding: "20px",
								   slidesToShow: 3,
								   autoplay: true,
								   autoplaySpeed: 2500,
							   },
						   },
						   {
							   breakpoint: 1000,
							   settings: {
								   arrows: false,
								   centerMode: true,
								   centerPadding: "0px",
								   slidesToShow: 4,
								   autoplay: true,
								   autoplaySpeed: 2500,
							   },
						   },
					   ]
		   };
		var projectSettings = {
			   dots: false,
			   infinite: true,
			   speed: 500,
			   slidesToShow:
				   dashData?.projects?.length < 3 ? dashData?.projects?.length : 3,
			   slidesToScroll: 1,
			   responsive: [
				   {
					   breakpoint: 480,
					   settings: {
						   arrows: false,
						   centerMode: true,
						   centerPadding: "30px",
						   slidesToShow: 1,
						   autoplay: true,
						   autoplaySpeed: 2500,
					   },
				   },
				   {
					   breakpoint: 780,
					   settings: {
						   arrows: false,
						   centerMode: true,
						   centerPadding: "20px",
						   slidesToShow:
							   dashData?.projects?.length < 2 ? dashData?.projects?.length : 2,
						   autoplay: true,
						   autoplaySpeed: 2500,
					   },
				   },
				   {
					   breakpoint: 1000,
					   settings: {
						   arrows: false,
						   centerMode: true,
						   centerPadding: "10px",
						   slidesToShow:
							   dashData?.projects?.length < 2 ? dashData?.projects?.length : 2,
						   autoplay: true,
						   autoplaySpeed: 2500,
					   },
				   },
			   ],
		   };

		return (
			<>
				<div
					className="pb-5 pb-md-0"
					style={{ height: "100%", overflowY: "scroll",overflowX:"hidden" }}
				>
					<div className="bg-accent rounded-bottom-5 pt-5 p-3 w-100 ">
						<div className="row h-100 mt-4 pb-5 my-3">
							<div className="col-1"></div>
							<div className="col-3 col-lg-2 ps-4">
								<div className="d-flex justify-content-center ">
									<img
										className="img-thumbnail rounded-circle"
										src={"https://api.eazyapp.in" + profile?.photo}
										alt=""
									/>
								</div>
							</div>
							<div className="col-7">
								<div className="">
									<p className="text-capitalize fw-bold fs-5 text-white p-0 m-0">
										{profile?.name}{" "}
									</p>
									<p className="text-white-50 p-0 m-0 fs-9">
										{profile?.email}{" "}
									</p>
									<p className="text-white p-0 m-0 fs-9">
										{profile?.designation}{" "}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="text-center my-4 mt-5">
						<h1 className="section_heading">Statistics</h1>
						<div className="line"></div>
					</div>
					<div className="pb-5 pb-md-0">
						<div className="row p-3 ">
							<div className="p-2 col-6 ">
								<div className="p-3 rounded bg-faded-danger shadow-sm ">
									<h4>Projects</h4>
									<div>
										<p className="display-4">{dashData?.mybucketCount}</p>
									</div>
								</div>
							</div>
							<div className="p-2 col-6 ">
								<div className="p-3 rounded bg-faded-primary shadow-sm ">
									<h4>My Leads</h4>
									<div>
										<p className="display-4">{dashData?.leads}</p>
									</div>
								</div>
							</div>
							<div className="p-2 col-6 ">
								<div className="p-3 rounded bg-faded-warning shadow-sm ">
									<h4>Visits</h4>
									<div>
										<p className="display-4">{dashData?.my_total_visits}</p>
									</div>
								</div>
							</div>
							<div className="p-2 col-6 ">
								<div className="p-3 rounded bg-faded-success shadow-sm ">
									<h4>Ratio</h4>
									<div>
										<p className="display-4">
											{dashData?.lead_to_visit_ratio}%
										</p>
									</div>
								</div>
							</div>
						</div>
						{/* <div>
							<div className="text-center my-2">
								<h1 className="section_heading">My Projects</h1>
								<div className="line"></div>
							</div>
							<div className="pb-5 pb-md-0">
								<Slider {...projectSettings}>
									{dashData?.projects?.map((project) => {
										return (
											<div className="p-2">
												<div
													className="p-3 rounded border shadow-sm"
													onClick={() => {
														navigate(`/project/${project.project_id}`);
													}}
												>
													<div style={{ height: "300px", width: "auto" }}>
														<img
															className="w-100 h-100"
															src={"https://api.eazyapp.in" + project.cover_img}
															alt=""
														/>
													</div>
													<div className="text-card p-2">
														<h5 className="text-capitalize card_heading">
															{project.name}
														</h5>
														<p className="m-0">
															<b>Configuration:</b> {project.configs}
														</p>
														<p className="m-0">
															<b>Carpet Area:</b>{" "}
															{project.starting_carpert_area}
														</p>
														<p className="m-0">
															<b>Price:</b>{" "}
															{numberToWords(project.starting_price)}
														</p>
														<p className="m-0">
															<b>Leads:</b> {project.leads} | <b>Visits:</b>{" "}
															{project.visits} | <b>Booked:</b> {project.booked}
														</p>
													</div>
												</div>
											</div>
										);
									})}
								</Slider>
							</div>
						</div> */}
					</div>
				</div>
			</>
		);
	}
};

const CPDashboard = () => {
	return (
		<Layout pageTitle="Dashboard">
			<Home />
		</Layout>
	);
};

export default CPDashboard;
