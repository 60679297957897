
import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import { getProjectPricingList } from '../../Actions/CommonAction'
import { useDispatch, useSelector } from 'react-redux'
import { addBookingPriceList } from '../../Actions/SEAction'


const ExtraPricingDetail = ({ item, itemHandler, removeHandler }) => {
    return <div className="d-grid p-3 border rounded gap-2 position-relative  ">
        <span className='fs-3 position-absolute top-0 end-0 cursor-pointer mx-2 ' onClick={removeHandler}  >X</span>
        <div>
            <label>Label</label>
            <input
                type="text"
                value={item.key}
                onChange={(e) => {
                    const data = { ...item };
                    data["key"] = e.target.value;
                    itemHandler(data);
                }}
                name="key"
                className="form-control"
            />
        </div>
        <div>
            <label>Value</label>
            <input
                type="number"
                value={item.value}
                onChange={(e) => {
                    const data = { ...item };
                    data["value"] = e.target.value;
                    itemHandler(data);
                }}
                name="value"
                className="form-control"
            />
        </div>
        <div class="form-check">
            <input
                class="form-check-input"
                type="checkbox"
                checked={item.in_percantage}
                onChange={(e) => {
                    const data = { ...item };
                    data["in_percantage"] = e.target.checked;
                    itemHandler(data);
                }}
                id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault">
                Value is in Percantage
            </label>
        </div>
    </div>
}


const AddBookingPricing = () => {
    const [pricingList, setPricingList] = useState([]);
    const [extraPricingList, setExtraPricingList] = useState([])
    const { bookingId, projectId } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projectPricing } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getProjectPricingList(projectId))
    }, [])

    const submitHandler = () => {
        const postData = {
            pricing_list: pricingList,
            extra_pricing_list: extraPricingList,
        };
        dispatch(addBookingPriceList(postData, navigate, bookingId))
    }


    return (
        <Layout pageTitle={'Add Pricing'}>
            <div className="d-grid gap-2 p-2 ">
                <div>
                    <label>Pricing List</label>
                    <select name="pricing_list" className='form-control' id="" multiple onChange={(e) => {
                        var options = e.target.options;
                        var value = [];
                        for (var i = 0, l = options.length; i < l; i++) {
                            if (options[i].selected) {
                                value.push(options[i].value);
                            }
                        }
                        setPricingList(value)
                    }} >

                        {projectPricing?.list?.map((item, index) => {
                            return <option key={index} value={item.id}>
                                {item.key} {item.value}{item.in_percantage && "%"}
                            </option>
                        })}
                    </select>
                </div>
                <div className="d-grid gap-2">
                    <div className='d-grid gap-2 p-2 border rounded ' style={{ maxHeight: "300px", overflowY: "auto" }} >
                        {extraPricingList.map((item) => {
                            return <ExtraPricingDetail item={item}
                                itemHandler={(e) => {
                                    setExtraPricingList(
                                        [...extraPricingList.filter((d) => d.id != e.id), e].sort(
                                            (a, b) => {
                                                return a.id - b.id;
                                            }
                                        )
                                    );
                                }}
                                removeHandler={() => {
                                    setExtraPricingList(extraPricingList.filter((d) => d.id !== item.id))
                                }}
                            />
                        })}

                    </div>
                    <button
                        className="btn btn-dark  "
                        onClick={() => {
                            setExtraPricingList([
                                ...extraPricingList,
                                {
                                    id:
                                        extraPricingList.length > 0
                                            ? extraPricingList[extraPricingList.length - 1].id + 1
                                            : extraPricingList.length,
                                    key: "",
                                    value: 0,
                                    in_percantage: false
                                },
                            ])
                        }}
                    >
                        Add Extra Pricing Detail
                    </button>
                </div>
                <div className="text-end" >
                    <button
                        className="btn btn-success  "
                        onClick={submitHandler}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </Layout>
    )
}

export default AddBookingPricing
