import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembers, removeTeamMember   } from "../../../Actions/AdminAction";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs, Accordion, Dropdown } from "react-bootstrap";

const AdminTeamMemeberView = () => {
	const { projectId, team } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { teamData, loading, error } = useSelector(
		(state) => state?.developer?.teamList
	);

	useEffect(() => {
		dispatch(getTeamMembers(team, projectId));
	}, []);

	if (teamData !== undefined) {
		return (
			<Layout pageTitle={"Team Member View"}>
				
				<div className="p-3  row">
					{teamData.map((employee) => {
						return (
							<div className="col-md-6 col-lg-4 p-3 position-relative ">
								<Dropdown
									className="position-absolute end-0 m-1 mx-3 "
									style={{ zIndex: "1" }}
								>
									<Dropdown.Toggle
										className="hide-arrow"
										variant=""
										id="dropdown-basic"
									>
										<FontAwesomeIcon icon={icon.faEllipsis} />
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item
											onClick={() => {
												dispatch(removeTeamMember(employee.id,team,projectId));
											}}
										>
											{employee.left_team ? "Add" : "Remove"}
										</Dropdown.Item>
										<Dropdown.Item
											onClick={() => {
												navigate(`/edit/team-member/${employee.id}/${projectId}`);
											}}
										>
											Edit
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<div className="p-3 border rounded   ">
									<div className="row">
										<div className="col-3">
											<img
												style={{
													width: "64px",
													aspectRatio: "1/1",
													objectFit: "contain",
												}}
												src={"https://api.eazyapp.in" + employee.employee.photo}
												alt=""
											/>
										</div>
										<div className="col-9">
											<p className="m-0 card_heading">
												{employee.employee.name}
											</p>
											<p className="m-0">{employee.designation}</p>
											<p className="m-0"><span className="text-muted">Reporting to:</span> {employee.report_to}</p>
											{employee.left_team ? <p className="m-0 text-danger ">Not in Team</p> : <p className="m-0 text-success">Working on Project</p>}
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</Layout>
		);
	}
};

export default AdminTeamMemeberView;
