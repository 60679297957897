import React from 'react'
import Layout from "./Layout/Layout"
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { genrateReport, get_projects } from "../../Actions/CommonAction";
import { useDispatch,useSelector } from 'react-redux';
const GenrateReport = () => {
    const [emails,setEmails]=React.useState([])
    const [focused, setFocused] = React.useState(false);
    const [projectList,setProjectList] = React.useState([])
    const dispatch = useDispatch();
    const date = new Date();
    const defaultValue = date.toLocaleDateString('en-CA');

    const {projects} = useSelector((state)=>state.project)

    React.useEffect(()=>{
        if(projects){
            setProjectList(projects.map(data=>{return {label:data.name,value:data.id}}))
        }
    },[projects])

    React.useEffect(() => {
       dispatch(get_projects()) 
    },[])
    const submitHandler=(e)=>{
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.append("emails", emails)
        dispatch(genrateReport(formData))
    }
    return (
        <Layout pageTitle={"EazyReport"}>
            <form action="" onSubmit={submitHandler} >
            <div className="p-2 row">
                <div className='col-md-6 p-2 '>
                        <label htmlFor="for_project">Projects</label>
                        <select className="form-select" name="for_project" id="for_project">
                        {projectList && projectList.map((data,index)=>{return <option key={index} value={data.value}>{data.label}</option>})}
                        </select>
                </div>
                <div className='col-md-6 p-2 '>
                    <label htmlFor="report_for">Report For</label>
                    <select className="form-select" name="report_for" id="report_for">
                        <option value="visitors">Visitors</option>
                        <option value="employee">Employee</option>
                        <option value="channel_partner">Channel Partner</option>
                    </select>
                </div>
                <div className='col-md-6 p-2 '>
                    <label htmlFor="start_date">Start Date</label>
                    <input type="date"  className='form-control'  name="start_date" id="start_date" />
                </div>
                <div className='col-md-6 p-2 '>
                    <label htmlFor="end_date">End Date</label>
                        <input type="date" className='form-control' name="end_date" id="end_date" value={defaultValue}  />
                </div>
                <div className='col-12 p-2 '>
                    <ReactMultiEmail
                        placeholder='Input your email'
                        emails={emails}
                        onChange={(_emails) => {
                            setEmails(_emails);
                        }}
                        autoFocus={true}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        getLabel={(email, index, removeEmail) => {
                            return (
                                <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            );
                        }}
                    />
                </div>
                <div className='p-3 col-12 text-center'>
                    <button type='submit' className='btn btn-primary ' >Genrate Report</button>
                </div>
            </div>
            </form>
        </Layout>
    )
}

export default GenrateReport
