import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import axios from 'axios'
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { AddAutomation as saveAutomation } from '../../../../Actions/AdminAction'


const ComponentHandler = ({ component, dataHandler }) => {
    const {projectId} = useParams();
    const [data,setData]=useState({
        type:component.type,
        parameters:[]
    });
    useEffect(()=>{
        dataHandler(data)
    },[data])

    switch(component.type) {
        case "HEADER":
            switch(component.format) {
                case "TEXT":
                    return (
                        <div>
                            {component.example.header_text?.map((d, index) => {
                                const newData = { ...data }
                                return <input type="text" className="form-control" onChange={(e) => {
                                    newData.parameters = [...newData.parameters.filter(d=>d.id !== index), { "type": "TEXT", "text": e.target.value ,id:index  }].filter(d => d !== undefined)
                                    setData(newData)
                                }} />
                            })}
                        </div>
                    )
                case "IMAGE":
                    return (
                        <div>
                            <input type="file" className="form-control" onChange={async (e) => {
                                const newData = { ...data }
                                
                                const formData = new FormData();
                                formData.append("file", e.target.files[0])
                                const response = await axios.post("https://api.eazyapp.in/api/upload-file/" + projectId, formData, { headers: { "Content-Type": "multipart/form-data" } })
                                newData.parameters = [{ "type": "IMAGE", "image": { "link": response.data.url } }].filter(d => d !== undefined)
                                setData(newData)
                            }} />
                        </div>
                    )
                case "VIDEO":
                    return (
                        <div>
                            <input type="file" className="form-control" onChange={async (e) => {
                                  const newData = { ...data }
                                
                                const formData = new FormData();
                                formData.append("file", e.target.files[0])
                                const response = await axios.post("https://api.eazyapp.in/api/upload-file/" + projectId, formData, { headers: { "Content-Type": "multipart/form-data" } })
                                newData.parameters = [{ "type": "VIDEO", "video": { "link": response.data.url } }].filter(d => d !== undefined )
                                setData(newData)
                            }} />
                        </div>
                    )
                case "DOCUMENT":
                    return (
                        <div>
                            <input type="file" className="form-control" onChange={async (e) => {
                                const newData = { ...data }
                               
                                const formData = new FormData();
                                formData.append("file", e.target.files[0])
                                const response = await axios.post("https://api.eazyapp.in/api/upload-file/"+projectId, formData, { headers: { "Content-Type": "multipart/form-data" } })
                                newData.parameters = [{ "type": "DOCUMENT", "document": { "link": response.data.url } }].filter(d => d !== undefined)

                                setData(newData)
                            }} />
                        </div>
                    )
                case "LOCATION":
                    return
            }
        case "BODY":
            if (component.example?.body_text !== undefined) {
                return component.example.body_text?.map((d, index) => {
                    const newData = { ...data }
                    return (
                    <div key={index}>
                        <label htmlFor={"#text_example" + (index + 1)}> Select Option for varaible {index + 1}</label>
                        <select className='form-select' name="text_example_name" id={"text_example" + (index + 1)} onChange={(e)=>{
                            
                                newData.parameters[index] = [newData.parameters.filter(d => d.id === index),{type:"TEXT",text:e.target.value,id:index}].filter(d => d !== undefined)
                                setData(newData)
                        }} >
                            <option value="">Select Option</option>
                            <option value="{visitor_name}">Visitor Name</option>
                            <option value="{channel_partner_name}">Channel Partner Name</option>
                        </select>
                    </div>
                    )
                })
            }
            return
        case "BUTTONS":
            return component.buttons?.map((button,index)=>{
                const newData = { ...data }
                switch(button.type) {
                    case "QUICK_REPLY":
                        return <></>
                    case "URL":
                        return <></>
                    case "PHONE_NUMBER":
                        return <></>
                    case "COPY_CODE":
                        return <>
                            <input type="text" className='form-control' placeholder='Enter Code' onChange={(e)=>{
                                newData.sub_type= "COPY_CODE"
                                newData.index= index 
                                newData.parameters = [newData.parameters.filter(d => d.id !== index),{ type: "coupon_code", coupon_code:e.target.value,id:index}].filter(d => d !== undefined)
                                setData(newData)
                            }}  />
                        </>
                }
            })
        case "CAROUSEL":
            return (
                <div>
                    {
                        component.cards?.map((card, index) => {
                            return <div>
                                <h3>Card {index + 1}</h3>
                                <div className="p-2">
                                    {card.components.map((component,componentIndex) => { return <ComponentHandler component={component}  dataHandler={(e)=>{
                                        const newData = {...data}
                                        if (newData?.cards === undefined) {
                                            newData.cards = []
                                            delete newData.parameters
                                        }
                                        const componentData={}
                                        componentData.card_index = index
                                        const filteredData =  newData.cards.filter(d => d.card_index === index)[0]?.components?.filter(i => i.type != e.type) || []
                                        componentData.components = [...filteredData, e].filter(d => d !== undefined)
                                        newData.cards=[...newData.cards.filter(d=> d.card_index !== index),componentData].filter(d => d !== undefined)
                                        setData(newData)
                                    }} /> })}
                                </div>
                            </div>
                        })
                    }
                </div>
            )
        default:
            return
    }
}

const TemplateVariableHandler = ({ templateData, dataHandler }) => {
    const [components, setComponents] = useState([])
    useEffect(()=>{
        dataHandler(components)
    },[components])
    if (templateData !== null) {
        return templateData.components.map((component) => { return <ComponentHandler component={component} dataHandler={(e)=>{
            setComponents([...components.filter(d => d.type != e.type),e])
        }}  /> })
    }

}


const AutomationHandler = ({ autoType }) => {
    const [templateName, setTemplateName] = useState(null);
    const [templateData, setTemplateData] = useState(null);
    const [whatsappData,setWhatsappData]=useState(null);
    const dispatch = useDispatch();

    const {whatsappAuth} = useSelector((state) => state?.developer?.whatsappAuth);

    useEffect(() => {
        if (templateName !== null) {
            const delay = 1000;
            const Debouncer = setTimeout(async () => {
                try {
                    const config = {
                        headers: {
                            "Content-type": "application/json",
                        },
                        params: { user_token: whatsappAuth.access_token, wba_id: whatsappAuth.wba_id },
                    }
                    const response = await axios.get(
                        `https://api.eazyapp.in/api/get/whatsapp-templates/${templateName}/`,
                        config
                    );
                    setTemplateData(response.data)
                } catch (error) {
                    toast.error("Something went wrong");
                }
            }, delay);
            return () => clearTimeout(Debouncer);
        }
    }, [templateName])

    switch (autoType) {
        case "Mail":
            return <div className='d-grid gap-4'>
                <input type="text" name="subject" placeholder="Subject" />
                <textarea name="body" ></textarea>
            </div>
        case "Message":
            return <div className="d-grid gap-4">
                <textarea name="body" ></textarea>
            </div>
        case "Whatsapp":
            return <div className="d-grid gap-4">
                <input type="text" name="template_name" placeholder="Template Name" className='form-control' onChange={(e) => {
                    e.target.value = e.target.value.replaceAll(" ", "_").toLowerCase()
                    const newData={...whatsappData}
                    newData.name = e.target.value
                    setTemplateName(e.target.value)
                    setWhatsappData(newData)
                }} />
                <input type="text" name="language" placeholder="Language" className='form-control' onChange={(e)=>{
                    const newData = { ...whatsappData }
                    newData.language = {code :e.target.value}
                    setWhatsappData(newData)
                }} />
                {
                    templateData !== null && <TemplateVariableHandler templateData={templateData} dataHandler={(e)=>{
                        const newData = {...whatsappData}
                        newData.components = e
                        setWhatsappData(newData)
                    }} />
                }
                <div className="text-end">
                    <button className="btn btn-success" onClick={()=>{
                        dispatch(saveAutomation(whatsappData))
                    }} >Save</button>
                </div>
            </div>
        default:
            return <></>
    }
}

const AddAutomation = () => {
    const [autoType, setAutoType] = useState("")

    return (
        <Layout pageTitle={"Add Automation"} >
            <div className="d-grid gap-4 p-4 ">
                <select name="" id="" className='form-control' onChange={(e) => { setAutoType(e.target.value) }} value={autoType}  >
                    {["Mail", "Message", "Whatsapp"].map((auto_type) => {
                        return <option value={auto_type}>{auto_type}</option>
                    })}
                </select>
                <AutomationHandler autoType={autoType} />
            </div>

        </Layout>
    )
}

export default AddAutomation
