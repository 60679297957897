import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const LightGallery = ({ slides, index, setShow, show }) => {
	return (
		<Lightbox
			index={index}
			slides={slides}
			open={show}
			close={() => setShow(false)}
		/>
	);
};

export default LightGallery;
