import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import toast from "react-hot-toast";
import axios from "../../../Actions/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { saveCheckInData } from '../../../Actions/CREAction'
const Source = () => {
	const { userInfo } = useSelector((state) => state?.userState);
	const [source, setSource] = useState("direct");
	const [addOrganization, setAddOrganization] = useState(false);
	const [addEmployee, setAddEmployee] = useState(false);
	const [reraNumber, setReraNumber] = useState(null);
	const [empEmail, setEmpEmail] = useState(null);
	const [organizationName, setOrganizationName] = useState(null);
	const [getEmployeeEmail, setGetEmployeeEmail] = useState(false);
	const [getEmployeeDetail, setGetEmployeeDetail] = useState(false);
	const [empFirstName, setEmpFirstName] = useState(null);
	const [empLastName, setEmpLastName] = useState(null);
	const [empMobileNumber, setEmpMobileNumber] = useState(null);
	const [getOrganizationName, setGetOrganizationName] = useState(false);
	const [empDetailExist, setEmpDetailExist] = useState(false)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { checkInData } = useSelector((state) => state?.cre?.checkInDetial);
	const moreQuestion = (source) => {
		switch (source) {
			case "direct":
				return (
					<>
						<div className="col-md-12">
							<label>Where Have You heard about the project</label>
							<select name="sub_source" id="" className="form-select">
								<option value="Social Media">Social Media</option>
								<option value="Google">Google</option>
								<option value="News Paper">News Paper</option>
								<option value="Hoardings">Hoardings</option>
								<option value="Advertisment">Advertisment</option>
								<option value="Direct Walk-In">Direct Walk-In</option>
								<option value="Word Of Mouth">Word Of Mouth</option>
							</select>
						</div>
					</>
				);
			case "reference":
				return <></>;
			case "channel_partner":
				return (
					<>
						<div className="col-md-12">
							<div className="row">
								<div className="col-12">
									<h4>Organization Detail</h4>
								</div>
								<div className="col-md-6">
									<label>Rera Number</label>
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											name="rera_number"
											onChange={(e) => {
												setReraNumber(e.target.value);
											}}
										/>
										<span
											className="btn btn-light h-100  rounded-start-0 "
											type="button"
											onClick={async () => {
												try {
													const config = {
														headers: {
															"Content-type": "application/json",
															Authorization: `Bearer ${userInfo.token}`,
														},
													};
													const { data } = await axios.post(
														"get/organization/",
														{ rera_number: reraNumber },
														config
													);
													setOrganizationName(data.name);
													setGetOrganizationName(true);
													setGetEmployeeEmail(true);
												} catch (error) {
													toast.error(error.response.data.error);
													setOrganizationName(null);
													setGetOrganizationName(true);
													setAddOrganization(true);
													setGetEmployeeDetail(true);
													setAddEmployee(true);
												}
											}}
										>
											<Icon.Search />
										</span>
									</div>
								</div>
								{getOrganizationName && (
									<div className="col-md-6">
										<label>Organization Name</label>
										{organizationName !== null ? (
											<input
												type="text"
												className="form-control"
												name="organization_name"
												value={organizationName}
												readonly
											/>
										) : (
											<input
												type="text"
												className="form-control"
												name="organization_name"
												onBlur={(e) => {
													if (e.target.value !== "") {
														setGetEmployeeEmail(true);
														return;
													}
													setGetEmployeeEmail(false);
													return;
												}}
											/>
										)}
									</div>
								)}
								{getEmployeeEmail && (
									//
									<>
										<div className="col-12">
											<h4>Employee Detail</h4>
										</div>
										<div className="col-md-6">
											<label>Employee Email</label>
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													name="emp_email"
													onChange={(e) => {
														setEmpEmail(e.target.value);
													}}
												/>
												<span
													className="btn btn-light h-100  rounded-start-0 "
													type="button"
													onClick={async () => {
														try {
															const config = {
																headers: {
																	"Content-type": "application/json",
																	Authorization: `Bearer ${userInfo.token}`,
																},
															};
															const { data } = await axios.post(
																"get/organization-employee/",
																{
																	rera_number: reraNumber,
																	emp_email: empEmail,
																},
																config
															);
															// setEmployeeName(data.first_name+" "+data.last_name);
															setEmpFirstName(data.first_name);
															setEmpLastName(data.last_name);
															setEmpMobileNumber(data.phone_number);
															setGetEmployeeDetail(true);
															setEmpDetailExist(true);
														} catch (error) {
															toast.error(error.response.data.error);
															setAddEmployee(true);
															setGetEmployeeDetail(true);
														}
													}}
												>
													<Icon.Search />
												</span>
											</div>
										</div>
										{getEmployeeDetail && (
											<>
												<div className="col-md-6">
													<label> Employee First Name </label>
													{empDetailExist ? (
														<input
															type="text"
															name="emp_first_name"
															value={empFirstName}
															className="form-control"
															readonly
														/>
													) : (
														<input
															type="text"
															name="emp_first_name"
															className="form-control"
														/>
													)}
												</div>
												<div className="col-md-6">
													<label> Employee Last Name </label>
													{empDetailExist ? (
														<input
															type="text"
															name="emp_last_name"
															value={empLastName}
															className="form-control"
															readonly
														/>
													) : (
														<input
															type="text"
															name="emp_last_name"
															className="form-control"
														/>
													)}
												</div>
												<div className="col-md-6">
													<label> Employee Mobile Number </label>
													{empDetailExist ? (
														<PhoneInput
															country={"in"}
															countryCodeEditable={false}
															placeholder="Enter phone number"
															value={empMobileNumber}
															enableSearch={true}
															containerClass="my-container-class"
															inputProps={{
																name: "emp_phone_number",
																class: "form-control",
															}}
															// className="form-control"
															inputClass="form-control"
															onChange={setEmpMobileNumber}
															disabled={true}
															disableDropdown={true}
														/>
													) : (
														<PhoneInput
															country={"in"}
															countryCodeEditable={false}
															placeholder="Enter phone number"
															value={empMobileNumber}
															enableSearch={true}
															inputProps={{
																name: "emp_phone_number",
																class: "form-control",
															}}
															containerClass="my-container-class"
															// className="form-control"
															inputClass="form-control"
															onChange={setEmpMobileNumber}
														/>
													)}
												</div>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</>
				);
			default:
				return <></>;
		}
	};
	const submitHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		object["add_organization"]=addOrganization
		object["add_employee"]=addEmployee
		object["emp_phone_number"] = empMobileNumber;
		const postData={...checkInData,source:object}
		dispatch(saveCheckInData(postData,navigate));
		// navigate("/check-in/work-info");
	};
	return (
		<div className="h-100">
			<form action="" className="h-100" onSubmit={submitHandler}>
				<div className="row px-2">
					<div className="col-md-12">
						<label>Select Source</label>
						<div
							class=" d-flex gap-4 "
							role="group"
							aria-label="Basic radio toggle button group"
						>
							<input
								type="radio"
								class="btn-check"
								name="source"
								value="Direct"
								id="btnradio1"
								autocomplete="off"
								checked={source === "direct"}
							/>
							<label
								class="btn btn-outline-warning"
								for="btnradio1"
								onClick={() => {
									setSource("direct");
								}}
							>
								Direct
							</label>
							<input
								type="radio"
								class="btn-check"
								name="source"
								value="Refrence"
								id="btnradio2"
								autocomplete="off"
								checked={source === "reference"}
							/>
							<label
								class="btn btn-outline-warning"
								for="btnradio2"
								onClick={() => {
									setSource("reference");
								}}
							>
								Reference
							</label>
						</div>
					</div>
					{moreQuestion(source)}
				</div>
				<div className="row mt-5 px-5">
					<div className="col-md-4"></div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<button className="btn btn-success w-100 ">Next</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Source;
