import axios from "./axios";
import {
	GET_DEVELOPER_PROJECTS_LIST_REQUEST,
	GET_DEVELOPER_PROJECTS_LIST_SUCCESS,
	GET_DEVELOPER_PROJECTS_LIST_FAIL,
	//
	GET_PROJECT_DETAIL_REQUEST,
	GET_PROJECT_DETAIL_SUCCESS,
	GET_PROJECT_DETAIL_FAIL,
	//
	UPDATE_PROJECT_DETAIL_REQUEST,
	UPDATE_PROJECT_DETAIL_SUCCESS,
	UPDATE_PROJECT_DETAIL_FAIL,
	//
	GET_CONFIG_DETAIL_REQUEST,
	GET_CONFIG_DETAIL_SUCCESS,
	GET_CONFIG_DETAIL_FAIL,
	//
	EDIT_CONFIG_DETAIL_REQUEST,
	EDIT_CONFIG_DETAIL_SUCCESS,
	EDIT_CONFIG_DETAIL_FAIL,
	//
	ADD_CONFIG_DETAIL_REQUEST,
	ADD_CONFIG_DETAIL_SUCCESS,
	ADD_CONFIG_DETAIL_FAIL,
	//
	DELETE_CONFIG_REQUEST,
	DELETE_CONFIG_SUCCESS,
	DELETE_CONFIG_FAIL,
	//
	GET_GALLERY_DETAILS_REQUEST,
	GET_GALLERY_DETAILS_SUCCESS,
	GET_GALLERY_DETAILS_FAIL,
	//
	ADD_GALLERY_ITEM_REQUEST,
	ADD_GALLERY_ITEM_SUCCESS,
	ADD_GALLERY_ITEM_FAIL,
	//
	DELETE_GALLERY_ITEM_REQUEST,
	DELETE_GALLERY_ITEM_SUCCESS,
	DELETE_GALLERY_ITEM_FAIL,
	//
	EDIT_GALLERY_DETAIL_REQUEST,
	EDIT_GALLERY_DETAIL_SUCCESS,
	EDIT_GALLERY_DETAIL_FAIL,
	//
	REMOVE_AMENITIE_REQUEST,
	REMOVE_AMENITIE_SUCCESS,
	REMOVE_AMENITIE_FAIL,
	//
	GET_DEVELOPER_AMENITIES_REQUEST,
	GET_DEVELOPER_AMENITIES_SUCCESS,
	GET_DEVELOPER_AMENITIES_FAIL,
	//
	UPDATE_AMENITIE_LIST_REQUEST,
	UPDATE_AMENITIE_LIST_SUCCESS,
	UPDATE_AMENITIE_LIST_FAIL,
	//
	GET_DOCUMENT_DETAIL_REQUEST,
	GET_DOCUMENT_DETAIL_SUCCESS,
	GET_DOCUMENT_DETAIL_FAIL,
	//
	UPLOAD_DOCUMENT_REQUEST,
	UPLOAD_DOCUMENT_SUCCESS,
	UPLOAD_DOCUMENT_FAIL,
	//
	DELETE_DOCUMENT_REQUEST,
	DELETE_DOCUMENT_SUCCESS,
	DELETE_DOCUMENT_FAIL,
	//
	UPDATE_DOCUMENT_DETAILS_REQUEST,
	UPDATE_DOCUMENT_DETAILS_SUCCESS,
	UPDATE_DOCUMENT_DETAILS_FAIL,
	//
	DEVELOPER_EMPLOYEES_REQUEST,
	DEVELOPER_EMPLOYEES_SUCCESS,
	DEVELOPER_EMPLOYEES_FAIL,
	//
	ADD_NEW_EMPLOYEES_REQUEST,
	ADD_NEW_EMPLOYEES_SUCCESS,
	ADD_NEW_EMPLOYEES_FAIL,
	//
	DELETE_DEVELOPER_EMPLOYEE_REQUEST,
	DELETE_DEVELOPER_EMPLOYEE_SUCCESS,
	DELETE_DEVELOPER_EMPLOYEE_FAIL,
	//
	GET_TEAM_MEMBERS_REQUEST,
	GET_TEAM_MEMBERS_SUCCESS,
	GET_TEAM_MEMBERS_FAIL,
	//
	ADD_NEW_TEAM_MEMBER_REQUEST,
	ADD_NEW_TEAM_MEMBER_SUCCESS,
	ADD_NEW_TEAM_MEMBER_FAIL,
	//
	REMOVE_TEAM_MEMBER_REQUEST,
	REMOVE_TEAM_MEMBER_SUCCESS,
	REMOVE_TEAM_MEMBER_FAIL,
	//
	GET_TEAM_MEMBER_DETAIL_REQUEST,
	GET_TEAM_MEMBER_DETAIL_SUCCESS,
	GET_TEAM_MEMBER_DETAIL_FAIL,
	//
	EDIT_TEAM_MEMBER_DETAIL_REQUEST,
	EDIT_TEAM_MEMBER_DETAIL_SUCCESS,
	EDIT_TEAM_MEMBER_DETAIL_FAIL,
	//
	DEVELOPER_DETAIL_REQUEST,
	DEVELOPER_DETAIL_SUCCESS,
	DEVELOPER_DETAIL_FAIL,
	//
	EDIT_DEVELOPER_DETAIL_REQUEST,
	EDIT_DEVELOPER_DETAIL_SUCCESS,
	EDIT_DEVELOPER_DETAIL_FAIL,
	//
	GET_DEVELOPER_DASHBOARD_REQUEST,
	GET_DEVELOPER_DASHBOARD_SUCCESS,
	GET_DEVELOPER_DASHBOARD_FAIL,
	//
	GET_DEVELOEPR_CHANNEL_PARTNER_REQUEST,
	GET_DEVELOEPR_CHANNEL_PARTNER_SUCCESS,
	GET_DEVELOEPR_CHANNEL_PARTNER_FAIL,
	//
	DEVELOPER_CHANNEL_PARTNER_DETAIL_REQUEST,
	DEVELOPER_CHANNEL_PARTNER_DETAIL_SUCCESS,
	DEVELOPER_CHANNEL_PARTNER_DETAIL_FAIL,
	//
	SAVE_INVENTORY_DATA_REQUEST,
	SAVE_INVENTORY_DATA_SUCCESS,
	SAVE_INVENTORY_DATA_FAIL,
	//
	EDIT_INVENTORY_DATA_REQUEST,
	EDIT_INVENTORY_DATA_SUCCESS,
	EDIT_INVENTORY_DATA_FAIL,
	//
	GET_PROJECT_PAYMENT_SCHEDULE_REQUEST,
	GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS,
	GET_PROJECT_PAYMENT_SCHEDULE_FAIL,
	//
	ADD_PROJECT_PAYMENT_PLAN_REQUEST,
	ADD_PROJECT_PAYMENT_PLAN_SUCCESS,
	ADD_PROJECT_PAYMENT_PLAN_FAIL,
	//
	GET_PAYMENT_SLAB_DATA_REQUEST,
	GET_PAYMENT_SLAB_DATA_SUCCESS,
	GET_PAYMENT_SLAB_DATA_FAIL,
	//
	UPDATE_PAYMENT_SLAB_DATA_REQUEST,
	UPDATE_PAYMENT_SLAB_DATA_SUCCESS,
	UPDATE_PAYMENT_SLAB_DATA_FAIL,
	//
	GET_PAYMENT_SCHEDULE_REQUEST,
	GET_PAYMENT_SCHEDULE_SUCCESS,
	GET_PAYMENT_SCHEDULE_FAIL,
	//
	DELETE_PAYMENT_SLAB_REQUEST,
	DELETE_PAYMENT_SLAB_SUCCESS,
	DELETE_PAYMENT_SLAB_FAIL,
	//
	DELETE_PAYMENT_SCHEDULE_REQUEST,
	DELETE_PAYMENT_SCHEDULE_SUCCESS,
	DELETE_PAYMENT_SCHEDULE_FAIL,
	//
	GET_PROJECT_PRICING_LIST_REQUEST,
	GET_PROJECT_PRICING_LIST_SUCCESS,
	GET_PROJECT_PRICING_LIST_FAIL,
	//
	ADD_PROJECT_PRICING_LIST_REQUEST,
	ADD_PROJECT_PRICING_LIST_SUCCESS,
	ADD_PROJECT_PRICING_LIST_FAIL,
	//
	GET_PRICING_DETAILS_REQUEST,
	GET_PRICING_DETAILS_SUCCESS,
	GET_PRICING_DETAILS_FAIL,
	// 
	UPDATE_PRICING_DETAIL_REQUEST,
	UPDATE_PRICING_DETAIL_SUCCESS,
	UPDATE_PRICING_DETAIL_FAIL,
	// 
	DELETE_PRICING_DETAIL_REQUEST,
	DELETE_PRICING_DETAIL_SUCCESS,
	DELETE_PRICING_DETAIL_FAIL,
	// 
	GET_PROJECT_OFFER_LIST_REQUEST,
	GET_PROJECT_OFFER_LIST_SUCCESS,
	GET_PROJECT_OFFER_LIST_FAIL,
	// 
	ADD_PROJECT_OFFERS_REQUEST,
	ADD_PROJECT_OFFERS_SUCCESS,
	ADD_PROJECT_OFFERS_FAIL,
	// 
	GET_OFFER_DETAIL_REQUEST,
	GET_OFFER_DETAIL_SUCCESS,
	GET_OFFER_DETAIL_FAIL,
	// 
	UPDATE_OFFER_DETAIL_REQUEST,
	UPDATE_OFFER_DETAIL_SUCCESS,
	UPDATE_OFFER_DETAIL_FAIL,
	// 
	DELETE_OFFER_DETAIL_REQUEST,
	DELETE_OFFER_DETAIL_SUCCESS,
	DELETE_OFFER_DETAIL_FAIL,
	// 
	GET_INVENTORY_DATA_REQUEST,
	GET_INVENTORY_DATA_SUCCESS,
	GET_INVENTORY_DATA_FAIL,
	// 
	DELETE_INVENTORY_REQUEST,
	DELETE_INVENTORY_SUCCESS,
	DELETE_INVENTORY_FAIL,
	// 
	EDIT_DEVELOPER_SETTING_REQUEST,
	EDIT_DEVELOPER_SETTING_SUCCESS,
	EDIT_DEVELOPER_SETTING_FAIL,
	// 
	GET_DEVELOPER_SETTING_REQUEST,
	GET_DEVELOPER_SETTING_SUCCESS,
	GET_DEVELOPER_SETTING_FAIL,
	// 
	DELETE_DEVELOPER_SETTING_REQUEST,
	DELETE_DEVELOPER_SETTING_SUCCESS,
	DELETE_DEVELOPER_SETTING_FAIL,
	// 
	GET_EMPLOYEE_DETAIL_REQUEST,
	GET_EMPLOYEE_DETAIL_SUCCESS,
	GET_EMPLOYEE_DETAIL_FAIL,
	// 
	EDIT_EMPLOYEE_DETAIL_REQUEST,
	EDIT_EMPLOYEE_DETAIL_SUCCESS,
	EDIT_EMPLOYEE_DETAIL_FAIL,
	// 
	EDIT_PAYMENT_SCHEDULE_REQUEST,
	EDIT_PAYMENT_SCHEDULE_SUCCESS,
	EDIT_PAYMENT_SCHEDULE_FAIL,
	// 
	GET_CUSTOMER_BOOKING_LIST_REQUEST,
	GET_CUSTOMER_BOOKING_LIST_SUCCESS,
	GET_CUSTOMER_BOOKING_LIST_FAIL,
	// 
	GET_CUSTOMER_BOOKING_DETAIL_REQUEST,
	GET_CUSTOMER_BOOKING_DETAIL_SUCCESS,
	GET_CUSTOMER_BOOKING_DETAIL_FAIL,
	// 
	GET_PROJECT_CONFIGS_REQUEST,
	GET_PROJECT_CONFIGS_SUCCESS,
	GET_PROJECT_CONFIGS_FAIL,
	// 
	BOOK_UNIT_REQUEST,
	BOOK_UNIT_SUCCESS,
	BOOK_UNIT_FAIL,
	// 
	ADD_BOOKING_PRICING_LIST_REQUEST,
	ADD_BOOKING_PRICING_LIST_SUCCESS,
	ADD_BOOKING_PRICING_LIST_FAIL,
	// 
	EDIT_PROJECT_DOMAIN_SETTING_REQUEST,
	EDIT_PROJECT_DOMAIN_SETTING_SUCCESS,
	EDIT_PROJECT_DOMAIN_SETTING_FAIL,
	// 
	GET_PROJECT_AUTOMATION_LIST_REQUEST,
	GET_PROJECT_AUTOMATION_LIST_SUCCESS,
	GET_PROJECT_AUTOMATION_LIST_FAIL,
	// 
	SAVE_PROJECT_AUTOMATION_REQUEST,
	SAVE_PROJECT_AUTOMATION_SUCCESS,
	SAVE_PROJECT_AUTOMATION_FAIL,
	// 
	DELETE_PROJECT_AUTOMATION_REQUEST,
	DELETE_PROJECT_AUTOMATION_SUCCESS,
	DELETE_PROJECT_AUTOMATION_FAIL,
	// 
	GET_WHATSAPP_TEMPLATES_REQUEST,
	GET_WHATSAPP_TEMPLATES_SUCCESS,
	GET_WHATSAPP_TEMPLATES_FAIL,
	// 
	ADD_AUTOMATION_REQUEST,
	ADD_AUTOMATION_SUCCESS,
	ADD_AUTOMATION_FAIL,
	// 
	GET_WHATSAPP_AUTH_REQUEST,
	GET_WHATSAPP_AUTH_SUCCESS,
	GET_WHATSAPP_AUTH_FAIL,
	// 
} from "../Constants/AdminConstants";
import { get_sale_reports } from "./CommonAction";
import { GET_DETAIL_CHANNEL_PARTNER_REQUEST, GET_INVENTORY_REQUEST } from "../Constants/SEConstatant";
import toast from "react-hot-toast";


export const getDeveloperProjects =
	(params = { page: 1 }) =>
		async (dispatch, getState) => {
			try {
				dispatch({
					type: GET_DEVELOPER_PROJECTS_LIST_REQUEST,
				});

				const {
					userState: { userInfo },
				} = getState();

				const config = {
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${userInfo?.token}`,
					},
					params: params,
				};

				const { data } = await axios.get("get/developer-projects/", config);
				dispatch({
					type: GET_DEVELOPER_PROJECTS_LIST_SUCCESS,
					payload: data,
				});
			} catch (error) {

				dispatch({
					type: GET_DEVELOPER_PROJECTS_LIST_FAIL,
					payload:
						error.response && error.response.data.error
							? error.response.data.error
							: error.message,
				});
			}
		};

export const getProjectDetail = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PROJECT_DETAIL_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(
			`get/developer-project-detail/${projectId}`,
			config
		);

		dispatch({
			type: GET_PROJECT_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PROJECT_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const updateProjectDetail =
	(postData, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPDATE_PROJECT_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.put(
				`update/developer-project/${projectId}`,
				postData,
				config
			);
			dispatch(getProjectDetail(projectId))
			toast.success("Project Updated Successfully")
			dispatch({
				type: UPDATE_PROJECT_DETAIL_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: UPDATE_PROJECT_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getConfigDetail = (configId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_CONFIG_DETAIL_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(`/get/config-detail/${configId}`, config);

		dispatch({
			type: GET_CONFIG_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_CONFIG_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const editConfigDetail =
	(postData, configId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: EDIT_CONFIG_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.put(
				`update/config-detail/${configId}`,
				postData,
				config
			);
			toast.success("Config Updated Successfully")
			// dispatch(getProjectDetail(projectId))
			dispatch({
				type: EDIT_CONFIG_DETAIL_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: EDIT_CONFIG_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const addNewConfigDetail =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_CONFIG_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post("add/config", postData, config);
			toast.success("Config Added Successfully")
			dispatch({
				type: ADD_CONFIG_DETAIL_SUCCESS,
				payload: data,
			});
			// dispatch()
			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_CONFIG_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const deleteConfig =
	(configId, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_CONFIG_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.delete(`delete/config/${configId}`, config);
			toast.success("Config Deleted Successfully")
			dispatch({
				type: DELETE_CONFIG_SUCCESS,
				payload: data,
			});
			dispatch(getProjectDetail(projectId));
		} catch (error) {
			dispatch({
				type: DELETE_CONFIG_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getGalleryDetail = (galleryId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_GALLERY_DETAILS_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(`get/gallery-detail/${galleryId}`, config);

		dispatch({
			type: GET_GALLERY_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_GALLERY_DETAILS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const addGalleryItem =
	(postData, navigate, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_GALLERY_ITEM_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};


			const { data } = await axios.post(
				`add/gallery-item/${projectId}`,
				postData,
				config
			);
			toast.success("Gallery Item Added Successfully")
			dispatch({
				type: ADD_GALLERY_ITEM_SUCCESS,
				payload: data,
			});
			dispatch(getProjectDetail(projectId));
			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_GALLERY_ITEM_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const deleteGalleryItem =
	(galleryId, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_GALLERY_ITEM_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.delete(
				`delete/gallery-item/${galleryId}`,
				config
			);

			toast.success("Gallery Item Deleted Successfully")

			dispatch({
				type: DELETE_GALLERY_ITEM_SUCCESS,
				payload: data,
			});

			dispatch(getProjectDetail(projectId));
		} catch (error) {
			dispatch({
				type: DELETE_GALLERY_ITEM_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const editGalleryDetail =
	(postData, navigate, galleryId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: EDIT_GALLERY_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.put(
				`edit/gallery-detail/${galleryId}`,
				postData,
				config
			);
			toast.success("Gallery Item Updated Successfully")
			// dispatch(getProjectDetail(projectId));
			dispatch({
				type: EDIT_GALLERY_DETAIL_SUCCESS,
				payload: data,
			});

			navigate(-1);
		} catch (error) {
			dispatch({
				type: EDIT_GALLERY_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const removeAmenitie =
	(postData, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: REMOVE_AMENITIE_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.patch(
				`remove/amenitie/${projectId}`,
				postData,
				config
			);
			toast.success("Amenitie Removed Successfully")
			dispatch(getProjectDetail(projectId));
			dispatch({
				type: REMOVE_AMENITIE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: REMOVE_AMENITIE_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getDeveloperAmenities =
	(projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_DEVELOPER_AMENITIES_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/developer-amenities/${projectId}`,
				config
			);

			dispatch({
				type: GET_DEVELOPER_AMENITIES_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_DEVELOPER_AMENITIES_FAIL,
				error:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const updateAmenitieList =
	(postData, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPDATE_AMENITIE_LIST_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.patch(
				`update/amenitie-list/${projectId}`,
				postData,
				config
			);
			toast.success("Amenitie List Updated Successfully")
			dispatch({
				type: UPDATE_AMENITIE_LIST_SUCCESS,
				payload: data,
			});
			dispatch(getDeveloperAmenities(projectId));
		} catch (error) {
			dispatch({
				type: UPDATE_AMENITIE_LIST_FAIL,
				error:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getDocumentDetail = (documentId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_DOCUMENT_DETAIL_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "applcation/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(
			`get/document-detail/${documentId}`,
			config
		);

		dispatch({
			type: GET_DOCUMENT_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_DOCUMENT_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const uploadNewDocument =
	(postData, projectId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPLOAD_DOCUMENT_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				`add/document/${projectId}`,
				postData,
				config
			);
			toast.success("Document Added Successfully")

			dispatch({
				type: UPLOAD_DOCUMENT_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: UPLOAD_DOCUMENT_FAIL,
				error:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const deleteDocument =
	(documentId, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_DOCUMENT_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.delete(
				`delete/document/${documentId}`,
				config
			);
			toast.success("Document Deleted Successfully")
			dispatch({
				type: DELETE_DOCUMENT_SUCCESS,
				payload: data,
			});

			dispatch(getProjectDetail(projectId));
			// toast.success(data);
		} catch (error) {
			dispatch({
				type: DELETE_DOCUMENT_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const updateDocumentDetail =
	(postData, documentId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPDATE_DOCUMENT_DETAILS_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.put(
				`update/document-detail/${documentId}`,
				postData,
				config
			);
			toast.success("Document Updated Successfully")

			dispatch({
				type: UPDATE_DOCUMENT_DETAILS_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: UPDATE_DOCUMENT_DETAILS_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getDeveloperEmployees = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: DEVELOPER_EMPLOYEES_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get("get/developer-employees/", config);

		dispatch({
			type: DEVELOPER_EMPLOYEES_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: DEVELOPER_EMPLOYEES_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const addNewEmployee =
	(postdata, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_NEW_EMPLOYEES_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				"add/developer-employee/",
				postdata,
				config
			);

			toast.success("Employee Added Successfully")

			dispatch({
				type: ADD_NEW_EMPLOYEES_SUCCESS,
				payload: data,
			});

			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_NEW_EMPLOYEES_FAIL,
				error:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const deletedeveloperEmployee =
	(employeeId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_DEVELOPER_EMPLOYEE_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.delete(
				`delete/developer-employee/${employeeId}`,
				config
			);

			toast.success("Employee Deleted Successfully")

			dispatch({
				type: DELETE_DEVELOPER_EMPLOYEE_SUCCESS,
				payload: data,
			});

			dispatch(getDeveloperEmployees());
		} catch (error) {
			dispatch({
				type: DELETE_DEVELOPER_EMPLOYEE_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getTeamMembers =
	(team, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_TEAM_MEMBERS_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
				params: {
					project_id: projectId,
				},
			};

			const { data } = await axios.get(`get/team-members/${team}`, config);

			dispatch({
				type: GET_TEAM_MEMBERS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_TEAM_MEMBERS_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const addTeamMember = (postData, navigate) => {
	return async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_NEW_TEAM_MEMBER_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				"add/new-team-member/",
				postData,
				config
			);

			dispatch({
				type: ADD_NEW_TEAM_MEMBER_SUCCESS,
				payload: data,
			});
			toast.success(data);
			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_NEW_TEAM_MEMBER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};
};

export const removeTeamMember =
	(teamMemberId, team, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: REMOVE_TEAM_MEMBER_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.delete(
				`remove/team-member/${teamMemberId}`,
				config
			);

			toast.success("Removed From Team");

			dispatch({
				type: REMOVE_TEAM_MEMBER_SUCCESS,
				payload: data,
			});
			toast.success("Removed From Team");
			dispatch(getTeamMembers(team, projectId));
		} catch (error) {
			dispatch({
				type: REMOVE_TEAM_MEMBER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getTeamMemberDetail =
	(teamMemberId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_TEAM_MEMBER_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/team-member-detail/${teamMemberId}`,
				config
			);

			dispatch({
				type: GET_TEAM_MEMBER_DETAIL_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_TEAM_MEMBER_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const editTeamMemberDetail =
	(postData, teamMemberId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: EDIT_TEAM_MEMBER_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.put(
				`edit/team-member-detail/${teamMemberId}`,
				postData,
				config
			);
			toast.success("Team Member Updated Successfully");
			dispatch({
				type: EDIT_TEAM_MEMBER_DETAIL_SUCCESS,
				payload: data,
			});

			navigate(-1);
		} catch (error) {
			dispatch({
				type: EDIT_TEAM_MEMBER_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getDeveloperDetails = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: DEVELOPER_DETAIL_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get("view/developer-detail/", config);

		dispatch({
			type: DEVELOPER_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: DEVELOPER_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const editDeveloperDetail =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: EDIT_DEVELOPER_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				"edit/developer-detail/",
				postData,
				config
			);

			toast.success("Developer Updated Successfully");

			dispatch({
				type: EDIT_DEVELOPER_DETAIL_SUCCESS,
				payload: data,
			});
			// dispatch(getDeveloperDetails());
			navigate(-1);
		} catch (error) {
			dispatch({
				type: EDIT_DEVELOPER_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getDeveloperDashboard = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_DEVELOPER_DASHBOARD_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get("get/developer-dashboard/", config);

		dispatch({
			type: GET_DEVELOPER_DASHBOARD_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_DEVELOPER_DASHBOARD_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getDeveloperChannelPartner =
	(params) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_DEVELOEPR_CHANNEL_PARTNER_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
				params: params,
			};
			const { data } = await axios.get(
				"get/developer-channel-partners/",
				config
			);
			dispatch({
				type: GET_DEVELOEPR_CHANNEL_PARTNER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_DEVELOEPR_CHANNEL_PARTNER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getdeveloperChannelPartnerDetail =
	(cpId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DEVELOPER_CHANNEL_PARTNER_DETAIL_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/developer-channel-partner/${cpId}`,
				config
			);

			dispatch({
				type: DEVELOPER_CHANNEL_PARTNER_DETAIL_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DEVELOPER_CHANNEL_PARTNER_DETAIL_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const saveInventory = (postData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: SAVE_INVENTORY_DATA_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.post("save/inventory-data/", postData, config);

		toast.success("Inventory Added Successfully");

		dispatch({
			type: SAVE_INVENTORY_DATA_SUCCESS,
			payload: data,
		});
		toast.success("Inventory Added Successfully");
	} catch (error) {
		dispatch({
			type: SAVE_INVENTORY_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const editInventoryData = (postData, inventoryId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: EDIT_INVENTORY_DATA_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();


		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};


		const { data } = await axios.put(
			`edit/inventory-data/${inventoryId}`,
			postData,
			config
		);


		toast.success("Inventory Updated Successfully");

		dispatch({
			type: GET_INVENTORY_DATA_SUCCESS,
			payload: postData
		})


		dispatch({
			type: EDIT_INVENTORY_DATA_SUCCESS,
			payload: data,
		});
		toast.success("Inventory Updated Successfully");
	} catch (error) {
		dispatch({
			type: EDIT_INVENTORY_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getPaymentScheduleList =
	(projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_PROJECT_PAYMENT_SCHEDULE_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/developer-payment-schedule/${projectId}`,
				config
			);

			dispatch({
				type: GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_PROJECT_PAYMENT_SCHEDULE_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const addPaymentPlan =
	(postData, projectId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_PROJECT_PAYMENT_PLAN_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				`add/developer-payment-schedule/${projectId}`,
				postData,
				config
			);
			toast.success("Payment Plan Added Successfully");
			dispatch({
				type: ADD_PROJECT_PAYMENT_PLAN_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_PROJECT_PAYMENT_PLAN_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getPaymentSlab = (slabId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PAYMENT_SLAB_DATA_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(`get/payment-slab/${slabId}`, config);

		dispatch({
			type: GET_PAYMENT_SLAB_DATA_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PAYMENT_SLAB_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const updatePaymentSlab =
	(postData, slabId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: UPDATE_PAYMENT_SLAB_DATA_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.put(
				`update/payment-slab/${slabId}`,
				postData,
				config
			);

			toast.success("Payment Slab Updated Successfully");

			dispatch({
				type: UPDATE_PAYMENT_SLAB_DATA_SUCCESS,
				payload: data,
			});

			navigate(-1);
		} catch (error) {
			dispatch({
				type: UPDATE_PAYMENT_SLAB_DATA_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getPaymentSchedule =
	(scheduleId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_PAYMENT_SCHEDULE_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/payment-schedule/${scheduleId}`,
				config
			);

			dispatch({
				type: GET_PAYMENT_SCHEDULE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_PAYMENT_SCHEDULE_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};



export const editPaymentSchedule = (scheduleId, postData, navigate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: EDIT_PAYMENT_SCHEDULE_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.put(`edit/payment-schedule/${scheduleId}`, postData, config);

		toast.success("Payment Schedule Updated Successfully")

		dispatch({
			type: EDIT_PAYMENT_SCHEDULE_SUCCESS,
			payload: data
		})
		navigate(-1)

	} catch (error) {
		dispatch({
			type: EDIT_PAYMENT_SCHEDULE_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		})
	}
}



export const deletePaymentSlab = (slabId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: DELETE_PAYMENT_SLAB_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.delete(
			`delete/payment-slab/${slabId}`,
			config
		);

		toast.success("Payment Slab Deleted Successfully");

		dispatch({
			type: DELETE_PAYMENT_SLAB_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: DELETE_PAYMENT_SLAB_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const deletePaymentSchedule =
	(scheduleId, projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_PAYMENT_SCHEDULE_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.delete(`delete/payment-schedule/${scheduleId}`, config);

			toast.success("Payment Schedule Deleted Successfully");

			dispatch(getPaymentScheduleList(projectId))

			dispatch({
				type: DELETE_PAYMENT_SCHEDULE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_PAYMENT_SCHEDULE_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getProjectPricingList =
	(projectId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_PROJECT_PRICING_LIST_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.get(
				`get/project-pricing-list/${projectId}`,
				config
			);
			dispatch({
				type: GET_PROJECT_PRICING_LIST_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_PROJECT_PRICING_LIST_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const addProjectPricing =
	(postData, projectId, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_PROJECT_PRICING_LIST_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				`add/project-pricing-list/${projectId}`,
				postData,
				config
			);

			toast.success("Pricing Added Successfully");

			dispatch({
				type: ADD_PROJECT_PRICING_LIST_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_PROJECT_PRICING_LIST_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};



export const getPricingDetails = (pricingId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PRICING_DETAILS_REQUEST
		})
		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get/project-pricing/${pricingId}`, config);

		dispatch({
			type: GET_PRICING_DETAILS_SUCCESS,
			payload: data
		})
	} catch (error) {
		dispatch({
			type: GET_PRICING_DETAILS_FAIL,
			payload: error?.response && error?.response?.data?.error ? error?.response?.data?.error : error.message,
		})
	}
}

export const updatePricingDetails = (postData, pricingId, navigate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: UPDATE_PRICING_DETAIL_REQUEST
		})
		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.put(`update/project-pricing/${pricingId}`, postData, config)

		toast.success("Pricing Updated Successfully")

		dispatch({
			type: UPDATE_PRICING_DETAIL_SUCCESS,
			payload: data
		})

		navigate(-1)

	} catch (error) {
		dispatch({
			type: UPDATE_PRICING_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error ? error?.response?.data?.error : error.message,
		})
	}
}


export const deletePricingDetail = (pricingId, projectId) => async (dispatch, getState) => {
	try {

		dispatch({
			type: DELETE_PRICING_DETAIL_REQUEST
		})

		const { userState: { userInfo } } = getState()

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.delete(`delete/project-pricing/${pricingId}`, config)

		toast.success("Pricing Deleted Successfully")

		dispatch({
			type: DELETE_PRICING_DETAIL_SUCCESS,
			payload: data
		})

		dispatch(getProjectPricingList(projectId))

	} catch (error) {

		dispatch({
			type: DELETE_PRICING_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error ? error?.response?.data?.error : error.message,
		})

	}
}


export const getProjectOfferList = (projectId) => async (dispatch, getState) => {
	try {

		dispatch({
			type: GET_PROJECT_OFFER_LIST_REQUEST
		})

		const { userState: { userInfo } } = getState()

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get/project-offer-list/${projectId}`, config)

		dispatch({
			type: GET_PROJECT_OFFER_LIST_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GET_PROJECT_OFFER_LIST_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const addProjectOffers = (postData, projectId, navigate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: ADD_PROJECT_OFFERS_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post(`add/project-offers/${projectId}`, postData, config)

		toast.success("Offer Added Successfully")

		dispatch({
			type: ADD_PROJECT_OFFERS_SUCCESS,
			payload: data
		})

		navigate(-1)

	} catch (error) {
		dispatch({
			type: ADD_PROJECT_OFFERS_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}

export const getOfferDetail = (offerId) => async (dispatch, getState) => {
	try {

		dispatch({
			type: GET_OFFER_DETAIL_REQUEST
		})
		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get/project-offer-detail/${offerId}`, config)

		dispatch({
			type: GET_OFFER_DETAIL_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GET_OFFER_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const updateOfferDetail = (postData, offerId, navigate) => async (dispatch, getState) => {
	try {

		dispatch({
			type: UPDATE_OFFER_DETAIL_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.put(`update/project-offer-detail/${offerId}`, postData, config)

		toast.success("Offer Updated Successfully")

		dispatch({
			type: UPDATE_OFFER_DETAIL_SUCCESS,
			payload: data
		})

		navigate(-1)

	} catch (error) {
		dispatch({
			type: UPDATE_OFFER_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const deleteOfferData = (offerId, projectId) => async (dispatch, getState) => {
	try {

		dispatch({
			type: DELETE_OFFER_DETAIL_REQUEST
		})

		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.delete(`delete/project-offer-detail/${offerId}`, config)

		toast.success("Offer Deleted Successfully")

		dispatch({
			type: DELETE_OFFER_DETAIL_SUCCESS,
			payload: data
		})
		dispatch(getProjectOfferList(projectId))
	} catch (error) {

		dispatch({
			type: DELETE_OFFER_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})

	}
}

export const getInventoryData = (inventoryId) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_INVENTORY_DATA_REQUEST })
		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get/inventory-data/${inventoryId}`, config)

		dispatch({ type: GET_INVENTORY_DATA_SUCCESS, payload: data })


	} catch (error) {
		dispatch({
			type: GET_INVENTORY_DATA_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const deleteInventory = (inventoryId, projectId, calbackFunction) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_INVENTORY_REQUEST })
		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.delete(`get/inventory-data/${inventoryId}`, config)

		toast.success("Inventory Deleted Successfully")

		dispatch({ type: DELETE_INVENTORY_SUCCESS, payload: data })
		if (calbackFunction) {
			dispatch(calbackFunction(projectId))
		}
		toast.success("Inventory Deleted Successfully")
	} catch (error) {
		dispatch({
			type: DELETE_INVENTORY_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const getDeveloperSetting = (id) => async (dispatch, getState) => {

	try {

		dispatch({
			type: GET_DEVELOPER_SETTING_REQUEST
		})

		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.get(`admin/developer/setting/${id}`, config)

		dispatch({
			type: GET_DEVELOPER_SETTING_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GET_DEVELOPER_SETTING_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const updateDeveloperSetting = (id, postData) => async (dispatch, getState) => {
	try {
		dispatch({ type: EDIT_DEVELOPER_SETTING_REQUEST })

		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.put(`admin/developer/setting/${id}/`, postData, config)

		dispatch({ type: EDIT_DEVELOPER_SETTING_SUCCESS, payload: data })
		dispatch(getDeveloperSetting(id))
		toast.success("Setting Updated Successfully")

	} catch (error) {
		dispatch({
			type: EDIT_DEVELOPER_SETTING_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}

export const deleteDeveloperSetting = (id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: DELETE_DEVELOPER_SETTING_REQUEST
		})

		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.delete(`admin/developer/setting/${id}/`, config)

		toast.success("Setting Deleted Successfully")

		dispatch({
			type: DELETE_DEVELOPER_SETTING_SUCCESS,
			payload: data
		})
		dispatch(getDeveloperSetting(id))
		toast.success("Setting Deleted Successfully")
	} catch (error) {
		dispatch({
			type: DELETE_DEVELOPER_SETTING_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}



export const getEmployeeDetail = (emp_id) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_EMPLOYEE_DETAIL_REQUEST })

		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get/developer-employee-detail/${emp_id}`, config)
		dispatch({ type: GET_EMPLOYEE_DETAIL_SUCCESS, payload: data })


	} catch (error) {
		dispatch({
			type: GET_EMPLOYEE_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const updateEmployeeDetail = (emp_id, postData, navigate) => async (dispatch, getState) => {
	try {

		dispatch({
			type: EDIT_EMPLOYEE_DETAIL_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "multipart/form-data",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.put(`edit/developer-employee-detail/${emp_id}`, postData, config)

		toast.success("Employee Detail Updated Successfully")

		dispatch({
			type: EDIT_EMPLOYEE_DETAIL_SUCCESS,
			payload: data
		})

		navigate(-1)

	} catch (error) {
		dispatch({
			type: EDIT_EMPLOYEE_DETAIL_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const getCustomerBookingList = () => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_CUSTOMER_BOOKING_LIST_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`admin/customer/list`, config)
		dispatch({ type: GET_CUSTOMER_BOOKING_LIST_SUCCESS, payload: data })

	} catch (error) {
		dispatch({
			type: GET_CUSTOMER_BOOKING_LIST_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const getCustomerBookingDetail = (bookingId) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_CUSTOMER_BOOKING_DETAIL_REQUEST })
		const { userState: { userInfo } } = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.get(`admin/customer/booking/${bookingId}`, config)
		dispatch({ type: GET_CUSTOMER_BOOKING_DETAIL_SUCCESS, payload: data })

	} catch (error) {
		dispatch({
			type: GET_CUSTOMER_BOOKING_DETAIL_FAIL
			, payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}



export const getProjectConfigs = (projectId) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_PROJECT_CONFIGS_REQUEST })
		const { userState: { userInfo } } = getState()
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`admin/project/configs/${projectId}`, config)
		dispatch({ type: GET_PROJECT_CONFIGS_SUCCESS, payload: data })

	} catch (error) {
		dispatch({
			type: GET_PROJECT_CONFIGS_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}



export const bookUnit = (postData, navigate, projectId) => async (dispatch, getState) => {
	try {

		dispatch({ type: BOOK_UNIT_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post(`admin/book-unit/`, postData, config);

		toast.success("Unit Booked Successfully");

		dispatch({ type: BOOK_UNIT_SUCCESS, payload: data });
		navigate(`/add-pricing-for-booking/${projectId}/${data.booking_id}`);
	} catch (error) {
		dispatch({
			type: BOOK_UNIT_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}




export const addBookingPriceList = (postData, navigate, bookingId) => async (dispatch, getState) => {
	try {

		dispatch({ type: ADD_BOOKING_PRICING_LIST_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post(`add/project-pricing-list/${bookingId}/`, postData, config);

		toast.success("Pricing List Added Successfully");

		dispatch({ type: ADD_BOOKING_PRICING_LIST_SUCCESS, payload: data });
		navigate("/customer-bookings/")

	} catch (error) {
		dispatch({
			type: ADD_BOOKING_PRICING_LIST_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}

}


export const editProjectWebsiteSetting = (postData, navigate, projectId) => async (dispatch, getState) => {
	try {

		dispatch({ type: EDIT_PROJECT_DOMAIN_SETTING_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "multipart/form-data",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post(`admin/project-domain-setting/${projectId}`, postData, config);

		dispatch({ type: EDIT_PROJECT_DOMAIN_SETTING_SUCCESS, payload: data });
		toast.success("Project Domain Setting Updated Successfully! Please Wait our team will help You with the rest of process");
		navigate("/project-detail/" + projectId)


	} catch (error) {

		dispatch({
			type: EDIT_PROJECT_DOMAIN_SETTING_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})


	}
}

export const getProjectAutomationList = (projectId) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_PROJECT_AUTOMATION_LIST_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get/project-automations/${projectId}`, config)

		dispatch({ type: GET_PROJECT_AUTOMATION_LIST_SUCCESS, payload: data })


	} catch (error) {

		dispatch({
			type: GET_PROJECT_AUTOMATION_LIST_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const addProjectAutomation = (projectId, postData) => async (dispatch, getState) => {
	try {

		dispatch({ type: SAVE_PROJECT_AUTOMATION_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post(`save/project-automation/${projectId}`, postData, config)

		dispatch({ type: SAVE_PROJECT_AUTOMATION_SUCCESS, payload: data })

	} catch (error) {

		dispatch({
			type: SAVE_PROJECT_AUTOMATION_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})

	}
}

export const deleteProjectAutomation = (automationId, navigate) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_PROJECT_AUTOMATION_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.delete(`delete/project-automation/${automationId}`, config)

		dispatch({ type: DELETE_PROJECT_AUTOMATION_SUCCESS, payload: data })

	} catch (error) {
		dispatch({
			type: DELETE_PROJECT_AUTOMATION_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}



export const getWhatsappTemplate = (templateName) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_WHATSAPP_TEMPLATES_REQUEST })

		const config = {
			headers: {
				"Content-type": "application/json",
			}
		}
		if (templateName) {
			const { data } = await axios.get(`get/whatsapp-templates/${templateName}/`, config)
			dispatch({ type: GET_WHATSAPP_TEMPLATES_SUCCESS, payload: data })
		} else {

			const { data } = await axios.get(`get/whatsapp-templates`, config)
			dispatch({ type: GET_WHATSAPP_TEMPLATES_SUCCESS, payload: data })
		}

		// dispatch({ type: GET_WHATSAPP_TEMPLATES_SUCCESS, payload: data })

	} catch (error) {
		dispatch({
			type: GET_WHATSAPP_TEMPLATES_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const AddAutomation = (postData) => async (dispatch, getState) => {
	try {

		dispatch({ type: ADD_AUTOMATION_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo.token}`
			}
		}

		const { data } = await axios.post("admin/add-automation", postData, config)

		dispatch({
			type: ADD_AUTOMATION_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: ADD_AUTOMATION_FAIL,
			payload: error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}


export const getWhatsappAuth=(developerId="")=>async(dispatch,getState)=>{
	try{

		dispatch({type:GET_WHATSAPP_AUTH_REQUEST});

		const {userState:{userInfo}}=getState();

		const config={
			headers:{
				"Content-type":"application/json",
				Authorization:`Bearer ${userInfo.token}`
			}
		}

		const { data } = await axios.get("get/whatsapp-setting/" + developerId,config);

		dispatch({type:GET_WHATSAPP_AUTH_SUCCESS,payload:data});

	}catch(error){
		dispatch({
			type:GET_WHATSAPP_AUTH_FAIL,
			payload:error?.response && error?.response?.data?.error
				? error?.response?.data?.error
				: error.message,
		})
	}
}

