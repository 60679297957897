export const PERMISSIONS_REQUEST = "PERMISSIONS_REQUEST"
export const PERMISSIONS_SUCCESS = "PERMISSIONS_SUCCESS"
export const PERMISSIONS_ERROR = "PERMISSIONS_ERROR"

export const CAN_VIEW_ADMIN_EMPLOYEES = 'CAN_VIEW_ADMIN_EMPLOYEES'
export const CAN_ADD_ADMIN_EMPLOYEES = 'CAN_ADD_ADMIN_EMPLOYEES'
export const CAN_EDIT_ADMIN_EMPLOYEES = 'CAN_EDIT_ADMIN_EMPLOYEES'
export const CAN_DELETE_ADMIN_EMPLOYEES = 'CAN_DELETE_ADMIN_EMPLOYEES'



export const CAN_VIEW_DEVELOPER = 'CAN_VIEW_DEVELOPER'
export const CAN_ADD_DEVELOPER = 'CAN_ADD_DEVELOPER'
export const CAN_EDIT_DEVELOPER = 'CAN_EDIT_DEVELOPER'
export const CAN_DELETE_DEVELOPER = 'CAN_DELETE_DEVELOPER'
export const CAN_APPROVE_DEVELOPER = 'CAN_APPROVE_DEVELOPER'



export const CAN_VIEW_PROJECTS = 'CAN_VIEW_PROJECTS'
export const CAN_ADD_PROJECTS = 'CAN_ADD_PROJECTS'
export const CAN_EDIT_PROJECTS = 'CAN_EDIT_PROJECTS'
export const CAN_DELETE_PROJECTS = 'CAN_DELETE_PROJECTS'
export const CAN_APPROVE_PROJECTS = 'CAN_APPROVE_PROJECTS'

// 

export const CAN_VIEW_DEVELOPER_PROFILE = 'CAN_VIEW_DEVELOPER_PROFILE'
export const CAN_EDIT_DEVELOPER_PROFILE = 'CAN_EDIT_DEVELOPER_PROFILE'


export const CAN_VIEW_DEVELOPER_SETTINGS = 'CAN_VIEW_DEVELOPER_SETTINGS'
export const CAN_ADD_DEVELOPER_SETTINGS = 'CAN_ADD_DEVELOPER_SETTINGS'
export const CAN_EDIT_DEVELOPER_SETTINGS = 'CAN_EDIT_DEVELOPER_SETTINGS'
export const CAN_DELETE_DEVELOPER_SETTINGS = 'CAN_DELETE_DEVELOPER_SETTINGS'


export const CAN_VIEW_DEVELOPER_EMPLOYEES = 'CAN_VIEW_DEVELOPER_EMPLOYEES'
export const CAN_ADD_DEVELOPER_EMPLOYEES = 'CAN_ADD_DEVELOPER_EMPLOYEES'
export const CAN_EDIT_DEVELOPER_EMPLOYEES = 'CAN_EDIT_DEVELOPER_EMPLOYEES'
export const CAN_DELETE_DEVELOPER_EMPLOYEES = 'CAN_DELETE_DEVELOPER_EMPLOYEES'


export const CAN_VIEW_CHANNEL_PARTNERS = 'CAN_VIEW_CHANNEL_PARTNERS'
export const CAN_EDIT_CHANNEL_PARTNERS = 'CAN_EDIT_CHANNEL_PARTNERS'
export const CAN_ADD_CHANNEL_PARTNERS = 'CAN_ADD_CHANNEL_PARTNERS'



export const CAN_VIEW_ADMIN_USERS = 'CAN_VIEW_ADMIN_USERS'
export const CAN_EDIT_ADMIN_USERS = 'CAN_EDIT_ADMIN_USERS'
export const CAN_ADD_ADMIN_USERS = 'CAN_ADD_ADMIN_USERS'
export const CAN_DELETE_ADMIN_USERS = 'CAN_DELETE_ADMIN_USERS'



export const CAN_CREATE_ROLES = 'CAN_CREATE_ROLES'
export const CAN_VIEW_ROLES = 'CAN_VIEW_ROLES'
export const CAN_EDIT_ROLES = 'CAN_EDIT_ROLES'
export const CAN_DELETE_ROLES = 'CAN_DELETE_ROLES'