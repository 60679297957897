import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProjectBucket } from "../../Actions/CPAction";
import { useNavigate } from "react-router-dom";
import numberToWords from "../numberFormater";
import ProjectTile from "../../Components/ProjectTile";
const MyBucket = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [search, setSearch] = useState(null);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [dataCount, setDataCount] = useState(1);
	const [hasMore, setHasMore] = useState(false);

	const { error, loading, projects } = useSelector(
		(state) => state?.cp?.myBucket
	);

	const searchHandler = (e) => {
		const delay = 1000;
		const Debouncer = setTimeout(() => {
			setSearch(e.target.value);
		}, delay);
		return () => clearTimeout(Debouncer);
	};

	useEffect(() => {
		var postData = {
			page: page,
		};
		if (search !== null) {
			postData["query"] = search;
		}
		dispatch(getProjectBucket(postData));
	}, [page, search]);
	useEffect(() => {
		if (projects !== undefined) {
			setData(projects?.projects);
			setPage(projects?.page);
			setDataCount(projects?.number_of_projects);
			setHasMore(projects?.next_page);
		}
	}, [projects]);
	return (
		<Layout pageTitle={"My Bucket"}>
			<InfiniteScroll
				dataLength={dataCount}
				next={() => setPage(page + 1)}
				className="row p-4"
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				scrollableTarget="scrollableDiv"
			>
				{data?.map((project) => {
					return (
						<div className="col-md-6 col-lg-4  p-2">
							<div
								className="bg-white  p-2  rounded-3 border shadow-sm"
								onClick={() => {
									navigate(`/project/${project.project_id}/`);
								}}
							>
								<div className="position-relative">
									<FontAwesomeIcon className="position-absolute top-1 end-0 me-2 mt-2" icon={icon.faArrowRight} />
								</div>
								<div className="d-flex">
									<div className="">
										<img
											className="img-thumbnail "
											src={"https://api.eazyapp.in" + project.cover_img}
											style={{ height: "150px", width: "100px" }}
											alt=""
										/>
									</div>
									<div className=" p-3">
										<h4 className="text-capitalize card_heading">
											{project.name}
										</h4>
										<p className="m-0">{project.organization}</p>
										<p className="m-0">
											<b>Configs:</b> {project.configs}
										</p>
										<p className="m-0">
											<b>Starting carpet area:</b>{" "}
											{project.starting_carpert_area} sq.ft
										</p>
										<p className="m-0">
											<b>Starting Price:</b>{" "}
											{numberToWords(project.starting_price)}
										</p>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</InfiniteScroll>
		</Layout>
	);
};

export default MyBucket;
