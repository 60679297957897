import React, { useState, useRef, useEffect } from "react";

const ImageUploader = ({ dbImage, name }) => {
	const inputFile = useRef(null);
	const [image, setImage] = useState();
	// console.log(image)
	return (
		<div
			className="w-100 h-100  hover_to_show position-relative"
			onDragOver={(e) => {
				e.preventDefault();
			}}
			onDrop={(event) => {
				event.preventDefault();
				inputFile.current.value = ""
				inputFile.current.files = event.dataTransfer.files;
				// const dT = new DataTransfer();
				const file = event.dataTransfer.files[0];
				const fileReader = new FileReader();
				fileReader.readAsDataURL(file);
				fileReader.onload = () => {
					setImage(fileReader.result);
				};
			}}
		>
			<input
				ref={inputFile}
				type="file"
				name={name ? name : "file"}
				onChange={(e) => {
					const file = e.target.files[0];
					const fileReader = new FileReader();
					fileReader.readAsDataURL(file);
					fileReader.onload = () => {
						setImage(fileReader.result);
					};
				}}
				hidden
			/>
			{image ? (
				<>
					<div className="d-flex justify-content-center align-items-center">
						<img className="img-thumbnail" src={image} alt="" style={{height:"300px",width:"300px"}} />
					</div>
					<div className="div_to_show position-absolute top-0  w-100 h-100 ">
						<div className="d-grid place-items-center p-3 border h-100  rounded ">
							<p className="m-0 text-danger fs-4 fw-bold text-shadow">
								Drag & Drop
							</p>
							<p className="my-3 text-danger fs-4 fw-bold text-shadow"> or </p>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									inputFile.current.click();
								}}
							>
								Choose a file to Upload
							</button>
						</div>
					</div>
				</>
			) : dbImage ? (
				<>
					<div className="d-flex justify-content-center align-items-center">
							<img className="img-thumbnail" src={dbImage} alt="" style={{ height: "300px", width: "300px", objectFit: "cover" }} />
					</div>
					<div className="div_to_show position-absolute top-0  w-100 h-100 ">
						<div className="d-grid place-items-center p-3 border h-100 rounded ">
							<p className="m-0 text-danger fs-4 fw-bold text-shadow">
								Drag & Drop
							</p>
							<p className="my-3 text-danger fs-4 fw-bold text-shadow"> or </p>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									inputFile.current.click();
								}}
							>
								Choose a file to Upload
							</button>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="d-grid place-items-center p-3 border rounded ">
						<p className="m-0">Drag & Drop</p>
						<p className="my-3"> or </p>
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={() => {
								inputFile.current.click();
							}}
						>
							Choose a file to Upload
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default ImageUploader;
