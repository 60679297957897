import SEDashboard from "./SEDashboard";
import VisitorDetail from "./visit/VisitorDetail";
import SEEazyVisits from "./SEEazyVisits";
import SEVisitorDetail from "./SEVisitorDetail";
import SETeams from "./SETeams";
import Layout from "./Layout/Layout";
import React from "react";
import Profile from "../../Profile";
import SETasks from "./SETasks";
import ChannelPartner from "./ChannelPartner";
import ChannelPartnerDetail from "./ChannelPartnerDetail";
import SEReport from './SEReport'
import Report from "./Report";
import ProjectReport from './ProjectReport'
import CPReport from "./CPReport";
import Inventory from "./Inventory";
import BookUnit from "./BookUnit";
import EditProfile from "../EditProfile";
import GenrateReport from "./GenrateReport";
import AddBookingPricing from "./AddBookingPricing"
const SEProfile = () => {
	return (
		<Layout pageTitle={"Profile"}>
			<Profile />
		</Layout>
	);
};
const SEEditProfile = () => {
	return (
		<Layout pageTitle={"Edit Profile"}>
			<EditProfile />
		</Layout>
	);
};

export {
	SEDashboard,
	VisitorDetail,
	SEEazyVisits,
	SEVisitorDetail,
	SEProfile,
	SETeams,
	SETasks,
	ChannelPartner,
	ChannelPartnerDetail,
	Report,
	SEReport,
	ProjectReport,
	CPReport,
	Inventory,
	BookUnit,
	SEEditProfile,
	GenrateReport,
	AddBookingPricing
};
