import {
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGIN_RESET,
	GET_USER_DATA_REQUEST,
	GET_USER_DATA_SUCCESS,
	GET_USER_DATA_FAIL,
} from "../Constants/UserConstant";


export const UserLoginReducer=(state = {}, action)=>{
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true, userInfo :null};
        case USER_LOGIN_SUCCESS:
            return{loading:false, userInfo:action.payload};
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload, userInfo :null};
        case USER_LOGIN_RESET:
            return { loading: false, userInfo :null};
        default:
            return state;
    }
}


export const userDataReducer=(state={},action)=>{
    switch(action.type) {
        case GET_USER_DATA_REQUEST:
            return {loading:true};
        case GET_USER_DATA_SUCCESS:
            return {loading:false, profile:action.payload};
        case GET_USER_DATA_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state;
    }
}