import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getDeveloperProjects } from "../../../Actions/AdminAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ProjectsSetting = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [query, setQuery] = useState("");
	const [serach, setSearch] = useState("");
	const [dataCount, setDataCount] = useState(20);
	const [hasMore, setHasMore] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		error,
		projects,
		total_projects,
		pages,
		page: currentPage,
		next_page,
	} = useSelector((state) => state?.developer?.projectList);
    useEffect(() => {
			const Debouncer = setTimeout(() => {
				setQuery(serach);
			}, 500);
			return () => clearTimeout(Debouncer);
		}, [serach]);

		useEffect(() => {
			if (projects) {
				setData(projects);
				setPage(currentPage);
				setDataCount(total_projects);
				setHasMore(next_page);
			}
		}, [projects]);
		useEffect(() => {
			let params = {
				page: page,
			};
			if (query) {
				params["query"] = query;
				setData([]);
			}
			dispatch(getDeveloperProjects(params));
		}, [query, page]);
	return (
		<Layout pageTitle={"Projects Setting"}>
			<div className="p-3">
				<input
					type="text"
					className="form-control"
					placeholder="Search by Project Name"
					onChange={(e) => setSearch(e.target.value)}
				/>
			</div>
			<InfiniteScroll
				dataLength={dataCount}
				next={() => setPage(page + 1)}
				className="d-grid gap-2 p-2 h-100 "
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				scrollableTarget="scrollableDiv"
			>
				{data.map((project) => {
					return (
						<div className="bg-white p-2 rounded border shadow position-relative">
							
							<div className="p-3">
								<h4 className="text-capitalize card_heading">{project.name}</h4>
								<div className="row gap-2 row-cols-auto">
								<button
									className="btn btn-dark "
									onClick={() => {
										navigate(
											`/project-payment-setting/${project.id}`
										);
									}}
								>
									Payment Setting
								</button>
								<button
									className="btn btn-dark "
									onClick={() => {
										navigate(`/project-offer-setting/${project.id}`)
									}}
								>
									Offers Setting
								</button>
								<button
									className="btn btn-dark "
									onClick={() => {
										navigate(`/project-pricing-setting/${project.id}`);
									}}
								>
									Pricing Setting
								</button>
								</div>
							</div>
						</div>
					);
				})}
			</InfiniteScroll>
		</Layout>
	);
};

export default ProjectsSetting;
