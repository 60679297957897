import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminRoles, getPermissionList, changeRolePermissions, addNewAdminRole } from '../../Actions/MyAdminAction'
import Dropdown from 'react-bootstrap/Dropdown';
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from 'react-bootstrap/Modal';




const AdminRoleList = () => {
    const [addRole, setAddRole] = useState(false);
    const dispatch = useDispatch();
    const { roleList } = useSelector(state => state?.myAdmin?.adminRoleList)
    const { permissionList } = useSelector(state => state?.myAdmin?.adminPermissionsList)
    useEffect(() => {
        dispatch(getAdminRoles())
        dispatch(getPermissionList())
    }, [])

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target)
        dispatch(addNewAdminRole(formData, setAddRole))
    }

    return (
        <Layout pageTitle={"Admin Role List"}>
            <div className="d-grid gap-3 p-3">
                <div className="text-end"><button className="btn btn-dark" onClick={() => setAddRole(true)}>Add Role</button>
                    <Modal show={addRole} size="lg" onHide={setAddRole}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={submitHandler}>
                                <div className="d-grid gap-3 p-3">
                                    <div>
                                        <label>Role Name</label>
                                        <input type="text" name="name" className="form-control" />
                                    </div>
                                    <div>
                                        <label>Permissions</label>
                                        <div className="d-flex gap-3 flex-wrap">
                                            {
                                                permissionList?.map(permission => {
                                                    return (
                                                        <div className='form-check form-check-inline'>
                                                            <input type="checkbox" class="btn-check" value={permission.id} id={"permission" + permission.id} name="permissions" />
                                                            <label class="btn btn-outline-primary" for={"permission" + permission.id}>
                                                                {permission.name.replaceAll("_", " ")}
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button className="btn btn-success" type="submit" >Submit</button>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
                {
                    roleList?.map(role => {
                        return (
                            <div className="p-3 rounded shadow-sm border position-relative" >
                                <Dropdown
                                    className="position-absolute end-0 m-1 mx-3 "
                                    style={{ zIndex: "1" }}
                                >
                                    <Dropdown.Toggle
                                        className="hide-arrow"
                                        variant=""
                                        id="dropdown-basic"
                                    >
                                        <FontAwesomeIcon icon={icon.faEllipsis} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='px-4' style={{ height: "500px", overflow: "scroll" }}>
                                        {
                                            permissionList?.map(permission => {
                                                return (
                                                    <Dropdown.Item
                                                    >
                                                        <input class="form-check-input" type="checkbox" value="" id={"permission" + permission.id} onChange={(e) => {
                                                            var postData = {}
                                                            postData["role_id"] = role.id
                                                            if (e.target.checked) {
                                                                postData["add_permission_id"] = permission.id
                                                            } else {
                                                                postData["remove_permission_id"] = permission.id
                                                            }
                                                            dispatch(changeRolePermissions(postData))
                                                        }} checked={role.permissions.map(d => { return d.id }).includes(permission.id)} />
                                                        <label class="form-check-label" for={"permission" + permission.id}>
                                                            {permission.name.replaceAll("_", " ")}
                                                        </label>


                                                    </Dropdown.Item>
                                                )
                                            })
                                        }


                                    </Dropdown.Menu>
                                </Dropdown>
                                <p>{role?.name}</p>
                            </div>
                        )
                    })
                }
            </div>
        </Layout>
    )
}

export default AdminRoleList
