import React from 'react'
import Lottie from 'react-lottie'
import notFound from "./assets/Animation - 1703928201437.json"
const NotFound = () => {
  return (
		<div
			style={{
				height: "100dvh",
				width: "100dvw",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div>
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: notFound,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice",
						},
					}}
					height={400}
					width={400}
				/>
			</div>
		</div>
	);
}

export default NotFound
