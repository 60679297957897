import React,{useState,useEffect} from 'react'
import Layout from './Layout/Layout'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addCustomerPayment } from '../../Actions/CustomerAction'

const AddPayment = () => {
    const [paymentMode, setPaymentMode] = useState("Cash")
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);
    const PaymentModeField= ({paymentMode})=>{

        switch (paymentMode){
            case "Cash": return <>
                <div>
                    <label htmlFor="transaction_date"> Transaction Date</label>
                    <input type="date" name="transaction_date" className='form-control' id="transaction_date" />
                </div>
                <div>
                    <label htmlFor="receipt_no">Receipt No.</label>
                    <input type="text" className='form-control' id="receipt_no" name="receipt_no" />
                </div>
                <div>
                    <label htmlFor="sent_to">Sent to</label>
                    <input type="text" name="sent_to" id="sent_to" className='form-control' />
                </div>
            </>
            case "Cheque": 
                return <>
                    <div>
                        <label htmlFor="cheque_no">Cheque No</label>
                        <input type="text" name="cheque_no" id="cheque_no" className='form-control' />
                    </div>
                    <div>
                        <label htmlFor="date_of_cheque">Date of cheque</label>
                        <input type="date" name="date_of_cheque" id="date_of_cheque" className='form-control' />
                    </div>
                    <div>
                        <label htmlFor="bank_name">Bank Name</label>
                        <select name="bank_name" id='bank_name' className="form-control">
                            <option value="Aavas Financiers LTD" data-aura-rendered-by="3:361;a">Aavas Financiers LTD</option>
                            <option value="ABHYUDAYA BK" data-aura-rendered-by="5:361;a">ABHYUDAYA BANK</option>
                            <option value="ABN Amro" data-aura-rendered-by="7:361;a">ABN Amro</option>
                            <option value="Abu Dhabi Comm Bk Ltd." data-aura-rendered-by="9:361;a">Abu Dhabi Commercial Bank Ltd.</option>
                            <option value="ACE CO OP BK LTD" data-aura-rendered-by="11:361;a">ACE CO OPERATIVE BANK LTD</option>
                            <option value="Aditya Birla HSG Finance Ltd" data-aura-rendered-by="13:361;a">Aditya Birla Housing Finance Ltd</option>
                            <option value="African Banking Corporation Limited" data-aura-rendered-by="15:361;a">African Banking Corporation Limited</option>
                            <option value="Ahli United BK" data-aura-rendered-by="17:361;a">Ahli United Bank</option>
                            <option value="Ahmedabad Mercantile Co op Bk" data-aura-rendered-by="19:361;a">Ahmedabad Mercantile Co operative Bank</option>
                            <option value="AHMEDNAGAR SHAHAR SAH BK MARYADIT" data-aura-rendered-by="21:361;a">AHMEDNAGAR SHAHAR SAHAKARI BANK MARYADIT</option>
                            <option value="Ajara Urban Cooperative Bank" data-aura-rendered-by="23:361;a">Ajara Urban Cooperative Bank</option>
                            <option value="AL AHALIA MONEY EXC BUREAU &amp; Payment" data-aura-rendered-by="25:361;a">AL AHALIA MONEY EXCHANGE BUREAU &amp; Payment</option>
                            <option value="AL ANSARI EXC" data-aura-rendered-by="27:361;a">AL ANSARI EXCHANGE</option>
                            <option value="ALAPPUZHA DIS CO -OP BANK" data-aura-rendered-by="29:361;a">ALAPPUZHA DISTRICT CO -OP BANK</option>
                            <option value="Aldar Exc, Doha, Qatar" data-aura-rendered-by="31:361;a">Aldar Exchange, Doha, Qatar</option>
                            <option value="ALFALA EXC CO" data-aura-rendered-by="33:361;a">ALFALA EXCHANGE CO</option>
                            <option value="Al Fardan Exc" data-aura-rendered-by="35:361;a">Al Fardan Exchange</option>
                            <option value="Allahabad Bk" data-aura-rendered-by="37:361;a">Allahabad Bank</option>
                            <option value="AL MIRQAB EXC" data-aura-rendered-by="39:361;a">AL MIRQAB EXCHANGE</option>
                            <option value="Almora Urban Coop Bk Ltd" data-aura-rendered-by="41:361;a">Almora Urban Coop Bank Ltd</option>
                            <option value="AL Mulla International Exc Com K.S.C." data-aura-rendered-by="43:361;a">AL Mulla International Exchange Company K.S.C.</option>
                            <option value="Al Muzaini Exch Co." data-aura-rendered-by="45:361;a">Al Muzaini Exchange Co.</option>
                            <option value="AL RAZOUKI EXC SHARJAH UAE" data-aura-rendered-by="47:361;a">AL RAZOUKI EXCHANGE SHARJAH UAE</option>
                            <option value="AL ROSTAMANI EXC" data-aura-rendered-by="49:361;a">AL ROSTAMANI EXCHANGE</option>
                            <option value="AL-ZAMAN Exc" data-aura-rendered-by="51:361;a">AL-ZAMAN Exchange</option>
                            <option value="Ambernath JAI Hind Co Op Bk" data-aura-rendered-by="53:361;a">Ambernath JAI Hind Co Operative Bank</option>
                            <option value="American Chartered Bk" data-aura-rendered-by="55:361;a">American Chartered Bank</option>
                            <option value="American Express Bank Ltd." data-aura-rendered-by="57:361;a">American Express Bank Ltd.</option>
                            <option value="Andhra Bk" data-aura-rendered-by="59:361;a">Andhra Bank</option>
                            <option value="AnnaSaheb Magar Sah Bk" data-aura-rendered-by="61:361;a">AnnaSaheb Magar Sahakari Bank</option>
                            <option value="Antwerp Diamond Bk N.V." data-aura-rendered-by="63:361;a">Antwerp Diamond Bank N.V.</option>
                            <option value="ANZ Bk" data-aura-rendered-by="65:361;a">ANZ Bank</option>
                            <option value="Apna Sah Bk" data-aura-rendered-by="67:361;a">Apna Sahakari Bank</option>
                            <option value="ASB Auckland New Zealand" data-aura-rendered-by="69:361;a">ASB Auckland New Zealand</option>
                            <option value="AU Small Finance Bank" data-aura-rendered-by="71:361;a">AU Small Finance Bank</option>
                            <option value="Australia and New Zealand Banking Group Ltd" data-aura-rendered-by="73:361;a">Australia and New Zealand Banking Group Ltd</option>
                            <option value="Axis Bk" data-aura-rendered-by="75:361;a">Axis Bank</option>
                            <option value="BAHRAIN EXC CO W L L" data-aura-rendered-by="77:361;a">BAHRAIN EXCHANGE CO W L L</option>
                            <option value="Bajaj Housing Finance Ltd" data-aura-rendered-by="79:361;a">Bajaj Housing Finance Ltd</option>
                            <option value="BANDHAN BK" data-aura-rendered-by="81:361;a">BANDHAN BANK</option>
                            <option value="Bk of America N.A." data-aura-rendered-by="83:361;a">Bank of America N.A.</option>
                            <option value="Bk of Bahrain &amp; Kuwait" data-aura-rendered-by="85:361;a">Bank of Bahrain &amp; Kuwait</option>
                            <option value="Bk of Bahrain and Kuwait" data-aura-rendered-by="87:361;a">Bank of Bahrain and Kuwait</option>
                            <option value="Bk of Baroda" data-aura-rendered-by="89:361;a">Bank of Baroda</option>
                            <option value="Bk of Cyprus" data-aura-rendered-by="91:361;a">Bank of Cyprus</option>
                            <option value="Bk of India" data-aura-rendered-by="93:361;a">Bank of India</option>
                            <option value="Bk of Maharashtra" data-aura-rendered-by="95:361;a">Bank of Maharashtra</option>
                            <option value="Bk of Muscat" data-aura-rendered-by="97:361;a">Bank of Muscat</option>
                            <option value="Bk of Newzealand" data-aura-rendered-by="99:361;a">Bank of Newzealand</option>
                            <option value="Bk Of Nova Scotia" data-aura-rendered-by="101:361;a">Bank Of Nova Scotia</option>
                            <option value="Bk Of Tokyo" data-aura-rendered-by="103:361;a">Bank Of Tokyo</option>
                            <option value="Bk Sohar" data-aura-rendered-by="105:361;a">Bank Sohar</option>
                            <option value="Baramati Sah Bk LTD" data-aura-rendered-by="107:361;a">Baramati Sahakari Bank LTD</option>
                            <option value="Barclays Bk Plc" data-aura-rendered-by="109:361;a">Barclays Bank Plc</option>
                            <option value="Bassein Catholic Co-op Bk" data-aura-rendered-by="111:361;a">Bassein Catholic Co-op Bank</option>
                            <option value="Belhasa Global Exc" data-aura-rendered-by="113:361;a">Belhasa Global Exchange</option>
                            <option value="Bharat Co-op Bk" data-aura-rendered-by="115:361;a">Bharat Co-operative Bank</option>
                            <option value="BNP PARIBAS" data-aura-rendered-by="117:361;a">BNP PARIBAS</option>
                            <option value="Bombay Mercantile Co-op Bk" data-aura-rendered-by="119:361;a">Bombay Mercantile Co-op Bank</option>
                            <option value="Bombay Merchantile Bk" data-aura-rendered-by="121:361;a">Bombay Merchantile Bank</option>
                            <option value="BULDHANA URBAN CO-OP BK" data-aura-rendered-by="123:361;a">BULDHANA URBAN CO-OP BANK</option>
                            <option value="Canara Bk" data-aura-rendered-by="125:361;a">Canara Bank</option>
                            <option value="Catholic Syrian Bk Ltd." data-aura-rendered-by="127:361;a">Catholic Syrian Bank Ltd.</option>
                            <option value="Central Bk of India" data-aura-rendered-by="129:361;a">Central Bank of India</option>
                            <option value="Chase Bk" data-aura-rendered-by="131:361;a">Chase Bank</option>
                            <option value="China Construction Bk" data-aura-rendered-by="133:361;a">China Construction Bank</option>
                            <option value="Citibank N.A." data-aura-rendered-by="135:361;a">Citibank N.A.</option>
                            <option value="Citizen Co-op Bk Ltd." data-aura-rendered-by="137:361;a">Citizen Co-operative Bank Ltd.</option>
                            <option value="Citizens Bk" data-aura-rendered-by="139:361;a">Citizens Bank</option>
                            <option value="City Exc LLC" data-aura-rendered-by="141:361;a">City Exchange LLC</option>
                            <option value="City Union Bk Ltd." data-aura-rendered-by="143:361;a">City Union Bank Ltd.</option>
                            <option value="Comm Bk International" data-aura-rendered-by="145:361;a">Commercial Bank International</option>
                            <option value="Commonwealth Bk" data-aura-rendered-by="147:361;a">Commonwealth Bank</option>
                            <option value="Compass Global Holdings" data-aura-rendered-by="149:361;a">Compass Global Holdings</option>
                            <option value="Corporation Bk" data-aura-rendered-by="151:361;a">Corporation Bank</option>
                            <option value="Cosmos Coop Bk" data-aura-rendered-by="153:361;a">Cosmos Cooperative Bank</option>
                            <option value="Crane Bk" data-aura-rendered-by="155:361;a">Crane Bank</option>
                            <option value="Credit Suisse" data-aura-rendered-by="157:361;a">Credit Suisse</option>
                            <option value="Daivadnya Sah Bk Niyamit" data-aura-rendered-by="159:361;a">Daivadnya Sahakari Bank Niyamit</option>
                            <option value="Dapoli Urban Co Op Bk Ltd" data-aura-rendered-by="161:361;a">Dapoli Urban Co Op Bank Ltd</option>
                            <option value="DBS Bk" data-aura-rendered-by="163:361;a">DBS Bank</option>
                            <option value="DCB Bk" data-aura-rendered-by="165:361;a">DCB Bank</option>
                            <option value="Deccan Grameen Bk" data-aura-rendered-by="167:361;a">Deccan Grameen Bank</option>
                            <option value="Dena Bk" data-aura-rendered-by="169:361;a">Dena Bank</option>
                            <option value="Deutsche Bk AG" data-aura-rendered-by="171:361;a">Deutsche Bank AG</option>
                            <option value="Development Credit Bk Ltd." data-aura-rendered-by="173:361;a">Development Credit Bank Ltd.</option>
                            <option value="DHANLAXMI BK LTD" data-aura-rendered-by="175:361;a">DHANLAXMI BANK LTD</option>
                            <option value="DHFL" data-aura-rendered-by="177:361;a">DHFL</option>
                            <option value="Diamond Trust Bk Kenya LTD, Kenya" data-aura-rendered-by="179:361;a">Diamond Trust Bank Kenya LTD, Kenya</option>
                            <option value="Discover Bank" data-aura-rendered-by="181:361;a">Discover Bank</option>
                            <option value="DMK JAOLI SAH BK LTD" data-aura-rendered-by="183:361;a">DMK JAOLI SAHAKARI BANK LTD</option>
                            <option value="Doha Bk" data-aura-rendered-by="185:361;a">Doha Bank</option>
                            <option value="DOLLARCO EXC CO LTD" data-aura-rendered-by="187:361;a">DOLLARCO EXCHANGE CO LTD</option>
                            <option value="Dombivali Nagarik Sah Bk" data-aura-rendered-by="189:361;a">Dombivali Nagarik Sahakari Bank</option>
                            <option value="Dyandeep Co op bk" data-aura-rendered-by="191:361;a">Dyandeep Co op bank</option>
                            <option value="Eastern Exc Est" data-aura-rendered-by="193:361;a">Eastern Exchange Est</option>
                            <option value="East West Bk" data-aura-rendered-by="195:361;a">East West Bank</option>
                            <option value="Economic Exch Centre, Dubai" data-aura-rendered-by="197:361;a">Economic Exchange Centre, Dubai</option>
                            <option value="Emirates India International Exch L.P.C" data-aura-rendered-by="199:361;a">Emirates India International Exchange L.P.C</option>
                            <option value="Emirates NBD bk" data-aura-rendered-by="201:361;a">Emirates NBD bank</option>
                            <option value="Equitas Small Finance Bank" data-aura-rendered-by="203:361;a">Equitas Small Finance Bank</option>
                            <option value="EXCELLENT CO OP BK LTD" data-aura-rendered-by="205:361;a">EXCELLENT CO OP BANK LTD</option>
                            <option value="EXIM Bk" data-aura-rendered-by="207:361;a">EXIM Bank</option>
                            <option value="FEDERAL BK" data-aura-rendered-by="209:361;a">FEDERAL BANK</option>
                            <option value="​Federal Bk Of Dwarka" data-aura-rendered-by="211:361;a">​Federal Bank Of Dwarka</option>
                            <option value="FirstRand Bk India LTD" data-aura-rendered-by="213:361;a">FirstRand Bank India Limited</option>
                            <option value="GCC Exc" data-aura-rendered-by="215:361;a">GCC Exchange</option>
                            <option value="​Gopinath Patil Janata Sah Bk" data-aura-rendered-by="217:361;a">​Gopinath Patil Janata Sahakari Bank</option>
                            <option value="GP Parsik Bk Ltd" data-aura-rendered-by="219:361;a">GP Parsik Bank Ltd</option>
                            <option value="GREATER BOMBAY CO.OP.BK LTD." data-aura-rendered-by="221:361;a">GREATER BOMBAY CO.OP.BANK LTD.</option>
                            <option value="Habib exc" data-aura-rendered-by="223:361;a">Habib exchange</option>
                            <option value="Hadi Exc" data-aura-rendered-by="225:361;a">Hadi Exchange</option>
                            <option value="Hindustan Co-op Bk Ltd" data-aura-rendered-by="227:361;a">Hindustan Co-operative Bank Limited</option>
                            <option value="HOME FIRST FINANCE COM INDIA PVT LTD" data-aura-rendered-by="229:361;a">HOME FIRST FINANCE COMPANY INDIA PRIVATE LIMITED</option>
                            <option value="HSBC Bk" data-aura-rendered-by="231:361;a">HSBC Bank</option>
                            <option value="I &amp; M bk Ltd" data-aura-rendered-by="233:361;a">I &amp; M bank Ltd</option>
                            <option value="ICICI Bk" data-aura-rendered-by="235:361;a">ICICI Bank</option>
                            <option value="IDBI Bk Ltd" data-aura-rendered-by="237:361;a">IDBI Bank Limited</option>
                            <option value="IDFC Bk" data-aura-rendered-by="239:361;a">IDFC Bank</option>
                            <option value="India Bulls Bk" data-aura-rendered-by="241:361;a">India Bulls Bank</option>
                            <option value="INDIA INFOLINE HSG FINANCE LTD" data-aura-rendered-by="243:361;a">INDIA INFOLINE HOUSING FINANCE LIMITED</option>
                            <option value="Indian Bk" data-aura-rendered-by="245:361;a">Indian Bank</option>
                            <option value="Indian Overseas Bk" data-aura-rendered-by="247:361;a">Indian Overseas Bank</option>
                            <option value="IndusInd Bank Ltd" data-aura-rendered-by="249:361;a">IndusInd Bank Limited</option>
                            <option value="Industrial Development Bk of India" data-aura-rendered-by="251:361;a">Industrial Development Bank of India</option>
                            <option value="ING Vysya Bk" data-aura-rendered-by="253:361;a">ING Vysya Bank</option>
                            <option value="Investec Bank Limited" data-aura-rendered-by="255:361;a">Investec Bank Limited</option>
                            <option value="Jai Vaibhav Laxmi Multi State Co.op Credit Soc Ltd" data-aura-rendered-by="257:361;a">Jai Vaibhav Laxmi Multi State Co.op Credit Society Ltd</option>
                            <option value="JALGOAN JAN SAH BK" data-aura-rendered-by="259:361;a">JALGOAN JANATA SAHAKARI BANK</option>
                            <option value="Jan Sah Bk" data-aura-rendered-by="261:361;a">Janakalyan Sahakari Bank</option>
                            <option value="Janaseva Sah Bk LTD" data-aura-rendered-by="263:361;a">Janaseva Sahakari Bank LTD</option>
                            <option value="Janta Sah Bk Ltd." data-aura-rendered-by="265:361;a">Janta Sahakari Bank Ltd.</option>
                            <option value="JK Bk Ltd" data-aura-rendered-by="267:361;a">JK Bank Ltd</option>
                            <option value="Joyalukkas Exc" data-aura-rendered-by="269:361;a">Joyalukkas Exchange</option>
                            <option value="J P Morgan Chase Bk, National Association" data-aura-rendered-by="271:361;a">J P Morgan Chase Bank, National Association</option>
                            <option value="Kallappanna Awade Ichalkaranji Jan Sah Bk Ltd" data-aura-rendered-by="273:361;a">Kallappanna Awade Ichalkaranji Janta Sahakari Bank Limited</option>
                            <option value="Kalyan Jan Sah Bk Ltd" data-aura-rendered-by="275:361;a">Kalyan Janata Sahakari Bank Ltd</option>
                            <option value="Kapol Co.Op Bk Ltd" data-aura-rendered-by="277:361;a">Kapol Co.Op Bank Ltd</option>
                            <option value="Karad Janata Sah Bk Ltd" data-aura-rendered-by="279:361;a">Karad Janata Sahakari Bank Ltd</option>
                            <option value="Karad Urban Co-op bk ltd" data-aura-rendered-by="281:361;a">Karad Urban Co-operative bank ltd</option>
                            <option value="Karnataka Bk" data-aura-rendered-by="283:361;a">Karnataka Bank</option>
                            <option value="Karur Vysya Bk Ltd." data-aura-rendered-by="285:361;a">Karur Vysya Bank Limited.</option>
                            <option value="Kokan Mercantile co-op bk" data-aura-rendered-by="287:361;a">Kokan Mercantile co-op bank</option>
                            <option value="Kotak Mahindra Bk Ltd" data-aura-rendered-by="289:361;a">Kotak Mahindra Bank Limited</option>
                            <option value="Kurla nagrik bk" data-aura-rendered-by="291:361;a">Kurla nagrik bank</option>
                            <option value="KUWAIT INDIA INTERNATIONAL EXC" data-aura-rendered-by="293:361;a">KUWAIT INDIA INTERNATIONAL EXCHANGE</option>
                            <option value="L&amp;T Housing Finance Ltd" data-aura-rendered-by="295:361;a">L&amp;T Housing Finance Ltd</option>
                            <option value="Lakshmi Vishnu Sah Bk Ltd" data-aura-rendered-by="297:361;a">Lakshmi Vishnu Sahakari Bank Ltd</option>
                            <option value="LARI EXC" data-aura-rendered-by="299:361;a">LARI EXCHANGE</option>
                            <option value="Latur Urban Bk" data-aura-rendered-by="301:361;a">Latur Urban Bank</option>
                            <option value="Laxmi Vilas Bk" data-aura-rendered-by="303:361;a">Laxmi Vilas Bank</option>
                            <option value="Lodhapay" data-aura-rendered-by="305:361;a">Lodhapay</option>
                            <option value="Lord Krishna Bk Ltd." data-aura-rendered-by="307:361;a">Lord Krishna Bank Ltd.</option>
                            <option value="LULU bk credit" data-aura-rendered-by="309:361;a">LULU bank credit</option>
                            <option value="Macquarie Bk Ltd" data-aura-rendered-by="311:361;a">Macquarie Bank Limited</option>
                            <option value="Mahanagar Bk" data-aura-rendered-by="313:361;a">Mahanagar Bank</option>
                            <option value="Maharashtra Bk" data-aura-rendered-by="315:361;a">Maharashtra Bank</option>
                            <option value="Maharashtra Grahmin Bk" data-aura-rendered-by="317:361;a">Maharashtra Grahmin Bank</option>
                            <option value="Mahesh Sah Bk Ltd" data-aura-rendered-by="319:361;a">Mahesh Sahakari Bank Ltd</option>
                            <option value="MASHREQ BK" data-aura-rendered-by="321:361;a">MASHREQ BANK</option>
                            <option value="Maybk" data-aura-rendered-by="323:361;a">Maybank</option>
                            <option value="Mehsana Urban Co-op Bk Ltd" data-aura-rendered-by="325:361;a">Mehsana Urban Co-op Bank Ltd</option>
                            <option value="Merrill Lynch" data-aura-rendered-by="327:361;a">Merrill Lynch</option>
                            <option value="Model Co-Op Bk Ltd" data-aura-rendered-by="329:361;a">Model Co-Op Bank Ltd</option>
                            <option value="Moga Veera Co- Op Bk" data-aura-rendered-by="331:361;a">Moga Veera Co- Op Bank</option>
                            <option value="Money Dart Global Services" data-aura-rendered-by="333:361;a">Money Dart Global Services</option>
                            <option value="Mumbai Citizen Credit Co op Bank" data-aura-rendered-by="335:361;a">Mumbai Citizen Credit Co op Bank</option>
                            <option value="Mumbai Dist Central Coop Bk" data-aura-rendered-by="337:361;a">Mumbai District Central Cooperative Bank</option>
                            <option value="Municipal Co-Op Bk LTD" data-aura-rendered-by="339:361;a">Municipal Co-Op Bank LTD</option>
                            <option value="Nagar Urban Co op Bk" data-aura-rendered-by="341:361;a">Nagar Urban Co operative Bank</option>
                            <option value="Nagpur Nagrik Sah Bk" data-aura-rendered-by="343:361;a">Nagpur Nagrik Sahakari Bank</option>
                            <option value="Nashik Merchant Co. Op. Bk Ltd" data-aura-rendered-by="345:361;a">Nashik Merchant Co. Op. Bank Ltd</option>
                            <option value="National Bk for Agriculture and Rural Development" data-aura-rendered-by="347:361;a">National Bank for Agriculture and Rural Development</option>
                            <option value="National Bk Of Abudhabi" data-aura-rendered-by="349:361;a">National Bank Of Abudhabi</option>
                            <option value="National Bk of Dubai" data-aura-rendered-by="351:361;a">National Bank of Dubai</option>
                            <option value="National Bk of Ras Al-Khaimah" data-aura-rendered-by="353:361;a">National Bank of Ras Al-Khaimah</option>
                            <option value="Natwest Bk" data-aura-rendered-by="355:361;a">Natwest Bank</option>
                            <option value="Naval Dockyard Cooperative Bank" data-aura-rendered-by="357:361;a">Naval Dockyard Cooperative Bank</option>
                            <option value="Navi Mum Co-op Bk" data-aura-rendered-by="359:361;a">Navi Mumbai Co-operative Bank</option>
                            <option value="Navjivan Co-op Bk" data-aura-rendered-by="361:361;a">Navjivan Co-op Bank</option>
                            <option value="New India Co-op Bk" data-aura-rendered-by="363:361;a">New India Co-operative Bank</option>
                            <option value="NKGSB Coop Bk Ltd" data-aura-rendered-by="365:361;a">NKGSB Cooperative Bank Ltd</option>
                            <option value="North Kanara Gaud Saraswat Bk" data-aura-rendered-by="367:361;a">North Kanara Gaud Saraswat Bank</option>
                            <option value="Oman Exc" data-aura-rendered-by="369:361;a">Oman Exchange</option>
                            <option value="Oman International Bk S A O G" data-aura-rendered-by="371:361;a">Oman International Bank S A O G</option>
                            <option value="Oriental Bk of Comm" data-aura-rendered-by="373:361;a">Oriental Bank of Commerce</option>
                            <option value="Orient exc" data-aura-rendered-by="375:361;a">Orient exchange</option>
                            <option value="Other" data-aura-rendered-by="377:361;a">Other</option>
                            <option value="Panchsheel Mercantile Co Op Bk" data-aura-rendered-by="379:361;a">Panchsheel Mercantile Co Op Bank</option>
                            <option value="Parner Taluka Sainik Sah Bk" data-aura-rendered-by="381:361;a">Parner Taluka Sainik Sahakari Bank</option>
                            <option value="Parshwanath Co Op Bk Ltd" data-aura-rendered-by="383:361;a">Parshwanath Co Op Bank Ltd</option>
                            <option value="Parsik Janta Sahkari Bank" data-aura-rendered-by="385:361;a">Parsik Janta Sahkari Bank</option>
                            <option value="PAVANA SAH BK LTD" data-aura-rendered-by="387:361;a">PAVANA SAHAKARI BANK LTD</option>
                            <option value="Piramal HSG Finance Ltd" data-aura-rendered-by="389:361;a">Piramal Housing Finance Limited</option>
                            <option value="PNB HSG Finance Ltd." data-aura-rendered-by="391:361;a">PNB Housing Finance Ltd.</option>
                            <option value="Post Office" data-aura-rendered-by="393:361;a">Post Office</option>
                            <option value="Pune Dist Central Coop Bk ltd" data-aura-rendered-by="395:361;a">Pune Dist Central Coop Bank ltd</option>
                            <option value="Pune Peoples Co-op Bk Ltd" data-aura-rendered-by="397:361;a">Pune Peoples Co-op Bank Ltd</option>
                            <option value="Punjab &amp; Maharashtra Co-op Bk" data-aura-rendered-by="399:361;a">Punjab &amp; Maharashtra Co-operative Bank</option>
                            <option value="Punjab &amp; Sind Bk" data-aura-rendered-by="401:361;a">Punjab &amp; Sind Bank</option>
                            <option value="Punjab National Bk" data-aura-rendered-by="403:361;a">Punjab National Bank</option>
                            <option value="Qatar National Bk" data-aura-rendered-by="405:361;a">Qatar National Bank</option>
                            <option value="RAJAPUR BK" data-aura-rendered-by="407:361;a">RAJAPUR BANK</option>
                            <option value="RAJARAMBAPU SAH BK" data-aura-rendered-by="409:361;a">RAJARAMBAPU SAHAKARI BANK</option>
                            <option value="Rajgurunagar Co-op Bk Ltd" data-aura-rendered-by="411:361;a">Rajgurunagar Co-op Bank Ltd</option>
                            <option value="Rajkot Nagrik Sah. Bk LTD" data-aura-rendered-by="413:361;a">Rajkot Nagrik Sah. Bank LTD</option>
                            <option value="RAK BK" data-aura-rendered-by="415:361;a">RAK BANK</option>
                            <option value="Rational FX – Foreign Exc Ltd" data-aura-rendered-by="417:361;a">Rational FX – Foreign Exchange Ltd</option>
                            <option value="Ratnakar Bk" data-aura-rendered-by="419:361;a">Ratnakar Bank</option>
                            <option value="RBL Bk" data-aura-rendered-by="421:361;a">RBL Bank</option>
                            <option value="Regions Bk" data-aura-rendered-by="423:361;a">Regions Bank</option>
                            <option value="Reserve Bk Of India" data-aura-rendered-by="425:361;a">Reserve Bank Of India</option>
                            <option value="Royal Bk Of Canada" data-aura-rendered-by="427:361;a">Royal Bank Of Canada</option>
                            <option value="SABB" data-aura-rendered-by="429:361;a">SABB</option>
                            <option value="Sahebrao Deshmukh Bk" data-aura-rendered-by="431:361;a">Sahebrao Deshmukh Bank</option>
                            <option value="Sangli Co-Op Bk" data-aura-rendered-by="433:361;a">Sangli Co-Op Bank</option>
                            <option value="Saraswat Bk" data-aura-rendered-by="435:361;a">Saraswat Bank</option>
                            <option value="Sarvodaya Bk" data-aura-rendered-by="437:361;a">Sarvodaya Bank</option>
                            <option value="SAWAI MADHOPUR KENDRIYA SAH BK LTD" data-aura-rendered-by="439:361;a">SAWAI MADHOPUR KENDRIYA SAHAKARI BANK LTD</option>
                            <option value="SBI Comm and International Bk Ltd." data-aura-rendered-by="441:361;a">SBI Commercial and International Bank Ltd.</option>
                            <option value="SCOTIA BK" data-aura-rendered-by="443:361;a">SCOTIA BANK</option>
                            <option value="Shamrao Vithal Bk" data-aura-rendered-by="445:361;a">Shamrao Vithal Bank</option>
                            <option value="Shankarrao Mohite Patil Sah Bk" data-aura-rendered-by="447:361;a">Shankarrao Mohite Patil Sahkari Bank</option>
                            <option value="Sharjah Islamic Bk" data-aura-rendered-by="449:361;a">Sharjah Islamic Bank</option>
                            <option value="Shinhan Bk" data-aura-rendered-by="451:361;a">Shinhan Bank</option>
                            <option value="Shree Durgaparameshwari Co-op Credit Soc Ltd" data-aura-rendered-by="453:361;a">Shree Durgaparameshwari Co-operative Credit Society Ltd</option>
                            <option value="Shree Laxmi Co-Op Bk LTD" data-aura-rendered-by="455:361;a">Shree Laxmi Co-Op Bank LTD</option>
                            <option value="Shri Arihant Co-op Bk Ltd" data-aura-rendered-by="457:361;a">Shri Arihant Co-op Bank Ltd</option>
                            <option value="Shri Chhatrapati Rajashri Shahu Urban Co-Op. Bk LTD" data-aura-rendered-by="459:361;a">Shri Chhatrapati Rajashri Shahu Urban Co-Op. Bank LTD</option>
                            <option value="Shri Warna co -op bk." data-aura-rendered-by="461:361;a">Shri Warna co -op bank.</option>
                            <option value="SINDHUDURGA CO OP BK LTD" data-aura-rendered-by="463:361;a">SINDHUDURGA CO OPERAT VE BANK LTD</option>
                            <option value="Social Islami Bk" data-aura-rendered-by="465:361;a">Social Islami Bank</option>
                            <option value="Solapur Jan Sah Bk Ltd" data-aura-rendered-by="467:361;a">Solapur Janta Sahakari Bank Ltd</option>
                            <option value="South Indian Bk" data-aura-rendered-by="469:361;a">South Indian Bank</option>
                            <option value="Stand Chart Bk" data-aura-rendered-by="471:361;a">Standard Chartered Bank</option>
                            <option value="S Bk of Bikaner and Jaipur" data-aura-rendered-by="473:361;a">State Bank of Bikaner and Jaipur</option>
                            <option value="S B of Hyd" data-aura-rendered-by="475:361;a">State Bank of Hyderabad</option>
                            <option value="S B I" data-aura-rendered-by="477:361;a">State Bank of India</option>
                            <option value="State Bk of Mauritius Ltd." data-aura-rendered-by="479:361;a">State Bank of Mauritius Ltd.</option>
                            <option value="State Bk of Mysore" data-aura-rendered-by="481:361;a">State Bank of Mysore</option>
                            <option value="State Bk of Patiala" data-aura-rendered-by="483:361;a">State Bank of Patiala</option>
                            <option value="State Bk of Travancore" data-aura-rendered-by="485:361;a">State Bank of Travancore</option>
                            <option value="State Transport Co op Bk Ltd" data-aura-rendered-by="487:361;a">State Transport Co op Bank Ltd</option>
                            <option value="Suncorp Bk" data-aura-rendered-by="489:361;a">Suncorp Bank</option>
                            <option value="Syndicate Bk" data-aura-rendered-by="491:361;a">Syndicate Bank</option>
                            <option value="Tamilnad Mercantile Bk Ltd." data-aura-rendered-by="493:361;a">Tamilnad Mercantile Bank Ltd.</option>
                            <option value="TATA CAPITAL HSG" data-aura-rendered-by="495:361;a">TATA CAPITAL HOUSING</option>
                            <option value="TD Bk" data-aura-rendered-by="497:361;a">TD Bank</option>
                            <option value="Techcombank Vietnam" data-aura-rendered-by="499:361;a">Techcombank Vietnam</option>
                            <option value="Thane Bharat Sah Bk Ltd" data-aura-rendered-by="501:361;a">Thane Bharat Sahakari Bank Ltd</option>
                            <option value="Thane Jan Sah Bk" data-aura-rendered-by="503:361;a">Thane Janata Sahakari Bank</option>
                            <option value="The A.P Mahesh Co-op Urban Bk Ltd" data-aura-rendered-by="505:361;a">The A.P Mahesh Co-operative Urban Bank Ltd</option>
                            <option value="The Abhinav Sah Bk Ltd" data-aura-rendered-by="507:361;a">The Abhinav Sahakari Bank Ltd</option>
                            <option value="The Akola Jan Comm Co-op Bk Ltd" data-aura-rendered-by="509:361;a">The Akola Janata Commercial Co-operative Bank Ltd</option>
                            <option value="The Annasaheb Savant Co-op Urban Bank Mahad Ltd" data-aura-rendered-by="511:361;a">The Annasaheb Savant Co-op Urban Bank Mahad Ltd</option>
                            <option value="THE ANNASAHEB SAVANT COOP URB BK MA" data-aura-rendered-by="513:361;a">THE ANNASAHEB SAVANT COOP URB BK MA</option>
                            <option value="The Bk of Rajasthan Ltd" data-aura-rendered-by="515:361;a">The Bank of Rajasthan Limited</option>
                            <option value="The Chembur Nag Sah Bk Ltd" data-aura-rendered-by="517:361;a">The Chembur Nagarik Sahakari Bank Ltd</option>
                            <option value="The Deccan Merchants Co-op Bk Ltd" data-aura-rendered-by="519:361;a">The Deccan Merchants Co-operative Bank Ltd</option>
                            <option value="The Development Bk Of Singapore Ltd" data-aura-rendered-by="521:361;a">The Development Bank Of Singapore Limited</option>
                            <option value="The Dhanalakshmi Bk Ltd." data-aura-rendered-by="523:361;a">The Dhanalakshmi Bank Limited.</option>
                            <option value="The Federal Bk Ltd." data-aura-rendered-by="525:361;a">The Federal Bank Ltd.</option>
                            <option value="THE GOA URBAN CO OP BK" data-aura-rendered-by="527:361;a">THE GOA URBAN CO OP BANK</option>
                            <option value="The HDFC Bk Ltd." data-aura-rendered-by="529:361;a">HDFC Bank Ltd.</option>
                            <option value="The HSBC Ltd." data-aura-rendered-by="531:361;a">The Hongkong &amp; Shanghai Banking Corporation Ltd.</option>
                            <option value="The Income Tax Dept. Co-op. Bk Ltd" data-aura-rendered-by="533:361;a">The Income Tax Dept. Co-op. Bank Ltd</option>
                            <option value="The Jain Sah Bk Ltd" data-aura-rendered-by="535:361;a">The Jain Sahakari Bank Ltd</option>
                            <option value="The Jalgaon Peoples Co-Op Bk Ltd" data-aura-rendered-by="537:361;a">The Jalgaon Peoples Co-Op Bank Ltd</option>
                            <option value="The Jammu &amp; Kashmir Bk Ltd." data-aura-rendered-by="539:361;a">The Jammu &amp; Kashmir Bank Ltd.</option>
                            <option value="THE KALUPUR COMM CO. OP. BK LTD." data-aura-rendered-by="541:361;a">THE KALUPUR COMMERCIAL CO. OP. BANK LTD.</option>
                            <option value="The Kalyan Jan Sah Bk Ltd" data-aura-rendered-by="543:361;a">The Kalyan Janata Sahakari Bank Ltd</option>
                            <option value="The Kurla Nag Sah Bk" data-aura-rendered-by="545:361;a">The Kurla Nagrik Sahakari Bank</option>
                            <option value="The Lakshmi Vilas Bk Ltd" data-aura-rendered-by="547:361;a">The Lakshmi Vilas Bank Ltd</option>
                            <option value="The Maharashtra State Co-op. Bk Ltd" data-aura-rendered-by="549:361;a">The Maharashtra State Co-op. Bank Ltd</option>
                            <option value="The Maharashtra State Co-op Bk Ltd" data-aura-rendered-by="551:361;a">The Maharashtra State Co-operative Bank Ltd</option>
                            <option value="The Naroda Nagrik Co-op Bank Limited" data-aura-rendered-by="553:361;a">The Naroda Nagrik Co-op Bank Limited</option>
                            <option value="The National Co-op bk ltd" data-aura-rendered-by="555:361;a">The National Co-operative bank limited</option>
                            <option value="The Naval Dockyard Co-op Bk Ltd" data-aura-rendered-by="557:361;a">The Naval Dockyard Co-op Bank Ltd</option>
                            <option value="The Ratnakar Bk Ltd." data-aura-rendered-by="559:361;a">The Ratnakar Bank Ltd.</option>
                            <option value="The Royal Bk of Scotland N.V." data-aura-rendered-by="561:361;a">The Royal Bank of Scotland N.V.</option>
                            <option value="The Satara Sah Bk Ltd" data-aura-rendered-by="563:361;a">The Satara Sahkari Bank Ltd</option>
                            <option value="The Seva Vikas Co-op bk Ltd" data-aura-rendered-by="565:361;a">The Seva Vikas Co-operative bank Ltd</option>
                            <option value="The South Indian Bk Ltd." data-aura-rendered-by="567:361;a">The South Indian Bank Ltd.</option>
                            <option value="The Surat People's Co-op Bk Ltd" data-aura-rendered-by="569:361;a">The Surat People's Co-op Bank Ltd</option>
                            <option value="The Thane Dist Central Co-Op Bk LTD" data-aura-rendered-by="571:361;a">The Thane District Central Co-Op Bank LTD</option>
                            <option value="The Vasai Jan Sah Bk Ltd" data-aura-rendered-by="573:361;a">The Vasai Janata Sahakari Bank Ltd</option>
                            <option value="TIMES OF MONEY PVT LTD" data-aura-rendered-by="575:361;a">TIMES OF MONEY PVT LTD</option>
                            <option value="TIRUPATI URBAN COOP BK" data-aura-rendered-by="577:361;a">TIRUPATI URBAN COOPERATIVE BANK</option>
                            <option value="UAE Exc Centre LLC" data-aura-rendered-by="579:361;a">UAE Exchange Centre LLC</option>
                            <option value="UCO Bk" data-aura-rendered-by="581:361;a">UCO Bank</option>
                            <option value="Ujjivan Small Finance Bank" data-aura-rendered-by="583:361;a">Ujjivan Small Finance Bank</option>
                            <option value="Union Bk of India" data-aura-rendered-by="585:361;a">Union Bank of India</option>
                            <option value="Union Bk of Trichur" data-aura-rendered-by="587:361;a">Union Bank of Trichur</option>
                            <option value="United Arab Bk" data-aura-rendered-by="589:361;a">United Arab Bank</option>
                            <option value="United Bk Of India" data-aura-rendered-by="591:361;a">United Bank Of India</option>
                            <option value="Unit Trust of India" data-aura-rendered-by="593:361;a">Unit Trust of India</option>
                            <option value="Unity Small Finance Bank" data-aura-rendered-by="595:361;a">Unity Small Finance Bank</option>
                            <option value="Vaidyanath Bk" data-aura-rendered-by="597:361;a">Vaidyanath Bank</option>
                            <option value="Vasai Vikas Sah Bk Ltd" data-aura-rendered-by="599:361;a">Vasai Vikas Sahakari Bank Ltd</option>
                            <option value="Vijaya Bk" data-aura-rendered-by="601:361;a">Vijaya Bank</option>
                            <option value="Vishwas Co-op Bk Ltd" data-aura-rendered-by="603:361;a">Vishwas Co-op Bank Ltd</option>
                            <option value="VYSVSAYIK SAH BK LTD." data-aura-rendered-by="605:361;a">VYSVSAYIK SAHAKARI BANK LIMITED.</option>
                            <option value="Wall Street Exc" data-aura-rendered-by="607:361;a">Wall Street Exchange</option>
                            <option value="Wardhaman Urban Co-Op Bk Ltd" data-aura-rendered-by="609:361;a">Wardhaman Urban Co-Operation Bank Ltd</option>
                            <option value="Wells Fargo Bk" data-aura-rendered-by="611:361;a">Wells Fargo Bank</option>
                            <option value="Western Union Money Transfer Exc" data-aura-rendered-by="613:361;a">Western Union Money Transfer Exchange</option>
                            <option value="WESTPAC BANKING CO-OP" data-aura-rendered-by="615:361;a">WESTPAC BANKING CORPORATION</option>
                            <option value="Yes Bk" data-aura-rendered-by="617:361;a">Yes Bank</option>
                            <option value="ZORASTRIAN BK" data-aura-rendered-by="619:361;a">ZORASTRIAN BANK</option>
                            <option value="IDFC FIRST BANK LIMITED" data-aura-rendered-by="621:361;a">IDFC FIRST BANK LIMITED</option>

                        </select>
                    </div>
                    <div>
                        <label htmlFor="account_holder_name">Account Holder Name</label>
                        <input type="text" className='form-control' name="account_holder_name" id="account_holder_name" />
                    </div>
                    <div>
                        <label htmlFor="cheque_sent_to">Cheque Sent to</label>
                        <input type="text" name="cheque_sent_to" id="cheque_sent_to" className='form-control' />
                    </div>
                </>
            case "Debit/Credit Card":
                return <>
                <div>
                    <label htmlFor="transaction_date"> Transaction Date</label>
                    <input type="date" name="transaction_date" className='form-control' id="transaction_date" />
                </div>
                <div>
                    <label htmlFor="debit_credit_card_no">Debit/Credit Card Number</label>
                    <input type="text" className='form-control' name="debit_credit_card_no" id="debit_credit_card_no" />
                </div>
                <div>
                        <label htmlFor="bank_name">Bank Name</label>
                        <select name="bank_name" id='bank_name' className="form-control">
                            <option value="Aavas Financiers LTD" data-aura-rendered-by="3:361;a">Aavas Financiers LTD</option>
                            <option value="ABHYUDAYA BK" data-aura-rendered-by="5:361;a">ABHYUDAYA BANK</option>
                            <option value="ABN Amro" data-aura-rendered-by="7:361;a">ABN Amro</option>
                            <option value="Abu Dhabi Comm Bk Ltd." data-aura-rendered-by="9:361;a">Abu Dhabi Commercial Bank Ltd.</option>
                            <option value="ACE CO OP BK LTD" data-aura-rendered-by="11:361;a">ACE CO OPERATIVE BANK LTD</option>
                            <option value="Aditya Birla HSG Finance Ltd" data-aura-rendered-by="13:361;a">Aditya Birla Housing Finance Ltd</option>
                            <option value="African Banking Corporation Limited" data-aura-rendered-by="15:361;a">African Banking Corporation Limited</option>
                            <option value="Ahli United BK" data-aura-rendered-by="17:361;a">Ahli United Bank</option>
                            <option value="Ahmedabad Mercantile Co op Bk" data-aura-rendered-by="19:361;a">Ahmedabad Mercantile Co operative Bank</option>
                            <option value="AHMEDNAGAR SHAHAR SAH BK MARYADIT" data-aura-rendered-by="21:361;a">AHMEDNAGAR SHAHAR SAHAKARI BANK MARYADIT</option>
                            <option value="Ajara Urban Cooperative Bank" data-aura-rendered-by="23:361;a">Ajara Urban Cooperative Bank</option>
                            <option value="AL AHALIA MONEY EXC BUREAU &amp; Payment" data-aura-rendered-by="25:361;a">AL AHALIA MONEY EXCHANGE BUREAU &amp; Payment</option>
                            <option value="AL ANSARI EXC" data-aura-rendered-by="27:361;a">AL ANSARI EXCHANGE</option>
                            <option value="ALAPPUZHA DIS CO -OP BANK" data-aura-rendered-by="29:361;a">ALAPPUZHA DISTRICT CO -OP BANK</option>
                            <option value="Aldar Exc, Doha, Qatar" data-aura-rendered-by="31:361;a">Aldar Exchange, Doha, Qatar</option>
                            <option value="ALFALA EXC CO" data-aura-rendered-by="33:361;a">ALFALA EXCHANGE CO</option>
                            <option value="Al Fardan Exc" data-aura-rendered-by="35:361;a">Al Fardan Exchange</option>
                            <option value="Allahabad Bk" data-aura-rendered-by="37:361;a">Allahabad Bank</option>
                            <option value="AL MIRQAB EXC" data-aura-rendered-by="39:361;a">AL MIRQAB EXCHANGE</option>
                            <option value="Almora Urban Coop Bk Ltd" data-aura-rendered-by="41:361;a">Almora Urban Coop Bank Ltd</option>
                            <option value="AL Mulla International Exc Com K.S.C." data-aura-rendered-by="43:361;a">AL Mulla International Exchange Company K.S.C.</option>
                            <option value="Al Muzaini Exch Co." data-aura-rendered-by="45:361;a">Al Muzaini Exchange Co.</option>
                            <option value="AL RAZOUKI EXC SHARJAH UAE" data-aura-rendered-by="47:361;a">AL RAZOUKI EXCHANGE SHARJAH UAE</option>
                            <option value="AL ROSTAMANI EXC" data-aura-rendered-by="49:361;a">AL ROSTAMANI EXCHANGE</option>
                            <option value="AL-ZAMAN Exc" data-aura-rendered-by="51:361;a">AL-ZAMAN Exchange</option>
                            <option value="Ambernath JAI Hind Co Op Bk" data-aura-rendered-by="53:361;a">Ambernath JAI Hind Co Operative Bank</option>
                            <option value="American Chartered Bk" data-aura-rendered-by="55:361;a">American Chartered Bank</option>
                            <option value="American Express Bank Ltd." data-aura-rendered-by="57:361;a">American Express Bank Ltd.</option>
                            <option value="Andhra Bk" data-aura-rendered-by="59:361;a">Andhra Bank</option>
                            <option value="AnnaSaheb Magar Sah Bk" data-aura-rendered-by="61:361;a">AnnaSaheb Magar Sahakari Bank</option>
                            <option value="Antwerp Diamond Bk N.V." data-aura-rendered-by="63:361;a">Antwerp Diamond Bank N.V.</option>
                            <option value="ANZ Bk" data-aura-rendered-by="65:361;a">ANZ Bank</option>
                            <option value="Apna Sah Bk" data-aura-rendered-by="67:361;a">Apna Sahakari Bank</option>
                            <option value="ASB Auckland New Zealand" data-aura-rendered-by="69:361;a">ASB Auckland New Zealand</option>
                            <option value="AU Small Finance Bank" data-aura-rendered-by="71:361;a">AU Small Finance Bank</option>
                            <option value="Australia and New Zealand Banking Group Ltd" data-aura-rendered-by="73:361;a">Australia and New Zealand Banking Group Ltd</option>
                            <option value="Axis Bk" data-aura-rendered-by="75:361;a">Axis Bank</option>
                            <option value="BAHRAIN EXC CO W L L" data-aura-rendered-by="77:361;a">BAHRAIN EXCHANGE CO W L L</option>
                            <option value="Bajaj Housing Finance Ltd" data-aura-rendered-by="79:361;a">Bajaj Housing Finance Ltd</option>
                            <option value="BANDHAN BK" data-aura-rendered-by="81:361;a">BANDHAN BANK</option>
                            <option value="Bk of America N.A." data-aura-rendered-by="83:361;a">Bank of America N.A.</option>
                            <option value="Bk of Bahrain &amp; Kuwait" data-aura-rendered-by="85:361;a">Bank of Bahrain &amp; Kuwait</option>
                            <option value="Bk of Bahrain and Kuwait" data-aura-rendered-by="87:361;a">Bank of Bahrain and Kuwait</option>
                            <option value="Bk of Baroda" data-aura-rendered-by="89:361;a">Bank of Baroda</option>
                            <option value="Bk of Cyprus" data-aura-rendered-by="91:361;a">Bank of Cyprus</option>
                            <option value="Bk of India" data-aura-rendered-by="93:361;a">Bank of India</option>
                            <option value="Bk of Maharashtra" data-aura-rendered-by="95:361;a">Bank of Maharashtra</option>
                            <option value="Bk of Muscat" data-aura-rendered-by="97:361;a">Bank of Muscat</option>
                            <option value="Bk of Newzealand" data-aura-rendered-by="99:361;a">Bank of Newzealand</option>
                            <option value="Bk Of Nova Scotia" data-aura-rendered-by="101:361;a">Bank Of Nova Scotia</option>
                            <option value="Bk Of Tokyo" data-aura-rendered-by="103:361;a">Bank Of Tokyo</option>
                            <option value="Bk Sohar" data-aura-rendered-by="105:361;a">Bank Sohar</option>
                            <option value="Baramati Sah Bk LTD" data-aura-rendered-by="107:361;a">Baramati Sahakari Bank LTD</option>
                            <option value="Barclays Bk Plc" data-aura-rendered-by="109:361;a">Barclays Bank Plc</option>
                            <option value="Bassein Catholic Co-op Bk" data-aura-rendered-by="111:361;a">Bassein Catholic Co-op Bank</option>
                            <option value="Belhasa Global Exc" data-aura-rendered-by="113:361;a">Belhasa Global Exchange</option>
                            <option value="Bharat Co-op Bk" data-aura-rendered-by="115:361;a">Bharat Co-operative Bank</option>
                            <option value="BNP PARIBAS" data-aura-rendered-by="117:361;a">BNP PARIBAS</option>
                            <option value="Bombay Mercantile Co-op Bk" data-aura-rendered-by="119:361;a">Bombay Mercantile Co-op Bank</option>
                            <option value="Bombay Merchantile Bk" data-aura-rendered-by="121:361;a">Bombay Merchantile Bank</option>
                            <option value="BULDHANA URBAN CO-OP BK" data-aura-rendered-by="123:361;a">BULDHANA URBAN CO-OP BANK</option>
                            <option value="Canara Bk" data-aura-rendered-by="125:361;a">Canara Bank</option>
                            <option value="Catholic Syrian Bk Ltd." data-aura-rendered-by="127:361;a">Catholic Syrian Bank Ltd.</option>
                            <option value="Central Bk of India" data-aura-rendered-by="129:361;a">Central Bank of India</option>
                            <option value="Chase Bk" data-aura-rendered-by="131:361;a">Chase Bank</option>
                            <option value="China Construction Bk" data-aura-rendered-by="133:361;a">China Construction Bank</option>
                            <option value="Citibank N.A." data-aura-rendered-by="135:361;a">Citibank N.A.</option>
                            <option value="Citizen Co-op Bk Ltd." data-aura-rendered-by="137:361;a">Citizen Co-operative Bank Ltd.</option>
                            <option value="Citizens Bk" data-aura-rendered-by="139:361;a">Citizens Bank</option>
                            <option value="City Exc LLC" data-aura-rendered-by="141:361;a">City Exchange LLC</option>
                            <option value="City Union Bk Ltd." data-aura-rendered-by="143:361;a">City Union Bank Ltd.</option>
                            <option value="Comm Bk International" data-aura-rendered-by="145:361;a">Commercial Bank International</option>
                            <option value="Commonwealth Bk" data-aura-rendered-by="147:361;a">Commonwealth Bank</option>
                            <option value="Compass Global Holdings" data-aura-rendered-by="149:361;a">Compass Global Holdings</option>
                            <option value="Corporation Bk" data-aura-rendered-by="151:361;a">Corporation Bank</option>
                            <option value="Cosmos Coop Bk" data-aura-rendered-by="153:361;a">Cosmos Cooperative Bank</option>
                            <option value="Crane Bk" data-aura-rendered-by="155:361;a">Crane Bank</option>
                            <option value="Credit Suisse" data-aura-rendered-by="157:361;a">Credit Suisse</option>
                            <option value="Daivadnya Sah Bk Niyamit" data-aura-rendered-by="159:361;a">Daivadnya Sahakari Bank Niyamit</option>
                            <option value="Dapoli Urban Co Op Bk Ltd" data-aura-rendered-by="161:361;a">Dapoli Urban Co Op Bank Ltd</option>
                            <option value="DBS Bk" data-aura-rendered-by="163:361;a">DBS Bank</option>
                            <option value="DCB Bk" data-aura-rendered-by="165:361;a">DCB Bank</option>
                            <option value="Deccan Grameen Bk" data-aura-rendered-by="167:361;a">Deccan Grameen Bank</option>
                            <option value="Dena Bk" data-aura-rendered-by="169:361;a">Dena Bank</option>
                            <option value="Deutsche Bk AG" data-aura-rendered-by="171:361;a">Deutsche Bank AG</option>
                            <option value="Development Credit Bk Ltd." data-aura-rendered-by="173:361;a">Development Credit Bank Ltd.</option>
                            <option value="DHANLAXMI BK LTD" data-aura-rendered-by="175:361;a">DHANLAXMI BANK LTD</option>
                            <option value="DHFL" data-aura-rendered-by="177:361;a">DHFL</option>
                            <option value="Diamond Trust Bk Kenya LTD, Kenya" data-aura-rendered-by="179:361;a">Diamond Trust Bank Kenya LTD, Kenya</option>
                            <option value="Discover Bank" data-aura-rendered-by="181:361;a">Discover Bank</option>
                            <option value="DMK JAOLI SAH BK LTD" data-aura-rendered-by="183:361;a">DMK JAOLI SAHAKARI BANK LTD</option>
                            <option value="Doha Bk" data-aura-rendered-by="185:361;a">Doha Bank</option>
                            <option value="DOLLARCO EXC CO LTD" data-aura-rendered-by="187:361;a">DOLLARCO EXCHANGE CO LTD</option>
                            <option value="Dombivali Nagarik Sah Bk" data-aura-rendered-by="189:361;a">Dombivali Nagarik Sahakari Bank</option>
                            <option value="Dyandeep Co op bk" data-aura-rendered-by="191:361;a">Dyandeep Co op bank</option>
                            <option value="Eastern Exc Est" data-aura-rendered-by="193:361;a">Eastern Exchange Est</option>
                            <option value="East West Bk" data-aura-rendered-by="195:361;a">East West Bank</option>
                            <option value="Economic Exch Centre, Dubai" data-aura-rendered-by="197:361;a">Economic Exchange Centre, Dubai</option>
                            <option value="Emirates India International Exch L.P.C" data-aura-rendered-by="199:361;a">Emirates India International Exchange L.P.C</option>
                            <option value="Emirates NBD bk" data-aura-rendered-by="201:361;a">Emirates NBD bank</option>
                            <option value="Equitas Small Finance Bank" data-aura-rendered-by="203:361;a">Equitas Small Finance Bank</option>
                            <option value="EXCELLENT CO OP BK LTD" data-aura-rendered-by="205:361;a">EXCELLENT CO OP BANK LTD</option>
                            <option value="EXIM Bk" data-aura-rendered-by="207:361;a">EXIM Bank</option>
                            <option value="FEDERAL BK" data-aura-rendered-by="209:361;a">FEDERAL BANK</option>
                            <option value="​Federal Bk Of Dwarka" data-aura-rendered-by="211:361;a">​Federal Bank Of Dwarka</option>
                            <option value="FirstRand Bk India LTD" data-aura-rendered-by="213:361;a">FirstRand Bank India Limited</option>
                            <option value="GCC Exc" data-aura-rendered-by="215:361;a">GCC Exchange</option>
                            <option value="​Gopinath Patil Janata Sah Bk" data-aura-rendered-by="217:361;a">​Gopinath Patil Janata Sahakari Bank</option>
                            <option value="GP Parsik Bk Ltd" data-aura-rendered-by="219:361;a">GP Parsik Bank Ltd</option>
                            <option value="GREATER BOMBAY CO.OP.BK LTD." data-aura-rendered-by="221:361;a">GREATER BOMBAY CO.OP.BANK LTD.</option>
                            <option value="Habib exc" data-aura-rendered-by="223:361;a">Habib exchange</option>
                            <option value="Hadi Exc" data-aura-rendered-by="225:361;a">Hadi Exchange</option>
                            <option value="Hindustan Co-op Bk Ltd" data-aura-rendered-by="227:361;a">Hindustan Co-operative Bank Limited</option>
                            <option value="HOME FIRST FINANCE COM INDIA PVT LTD" data-aura-rendered-by="229:361;a">HOME FIRST FINANCE COMPANY INDIA PRIVATE LIMITED</option>
                            <option value="HSBC Bk" data-aura-rendered-by="231:361;a">HSBC Bank</option>
                            <option value="I &amp; M bk Ltd" data-aura-rendered-by="233:361;a">I &amp; M bank Ltd</option>
                            <option value="ICICI Bk" data-aura-rendered-by="235:361;a">ICICI Bank</option>
                            <option value="IDBI Bk Ltd" data-aura-rendered-by="237:361;a">IDBI Bank Limited</option>
                            <option value="IDFC Bk" data-aura-rendered-by="239:361;a">IDFC Bank</option>
                            <option value="India Bulls Bk" data-aura-rendered-by="241:361;a">India Bulls Bank</option>
                            <option value="INDIA INFOLINE HSG FINANCE LTD" data-aura-rendered-by="243:361;a">INDIA INFOLINE HOUSING FINANCE LIMITED</option>
                            <option value="Indian Bk" data-aura-rendered-by="245:361;a">Indian Bank</option>
                            <option value="Indian Overseas Bk" data-aura-rendered-by="247:361;a">Indian Overseas Bank</option>
                            <option value="IndusInd Bank Ltd" data-aura-rendered-by="249:361;a">IndusInd Bank Limited</option>
                            <option value="Industrial Development Bk of India" data-aura-rendered-by="251:361;a">Industrial Development Bank of India</option>
                            <option value="ING Vysya Bk" data-aura-rendered-by="253:361;a">ING Vysya Bank</option>
                            <option value="Investec Bank Limited" data-aura-rendered-by="255:361;a">Investec Bank Limited</option>
                            <option value="Jai Vaibhav Laxmi Multi State Co.op Credit Soc Ltd" data-aura-rendered-by="257:361;a">Jai Vaibhav Laxmi Multi State Co.op Credit Society Ltd</option>
                            <option value="JALGOAN JAN SAH BK" data-aura-rendered-by="259:361;a">JALGOAN JANATA SAHAKARI BANK</option>
                            <option value="Jan Sah Bk" data-aura-rendered-by="261:361;a">Janakalyan Sahakari Bank</option>
                            <option value="Janaseva Sah Bk LTD" data-aura-rendered-by="263:361;a">Janaseva Sahakari Bank LTD</option>
                            <option value="Janta Sah Bk Ltd." data-aura-rendered-by="265:361;a">Janta Sahakari Bank Ltd.</option>
                            <option value="JK Bk Ltd" data-aura-rendered-by="267:361;a">JK Bank Ltd</option>
                            <option value="Joyalukkas Exc" data-aura-rendered-by="269:361;a">Joyalukkas Exchange</option>
                            <option value="J P Morgan Chase Bk, National Association" data-aura-rendered-by="271:361;a">J P Morgan Chase Bank, National Association</option>
                            <option value="Kallappanna Awade Ichalkaranji Jan Sah Bk Ltd" data-aura-rendered-by="273:361;a">Kallappanna Awade Ichalkaranji Janta Sahakari Bank Limited</option>
                            <option value="Kalyan Jan Sah Bk Ltd" data-aura-rendered-by="275:361;a">Kalyan Janata Sahakari Bank Ltd</option>
                            <option value="Kapol Co.Op Bk Ltd" data-aura-rendered-by="277:361;a">Kapol Co.Op Bank Ltd</option>
                            <option value="Karad Janata Sah Bk Ltd" data-aura-rendered-by="279:361;a">Karad Janata Sahakari Bank Ltd</option>
                            <option value="Karad Urban Co-op bk ltd" data-aura-rendered-by="281:361;a">Karad Urban Co-operative bank ltd</option>
                            <option value="Karnataka Bk" data-aura-rendered-by="283:361;a">Karnataka Bank</option>
                            <option value="Karur Vysya Bk Ltd." data-aura-rendered-by="285:361;a">Karur Vysya Bank Limited.</option>
                            <option value="Kokan Mercantile co-op bk" data-aura-rendered-by="287:361;a">Kokan Mercantile co-op bank</option>
                            <option value="Kotak Mahindra Bk Ltd" data-aura-rendered-by="289:361;a">Kotak Mahindra Bank Limited</option>
                            <option value="Kurla nagrik bk" data-aura-rendered-by="291:361;a">Kurla nagrik bank</option>
                            <option value="KUWAIT INDIA INTERNATIONAL EXC" data-aura-rendered-by="293:361;a">KUWAIT INDIA INTERNATIONAL EXCHANGE</option>
                            <option value="L&amp;T Housing Finance Ltd" data-aura-rendered-by="295:361;a">L&amp;T Housing Finance Ltd</option>
                            <option value="Lakshmi Vishnu Sah Bk Ltd" data-aura-rendered-by="297:361;a">Lakshmi Vishnu Sahakari Bank Ltd</option>
                            <option value="LARI EXC" data-aura-rendered-by="299:361;a">LARI EXCHANGE</option>
                            <option value="Latur Urban Bk" data-aura-rendered-by="301:361;a">Latur Urban Bank</option>
                            <option value="Laxmi Vilas Bk" data-aura-rendered-by="303:361;a">Laxmi Vilas Bank</option>
                            <option value="Lodhapay" data-aura-rendered-by="305:361;a">Lodhapay</option>
                            <option value="Lord Krishna Bk Ltd." data-aura-rendered-by="307:361;a">Lord Krishna Bank Ltd.</option>
                            <option value="LULU bk credit" data-aura-rendered-by="309:361;a">LULU bank credit</option>
                            <option value="Macquarie Bk Ltd" data-aura-rendered-by="311:361;a">Macquarie Bank Limited</option>
                            <option value="Mahanagar Bk" data-aura-rendered-by="313:361;a">Mahanagar Bank</option>
                            <option value="Maharashtra Bk" data-aura-rendered-by="315:361;a">Maharashtra Bank</option>
                            <option value="Maharashtra Grahmin Bk" data-aura-rendered-by="317:361;a">Maharashtra Grahmin Bank</option>
                            <option value="Mahesh Sah Bk Ltd" data-aura-rendered-by="319:361;a">Mahesh Sahakari Bank Ltd</option>
                            <option value="MASHREQ BK" data-aura-rendered-by="321:361;a">MASHREQ BANK</option>
                            <option value="Maybk" data-aura-rendered-by="323:361;a">Maybank</option>
                            <option value="Mehsana Urban Co-op Bk Ltd" data-aura-rendered-by="325:361;a">Mehsana Urban Co-op Bank Ltd</option>
                            <option value="Merrill Lynch" data-aura-rendered-by="327:361;a">Merrill Lynch</option>
                            <option value="Model Co-Op Bk Ltd" data-aura-rendered-by="329:361;a">Model Co-Op Bank Ltd</option>
                            <option value="Moga Veera Co- Op Bk" data-aura-rendered-by="331:361;a">Moga Veera Co- Op Bank</option>
                            <option value="Money Dart Global Services" data-aura-rendered-by="333:361;a">Money Dart Global Services</option>
                            <option value="Mumbai Citizen Credit Co op Bank" data-aura-rendered-by="335:361;a">Mumbai Citizen Credit Co op Bank</option>
                            <option value="Mumbai Dist Central Coop Bk" data-aura-rendered-by="337:361;a">Mumbai District Central Cooperative Bank</option>
                            <option value="Municipal Co-Op Bk LTD" data-aura-rendered-by="339:361;a">Municipal Co-Op Bank LTD</option>
                            <option value="Nagar Urban Co op Bk" data-aura-rendered-by="341:361;a">Nagar Urban Co operative Bank</option>
                            <option value="Nagpur Nagrik Sah Bk" data-aura-rendered-by="343:361;a">Nagpur Nagrik Sahakari Bank</option>
                            <option value="Nashik Merchant Co. Op. Bk Ltd" data-aura-rendered-by="345:361;a">Nashik Merchant Co. Op. Bank Ltd</option>
                            <option value="National Bk for Agriculture and Rural Development" data-aura-rendered-by="347:361;a">National Bank for Agriculture and Rural Development</option>
                            <option value="National Bk Of Abudhabi" data-aura-rendered-by="349:361;a">National Bank Of Abudhabi</option>
                            <option value="National Bk of Dubai" data-aura-rendered-by="351:361;a">National Bank of Dubai</option>
                            <option value="National Bk of Ras Al-Khaimah" data-aura-rendered-by="353:361;a">National Bank of Ras Al-Khaimah</option>
                            <option value="Natwest Bk" data-aura-rendered-by="355:361;a">Natwest Bank</option>
                            <option value="Naval Dockyard Cooperative Bank" data-aura-rendered-by="357:361;a">Naval Dockyard Cooperative Bank</option>
                            <option value="Navi Mum Co-op Bk" data-aura-rendered-by="359:361;a">Navi Mumbai Co-operative Bank</option>
                            <option value="Navjivan Co-op Bk" data-aura-rendered-by="361:361;a">Navjivan Co-op Bank</option>
                            <option value="New India Co-op Bk" data-aura-rendered-by="363:361;a">New India Co-operative Bank</option>
                            <option value="NKGSB Coop Bk Ltd" data-aura-rendered-by="365:361;a">NKGSB Cooperative Bank Ltd</option>
                            <option value="North Kanara Gaud Saraswat Bk" data-aura-rendered-by="367:361;a">North Kanara Gaud Saraswat Bank</option>
                            <option value="Oman Exc" data-aura-rendered-by="369:361;a">Oman Exchange</option>
                            <option value="Oman International Bk S A O G" data-aura-rendered-by="371:361;a">Oman International Bank S A O G</option>
                            <option value="Oriental Bk of Comm" data-aura-rendered-by="373:361;a">Oriental Bank of Commerce</option>
                            <option value="Orient exc" data-aura-rendered-by="375:361;a">Orient exchange</option>
                            <option value="Other" data-aura-rendered-by="377:361;a">Other</option>
                            <option value="Panchsheel Mercantile Co Op Bk" data-aura-rendered-by="379:361;a">Panchsheel Mercantile Co Op Bank</option>
                            <option value="Parner Taluka Sainik Sah Bk" data-aura-rendered-by="381:361;a">Parner Taluka Sainik Sahakari Bank</option>
                            <option value="Parshwanath Co Op Bk Ltd" data-aura-rendered-by="383:361;a">Parshwanath Co Op Bank Ltd</option>
                            <option value="Parsik Janta Sahkari Bank" data-aura-rendered-by="385:361;a">Parsik Janta Sahkari Bank</option>
                            <option value="PAVANA SAH BK LTD" data-aura-rendered-by="387:361;a">PAVANA SAHAKARI BANK LTD</option>
                            <option value="Piramal HSG Finance Ltd" data-aura-rendered-by="389:361;a">Piramal Housing Finance Limited</option>
                            <option value="PNB HSG Finance Ltd." data-aura-rendered-by="391:361;a">PNB Housing Finance Ltd.</option>
                            <option value="Post Office" data-aura-rendered-by="393:361;a">Post Office</option>
                            <option value="Pune Dist Central Coop Bk ltd" data-aura-rendered-by="395:361;a">Pune Dist Central Coop Bank ltd</option>
                            <option value="Pune Peoples Co-op Bk Ltd" data-aura-rendered-by="397:361;a">Pune Peoples Co-op Bank Ltd</option>
                            <option value="Punjab &amp; Maharashtra Co-op Bk" data-aura-rendered-by="399:361;a">Punjab &amp; Maharashtra Co-operative Bank</option>
                            <option value="Punjab &amp; Sind Bk" data-aura-rendered-by="401:361;a">Punjab &amp; Sind Bank</option>
                            <option value="Punjab National Bk" data-aura-rendered-by="403:361;a">Punjab National Bank</option>
                            <option value="Qatar National Bk" data-aura-rendered-by="405:361;a">Qatar National Bank</option>
                            <option value="RAJAPUR BK" data-aura-rendered-by="407:361;a">RAJAPUR BANK</option>
                            <option value="RAJARAMBAPU SAH BK" data-aura-rendered-by="409:361;a">RAJARAMBAPU SAHAKARI BANK</option>
                            <option value="Rajgurunagar Co-op Bk Ltd" data-aura-rendered-by="411:361;a">Rajgurunagar Co-op Bank Ltd</option>
                            <option value="Rajkot Nagrik Sah. Bk LTD" data-aura-rendered-by="413:361;a">Rajkot Nagrik Sah. Bank LTD</option>
                            <option value="RAK BK" data-aura-rendered-by="415:361;a">RAK BANK</option>
                            <option value="Rational FX – Foreign Exc Ltd" data-aura-rendered-by="417:361;a">Rational FX – Foreign Exchange Ltd</option>
                            <option value="Ratnakar Bk" data-aura-rendered-by="419:361;a">Ratnakar Bank</option>
                            <option value="RBL Bk" data-aura-rendered-by="421:361;a">RBL Bank</option>
                            <option value="Regions Bk" data-aura-rendered-by="423:361;a">Regions Bank</option>
                            <option value="Reserve Bk Of India" data-aura-rendered-by="425:361;a">Reserve Bank Of India</option>
                            <option value="Royal Bk Of Canada" data-aura-rendered-by="427:361;a">Royal Bank Of Canada</option>
                            <option value="SABB" data-aura-rendered-by="429:361;a">SABB</option>
                            <option value="Sahebrao Deshmukh Bk" data-aura-rendered-by="431:361;a">Sahebrao Deshmukh Bank</option>
                            <option value="Sangli Co-Op Bk" data-aura-rendered-by="433:361;a">Sangli Co-Op Bank</option>
                            <option value="Saraswat Bk" data-aura-rendered-by="435:361;a">Saraswat Bank</option>
                            <option value="Sarvodaya Bk" data-aura-rendered-by="437:361;a">Sarvodaya Bank</option>
                            <option value="SAWAI MADHOPUR KENDRIYA SAH BK LTD" data-aura-rendered-by="439:361;a">SAWAI MADHOPUR KENDRIYA SAHAKARI BANK LTD</option>
                            <option value="SBI Comm and International Bk Ltd." data-aura-rendered-by="441:361;a">SBI Commercial and International Bank Ltd.</option>
                            <option value="SCOTIA BK" data-aura-rendered-by="443:361;a">SCOTIA BANK</option>
                            <option value="Shamrao Vithal Bk" data-aura-rendered-by="445:361;a">Shamrao Vithal Bank</option>
                            <option value="Shankarrao Mohite Patil Sah Bk" data-aura-rendered-by="447:361;a">Shankarrao Mohite Patil Sahkari Bank</option>
                            <option value="Sharjah Islamic Bk" data-aura-rendered-by="449:361;a">Sharjah Islamic Bank</option>
                            <option value="Shinhan Bk" data-aura-rendered-by="451:361;a">Shinhan Bank</option>
                            <option value="Shree Durgaparameshwari Co-op Credit Soc Ltd" data-aura-rendered-by="453:361;a">Shree Durgaparameshwari Co-operative Credit Society Ltd</option>
                            <option value="Shree Laxmi Co-Op Bk LTD" data-aura-rendered-by="455:361;a">Shree Laxmi Co-Op Bank LTD</option>
                            <option value="Shri Arihant Co-op Bk Ltd" data-aura-rendered-by="457:361;a">Shri Arihant Co-op Bank Ltd</option>
                            <option value="Shri Chhatrapati Rajashri Shahu Urban Co-Op. Bk LTD" data-aura-rendered-by="459:361;a">Shri Chhatrapati Rajashri Shahu Urban Co-Op. Bank LTD</option>
                            <option value="Shri Warna co -op bk." data-aura-rendered-by="461:361;a">Shri Warna co -op bank.</option>
                            <option value="SINDHUDURGA CO OP BK LTD" data-aura-rendered-by="463:361;a">SINDHUDURGA CO OPERAT VE BANK LTD</option>
                            <option value="Social Islami Bk" data-aura-rendered-by="465:361;a">Social Islami Bank</option>
                            <option value="Solapur Jan Sah Bk Ltd" data-aura-rendered-by="467:361;a">Solapur Janta Sahakari Bank Ltd</option>
                            <option value="South Indian Bk" data-aura-rendered-by="469:361;a">South Indian Bank</option>
                            <option value="Stand Chart Bk" data-aura-rendered-by="471:361;a">Standard Chartered Bank</option>
                            <option value="S Bk of Bikaner and Jaipur" data-aura-rendered-by="473:361;a">State Bank of Bikaner and Jaipur</option>
                            <option value="S B of Hyd" data-aura-rendered-by="475:361;a">State Bank of Hyderabad</option>
                            <option value="S B I" data-aura-rendered-by="477:361;a">State Bank of India</option>
                            <option value="State Bk of Mauritius Ltd." data-aura-rendered-by="479:361;a">State Bank of Mauritius Ltd.</option>
                            <option value="State Bk of Mysore" data-aura-rendered-by="481:361;a">State Bank of Mysore</option>
                            <option value="State Bk of Patiala" data-aura-rendered-by="483:361;a">State Bank of Patiala</option>
                            <option value="State Bk of Travancore" data-aura-rendered-by="485:361;a">State Bank of Travancore</option>
                            <option value="State Transport Co op Bk Ltd" data-aura-rendered-by="487:361;a">State Transport Co op Bank Ltd</option>
                            <option value="Suncorp Bk" data-aura-rendered-by="489:361;a">Suncorp Bank</option>
                            <option value="Syndicate Bk" data-aura-rendered-by="491:361;a">Syndicate Bank</option>
                            <option value="Tamilnad Mercantile Bk Ltd." data-aura-rendered-by="493:361;a">Tamilnad Mercantile Bank Ltd.</option>
                            <option value="TATA CAPITAL HSG" data-aura-rendered-by="495:361;a">TATA CAPITAL HOUSING</option>
                            <option value="TD Bk" data-aura-rendered-by="497:361;a">TD Bank</option>
                            <option value="Techcombank Vietnam" data-aura-rendered-by="499:361;a">Techcombank Vietnam</option>
                            <option value="Thane Bharat Sah Bk Ltd" data-aura-rendered-by="501:361;a">Thane Bharat Sahakari Bank Ltd</option>
                            <option value="Thane Jan Sah Bk" data-aura-rendered-by="503:361;a">Thane Janata Sahakari Bank</option>
                            <option value="The A.P Mahesh Co-op Urban Bk Ltd" data-aura-rendered-by="505:361;a">The A.P Mahesh Co-operative Urban Bank Ltd</option>
                            <option value="The Abhinav Sah Bk Ltd" data-aura-rendered-by="507:361;a">The Abhinav Sahakari Bank Ltd</option>
                            <option value="The Akola Jan Comm Co-op Bk Ltd" data-aura-rendered-by="509:361;a">The Akola Janata Commercial Co-operative Bank Ltd</option>
                            <option value="The Annasaheb Savant Co-op Urban Bank Mahad Ltd" data-aura-rendered-by="511:361;a">The Annasaheb Savant Co-op Urban Bank Mahad Ltd</option>
                            <option value="THE ANNASAHEB SAVANT COOP URB BK MA" data-aura-rendered-by="513:361;a">THE ANNASAHEB SAVANT COOP URB BK MA</option>
                            <option value="The Bk of Rajasthan Ltd" data-aura-rendered-by="515:361;a">The Bank of Rajasthan Limited</option>
                            <option value="The Chembur Nag Sah Bk Ltd" data-aura-rendered-by="517:361;a">The Chembur Nagarik Sahakari Bank Ltd</option>
                            <option value="The Deccan Merchants Co-op Bk Ltd" data-aura-rendered-by="519:361;a">The Deccan Merchants Co-operative Bank Ltd</option>
                            <option value="The Development Bk Of Singapore Ltd" data-aura-rendered-by="521:361;a">The Development Bank Of Singapore Limited</option>
                            <option value="The Dhanalakshmi Bk Ltd." data-aura-rendered-by="523:361;a">The Dhanalakshmi Bank Limited.</option>
                            <option value="The Federal Bk Ltd." data-aura-rendered-by="525:361;a">The Federal Bank Ltd.</option>
                            <option value="THE GOA URBAN CO OP BK" data-aura-rendered-by="527:361;a">THE GOA URBAN CO OP BANK</option>
                            <option value="The HDFC Bk Ltd." data-aura-rendered-by="529:361;a">HDFC Bank Ltd.</option>
                            <option value="The HSBC Ltd." data-aura-rendered-by="531:361;a">The Hongkong &amp; Shanghai Banking Corporation Ltd.</option>
                            <option value="The Income Tax Dept. Co-op. Bk Ltd" data-aura-rendered-by="533:361;a">The Income Tax Dept. Co-op. Bank Ltd</option>
                            <option value="The Jain Sah Bk Ltd" data-aura-rendered-by="535:361;a">The Jain Sahakari Bank Ltd</option>
                            <option value="The Jalgaon Peoples Co-Op Bk Ltd" data-aura-rendered-by="537:361;a">The Jalgaon Peoples Co-Op Bank Ltd</option>
                            <option value="The Jammu &amp; Kashmir Bk Ltd." data-aura-rendered-by="539:361;a">The Jammu &amp; Kashmir Bank Ltd.</option>
                            <option value="THE KALUPUR COMM CO. OP. BK LTD." data-aura-rendered-by="541:361;a">THE KALUPUR COMMERCIAL CO. OP. BANK LTD.</option>
                            <option value="The Kalyan Jan Sah Bk Ltd" data-aura-rendered-by="543:361;a">The Kalyan Janata Sahakari Bank Ltd</option>
                            <option value="The Kurla Nag Sah Bk" data-aura-rendered-by="545:361;a">The Kurla Nagrik Sahakari Bank</option>
                            <option value="The Lakshmi Vilas Bk Ltd" data-aura-rendered-by="547:361;a">The Lakshmi Vilas Bank Ltd</option>
                            <option value="The Maharashtra State Co-op. Bk Ltd" data-aura-rendered-by="549:361;a">The Maharashtra State Co-op. Bank Ltd</option>
                            <option value="The Maharashtra State Co-op Bk Ltd" data-aura-rendered-by="551:361;a">The Maharashtra State Co-operative Bank Ltd</option>
                            <option value="The Naroda Nagrik Co-op Bank Limited" data-aura-rendered-by="553:361;a">The Naroda Nagrik Co-op Bank Limited</option>
                            <option value="The National Co-op bk ltd" data-aura-rendered-by="555:361;a">The National Co-operative bank limited</option>
                            <option value="The Naval Dockyard Co-op Bk Ltd" data-aura-rendered-by="557:361;a">The Naval Dockyard Co-op Bank Ltd</option>
                            <option value="The Ratnakar Bk Ltd." data-aura-rendered-by="559:361;a">The Ratnakar Bank Ltd.</option>
                            <option value="The Royal Bk of Scotland N.V." data-aura-rendered-by="561:361;a">The Royal Bank of Scotland N.V.</option>
                            <option value="The Satara Sah Bk Ltd" data-aura-rendered-by="563:361;a">The Satara Sahkari Bank Ltd</option>
                            <option value="The Seva Vikas Co-op bk Ltd" data-aura-rendered-by="565:361;a">The Seva Vikas Co-operative bank Ltd</option>
                            <option value="The South Indian Bk Ltd." data-aura-rendered-by="567:361;a">The South Indian Bank Ltd.</option>
                            <option value="The Surat People's Co-op Bk Ltd" data-aura-rendered-by="569:361;a">The Surat People's Co-op Bank Ltd</option>
                            <option value="The Thane Dist Central Co-Op Bk LTD" data-aura-rendered-by="571:361;a">The Thane District Central Co-Op Bank LTD</option>
                            <option value="The Vasai Jan Sah Bk Ltd" data-aura-rendered-by="573:361;a">The Vasai Janata Sahakari Bank Ltd</option>
                            <option value="TIMES OF MONEY PVT LTD" data-aura-rendered-by="575:361;a">TIMES OF MONEY PVT LTD</option>
                            <option value="TIRUPATI URBAN COOP BK" data-aura-rendered-by="577:361;a">TIRUPATI URBAN COOPERATIVE BANK</option>
                            <option value="UAE Exc Centre LLC" data-aura-rendered-by="579:361;a">UAE Exchange Centre LLC</option>
                            <option value="UCO Bk" data-aura-rendered-by="581:361;a">UCO Bank</option>
                            <option value="Ujjivan Small Finance Bank" data-aura-rendered-by="583:361;a">Ujjivan Small Finance Bank</option>
                            <option value="Union Bk of India" data-aura-rendered-by="585:361;a">Union Bank of India</option>
                            <option value="Union Bk of Trichur" data-aura-rendered-by="587:361;a">Union Bank of Trichur</option>
                            <option value="United Arab Bk" data-aura-rendered-by="589:361;a">United Arab Bank</option>
                            <option value="United Bk Of India" data-aura-rendered-by="591:361;a">United Bank Of India</option>
                            <option value="Unit Trust of India" data-aura-rendered-by="593:361;a">Unit Trust of India</option>
                            <option value="Unity Small Finance Bank" data-aura-rendered-by="595:361;a">Unity Small Finance Bank</option>
                            <option value="Vaidyanath Bk" data-aura-rendered-by="597:361;a">Vaidyanath Bank</option>
                            <option value="Vasai Vikas Sah Bk Ltd" data-aura-rendered-by="599:361;a">Vasai Vikas Sahakari Bank Ltd</option>
                            <option value="Vijaya Bk" data-aura-rendered-by="601:361;a">Vijaya Bank</option>
                            <option value="Vishwas Co-op Bk Ltd" data-aura-rendered-by="603:361;a">Vishwas Co-op Bank Ltd</option>
                            <option value="VYSVSAYIK SAH BK LTD." data-aura-rendered-by="605:361;a">VYSVSAYIK SAHAKARI BANK LIMITED.</option>
                            <option value="Wall Street Exc" data-aura-rendered-by="607:361;a">Wall Street Exchange</option>
                            <option value="Wardhaman Urban Co-Op Bk Ltd" data-aura-rendered-by="609:361;a">Wardhaman Urban Co-Operation Bank Ltd</option>
                            <option value="Wells Fargo Bk" data-aura-rendered-by="611:361;a">Wells Fargo Bank</option>
                            <option value="Western Union Money Transfer Exc" data-aura-rendered-by="613:361;a">Western Union Money Transfer Exchange</option>
                            <option value="WESTPAC BANKING CO-OP" data-aura-rendered-by="615:361;a">WESTPAC BANKING CORPORATION</option>
                            <option value="Yes Bk" data-aura-rendered-by="617:361;a">Yes Bank</option>
                            <option value="ZORASTRIAN BK" data-aura-rendered-by="619:361;a">ZORASTRIAN BANK</option>
                            <option value="IDFC FIRST BANK LIMITED" data-aura-rendered-by="621:361;a">IDFC FIRST BANK LIMITED</option>

                        </select>
                </div>
                    <div>
                        <label htmlFor="account_holder_name">Account Holder Name</label>
                        <input type="text" className='form-control' name="account_holder_name" id="account_holder_name" />
                    </div>
                    <div>
                        <label htmlFor="destination_bank_account_number">Destination Bank Account Number</label>
                        <input type="text" className='form-control' name="destination_bank_account_number" id="destination_bank_account_number" />
                    </div>
                    <div>
                        <label htmlFor="destination_bank_name">Destination Bank Name</label>
                        <input type="text" className='form-control' name="destination_bank_name" id="destination_bank_name" />
                    </div>
                </>

            case "Wire Transfer":
                return <>
                    <div>
                        <label htmlFor="transaction_date"> Transaction Date</label>
                        <input type="date" className='form-control' name="transaction_date" id="transaction_date" />
                    </div>
                    <div>
                        <label htmlFor="transaction_reference_number">Transaction Reference Number</label>
                        <input type="text" className='form-control' name="transaction_reference_number" id="transaction_reference_number" />
                    </div>
                    <div>
                        <label htmlFor="bank_name">Bank Name</label>
                        <select name="bank_name" id='bank_name' className="form-control">
                            <option value="Aavas Financiers LTD" data-aura-rendered-by="3:361;a">Aavas Financiers LTD</option>
                            <option value="ABHYUDAYA BK" data-aura-rendered-by="5:361;a">ABHYUDAYA BANK</option>
                            <option value="ABN Amro" data-aura-rendered-by="7:361;a">ABN Amro</option>
                            <option value="Abu Dhabi Comm Bk Ltd." data-aura-rendered-by="9:361;a">Abu Dhabi Commercial Bank Ltd.</option>
                            <option value="ACE CO OP BK LTD" data-aura-rendered-by="11:361;a">ACE CO OPERATIVE BANK LTD</option>
                            <option value="Aditya Birla HSG Finance Ltd" data-aura-rendered-by="13:361;a">Aditya Birla Housing Finance Ltd</option>
                            <option value="African Banking Corporation Limited" data-aura-rendered-by="15:361;a">African Banking Corporation Limited</option>
                            <option value="Ahli United BK" data-aura-rendered-by="17:361;a">Ahli United Bank</option>
                            <option value="Ahmedabad Mercantile Co op Bk" data-aura-rendered-by="19:361;a">Ahmedabad Mercantile Co operative Bank</option>
                            <option value="AHMEDNAGAR SHAHAR SAH BK MARYADIT" data-aura-rendered-by="21:361;a">AHMEDNAGAR SHAHAR SAHAKARI BANK MARYADIT</option>
                            <option value="Ajara Urban Cooperative Bank" data-aura-rendered-by="23:361;a">Ajara Urban Cooperative Bank</option>
                            <option value="AL AHALIA MONEY EXC BUREAU &amp; Payment" data-aura-rendered-by="25:361;a">AL AHALIA MONEY EXCHANGE BUREAU &amp; Payment</option>
                            <option value="AL ANSARI EXC" data-aura-rendered-by="27:361;a">AL ANSARI EXCHANGE</option>
                            <option value="ALAPPUZHA DIS CO -OP BANK" data-aura-rendered-by="29:361;a">ALAPPUZHA DISTRICT CO -OP BANK</option>
                            <option value="Aldar Exc, Doha, Qatar" data-aura-rendered-by="31:361;a">Aldar Exchange, Doha, Qatar</option>
                            <option value="ALFALA EXC CO" data-aura-rendered-by="33:361;a">ALFALA EXCHANGE CO</option>
                            <option value="Al Fardan Exc" data-aura-rendered-by="35:361;a">Al Fardan Exchange</option>
                            <option value="Allahabad Bk" data-aura-rendered-by="37:361;a">Allahabad Bank</option>
                            <option value="AL MIRQAB EXC" data-aura-rendered-by="39:361;a">AL MIRQAB EXCHANGE</option>
                            <option value="Almora Urban Coop Bk Ltd" data-aura-rendered-by="41:361;a">Almora Urban Coop Bank Ltd</option>
                            <option value="AL Mulla International Exc Com K.S.C." data-aura-rendered-by="43:361;a">AL Mulla International Exchange Company K.S.C.</option>
                            <option value="Al Muzaini Exch Co." data-aura-rendered-by="45:361;a">Al Muzaini Exchange Co.</option>
                            <option value="AL RAZOUKI EXC SHARJAH UAE" data-aura-rendered-by="47:361;a">AL RAZOUKI EXCHANGE SHARJAH UAE</option>
                            <option value="AL ROSTAMANI EXC" data-aura-rendered-by="49:361;a">AL ROSTAMANI EXCHANGE</option>
                            <option value="AL-ZAMAN Exc" data-aura-rendered-by="51:361;a">AL-ZAMAN Exchange</option>
                            <option value="Ambernath JAI Hind Co Op Bk" data-aura-rendered-by="53:361;a">Ambernath JAI Hind Co Operative Bank</option>
                            <option value="American Chartered Bk" data-aura-rendered-by="55:361;a">American Chartered Bank</option>
                            <option value="American Express Bank Ltd." data-aura-rendered-by="57:361;a">American Express Bank Ltd.</option>
                            <option value="Andhra Bk" data-aura-rendered-by="59:361;a">Andhra Bank</option>
                            <option value="AnnaSaheb Magar Sah Bk" data-aura-rendered-by="61:361;a">AnnaSaheb Magar Sahakari Bank</option>
                            <option value="Antwerp Diamond Bk N.V." data-aura-rendered-by="63:361;a">Antwerp Diamond Bank N.V.</option>
                            <option value="ANZ Bk" data-aura-rendered-by="65:361;a">ANZ Bank</option>
                            <option value="Apna Sah Bk" data-aura-rendered-by="67:361;a">Apna Sahakari Bank</option>
                            <option value="ASB Auckland New Zealand" data-aura-rendered-by="69:361;a">ASB Auckland New Zealand</option>
                            <option value="AU Small Finance Bank" data-aura-rendered-by="71:361;a">AU Small Finance Bank</option>
                            <option value="Australia and New Zealand Banking Group Ltd" data-aura-rendered-by="73:361;a">Australia and New Zealand Banking Group Ltd</option>
                            <option value="Axis Bk" data-aura-rendered-by="75:361;a">Axis Bank</option>
                            <option value="BAHRAIN EXC CO W L L" data-aura-rendered-by="77:361;a">BAHRAIN EXCHANGE CO W L L</option>
                            <option value="Bajaj Housing Finance Ltd" data-aura-rendered-by="79:361;a">Bajaj Housing Finance Ltd</option>
                            <option value="BANDHAN BK" data-aura-rendered-by="81:361;a">BANDHAN BANK</option>
                            <option value="Bk of America N.A." data-aura-rendered-by="83:361;a">Bank of America N.A.</option>
                            <option value="Bk of Bahrain &amp; Kuwait" data-aura-rendered-by="85:361;a">Bank of Bahrain &amp; Kuwait</option>
                            <option value="Bk of Bahrain and Kuwait" data-aura-rendered-by="87:361;a">Bank of Bahrain and Kuwait</option>
                            <option value="Bk of Baroda" data-aura-rendered-by="89:361;a">Bank of Baroda</option>
                            <option value="Bk of Cyprus" data-aura-rendered-by="91:361;a">Bank of Cyprus</option>
                            <option value="Bk of India" data-aura-rendered-by="93:361;a">Bank of India</option>
                            <option value="Bk of Maharashtra" data-aura-rendered-by="95:361;a">Bank of Maharashtra</option>
                            <option value="Bk of Muscat" data-aura-rendered-by="97:361;a">Bank of Muscat</option>
                            <option value="Bk of Newzealand" data-aura-rendered-by="99:361;a">Bank of Newzealand</option>
                            <option value="Bk Of Nova Scotia" data-aura-rendered-by="101:361;a">Bank Of Nova Scotia</option>
                            <option value="Bk Of Tokyo" data-aura-rendered-by="103:361;a">Bank Of Tokyo</option>
                            <option value="Bk Sohar" data-aura-rendered-by="105:361;a">Bank Sohar</option>
                            <option value="Baramati Sah Bk LTD" data-aura-rendered-by="107:361;a">Baramati Sahakari Bank LTD</option>
                            <option value="Barclays Bk Plc" data-aura-rendered-by="109:361;a">Barclays Bank Plc</option>
                            <option value="Bassein Catholic Co-op Bk" data-aura-rendered-by="111:361;a">Bassein Catholic Co-op Bank</option>
                            <option value="Belhasa Global Exc" data-aura-rendered-by="113:361;a">Belhasa Global Exchange</option>
                            <option value="Bharat Co-op Bk" data-aura-rendered-by="115:361;a">Bharat Co-operative Bank</option>
                            <option value="BNP PARIBAS" data-aura-rendered-by="117:361;a">BNP PARIBAS</option>
                            <option value="Bombay Mercantile Co-op Bk" data-aura-rendered-by="119:361;a">Bombay Mercantile Co-op Bank</option>
                            <option value="Bombay Merchantile Bk" data-aura-rendered-by="121:361;a">Bombay Merchantile Bank</option>
                            <option value="BULDHANA URBAN CO-OP BK" data-aura-rendered-by="123:361;a">BULDHANA URBAN CO-OP BANK</option>
                            <option value="Canara Bk" data-aura-rendered-by="125:361;a">Canara Bank</option>
                            <option value="Catholic Syrian Bk Ltd." data-aura-rendered-by="127:361;a">Catholic Syrian Bank Ltd.</option>
                            <option value="Central Bk of India" data-aura-rendered-by="129:361;a">Central Bank of India</option>
                            <option value="Chase Bk" data-aura-rendered-by="131:361;a">Chase Bank</option>
                            <option value="China Construction Bk" data-aura-rendered-by="133:361;a">China Construction Bank</option>
                            <option value="Citibank N.A." data-aura-rendered-by="135:361;a">Citibank N.A.</option>
                            <option value="Citizen Co-op Bk Ltd." data-aura-rendered-by="137:361;a">Citizen Co-operative Bank Ltd.</option>
                            <option value="Citizens Bk" data-aura-rendered-by="139:361;a">Citizens Bank</option>
                            <option value="City Exc LLC" data-aura-rendered-by="141:361;a">City Exchange LLC</option>
                            <option value="City Union Bk Ltd." data-aura-rendered-by="143:361;a">City Union Bank Ltd.</option>
                            <option value="Comm Bk International" data-aura-rendered-by="145:361;a">Commercial Bank International</option>
                            <option value="Commonwealth Bk" data-aura-rendered-by="147:361;a">Commonwealth Bank</option>
                            <option value="Compass Global Holdings" data-aura-rendered-by="149:361;a">Compass Global Holdings</option>
                            <option value="Corporation Bk" data-aura-rendered-by="151:361;a">Corporation Bank</option>
                            <option value="Cosmos Coop Bk" data-aura-rendered-by="153:361;a">Cosmos Cooperative Bank</option>
                            <option value="Crane Bk" data-aura-rendered-by="155:361;a">Crane Bank</option>
                            <option value="Credit Suisse" data-aura-rendered-by="157:361;a">Credit Suisse</option>
                            <option value="Daivadnya Sah Bk Niyamit" data-aura-rendered-by="159:361;a">Daivadnya Sahakari Bank Niyamit</option>
                            <option value="Dapoli Urban Co Op Bk Ltd" data-aura-rendered-by="161:361;a">Dapoli Urban Co Op Bank Ltd</option>
                            <option value="DBS Bk" data-aura-rendered-by="163:361;a">DBS Bank</option>
                            <option value="DCB Bk" data-aura-rendered-by="165:361;a">DCB Bank</option>
                            <option value="Deccan Grameen Bk" data-aura-rendered-by="167:361;a">Deccan Grameen Bank</option>
                            <option value="Dena Bk" data-aura-rendered-by="169:361;a">Dena Bank</option>
                            <option value="Deutsche Bk AG" data-aura-rendered-by="171:361;a">Deutsche Bank AG</option>
                            <option value="Development Credit Bk Ltd." data-aura-rendered-by="173:361;a">Development Credit Bank Ltd.</option>
                            <option value="DHANLAXMI BK LTD" data-aura-rendered-by="175:361;a">DHANLAXMI BANK LTD</option>
                            <option value="DHFL" data-aura-rendered-by="177:361;a">DHFL</option>
                            <option value="Diamond Trust Bk Kenya LTD, Kenya" data-aura-rendered-by="179:361;a">Diamond Trust Bank Kenya LTD, Kenya</option>
                            <option value="Discover Bank" data-aura-rendered-by="181:361;a">Discover Bank</option>
                            <option value="DMK JAOLI SAH BK LTD" data-aura-rendered-by="183:361;a">DMK JAOLI SAHAKARI BANK LTD</option>
                            <option value="Doha Bk" data-aura-rendered-by="185:361;a">Doha Bank</option>
                            <option value="DOLLARCO EXC CO LTD" data-aura-rendered-by="187:361;a">DOLLARCO EXCHANGE CO LTD</option>
                            <option value="Dombivali Nagarik Sah Bk" data-aura-rendered-by="189:361;a">Dombivali Nagarik Sahakari Bank</option>
                            <option value="Dyandeep Co op bk" data-aura-rendered-by="191:361;a">Dyandeep Co op bank</option>
                            <option value="Eastern Exc Est" data-aura-rendered-by="193:361;a">Eastern Exchange Est</option>
                            <option value="East West Bk" data-aura-rendered-by="195:361;a">East West Bank</option>
                            <option value="Economic Exch Centre, Dubai" data-aura-rendered-by="197:361;a">Economic Exchange Centre, Dubai</option>
                            <option value="Emirates India International Exch L.P.C" data-aura-rendered-by="199:361;a">Emirates India International Exchange L.P.C</option>
                            <option value="Emirates NBD bk" data-aura-rendered-by="201:361;a">Emirates NBD bank</option>
                            <option value="Equitas Small Finance Bank" data-aura-rendered-by="203:361;a">Equitas Small Finance Bank</option>
                            <option value="EXCELLENT CO OP BK LTD" data-aura-rendered-by="205:361;a">EXCELLENT CO OP BANK LTD</option>
                            <option value="EXIM Bk" data-aura-rendered-by="207:361;a">EXIM Bank</option>
                            <option value="FEDERAL BK" data-aura-rendered-by="209:361;a">FEDERAL BANK</option>
                            <option value="​Federal Bk Of Dwarka" data-aura-rendered-by="211:361;a">​Federal Bank Of Dwarka</option>
                            <option value="FirstRand Bk India LTD" data-aura-rendered-by="213:361;a">FirstRand Bank India Limited</option>
                            <option value="GCC Exc" data-aura-rendered-by="215:361;a">GCC Exchange</option>
                            <option value="​Gopinath Patil Janata Sah Bk" data-aura-rendered-by="217:361;a">​Gopinath Patil Janata Sahakari Bank</option>
                            <option value="GP Parsik Bk Ltd" data-aura-rendered-by="219:361;a">GP Parsik Bank Ltd</option>
                            <option value="GREATER BOMBAY CO.OP.BK LTD." data-aura-rendered-by="221:361;a">GREATER BOMBAY CO.OP.BANK LTD.</option>
                            <option value="Habib exc" data-aura-rendered-by="223:361;a">Habib exchange</option>
                            <option value="Hadi Exc" data-aura-rendered-by="225:361;a">Hadi Exchange</option>
                            <option value="Hindustan Co-op Bk Ltd" data-aura-rendered-by="227:361;a">Hindustan Co-operative Bank Limited</option>
                            <option value="HOME FIRST FINANCE COM INDIA PVT LTD" data-aura-rendered-by="229:361;a">HOME FIRST FINANCE COMPANY INDIA PRIVATE LIMITED</option>
                            <option value="HSBC Bk" data-aura-rendered-by="231:361;a">HSBC Bank</option>
                            <option value="I &amp; M bk Ltd" data-aura-rendered-by="233:361;a">I &amp; M bank Ltd</option>
                            <option value="ICICI Bk" data-aura-rendered-by="235:361;a">ICICI Bank</option>
                            <option value="IDBI Bk Ltd" data-aura-rendered-by="237:361;a">IDBI Bank Limited</option>
                            <option value="IDFC Bk" data-aura-rendered-by="239:361;a">IDFC Bank</option>
                            <option value="India Bulls Bk" data-aura-rendered-by="241:361;a">India Bulls Bank</option>
                            <option value="INDIA INFOLINE HSG FINANCE LTD" data-aura-rendered-by="243:361;a">INDIA INFOLINE HOUSING FINANCE LIMITED</option>
                            <option value="Indian Bk" data-aura-rendered-by="245:361;a">Indian Bank</option>
                            <option value="Indian Overseas Bk" data-aura-rendered-by="247:361;a">Indian Overseas Bank</option>
                            <option value="IndusInd Bank Ltd" data-aura-rendered-by="249:361;a">IndusInd Bank Limited</option>
                            <option value="Industrial Development Bk of India" data-aura-rendered-by="251:361;a">Industrial Development Bank of India</option>
                            <option value="ING Vysya Bk" data-aura-rendered-by="253:361;a">ING Vysya Bank</option>
                            <option value="Investec Bank Limited" data-aura-rendered-by="255:361;a">Investec Bank Limited</option>
                            <option value="Jai Vaibhav Laxmi Multi State Co.op Credit Soc Ltd" data-aura-rendered-by="257:361;a">Jai Vaibhav Laxmi Multi State Co.op Credit Society Ltd</option>
                            <option value="JALGOAN JAN SAH BK" data-aura-rendered-by="259:361;a">JALGOAN JANATA SAHAKARI BANK</option>
                            <option value="Jan Sah Bk" data-aura-rendered-by="261:361;a">Janakalyan Sahakari Bank</option>
                            <option value="Janaseva Sah Bk LTD" data-aura-rendered-by="263:361;a">Janaseva Sahakari Bank LTD</option>
                            <option value="Janta Sah Bk Ltd." data-aura-rendered-by="265:361;a">Janta Sahakari Bank Ltd.</option>
                            <option value="JK Bk Ltd" data-aura-rendered-by="267:361;a">JK Bank Ltd</option>
                            <option value="Joyalukkas Exc" data-aura-rendered-by="269:361;a">Joyalukkas Exchange</option>
                            <option value="J P Morgan Chase Bk, National Association" data-aura-rendered-by="271:361;a">J P Morgan Chase Bank, National Association</option>
                            <option value="Kallappanna Awade Ichalkaranji Jan Sah Bk Ltd" data-aura-rendered-by="273:361;a">Kallappanna Awade Ichalkaranji Janta Sahakari Bank Limited</option>
                            <option value="Kalyan Jan Sah Bk Ltd" data-aura-rendered-by="275:361;a">Kalyan Janata Sahakari Bank Ltd</option>
                            <option value="Kapol Co.Op Bk Ltd" data-aura-rendered-by="277:361;a">Kapol Co.Op Bank Ltd</option>
                            <option value="Karad Janata Sah Bk Ltd" data-aura-rendered-by="279:361;a">Karad Janata Sahakari Bank Ltd</option>
                            <option value="Karad Urban Co-op bk ltd" data-aura-rendered-by="281:361;a">Karad Urban Co-operative bank ltd</option>
                            <option value="Karnataka Bk" data-aura-rendered-by="283:361;a">Karnataka Bank</option>
                            <option value="Karur Vysya Bk Ltd." data-aura-rendered-by="285:361;a">Karur Vysya Bank Limited.</option>
                            <option value="Kokan Mercantile co-op bk" data-aura-rendered-by="287:361;a">Kokan Mercantile co-op bank</option>
                            <option value="Kotak Mahindra Bk Ltd" data-aura-rendered-by="289:361;a">Kotak Mahindra Bank Limited</option>
                            <option value="Kurla nagrik bk" data-aura-rendered-by="291:361;a">Kurla nagrik bank</option>
                            <option value="KUWAIT INDIA INTERNATIONAL EXC" data-aura-rendered-by="293:361;a">KUWAIT INDIA INTERNATIONAL EXCHANGE</option>
                            <option value="L&amp;T Housing Finance Ltd" data-aura-rendered-by="295:361;a">L&amp;T Housing Finance Ltd</option>
                            <option value="Lakshmi Vishnu Sah Bk Ltd" data-aura-rendered-by="297:361;a">Lakshmi Vishnu Sahakari Bank Ltd</option>
                            <option value="LARI EXC" data-aura-rendered-by="299:361;a">LARI EXCHANGE</option>
                            <option value="Latur Urban Bk" data-aura-rendered-by="301:361;a">Latur Urban Bank</option>
                            <option value="Laxmi Vilas Bk" data-aura-rendered-by="303:361;a">Laxmi Vilas Bank</option>
                            <option value="Lodhapay" data-aura-rendered-by="305:361;a">Lodhapay</option>
                            <option value="Lord Krishna Bk Ltd." data-aura-rendered-by="307:361;a">Lord Krishna Bank Ltd.</option>
                            <option value="LULU bk credit" data-aura-rendered-by="309:361;a">LULU bank credit</option>
                            <option value="Macquarie Bk Ltd" data-aura-rendered-by="311:361;a">Macquarie Bank Limited</option>
                            <option value="Mahanagar Bk" data-aura-rendered-by="313:361;a">Mahanagar Bank</option>
                            <option value="Maharashtra Bk" data-aura-rendered-by="315:361;a">Maharashtra Bank</option>
                            <option value="Maharashtra Grahmin Bk" data-aura-rendered-by="317:361;a">Maharashtra Grahmin Bank</option>
                            <option value="Mahesh Sah Bk Ltd" data-aura-rendered-by="319:361;a">Mahesh Sahakari Bank Ltd</option>
                            <option value="MASHREQ BK" data-aura-rendered-by="321:361;a">MASHREQ BANK</option>
                            <option value="Maybk" data-aura-rendered-by="323:361;a">Maybank</option>
                            <option value="Mehsana Urban Co-op Bk Ltd" data-aura-rendered-by="325:361;a">Mehsana Urban Co-op Bank Ltd</option>
                            <option value="Merrill Lynch" data-aura-rendered-by="327:361;a">Merrill Lynch</option>
                            <option value="Model Co-Op Bk Ltd" data-aura-rendered-by="329:361;a">Model Co-Op Bank Ltd</option>
                            <option value="Moga Veera Co- Op Bk" data-aura-rendered-by="331:361;a">Moga Veera Co- Op Bank</option>
                            <option value="Money Dart Global Services" data-aura-rendered-by="333:361;a">Money Dart Global Services</option>
                            <option value="Mumbai Citizen Credit Co op Bank" data-aura-rendered-by="335:361;a">Mumbai Citizen Credit Co op Bank</option>
                            <option value="Mumbai Dist Central Coop Bk" data-aura-rendered-by="337:361;a">Mumbai District Central Cooperative Bank</option>
                            <option value="Municipal Co-Op Bk LTD" data-aura-rendered-by="339:361;a">Municipal Co-Op Bank LTD</option>
                            <option value="Nagar Urban Co op Bk" data-aura-rendered-by="341:361;a">Nagar Urban Co operative Bank</option>
                            <option value="Nagpur Nagrik Sah Bk" data-aura-rendered-by="343:361;a">Nagpur Nagrik Sahakari Bank</option>
                            <option value="Nashik Merchant Co. Op. Bk Ltd" data-aura-rendered-by="345:361;a">Nashik Merchant Co. Op. Bank Ltd</option>
                            <option value="National Bk for Agriculture and Rural Development" data-aura-rendered-by="347:361;a">National Bank for Agriculture and Rural Development</option>
                            <option value="National Bk Of Abudhabi" data-aura-rendered-by="349:361;a">National Bank Of Abudhabi</option>
                            <option value="National Bk of Dubai" data-aura-rendered-by="351:361;a">National Bank of Dubai</option>
                            <option value="National Bk of Ras Al-Khaimah" data-aura-rendered-by="353:361;a">National Bank of Ras Al-Khaimah</option>
                            <option value="Natwest Bk" data-aura-rendered-by="355:361;a">Natwest Bank</option>
                            <option value="Naval Dockyard Cooperative Bank" data-aura-rendered-by="357:361;a">Naval Dockyard Cooperative Bank</option>
                            <option value="Navi Mum Co-op Bk" data-aura-rendered-by="359:361;a">Navi Mumbai Co-operative Bank</option>
                            <option value="Navjivan Co-op Bk" data-aura-rendered-by="361:361;a">Navjivan Co-op Bank</option>
                            <option value="New India Co-op Bk" data-aura-rendered-by="363:361;a">New India Co-operative Bank</option>
                            <option value="NKGSB Coop Bk Ltd" data-aura-rendered-by="365:361;a">NKGSB Cooperative Bank Ltd</option>
                            <option value="North Kanara Gaud Saraswat Bk" data-aura-rendered-by="367:361;a">North Kanara Gaud Saraswat Bank</option>
                            <option value="Oman Exc" data-aura-rendered-by="369:361;a">Oman Exchange</option>
                            <option value="Oman International Bk S A O G" data-aura-rendered-by="371:361;a">Oman International Bank S A O G</option>
                            <option value="Oriental Bk of Comm" data-aura-rendered-by="373:361;a">Oriental Bank of Commerce</option>
                            <option value="Orient exc" data-aura-rendered-by="375:361;a">Orient exchange</option>
                            <option value="Other" data-aura-rendered-by="377:361;a">Other</option>
                            <option value="Panchsheel Mercantile Co Op Bk" data-aura-rendered-by="379:361;a">Panchsheel Mercantile Co Op Bank</option>
                            <option value="Parner Taluka Sainik Sah Bk" data-aura-rendered-by="381:361;a">Parner Taluka Sainik Sahakari Bank</option>
                            <option value="Parshwanath Co Op Bk Ltd" data-aura-rendered-by="383:361;a">Parshwanath Co Op Bank Ltd</option>
                            <option value="Parsik Janta Sahkari Bank" data-aura-rendered-by="385:361;a">Parsik Janta Sahkari Bank</option>
                            <option value="PAVANA SAH BK LTD" data-aura-rendered-by="387:361;a">PAVANA SAHAKARI BANK LTD</option>
                            <option value="Piramal HSG Finance Ltd" data-aura-rendered-by="389:361;a">Piramal Housing Finance Limited</option>
                            <option value="PNB HSG Finance Ltd." data-aura-rendered-by="391:361;a">PNB Housing Finance Ltd.</option>
                            <option value="Post Office" data-aura-rendered-by="393:361;a">Post Office</option>
                            <option value="Pune Dist Central Coop Bk ltd" data-aura-rendered-by="395:361;a">Pune Dist Central Coop Bank ltd</option>
                            <option value="Pune Peoples Co-op Bk Ltd" data-aura-rendered-by="397:361;a">Pune Peoples Co-op Bank Ltd</option>
                            <option value="Punjab &amp; Maharashtra Co-op Bk" data-aura-rendered-by="399:361;a">Punjab &amp; Maharashtra Co-operative Bank</option>
                            <option value="Punjab &amp; Sind Bk" data-aura-rendered-by="401:361;a">Punjab &amp; Sind Bank</option>
                            <option value="Punjab National Bk" data-aura-rendered-by="403:361;a">Punjab National Bank</option>
                            <option value="Qatar National Bk" data-aura-rendered-by="405:361;a">Qatar National Bank</option>
                            <option value="RAJAPUR BK" data-aura-rendered-by="407:361;a">RAJAPUR BANK</option>
                            <option value="RAJARAMBAPU SAH BK" data-aura-rendered-by="409:361;a">RAJARAMBAPU SAHAKARI BANK</option>
                            <option value="Rajgurunagar Co-op Bk Ltd" data-aura-rendered-by="411:361;a">Rajgurunagar Co-op Bank Ltd</option>
                            <option value="Rajkot Nagrik Sah. Bk LTD" data-aura-rendered-by="413:361;a">Rajkot Nagrik Sah. Bank LTD</option>
                            <option value="RAK BK" data-aura-rendered-by="415:361;a">RAK BANK</option>
                            <option value="Rational FX – Foreign Exc Ltd" data-aura-rendered-by="417:361;a">Rational FX – Foreign Exchange Ltd</option>
                            <option value="Ratnakar Bk" data-aura-rendered-by="419:361;a">Ratnakar Bank</option>
                            <option value="RBL Bk" data-aura-rendered-by="421:361;a">RBL Bank</option>
                            <option value="Regions Bk" data-aura-rendered-by="423:361;a">Regions Bank</option>
                            <option value="Reserve Bk Of India" data-aura-rendered-by="425:361;a">Reserve Bank Of India</option>
                            <option value="Royal Bk Of Canada" data-aura-rendered-by="427:361;a">Royal Bank Of Canada</option>
                            <option value="SABB" data-aura-rendered-by="429:361;a">SABB</option>
                            <option value="Sahebrao Deshmukh Bk" data-aura-rendered-by="431:361;a">Sahebrao Deshmukh Bank</option>
                            <option value="Sangli Co-Op Bk" data-aura-rendered-by="433:361;a">Sangli Co-Op Bank</option>
                            <option value="Saraswat Bk" data-aura-rendered-by="435:361;a">Saraswat Bank</option>
                            <option value="Sarvodaya Bk" data-aura-rendered-by="437:361;a">Sarvodaya Bank</option>
                            <option value="SAWAI MADHOPUR KENDRIYA SAH BK LTD" data-aura-rendered-by="439:361;a">SAWAI MADHOPUR KENDRIYA SAHAKARI BANK LTD</option>
                            <option value="SBI Comm and International Bk Ltd." data-aura-rendered-by="441:361;a">SBI Commercial and International Bank Ltd.</option>
                            <option value="SCOTIA BK" data-aura-rendered-by="443:361;a">SCOTIA BANK</option>
                            <option value="Shamrao Vithal Bk" data-aura-rendered-by="445:361;a">Shamrao Vithal Bank</option>
                            <option value="Shankarrao Mohite Patil Sah Bk" data-aura-rendered-by="447:361;a">Shankarrao Mohite Patil Sahkari Bank</option>
                            <option value="Sharjah Islamic Bk" data-aura-rendered-by="449:361;a">Sharjah Islamic Bank</option>
                            <option value="Shinhan Bk" data-aura-rendered-by="451:361;a">Shinhan Bank</option>
                            <option value="Shree Durgaparameshwari Co-op Credit Soc Ltd" data-aura-rendered-by="453:361;a">Shree Durgaparameshwari Co-operative Credit Society Ltd</option>
                            <option value="Shree Laxmi Co-Op Bk LTD" data-aura-rendered-by="455:361;a">Shree Laxmi Co-Op Bank LTD</option>
                            <option value="Shri Arihant Co-op Bk Ltd" data-aura-rendered-by="457:361;a">Shri Arihant Co-op Bank Ltd</option>
                            <option value="Shri Chhatrapati Rajashri Shahu Urban Co-Op. Bk LTD" data-aura-rendered-by="459:361;a">Shri Chhatrapati Rajashri Shahu Urban Co-Op. Bank LTD</option>
                            <option value="Shri Warna co -op bk." data-aura-rendered-by="461:361;a">Shri Warna co -op bank.</option>
                            <option value="SINDHUDURGA CO OP BK LTD" data-aura-rendered-by="463:361;a">SINDHUDURGA CO OPERAT VE BANK LTD</option>
                            <option value="Social Islami Bk" data-aura-rendered-by="465:361;a">Social Islami Bank</option>
                            <option value="Solapur Jan Sah Bk Ltd" data-aura-rendered-by="467:361;a">Solapur Janta Sahakari Bank Ltd</option>
                            <option value="South Indian Bk" data-aura-rendered-by="469:361;a">South Indian Bank</option>
                            <option value="Stand Chart Bk" data-aura-rendered-by="471:361;a">Standard Chartered Bank</option>
                            <option value="S Bk of Bikaner and Jaipur" data-aura-rendered-by="473:361;a">State Bank of Bikaner and Jaipur</option>
                            <option value="S B of Hyd" data-aura-rendered-by="475:361;a">State Bank of Hyderabad</option>
                            <option value="S B I" data-aura-rendered-by="477:361;a">State Bank of India</option>
                            <option value="State Bk of Mauritius Ltd." data-aura-rendered-by="479:361;a">State Bank of Mauritius Ltd.</option>
                            <option value="State Bk of Mysore" data-aura-rendered-by="481:361;a">State Bank of Mysore</option>
                            <option value="State Bk of Patiala" data-aura-rendered-by="483:361;a">State Bank of Patiala</option>
                            <option value="State Bk of Travancore" data-aura-rendered-by="485:361;a">State Bank of Travancore</option>
                            <option value="State Transport Co op Bk Ltd" data-aura-rendered-by="487:361;a">State Transport Co op Bank Ltd</option>
                            <option value="Suncorp Bk" data-aura-rendered-by="489:361;a">Suncorp Bank</option>
                            <option value="Syndicate Bk" data-aura-rendered-by="491:361;a">Syndicate Bank</option>
                            <option value="Tamilnad Mercantile Bk Ltd." data-aura-rendered-by="493:361;a">Tamilnad Mercantile Bank Ltd.</option>
                            <option value="TATA CAPITAL HSG" data-aura-rendered-by="495:361;a">TATA CAPITAL HOUSING</option>
                            <option value="TD Bk" data-aura-rendered-by="497:361;a">TD Bank</option>
                            <option value="Techcombank Vietnam" data-aura-rendered-by="499:361;a">Techcombank Vietnam</option>
                            <option value="Thane Bharat Sah Bk Ltd" data-aura-rendered-by="501:361;a">Thane Bharat Sahakari Bank Ltd</option>
                            <option value="Thane Jan Sah Bk" data-aura-rendered-by="503:361;a">Thane Janata Sahakari Bank</option>
                            <option value="The A.P Mahesh Co-op Urban Bk Ltd" data-aura-rendered-by="505:361;a">The A.P Mahesh Co-operative Urban Bank Ltd</option>
                            <option value="The Abhinav Sah Bk Ltd" data-aura-rendered-by="507:361;a">The Abhinav Sahakari Bank Ltd</option>
                            <option value="The Akola Jan Comm Co-op Bk Ltd" data-aura-rendered-by="509:361;a">The Akola Janata Commercial Co-operative Bank Ltd</option>
                            <option value="The Annasaheb Savant Co-op Urban Bank Mahad Ltd" data-aura-rendered-by="511:361;a">The Annasaheb Savant Co-op Urban Bank Mahad Ltd</option>
                            <option value="THE ANNASAHEB SAVANT COOP URB BK MA" data-aura-rendered-by="513:361;a">THE ANNASAHEB SAVANT COOP URB BK MA</option>
                            <option value="The Bk of Rajasthan Ltd" data-aura-rendered-by="515:361;a">The Bank of Rajasthan Limited</option>
                            <option value="The Chembur Nag Sah Bk Ltd" data-aura-rendered-by="517:361;a">The Chembur Nagarik Sahakari Bank Ltd</option>
                            <option value="The Deccan Merchants Co-op Bk Ltd" data-aura-rendered-by="519:361;a">The Deccan Merchants Co-operative Bank Ltd</option>
                            <option value="The Development Bk Of Singapore Ltd" data-aura-rendered-by="521:361;a">The Development Bank Of Singapore Limited</option>
                            <option value="The Dhanalakshmi Bk Ltd." data-aura-rendered-by="523:361;a">The Dhanalakshmi Bank Limited.</option>
                            <option value="The Federal Bk Ltd." data-aura-rendered-by="525:361;a">The Federal Bank Ltd.</option>
                            <option value="THE GOA URBAN CO OP BK" data-aura-rendered-by="527:361;a">THE GOA URBAN CO OP BANK</option>
                            <option value="The HDFC Bk Ltd." data-aura-rendered-by="529:361;a">HDFC Bank Ltd.</option>
                            <option value="The HSBC Ltd." data-aura-rendered-by="531:361;a">The Hongkong &amp; Shanghai Banking Corporation Ltd.</option>
                            <option value="The Income Tax Dept. Co-op. Bk Ltd" data-aura-rendered-by="533:361;a">The Income Tax Dept. Co-op. Bank Ltd</option>
                            <option value="The Jain Sah Bk Ltd" data-aura-rendered-by="535:361;a">The Jain Sahakari Bank Ltd</option>
                            <option value="The Jalgaon Peoples Co-Op Bk Ltd" data-aura-rendered-by="537:361;a">The Jalgaon Peoples Co-Op Bank Ltd</option>
                            <option value="The Jammu &amp; Kashmir Bk Ltd." data-aura-rendered-by="539:361;a">The Jammu &amp; Kashmir Bank Ltd.</option>
                            <option value="THE KALUPUR COMM CO. OP. BK LTD." data-aura-rendered-by="541:361;a">THE KALUPUR COMMERCIAL CO. OP. BANK LTD.</option>
                            <option value="The Kalyan Jan Sah Bk Ltd" data-aura-rendered-by="543:361;a">The Kalyan Janata Sahakari Bank Ltd</option>
                            <option value="The Kurla Nag Sah Bk" data-aura-rendered-by="545:361;a">The Kurla Nagrik Sahakari Bank</option>
                            <option value="The Lakshmi Vilas Bk Ltd" data-aura-rendered-by="547:361;a">The Lakshmi Vilas Bank Ltd</option>
                            <option value="The Maharashtra State Co-op. Bk Ltd" data-aura-rendered-by="549:361;a">The Maharashtra State Co-op. Bank Ltd</option>
                            <option value="The Maharashtra State Co-op Bk Ltd" data-aura-rendered-by="551:361;a">The Maharashtra State Co-operative Bank Ltd</option>
                            <option value="The Naroda Nagrik Co-op Bank Limited" data-aura-rendered-by="553:361;a">The Naroda Nagrik Co-op Bank Limited</option>
                            <option value="The National Co-op bk ltd" data-aura-rendered-by="555:361;a">The National Co-operative bank limited</option>
                            <option value="The Naval Dockyard Co-op Bk Ltd" data-aura-rendered-by="557:361;a">The Naval Dockyard Co-op Bank Ltd</option>
                            <option value="The Ratnakar Bk Ltd." data-aura-rendered-by="559:361;a">The Ratnakar Bank Ltd.</option>
                            <option value="The Royal Bk of Scotland N.V." data-aura-rendered-by="561:361;a">The Royal Bank of Scotland N.V.</option>
                            <option value="The Satara Sah Bk Ltd" data-aura-rendered-by="563:361;a">The Satara Sahkari Bank Ltd</option>
                            <option value="The Seva Vikas Co-op bk Ltd" data-aura-rendered-by="565:361;a">The Seva Vikas Co-operative bank Ltd</option>
                            <option value="The South Indian Bk Ltd." data-aura-rendered-by="567:361;a">The South Indian Bank Ltd.</option>
                            <option value="The Surat People's Co-op Bk Ltd" data-aura-rendered-by="569:361;a">The Surat People's Co-op Bank Ltd</option>
                            <option value="The Thane Dist Central Co-Op Bk LTD" data-aura-rendered-by="571:361;a">The Thane District Central Co-Op Bank LTD</option>
                            <option value="The Vasai Jan Sah Bk Ltd" data-aura-rendered-by="573:361;a">The Vasai Janata Sahakari Bank Ltd</option>
                            <option value="TIMES OF MONEY PVT LTD" data-aura-rendered-by="575:361;a">TIMES OF MONEY PVT LTD</option>
                            <option value="TIRUPATI URBAN COOP BK" data-aura-rendered-by="577:361;a">TIRUPATI URBAN COOPERATIVE BANK</option>
                            <option value="UAE Exc Centre LLC" data-aura-rendered-by="579:361;a">UAE Exchange Centre LLC</option>
                            <option value="UCO Bk" data-aura-rendered-by="581:361;a">UCO Bank</option>
                            <option value="Ujjivan Small Finance Bank" data-aura-rendered-by="583:361;a">Ujjivan Small Finance Bank</option>
                            <option value="Union Bk of India" data-aura-rendered-by="585:361;a">Union Bank of India</option>
                            <option value="Union Bk of Trichur" data-aura-rendered-by="587:361;a">Union Bank of Trichur</option>
                            <option value="United Arab Bk" data-aura-rendered-by="589:361;a">United Arab Bank</option>
                            <option value="United Bk Of India" data-aura-rendered-by="591:361;a">United Bank Of India</option>
                            <option value="Unit Trust of India" data-aura-rendered-by="593:361;a">Unit Trust of India</option>
                            <option value="Unity Small Finance Bank" data-aura-rendered-by="595:361;a">Unity Small Finance Bank</option>
                            <option value="Vaidyanath Bk" data-aura-rendered-by="597:361;a">Vaidyanath Bank</option>
                            <option value="Vasai Vikas Sah Bk Ltd" data-aura-rendered-by="599:361;a">Vasai Vikas Sahakari Bank Ltd</option>
                            <option value="Vijaya Bk" data-aura-rendered-by="601:361;a">Vijaya Bank</option>
                            <option value="Vishwas Co-op Bk Ltd" data-aura-rendered-by="603:361;a">Vishwas Co-op Bank Ltd</option>
                            <option value="VYSVSAYIK SAH BK LTD." data-aura-rendered-by="605:361;a">VYSVSAYIK SAHAKARI BANK LIMITED.</option>
                            <option value="Wall Street Exc" data-aura-rendered-by="607:361;a">Wall Street Exchange</option>
                            <option value="Wardhaman Urban Co-Op Bk Ltd" data-aura-rendered-by="609:361;a">Wardhaman Urban Co-Operation Bank Ltd</option>
                            <option value="Wells Fargo Bk" data-aura-rendered-by="611:361;a">Wells Fargo Bank</option>
                            <option value="Western Union Money Transfer Exc" data-aura-rendered-by="613:361;a">Western Union Money Transfer Exchange</option>
                            <option value="WESTPAC BANKING CO-OP" data-aura-rendered-by="615:361;a">WESTPAC BANKING CORPORATION</option>
                            <option value="Yes Bk" data-aura-rendered-by="617:361;a">Yes Bank</option>
                            <option value="ZORASTRIAN BK" data-aura-rendered-by="619:361;a">ZORASTRIAN BANK</option>
                            <option value="IDFC FIRST BANK LIMITED" data-aura-rendered-by="621:361;a">IDFC FIRST BANK LIMITED</option>

                        </select>
                    </div>
                    <div>
                        <label htmlFor="account_holder_name">Account Holder Name</label>
                        <input type="text" className='form-control' name="account_holder_name" id="account_holder_name" />
                    </div>
                    <div>
                        <label htmlFor="destination_bank_account_number">Destination Bank Account Number</label>
                        <input type="text" className='form-control' name="destination_bank_account_number" id="destination_bank_account_number" />
                    </div>
                    <div>
                        <label htmlFor="destination_bank_name">Destination Bank Name</label>
                        <input type="text" className='form-control' name="destination_bank_name" id="destination_bank_name" />
                    </div>
                </>;
        }

    }
    const submitHandler=(e)=>{
        e.preventDefault();
        const formData=new FormData(e.target);
        dispatch(addCustomerPayment(formData,navigate))
    }
  return (
    <Layout pageTitle={"Add Payment"}>
      <div className="p-2">
        <form  onSubmit={submitHandler}  >
            <input type="text" name="booking_id" id="" value={currentBookingId}  hidden />
            <div className="gap-2 d-grid">
                <div>
                    <label htmlFor="payment_mode">Payment Mode</label>
                          <select name="payment_mode" className='form-control' id="payment_mode" onChange={(e)=>setPaymentMode(e.target.value)}>
                        {
                            ["Cash", "Cheque", "Debit/Credit Card", "Wire Transfer"].map((paymentMode)=>{
                                return <option value={paymentMode}>{paymentMode}</option>
                            })
                        }
                    </select>
                </div>
                      {<PaymentModeField paymentMode={paymentMode} />}
                <div>
                    <label htmlFor="amount">Amount</label>
                    <input type="number"  name="amount" className='form-control' />
                </div>
                <div>
                    <label htmlFor="file">Upload File</label>
                    <input type="file" name="file" className='form-control' />
                </div>
                <div className="d-flex justify-content-end gap-2">
                    <button className="btn btn-success">Save</button>
                          <button
                              className="btn btn-dark"
                              type='button'
                              onClick={() => {
                                  navigate(-1);
                              }}
                          >
                              Cancel
                          </button>
                </div>
            </div>
        </form>
      </div>
    </Layout>
  )
}

export default AddPayment
